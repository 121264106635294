import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForwardSpecialRequestMutation } from "services/specialRequest.services";
import { AutocompleteAgentsByRole } from "../../../components/shared";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { autoCompleteSchema } from "utils/sharedSchema";
import * as yup from "yup";

const schema = yup.object().shape({
  agent: autoCompleteSchema.default(null),
});

const DialogForwardRequest = ({ data, refetch }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();

  const [forwardSpecialRequest, { isLoading }] =
    useForwardSpecialRequestMutation();

  const handleCancel = () => {
    onClose();
    reset({
      agent: null,
    });
  };

  const handleForward = async (values) => {
    const payload = {
      id: data?.id,
      agentID: values.agent.value,
    };

    try {
      await forwardSpecialRequest(payload).unwrap();

      toast({
        title: "Data Updated",
        description: "forward special request successfully.",
        position: "top-right",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      refetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        isDisabled={data?.statusByAdmin === "hold"}
      >
        Forward
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg">Forward Request</AlertDialogHeader>
          <AlertDialogCloseButton onClick={handleCancel} />
          <AlertDialogBody>
            <AutocompleteAgentsByRole
              name="agent"
              label="Website Agent"
              placeholder="Select Website Agent"
              control={control}
              role="website_agent"
              isRequired
            />
            <Text mt="2" fontSize="sm" color="gray.500">
              This submitted special request will be sent to the selected
              Website Agent to process it.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={handleCancel} size="sm">
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              ml={3}
              size="sm"
              onClick={handleSubmit(handleForward)}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DialogForwardRequest;
