import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchFeature,
  deleteFeature,
} from "../../../../services/featuresProperty.service";
import { HStack, useToast } from "@chakra-ui/react";
import { TableV1 } from "../../../../components/table";
import { ConfirmDelete } from "../../../../components/alert";
import { IconCell } from "../../../../components/cell";
import PopupEdit from "./PopupEdit";

const TypesTable = () => {
  const toast = useToast();
  const toastIdRef = React.useRef();

  const dispatch = useDispatch();
  const { meta, features, loading, loadingDelete } = useSelector(
    (state) => state?.featuresProperty,
  );

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchFeature());
    };

    fetchData().catch(console.error);
  }, [dispatch]);

  const closeToastDeleting = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };

  const handleDelete = async (data) => {
    toastIdRef.current = toast({
      description: `Deleting '${data.data.name}' feature`,
      status: "info",
      position: "bottom-right",
    });
    await dispatch(deleteFeature(data.data.id));
    closeToastDeleting();
    dispatch(fetchFeature());
  };

  const handlePagination = (offset) => {
    const payload = {
      offset,
    };
    dispatch(fetchFeature(payload));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Icon",
        accessor: "icon",
        Cell: IconCell,
      },
      {
        Header: "Feature",
        accessor: "feature",
      },
      {
        Header: "Actions",
        accessor: "actions",
        isNumeric: true,
      },
    ],
    [],
  );

  const RenderAction = (data) => {
    return (
      <HStack justifyContent="flex-end">
        <PopupEdit data={data} />
        <ConfirmDelete
          iconOnly
          headerText={`Delete '${data.data.name}' Feature`}
          bodyText="Are you sure? You can't undo this action afterwards."
          eventClick={() => handleDelete(data)}
        />
      </HStack>
    );
  };

  const list = features.map((item) => {
    return {
      feature: item.name,
      icon: item.icon,
      actions: <RenderAction data={item} />,
    };
  });

  const data = React.useMemo(() => list, [features, loadingDelete]);

  return (
    <>
      <TableV1
        columns={columns}
        data={data}
        isLoading={loading}
        meta={meta}
        handlePagination={handlePagination}
      />
    </>
  );
};

export default TypesTable;
