import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "@chakra-ui/react";

const AvatarCell = ({ value }) => {
  return <Avatar size="sm" src={value} alt="avatar" />;
};

AvatarCell.propTypes = {
  value: PropTypes.string
};

export default AvatarCell;
