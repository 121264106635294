import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ImageUpload } from "../../../components/field";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../services/auth.service";
import { upload } from "../../../services/agents.service";

const ModalUpdateAvatar = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { me, loadingUpdateProfile } = useSelector((state) => state?.auth);
  const { loadingUpload } = useSelector((state) => state?.agents);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      imageURL: me?.imageURL,
    },
  });

  useEffect(() => {
    const initValue = {
      imageURL: me?.imageURL,
    };
    reset(initValue);
  }, [isOpen]);

  const handleCallback = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      data: file,
    };
    const res = await dispatch(upload(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData(res.payload.imageURL);

      // save thumbnail image
      setValue("thumbnailImageURL", res.payload.thumbnailImageURL);
    }
  };

  const onUpdate = async (values) => {
    await dispatch(updateProfile(values));
    onClose();

    toast({
      title: "Profile Picture Updated",
      description: "Profile picture updated successfully.",
      position: "top-right",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  return (
    <>
      <Button size="sm" onClick={onOpen}>
        Change Picture
      </Button>

      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid spacing="6">
              <ImageUpload
                name="imageURL"
                control={control}
                buttonUploadText="Change Picture"
                callback={handleCallback}
                isAvatar
              />
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleSubmit(onUpdate)}
              isLoading={loadingUpdateProfile}
              isDisabled={loadingUpload}
            >
              {loadingUpload ? "Uploading..." : "Update"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalUpdateAvatar;
