import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { TableV1 } from "../../../components/table";
import {
  StatusCell,
  RoleCell,
  AvatarCell,
  CombineCell
} from "../../../components/cell";

const AgentsTable = ({
  data = [],
  isLoading,
  meta,
  onPagination = () => {}
}) => {
  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "Agent Name",
        accessor: "name",
        Cell(props) {
          return (
            <CombineCell
              {...props}
              accessor={["name", "username"]}
              subText="username"
            />
          );
        },
        minWidth: 260
      },
      { Header: "Image", accessor: "image", Cell: AvatarCell },
      { Header: "City", accessor: "city" },
      { Header: "Category", accessor: "role", Cell: RoleCell },
      { Header: "Email", accessor: "email" },
      { Header: "Phone Number", accessor: "phoneNumber" },
      { Header: "Status", accessor: "status", Cell: StatusCell }
    ],
    []
  );

  const dataMemoized = React.useMemo(
    () =>
      data.map(item => {
        return {
          id: item.uid,
          username: item.username,
          name: item.displayName,
          image: item.imageURL,
          city: item.city?.label || item.city,
          role: item.role,
          phoneNumber: item.phoneNumber,
          email: item.email,
          status: item.status
        };
      }),
    [data]
  );

  const handleRowClick = row => {
    history.push(`/agent/${row.original.id}`);
  };

  return (
    <TableV1
      columns={columns}
      data={dataMemoized}
      isLoading={isLoading}
      meta={meta}
      handlePagination={onPagination}
      eventRowClick={handleRowClick}
    />
  );
};

AgentsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  loadingDelete: PropTypes.bool,
  meta: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onPagination: PropTypes.func
};

export default AgentsTable;
