import React, { useState } from 'react';
import Paper from 'components/paper';
import { InputField, PasswordField, PhoneField } from 'components/field';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { phoneSchema, stringSchema } from 'utils/sharedSchema';
import { Button, Flex, Stack, useToast, VStack } from '@chakra-ui/react';
import {
  nextStep,
  prevStep,
  selectForm,
  updateForm,
} from '../../../../redux/registerStepSlice';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../../../config/messages';
import { checkUsername } from '../../../../services/agents.service';
import { register } from '../../../../services/auth.service';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  displayName: stringSchema,
  username: yup
    .string()
    .min(8, 'Characters limit allowed 8-50')
    .max(50, 'Characters limit allowed 8-50')
    .required('Username is required')
    .matches(
      /^\s*([0-9a-zA-Z]+)\s*$/,
      'Allowed English letters and numbers only'
    ),
  email: yup
    .string()
    .email('Please enter a valid email format')
    .required(messages.required),
  phoneNumber: phoneSchema,
  password: yup.string().required('Password is required'),
  // .matches(
  //   /[a-zA-Z0-9]{6}[0-9]{3}/,
  //   "Allowed characters 6 letters and 3 digits"
  // ),
  confirmPassword: yup
    .string()
    .required(messages.required)
    .oneOf([yup.ref('password'), null], "Passwords don't match"),
});

const Step3 = () => {
  const { t, i18n } = useTranslation('register');
  const dispatch = useDispatch();
  const toast = useToast();
  const form = useSelector(selectForm);
  const [isLoading, setIsLoading] = useState(false);

  // const { loading } = useSelector((state) => state?.auth);
  const { loadingUsername } = useSelector((state) => state?.agents);

  const { control, handleSubmit, getValues, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      displayName: form.displayName || '',
      companyName: form.companyName || '',
      phoneNumber: form.phoneNumber || '',
      username: form.username || '',
      email: form.email || '',
      password: form.password || '',
      confirmPassword: form.confirmPassword || '',
    },
  });

  const handlePrev = () => {
    const values = getValues();

    // Update form to the redux
    dispatch(
      updateForm({
        ...form,
        ...values,
      })
    );

    dispatch(prevStep());
  };

  const onSubmit = async (values) => {
    // Update form to the redux
    dispatch(
      updateForm({
        ...form,
        ...values,
      })
    );

    const payload = {
      ...form,
      ...values,

      // to known if account is created by self, if not will send email generated account
      createdBy: 'himSelf',

      // add user language
      language: i18n.language,
    };

    if (form.role === 'personal') {
      delete payload.advertiserID;
    }

    // Check username
    const resUsername = await dispatch(
      checkUsername({ username: values.username })
    );

    // register user with available username
    if (resUsername.meta.requestStatus === 'fulfilled') {
      setIsLoading(true);
      const res = await dispatch(register(payload));

      setIsLoading(false);

      if (res.meta.requestStatus === 'fulfilled') {
        reset();
        dispatch(nextStep());

        toast({
          title: 'Account created.',
          position: 'top-right',
          description:
            "We've send link email verification to your email address.",
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper
        p={{ base: 6, md: 8, lg: 10 }}
        boxShadow="lg"
        mt="12"
        borderRadius="2xl"
      >
        <VStack spacing="6" align="start">
          <InputField
            name="displayName"
            label={t('register:full_name')}
            control={control}
            isRequired
          />
          <Stack direction={['column', 'row']} w="full" spacing="6">
            <InputField
              name="companyName"
              label={t('register:company_name')}
              control={control}
            />
            <PhoneField
              name="phoneNumber"
              label={t('register:phone_number')}
              control={control}
            />
          </Stack>

          {form.role !== 'service_office_provider' && (
            <InputField
              name="username"
              label={t('register:username')}
              control={control}
              isRequired
            />
          )}

          <InputField
            name="email"
            type="email"
            label={t('register:email_address')}
            control={control}
            isRequired
          />
          {form.role !== 'service_office_provider' && (
            <Stack direction={['column', 'row']} w="full" spacing="6">
              <PasswordField
                name="password"
                label={t('register:password')}
                control={control}
                isRequired
              />
              <PasswordField
                name="confirmPassword"
                label={t('register:confirm_password')}
                control={control}
                isRequired
              />
            </Stack>
          )}

          <Flex width="100%" justify="flex-end">
            <Button mr={4} onClick={handlePrev}>
              {t('register:back')}
            </Button>
            <Button
              type="submit"
              colorScheme="primary"
              isLoading={isLoading || loadingUsername}
            >
              {t('register:create_my_account')}
            </Button>
          </Flex>
        </VStack>
      </Paper>
    </form>
  );
};

export default Step3;
