import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
import getUserToken from '../utils/getUserToken';

const { toast } = createStandaloneToast();

const timeOut = 9000;
export let baseUrl;

if (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1'
) {
  baseUrl = 'http://127.0.0.1:5001/p300-e2855/me-central1/api';
  // baseUrl = 'https://me-central1-p300-e2855.cloudfunctions.net/api';
  // baseUrl = "https://us-central1-hub330-fc809.cloudfunctions.net/api";
} else {
  baseUrl = 'https://me-central1-p300-e2855.cloudfunctions.net/api';
}

const prop = {
  status: 'error',
  position: 'top-right',
  isClosable: true,
};

const errorHandler = async (error) => {
  // id for preventing duplicate toast
  const id = 'error-toast';

  if (error.response) {
    if (error.response.status === 401) {
      toast({
        title: 'Session Expired',
        description: 'You will redirected to login page.',
        duration: 2000,
        onCloseComplete: () => {
          window.location = '/auth/logout';
        },
        ...prop,
      });

      return Promise.reject({ ...error });
    }

    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    // const {message, data} = error.response.data;
    const { message } = error.response.data;

    toast({
      // dir: "rtl",
      title: 'An error occurred.',
      // description: `${data ? data.errorMsg_EN : message}`,
      description: `${message}`,
      duration: timeOut,
      ...prop,
    });
  } else {
    // Something happened in setting up the request and triggered an Error
    if (!toast.isActive(id)) {
      toast({
        id,
        title: 'An unexpected Error',
        description: `${error.message}`,
        duration: timeOut,
        ...prop,
      });
    }
  }

  return Promise.reject({ ...error });
};

const successHandler = (response) => {
  return response;
};

// Init Axios
const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getUserToken();

    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => {
    Promise.reject(error).then((r) => console.error(r));
  }
);

axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

export { axiosInstance };
