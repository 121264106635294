import React from 'react';
import Layout, { LayoutBody } from 'components/layout/client';
import {
  Box,
  Text,
  Container,
  Icon,
  HStack,
  VStack,
  Image,
  SimpleGrid,
} from '@chakra-ui/react';
import { FaRegEnvelope, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ContactUsPage = () => {
  const { t } = useTranslation('contactUs');

  return (
    <Layout>
      <LayoutBody>
        <Container maxW={'container.lg'}>
          <Box>
            <Text fontSize="3xl" fontWeight="bold" mb="8">
              {t('contactUs:title')}
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10}>
            <Box>
              <Text mb="8" fontSize="lg">
                {t('contactUs:content')}
              </Text>

              <VStack align="start">
                <Text>{t('contactUs:project')}</Text>

                <HStack>
                  <Icon as={FaMapMarkerAlt} />
                  <Text>{t('contactUs:location')}</Text>
                </HStack>

                <HStack>
                  <Icon as={FaPhoneAlt} />
                  <Text>{t('contactUs:phone')}</Text>
                </HStack>

                <HStack>
                  <Icon as={FaRegEnvelope} />
                  <Text>{t('contactUs:email')}</Text>
                </HStack>
              </VStack>
            </Box>
            <Box>
              <Image
                ignoreFallback
                src={process.env.PUBLIC_URL + '/illustration/contact.svg'}
                boxSize="380"
              />
            </Box>
          </SimpleGrid>
        </Container>
      </LayoutBody>
    </Layout>
  );
};

export default ContactUsPage;
