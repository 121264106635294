import React, { useImperativeHandle, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import ConfirmationImage from "./confirmation.svg";
import { useConfirm } from "hooks";

const ModalBylaw = () => {
  const { prompt = "", isOpen = false, proceed, cancel } = useConfirm();

  return (
    <Modal isOpen={isOpen} onClose={cancel} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalBody>
          <Flex justify="center">
            <Image src={ConfirmationImage} w="250px" ignoreFallback />
          </Flex>

          <Text fontSize="lg" mt="8" align="center">
            I admit that all entered data is correct and according to the bylaw
            of the real estate advertisements. Bylaw
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={cancel}>
            No
          </Button>
          <Button colorScheme="primary" onClick={proceed}>
            Yes, sure
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBylaw;
