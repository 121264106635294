import { createSlice } from "@reduxjs/toolkit";

import {
  checkUsername,
  fetchAgents,
  fetchAutocompleteAgentsByRole,
  fetchAutocompleteAgents,
  fetchAgentById,
  createAgent,
  updateAgent,
  deleteAgent,
  upload
} from "../services/agents.service";

const initialState = {
  agents: [],
  agent: null,
  autocomplete: [],
  autocompleteByRole: [],
  meta: {
    nextOffset: 0,
    prevOffset: 0,
    hasNext: false,
    hasPrev: false,
    totalRecords: 0
  },
  loading: false,
  loadingUsername: false,
  loadingAutocomplete: false,
  loadingAutocompleteByRole: false,
  loadingCreate: false,
  loadingDelete: false,
  loadingUpdate: false,
  loadingUpload: false,
  error: null
};

const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // FETCH AUTOCOMPLETE BY ROLE TODO: REMOVE THIS AND USE INDIVIDUAL AUTOCOMPLETE COMPONENT IN SHARED
    builder.addCase(fetchAutocompleteAgentsByRole.pending, (state) => {
      state.loadingAutocompleteByRole = true;
    })

    builder.addCase(fetchAutocompleteAgentsByRole.fulfilled, (state, action) => {
      state.loadingAutocompleteByRole = false;
      const { data } = action.payload;
      state.autocompleteByRole = data;
    })

    builder.addCase(fetchAutocompleteAgentsByRole.rejected, (state, action) => {
      state.loadingAutocompleteByRole = false;
      state.error = action.payload;
    })

    // FETCH AUTOCOMPLETE
    builder.addCase(fetchAutocompleteAgents.pending, (state, action) => {
      state.loadingAutocomplete = true;
    })

    builder.addCase(fetchAutocompleteAgents.fulfilled, (state, action) => {
      state.loadingAutocomplete = false;
      const { data } = action.payload;
      state.autocomplete = data;
    })

    builder.addCase(fetchAutocompleteAgents.rejected, (state, action) => {
      state.loadingAutocomplete = false;
      state.error = action.payload;
    })

    // FETCH USERNAME
    builder.addCase(checkUsername.pending, (state, action) => {
      state.loadingUsername = true;
    })

    builder.addCase(checkUsername.fulfilled, (state, action) => {
      state.loadingUsername = false;
    })

    builder.addCase(checkUsername.rejected, (state, action) => {
      state.loadingUsername = false;
      state.error = action.payload;
    })

    // FETCH
    builder.addCase(fetchAgents.pending, (state, action) => {
      state.loading = true;
    })

    builder.addCase(fetchAgents.fulfilled, (state, action) => {
      state.loading = false;
      state.meta = action.payload.meta;
      state.agents = action.payload.data;
    })

    builder.addCase(fetchAgents.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // FETCH BY ID
    builder.addCase(fetchAgentById.pending, (state, action) => {
      state.loading = true;
    })

    builder.addCase(fetchAgentById.fulfilled, (state, action) => {
      state.loading = false;
      state.meta = action.payload.meta;
      state.agent = action.payload.data;
    })

    builder.addCase(fetchAgentById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // CREATE
    builder.addCase(createAgent.pending, (state, action) => {
      state.loadingCreate = true;
    })

    builder.addCase(createAgent.fulfilled, (state, action) => {
      state.loadingCreate = false;
      state.meta.totalRecords = state.meta.totalRecords + 1;
      state.agents = [action.payload.data, ...state.agents];
    })

    builder.addCase(createAgent.rejected, (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    })

    // DELETE
    builder.addCase(deleteAgent.pending, (state, action) => {
      state.loadingDelete = true;
    })

    builder.addCase(deleteAgent.fulfilled, (state, action) => {
      state.loadingDelete = false;
    })

    builder.addCase(deleteAgent.rejected, (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
    })

    // UPDATE
    builder.addCase(updateAgent.pending, (state, action) => {
      state.loadingUpdate = true;
    })

    builder.addCase(updateAgent.fulfilled, (state, action) => {
      state.loadingUpdate = false;
    })

    builder.addCase(updateAgent.rejected, (state, action) => {
      state.loadingUpdate = false;
      state.error = action.payload;
    })

    // UPLOAD
    builder.addCase(upload.pending, state => {
      state.loadingUpload = true;
    })

    builder.addCase(upload.fulfilled, state => {
      state.loadingUpload = false;
    })

    builder.addCase(upload.rejected, (state, action) => {
      state.loadingUpload = false;
      state.error = action.payload;
    })
  }
});

export default agentsSlice.reducer;
