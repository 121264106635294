import React, { Fragment, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormLabel,
  Image,
  Button,
  HStack,
  Text,
  Box,
  Icon,
  Spinner,
  Avatar,
  FormErrorMessage,
} from "@chakra-ui/react";
import { BsImage } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { useController } from "react-hook-form";

const ImageUpload = ({
  name,
  acceptedFileTypes = ["image/png", "image/jpeg"],
  control,
  label,
  alt,
  callback = () => {},
  isLoading = false,
  isDisabled = false,
  isRequired = false,
  buttonUploadText = "Change Image",
  buttonDeleteText = "Delete",
  hideDeleteButton = false,
  isAvatar = false,
}) => {
  const [imageBlob, setImageBlob] = useState(null);
  const inputRef = useRef();

  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: { required: isRequired },
  });

  const handleChange = (e) => {
    if (e.target.files.length >= 1) {
      callback(e.target.files[0], (uploadedData) => {
        setImageBlob(URL.createObjectURL(e.target.files[0]));
        onChange(uploadedData);
      });
    } else {
      setImageBlob(null);
      onChange("");
    }
  };

  const handleDelete = () => {
    setImageBlob(null);
    onChange("");
  };

  const variantColor = invalid ? "red.500" : "gray.400";
  const variantBorder = invalid ? "red.500" : "gray.300";
  const fileInputKey = imageBlob ? imageBlob : +new Date();

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <HStack>
        {imageBlob || value ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
            color="white"
          >
            {isLoading && (
              <Fragment>
                <Spinner thickness="4px" position="absolute" zIndex="1" />
                <Box
                  position="absolute"
                  bg="black"
                  w="100px"
                  h="100px"
                  opacity=".5"
                />
              </Fragment>
            )}
            <Image
              boxSize="100px"
              border="1px"
              borderRadius={isAvatar ? "full" : "4"}
              color="gray.200"
              objectFit="cover"
              src={imageBlob || value}
              alt={alt || label}
              ignoreFallback
            />
          </Box>
        ) : (
          <Box
            display="flex"
            color={variantBorder}
            justifyContent="center"
            alignItems="center"
            boxSize="100px"
            border={isAvatar ? 0 : "2px"}
            borderRadius={isAvatar ? "full" : "4"}
            bg={isAvatar ? "transparent" : "gray.100"}
          >
            {isLoading ? (
              <Spinner thickness="4px" position="absolute" zIndex="1" />
            ) : isAvatar ? (
              <Avatar boxSize="100" />
            ) : (
              <Icon
                as={isAvatar ? FaUser : BsImage}
                color={variantColor}
                boxSize={8}
              />
            )}
          </Box>
        )}

        <Box pl="4">
          <HStack spacing="4">
            <Button
              onClick={() => inputRef.current.click()}
              isDisabled={isLoading || isDisabled}
            >
              {buttonUploadText}
            </Button>
            {!hideDeleteButton && (
              <Button
                onClick={handleDelete}
                variant="ghost"
                colorScheme="red"
                isDisabled={isLoading || isDisabled}
              >
                {buttonDeleteText}
              </Button>
            )}
          </HStack>
          <Text mt="3" color="gray.500" fontSize="sm">
            File support (.jpg, .gif, or .png) Max file size 700K.
          </Text>
        </Box>
      </HStack>
      <input
        key={fileInputKey}
        type="file"
        accept={acceptedFileTypes}
        name={name}
        ref={inputRef}
        {...inputProps}
        style={{ display: "none" }}
        onChange={handleChange}
      />
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

ImageUpload.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  alt: PropTypes.string,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  callback: PropTypes.func,
  buttonUploadText: PropTypes.string,
  buttonDeleteText: PropTypes.string,
  hideDeleteButton: PropTypes.bool,
  isAvatar: PropTypes.bool,
};

export default ImageUpload;
