import React from "react";
import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";

const LocationCell = value => {
  return (
    <Text align="center" noOfLines={1}>
      {value}
    </Text>
  );
};

LocationCell.propTypes = {
  value: PropTypes.string
};

export default LocationCell;
