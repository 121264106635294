import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { TextareaField } from "components/field";
import { useDeleteComplaintPropertyMutation } from "services/complaint.services";
import { useSendMailMutation } from "services/mail.services";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schemaDeleteProperty = yup.object().shape({
  reasonDeleted: yup.string().required("Reason can't be blank.").default(""),
});

const ModalDeleteProperty = ({ complaint }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { control, handleSubmit, reset, clearErrors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaDeleteProperty),
    defaultValues: { reasonDeleted: "" },
  });

  const [deleteProperty, { isLoading }] = useDeleteComplaintPropertyMutation();

  const onSubmit = async ({ reasonDeleted }) => {
    const payload = {
      id: complaint?.id, // id complaint
      reasonDeleted: reasonDeleted,
    };

    try {
      await deleteProperty({ ...payload }).unwrap();
      handleClose();

      toast({
        title: "Data Updated",
        description: "Delete property successfully.",
        position: "top-right",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
    clearErrors();
    reset();
  };

  return (
    <>
      <Button size="sm" colorScheme="gray" onClick={onOpen}>
        Delete
      </Button>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg">Delete Property</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TextareaField
              name="reasonDeleted"
              label="Reason"
              control={control}
              isRequired
            />
            <Text fontSize="sm" color="gray" mt="1">
              The reason for the deletion of this property will be send via
              email to the property owner.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" size="sm" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              size="sm"
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDeleteProperty;
