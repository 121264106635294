import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { HStack, IconButton } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StatusCell, UpperTextCell } from 'components/cell';
import { useGetTourRequestsQuery } from 'services/tour-request.services';
import { FaEye } from 'react-icons/fa6';
import { TableFilters, TablePagination } from '../../../components/table';
import PaperTable from '../../../components/paper-table';

const sortOptions = [
  { label: 'Created', value: 'createdAt', isDefault: true, direction: 'desc' },
];

const viewOptions = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
];

const TourRequestsTable = () => {
  const history = useHistory();
  const { me } = useSelector((state) => state?.auth);
  const role = me?.role;

  const [queryValue, setQueryValue] = useState({
    nextCursor: null,
    search: '',
    sort: '',
    status: '',
  });

  // service
  const {
    data: tourRequests,
    isLoading,
    isFetching,
    isError,
  } = useGetTourRequestsQuery({
    nextCursor: queryValue.nextCursor,
    search: queryValue.search,
    sort: queryValue.sort,
    status: queryValue.status,
    pageSize: 20,
  });

  // table function operation
  const handleNext = useCallback((cursor) => {
    setQueryValue((prev) => ({
      ...prev,
      nextCursor: cursor,
    }));
  }, []);

  const handleSearch = useCallback((value) => {
    setQueryValue((prev) => ({
      ...prev,
      nextCursor: null,
      search: value,
    }));
  }, []);

  const handleSorting = useCallback(({ field, direction }) => {
    setQueryValue((prev) => ({
      ...prev,
      nextCursor: null,
      sort: `${field} ${direction}`,
    }));
  }, []);

  const handleView = useCallback((value) => {
    setQueryValue((prev) => ({
      ...prev,
      nextCursor: null,
      status: value,
    }));
  }, []);

  const RenderAction = ({ selectedItem }) => (
    <HStack justifyContent="flex-end">
      <IconButton
        onClick={() => history.push(`/tour-request/${selectedItem.id}`)}
        colorScheme="primary"
        aria-label="Detail"
        size="sm"
        icon={<FaEye />}
      />
    </HStack>
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Request Id',
        accessor: 'generatedID',
        Cell: UpperTextCell,
      },
      { Header: 'Client Name', accessor: 'clientName' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Phone Number', accessor: 'phoneNumber' },
      { Header: 'Requested Property', accessor: 'requestedProperty' },
      { Header: 'Agent In Charge', accessor: 'agentInCharge' },
      { Header: 'Status', accessor: 'status', Cell: StatusCell },
      { Header: '', accessor: 'action', isNumeric: true },
    ],
    []
  );

  const dataMemoized = useMemo(
    () =>
      tourRequests?.data.map((item) => {
        return {
          id: item.id,
          generatedID: item.generatedID,
          clientName: item.clientName,
          companyName: item.companyName,
          phoneNumber: item.phoneNumber,
          agentInCharge: get(item.agentInCharge, 'label', '-'),
          requestedProperty: get(item.inquiredProperty, 'label', '-'),
          email: item.email,
          status:
            me?.role === 'property_agent' ? item.statusByAgent : item.status,
          action: <RenderAction selectedItem={item} />,
        };
      }),
    [tourRequests?.data, me?.role]
  );

  return (
    <PaperTable>
      <TableFilters
        sortOptions={sortOptions}
        handleSorting={handleSorting}
        handleView={handleView}
        viewOptions={viewOptions}
        handleSearch={handleSearch}
        searchPlaceholder="Search request id..."
      />

      <TablePagination
        columns={columns}
        data={dataMemoized || []}
        isLoading={isLoading || isFetching}
        isError={isError}
        meta={tourRequests?.meta}
        handleNextFn={handleNext}
      />
    </PaperTable>
  );
};

TourRequestsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  loadingDelete: PropTypes.bool,
  meta: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onPagination: PropTypes.func,
  role: PropTypes.string,
};

export default TourRequestsTable;
