import { api } from "../api";

const endpoint = "/properties/public";
const endpointElastic = "/properties/elastic";

export const propertiesClientApi = api.injectEndpoints({
  tagTypes: ["PropertiesClient"],
  endpoints: (build) => ({
    getElasticProperties: build.query({
      query: (payload) => ({
        url: `${endpointElastic}`,
        method: "GET",
        params: {
          search: payload?.search || "",
          hitsPerPage: 12,
          page: payload?.page || 0,
          ...payload,
        },
      }),
      providesTags: ["PropertiesClient"],
    }),
  }),
});

export const { useGetElasticPropertiesQuery } = propertiesClientApi;
