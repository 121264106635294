import React from "react";
import PropTypes from "prop-types";
import { AutoComplete } from "../field";
import { useDispatch, useSelector } from "react-redux";
import { fetchAutocompleteTag } from "../../services/tagsProperty.service";

const AutocompleteTags = ({
  control,
  name = "tags",
  label = "Tags",
  ...props
}) => {
  const dispatch = useDispatch();
  const { autocomplete, loadingAutocomplete } = useSelector(
    state => state?.tagsProperty
  );

  const handleAutocomplete = inputValue => {
    dispatch(fetchAutocompleteTag({ search: inputValue }));
  };

  return (
    <AutoComplete
      name={name}
      label={label}
      control={control}
      options={autocomplete}
      eventInputChange={handleAutocomplete}
      isLoading={loadingAutocomplete}
      {...props}
    />
  );
};

AutocompleteTags.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string
};

export default AutocompleteTags;
