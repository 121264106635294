import React, { Fragment, useEffect, useState } from 'react';
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from 'components/layout';
import { TextHeader } from 'components/layout/header';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import Paper from '../../components/paper';
import {
  InputField,
  PhoneField,
  LinkField,
  TimeRangeField,
  TextOnly,
} from '../../components/field';
import { useForm } from 'react-hook-form';
import { defaultValues, schema } from './constant';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useGetInquiryQuery,
  useApproveInquiryMutation,
  useRejectInquiryMutation,
} from '../../services/inquiries.services';
import { messageSuccessUpdate } from '../../components/messages';
import ModalDelegate from './components/ModalDelegate';
import ModalTransfer from './components/ModalTransfer';
import ModalRejection from './components/ModalRejection';
import ModalAccept from './components/ModalAccept';
// import ModalAttachmentImage from '../../components/modal/ModalAttachmentImage';
import Result from '../../components/Result';
import { yupResolver } from '@hookform/resolvers/yup';
import ROLE from '../../config/role';
import { FaArrowLeftLong } from 'react-icons/fa6';

const InquireDetailsPage = () => {
  const { id } = useParams();
  const { me } = useSelector((state) => state?.auth);

  const history = useHistory();

  // services
  const { data: inquiry, error, isLoading, refetch } = useGetInquiryQuery(id);

  const [approveInquiry, { isLoading: isLoadingApprove }] =
    useApproveInquiryMutation();

  const [rejectInquiry, { isLoading: isLoadingReject }] =
    useRejectInquiryMutation();

  // form control
  const { control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (inquiry) {
      const defaultValues = {
        ...inquiry?.data,
        responsible: inquiry?.data?.responsible?.label,
        agentInCharge: inquiry?.data?.agentInCharge?.label,
      };
      reset(defaultValues);
    }
  }, [inquiry, reset]);

  const onApproveInquiry = async () => {
    console.log('onApproveInquiry');
    try {
      const payload = {
        id,
        agentInCharge: {
          label: inquiry?.data?.propertyAgent.label,
          value: inquiry?.data?.propertyAgent.value,
        },
        responsible: {
          label: me?.displayName,
          value: me?.uid,
        },
      };

      const res = await approveInquiry(payload);

      messageSuccessUpdate({
        title: 'Success',
        description: res.message,
      });

      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const onRejectInquiry = async () => {
    try {
      const payload = {
        id,
        responsible: {
          label: me?.displayName,
          value: me?.uid,
        },
      };

      const res = await rejectInquiry(payload);

      messageSuccessUpdate({
        title: 'Success',
        description: res.message,
      });

      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusByRole = (role) => {
    if (role === ROLE.ADMIN) {
      return inquiry?.data.statusByAdmin;
    } else if (role === ROLE.WEBSITE_AGENT) {
      return inquiry?.data.statusByWebsiteAgent;
    } else {
      return inquiry?.data.statusByAgent;
    }
  };

  const ButtonApprove = () => (
    <Button size="sm" onClick={onApproveInquiry} isLoading={isLoadingApprove}>
      Approve
    </Button>
  );

  const ButtonReject = () => (
    <Button size="sm" onClick={onRejectInquiry} isLoading={isLoadingReject}>
      Reject
    </Button>
  );

  const renderActionButton = (role) => {
    if (role === ROLE.ADMIN) {
      return (
        <Fragment>
          <ButtonApprove />
          <ButtonReject />
          <ModalDelegate refetch={refetch} />
          <ModalTransfer refetch={refetch} />
        </Fragment>
      );
    } else if (role === ROLE.WEBSITE_AGENT) {
      return (
        <Fragment>
          <ButtonApprove />
          <ButtonReject />
          <ModalTransfer refetch={refetch} />
        </Fragment>
      );
    } else if (role === ROLE.PROPERTY_AGENT) {
      if (getStatusByRole(ROLE.PROPERTY_AGENT) === 'rejected') {
        return (
          <Text fontWeight="bold" color="red.500">
            You Rejected this inquiry
          </Text>
        );
      } else if (getStatusByRole(ROLE.PROPERTY_AGENT) === 'accepted') {
        return (
          <Text fontWeight="bold" color="green.500">
            You Accepted this inquiry.
          </Text>
        );
      } else {
        return (
          <Fragment>
            <ModalAccept refetch={refetch} inquiry={inquiry} />
            <ModalRejection refetch={refetch} inquiry={inquiry} />
          </Fragment>
        );
      }
    }
  };

  return (
    <Layout>
      <Result isFound={!!inquiry} isLoaded={!isLoading}>
        <LayoutHeader>
          <LayoutHeaderLeft>
            <Flex alignItems="center">
              <IconButton
                icon={<FaArrowLeftLong />}
                onClick={() => history.push('/inquiries')}
              />
              <TextHeader text="Inquiry Details" />
            </Flex>
          </LayoutHeaderLeft>
          <LayoutHeaderRight>
            <Fragment>{renderActionButton(me?.role)}</Fragment>
          </LayoutHeaderRight>
        </LayoutHeader>

        <LayoutBody>
          {[ROLE.ADMIN, ROLE.WEBSITE_AGENT].includes(me?.role) &&
            inquiry?.statusByAgent === 'new' && (
              <Alert status="warning" mb="8">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Waiting response from property agent!</AlertTitle>
                  <AlertDescription display="block">
                    agent not response? you can select another property agent in
                    field Agent In Charge
                  </AlertDescription>
                </Box>
              </Alert>
            )}

          {[ROLE.ADMIN, ROLE.WEBSITE_AGENT].includes(me?.role) &&
            inquiry?.statusByAgent === 'rejected' && (
              <Alert status="error" mb="8">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Tour request rejected by agent!</AlertTitle>
                  <AlertDescription display="block">
                    {me?.role === ROLE.ADMIN
                      ? 'you can Transfer to another property agent.'
                      : 'Inquiry is being re-processed by the admin.'}
                  </AlertDescription>
                </Box>
              </Alert>
            )}
          <Grid
            templateColumns={{ base: 'repeat(3, 1fr)', lg: 'repeat(6, 1fr)' }}
            gap={6}
          >
            <GridItem colSpan="4">
              <Paper>
                <VStack spacing="6">
                  <InputField
                    name="generatedID"
                    label="Request ID"
                    control={control}
                    isReadOnly
                  />
                  <HStack w="full" spacing="6">
                    <InputField
                      name="clientName"
                      label="Client Name"
                      control={control}
                      isReadOnly
                    />
                    <InputField
                      name="companyName"
                      label="Company Name"
                      control={control}
                      isReadOnly
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <InputField
                      name="email"
                      type="email"
                      label="Email"
                      control={control}
                      isReadOnly
                    />
                    <PhoneField
                      name="phoneNumber"
                      label="Phone Number"
                      control={control}
                      isGeneral
                      isReadOnly
                    />
                  </HStack>

                  <TimeRangeField
                    name="preferredVisitingTime"
                    label="Preferred Visiting Time"
                    control={control}
                    isReadOnly
                  />

                  <HStack w="full" spacing="6">
                    <LinkField
                      label="Inquired Property"
                      name="inquiredProperty"
                      control={control}
                      baseURL="/property"
                    />
                    <LinkField
                      label="Property Agent"
                      name="propertyAgent"
                      control={control}
                      baseURL="/v/agent"
                    />
                  </HStack>
                </VStack>
              </Paper>
            </GridItem>
            <GridItem colSpan={{ base: 4, lg: 2 }}>
              {me?.role !== ROLE.PROPERTY_AGENT && (
                <Paper mb="4">
                  <InputField
                    label="Status"
                    name="status"
                    textTransform="capitalize"
                    isReadOnly
                    control={control}
                  />
                </Paper>
              )}

              {me?.role !== ROLE.PROPERTY_AGENT && (
                <Paper mb="4">
                  <VStack spacing="6">
                    <Fragment>
                      <InputField
                        label="Responsible"
                        name="responsible"
                        helperText={
                          'please approve or delegate to assign responsible agent.'
                        }
                        isReadOnly
                        control={control}
                      />
                    </Fragment>
                  </VStack>
                </Paper>
              )}

              <Paper>
                <VStack spacing="6">
                  <InputField
                    label="Agent In Charge"
                    name="agentInCharge"
                    helperText={
                      'please approve or transfer to assign agent in charge.'
                    }
                    isReadOnly
                    control={control}
                  />
                  <InputField
                    label="Status by the Agent"
                    name="statusByAgent"
                    isReadOnly
                    textTransform="capitalize"
                    control={control}
                  />
                  {inquiry?.data.statusByAgent === 'rejected' && (
                    <TextOnly
                      label="Rejection Reason"
                      value={inquiry?.data.rejectionReason}
                    />
                  )}
                </VStack>
              </Paper>
            </GridItem>
          </Grid>
        </LayoutBody>
      </Result>
    </Layout>
  );
};

export default InquireDetailsPage;
