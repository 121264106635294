import ROLE, { ROLE_ADMIN_AND_AGENT, ROLE_WEB_AND_PERSONAL } from "./role";

import {
  FaAdversal,
  FaCalendarCheck,
  FaEnvelopeCircleCheck,
  FaFileCircleXmark,
  FaFileContract,
  FaGears,
  FaHouse,
  FaLocationDot,
  FaNewspaper,
  FaUserGroup,
} from "react-icons/fa6";

const menuData = [
  {
    key: "dashboard",
    title: "Dashboard",
    icon: FaHouse,
    url: "/dashboard",
    roles: [],
  },
  {
    key: "agents",
    title: "Agents",
    icon: FaUserGroup,
    url: "/agents",
    roles: [ROLE.ADMIN],
    subMenu: [
      {
        title: "Agents",
        url: "/agents",
        roles: [ROLE.ADMIN],
      },
      {
        title: "Add New Agent",
        url: "/agents/new",
        roles: [ROLE.ADMIN],
      },
    ],
  },
  {
    key: "properties",
    title: "Properties",
    icon: FaAdversal,
    url: "/properties",
    roles: [...ROLE_WEB_AND_PERSONAL],
  },
  {
    key: "properties",
    title: "Properties",
    icon: FaAdversal,
    url: "/properties",
    roles: [...ROLE_ADMIN_AND_AGENT],
    subMenu: [
      {
        title: "Properties",
        url: "/properties",
        roles: [],
      },
      {
        title: "Add New Property",
        url: "/properties/new",
        roles: [...ROLE_ADMIN_AND_AGENT],
      },
      {
        title: "Property Types",
        url: "/properties/types",
        roles: [ROLE.ADMIN],
      },
      {
        title: "Features",
        url: "/properties/features",
        roles: [ROLE.ADMIN],
      },
      {
        title: "Tags",
        url: "/properties/tags",
        roles: [ROLE.ADMIN],
      },
    ],
  },
  {
    key: "location",
    title: "Locations",
    icon: FaLocationDot,
    url: "/location/countries",
    roles: [ROLE.ADMIN],
    subMenu: [
      {
        title: "Countries",
        url: "/location/countries",
        roles: [ROLE.ADMIN],
      },
      {
        title: "Regions",
        url: "/location/regions",
        roles: [ROLE.ADMIN],
      },
      {
        title: "Cities",
        url: "/location/cities",
        roles: [ROLE.ADMIN],
      },
    ],
  },
  {
    key: "inquiries",
    title: "Inquiries",
    icon: FaNewspaper,
    url: "/inquiries",
    roles: [],
  },
  {
    key: "tour-request",
    title: "Tour Request",
    icon: FaCalendarCheck,
    url: "/tour-request",
    roles: [],
  },
  {
    key: "special-request",
    title: "Special Request",
    icon: FaEnvelopeCircleCheck,
    url: "/special-request",
    roles: [...ROLE_ADMIN_AND_AGENT, ROLE.WEBSITE_AGENT],
  },
  {
    key: "contracts",
    title: "Contracts",
    icon: FaFileContract,
    url: "/contracts",
    roles: [],
  },
  {
    key: "complaints",
    title: "Complaints",
    icon: FaFileCircleXmark,
    url: "/complaints",
    roles: [],
  },
  {
    key: "manage-content",
    title: "Manage Content",
    icon: FaGears,
    url: "/manage-content",
    roles: [ROLE.ADMIN],
    subMenu: [
      {
        title: "Menu",
        url: "/manage-content/menu",
        roles: [ROLE.ADMIN],
      },
    ],
  },
];

export default menuData;
