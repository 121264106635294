import React, { useEffect } from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "../../components/layout";
import { TextHeader } from "components/layout/header";
import { useHistory } from "react-router-dom";
import {
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
} from "@chakra-ui/react";
import { SearchField } from "../../components/field";
import Paper from "../../components/paper";
import AgentsTable from "./components/AgentsTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchAgents } from "../../services/agents.service";
import { metaTab } from "./constant";

const AgentsPage = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const { meta, agents, loading, loadingDelete } = useSelector(
    (state) => state?.agents
  );

  useEffect(() => {
    dispatch(fetchAgents());
  }, [dispatch]);

  const handleTabsChange = (index) => {
    if (index === metaTab.REJECTED) {
      dispatch(fetchAgents({ status: "rejected" }));
    } else {
      dispatch(fetchAgents());
    }
    setTabIndex(index);
  };

  const handlePagination = (offset) => {
    const payload = {
      offset,
    };
    dispatch(fetchAgents(payload));
  };

  const handleSearch = (value) => {
    setTabIndex(0);
    dispatch(fetchAgents({ search: value }));
  };

  const handleAddNew = () => {
    history.push("/agents/new");
  };

  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="agents" />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <SearchField placeholder="Search Agents" callback={handleSearch} />
          <Button onClick={handleAddNew} colorScheme="primary" size="md">
            <Text noOfLines={1}>Add New Agent</Text>
          </Button>
        </LayoutHeaderRight>
      </LayoutHeader>

      <LayoutBody>
        <Tabs
          isLazy
          size="sm"
          index={tabIndex}
          variant="soft-rounded"
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab
              fontWeight="semibold"
              _selected={{ color: "white", bg: "primary.500" }}
            >
              All Agents
            </Tab>
            <Tab
              fontWeight="semibold"
              _selected={{ color: "white", bg: "red.500" }}
            >
              Rejected
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0">
              <Paper>
                <AgentsTable
                  data={agents}
                  isLoading={loading}
                  meta={meta}
                  onPagination={handlePagination}
                />
              </Paper>
            </TabPanel>
            <TabPanel px="0">
              <Paper>
                <AgentsTable
                  data={agents}
                  isLoading={loading}
                  meta={meta}
                  onPagination={handlePagination}
                />
              </Paper>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </LayoutBody>
    </Layout>
  );
};

export default AgentsPage;
