import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Divider, Text } from "@chakra-ui/react";

const Paper = ({ children, ...props }) => (
  <Box bg="white" p="6" borderRadius="md" boxShadow="sm" {...props}>
    <Box>{children}</Box>
  </Box>
);

export const PaperHeader = ({ children, divider, ...props }) => (
  <Fragment>
    <Box mb="4">{children}</Box>
    {divider && <Divider mx="-6" px="6" mb="4" />}
  </Fragment>
);

export const PaperTitle = ({ children, ...props }) => (
  <Text color="gray.700" fontSize="md" fontWeight="semibold" {...props}>
    {children}
  </Text>
);

Paper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

PaperHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

PaperTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export default Paper;
