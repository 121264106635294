import React from 'react';
import { HStack, Box } from '@chakra-ui/react';
import { IndexTable, TableFilters } from 'components/table';
import { ConfirmDelete } from 'components/alert';
import { messageSuccessDelete } from 'components/messages';
import PopupEdit from './PopupEdit';
import {
  useDeleteCityMutation,
  useGetCitiesQuery,
} from 'services/location/cities.services';

const sortOptions = [
  { label: 'City', value: 'name' },
  { label: 'Region', value: 'region.label' },
  { label: 'Created', value: 'createdAt', isDefault: true, direction: 'desc' },
];

const CitiesTable = () => {
  const [queryValue, setQueryValue] = React.useState({
    nextCursor: null,
    search: '',
    sort: '',
  });

  const {
    data: cities,
    isLoading,
    isFetching,
    isError,
  } = useGetCitiesQuery({
    nextCursor: queryValue.nextCursor,
    search: queryValue.search,
    sort: queryValue.sort,
  });

  const [deleteCity, { isLoading: isLoadingDelete }] = useDeleteCityMutation();

  const handleNext = (cursor) => {
    setQueryValue({
      ...queryValue,
      nextCursor: cursor,
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteCity(id).unwrap();
      messageSuccessDelete();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (value) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      search: value,
    });
  };
  const handleSorting = ({ field, direction }) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      sort: `${field} ${direction}`,
    });
  };

  const RenderAction = ({ selectedItem }) => (
    <HStack justifyContent="flex-end">
      <PopupEdit data={selectedItem} />
      <ConfirmDelete
        iconOnly
        headerText={`Delete '${selectedItem.name}' Region`}
        bodyText="Are you sure? You can't undo this action afterwards."
        eventClick={() => handleDelete(selectedItem.id)}
        loadingDelete={isLoadingDelete}
      />
    </HStack>
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'City',
        accessor: 'name',
        width: '300',
      },
      {
        Header: 'Region',
        accessor: 'region',
        width: '200',
      },
      {
        Header: '',
        accessor: 'actions',
        width: '200',
        isNumeric: true,
      },
    ],
    []
  );

  const dataMemoized = React.useMemo(
    () =>
      cities?.data.map((item) => {
        return {
          name: item.name,
          region: item.region.label || item.region,
          actions: <RenderAction selectedItem={item} />,
        };
      }),
    [cities?.data]
  );

  return (
    <Box>
      <TableFilters
        sortOptions={sortOptions}
        handleSearch={handleSearch}
        searchPlaceholder="Search city..."
        handleSorting={handleSorting}
      />

      <IndexTable
        columns={columns}
        data={dataMemoized || []}
        isLoading={isLoading || isFetching}
        isError={isError}
        meta={cities?.meta}
        handleNextFn={handleNext}
      />
    </Box>
  );
};

export default CitiesTable;
