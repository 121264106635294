import React from "react";
import { chakra, IconButton, HStack, useToast } from "@chakra-ui/react";
import { FaChevronRight, FaChevronDown } from "react-icons/fa";
import { TableExpand } from "components/table";
import SomethingWrong from "components/error/SomethingWrong";
import { ConfirmDelete } from "components/alert";
import PopupEdit from "./PopupEdit";
import {
  useGetMenuQuery,
  useDeleteMenuMutation,
  useDeleteSubMenuMutation,
} from "services/menu.service";

const MenuTable = ({ onUpdate = () => {} }) => {
  const toast = useToast();
  const [offset, setOffset] = React.useState(0);

  const {
    data: menu,
    isLoading,
    isFetching,
    isError,
  } = useGetMenuQuery({
    offset: offset,
  });

  const [deleteMenu] = useDeleteMenuMutation();
  const [deleteSubMenu] = useDeleteSubMenuMutation();

  const handlePagination = (internalOffset) => {
    setOffset(internalOffset);
  };

  const handleDelete = async (selectedItem) => {
    if (selectedItem.isParent) {
      try {
        await deleteMenu(id).unwrap();
        toast({
          description: `Menu deleted successfully!`,
          status: "success",
          position: "top-right",
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      const parentID = selectedItem.parent.value;
      const submenuID = selectedItem.id;

      try {
        await deleteSubMenu({ parentID, submenuID }).unwrap();
        toast({
          description: `Submenu deleted successfully!`,
          status: "success",
          position: "top-right",
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const RenderAction = ({ selectedItem }) => {
    return (
      <HStack justifyContent="flex-end">
        <PopupEdit data={selectedItem} onUpdate={onUpdate} />
        <ConfirmDelete
          iconOnly
          headerText={`Delete '${selectedItem.name}'`}
          bodyText="Are you sure? You can't undo this action afterwards."
          eventClick={() => handleDelete(selectedItem)}
        />
      </HStack>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        width: 10,
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <chakra.span {...row.getToggleRowExpandedProps({})}>
              {row.isExpanded ? (
                <IconButton
                  size="xs"
                  icon={<FaChevronDown />}
                  variant="solid"
                />
              ) : (
                <IconButton
                  size="xs"
                  icon={<FaChevronRight />}
                  variant="solid"
                />
              )}
            </chakra.span>
          ) : null,
      },
      {
        Header: "Menu Name",
        accessor: "name",
      },
      {
        Header: "Arabic Name",
        accessor: "ar_name",
      },
      {
        Header: "Sequence",
        accessor: "sequence",
      },
      // {
      //   Header: "Parent",
      //   accessor: "parent",
      // },
      {
        Header: "Content URL",
        accessor: "contentUrl",
      },
      {
        Header: "Actions",
        accessor: "actions",
        isNumeric: true,
      },
    ],
    [],
  );

  const dataMemoized = React.useMemo(
    () =>
      menu?.data.map((item) => {
        // Generate Data for Sub row
        const subRow = item.subRows?.map((row) => {
          return {
            name: row.name,
            ar_name: row.ar_name,
            sequence: row.sequence,
            // parent: row.parent?.label,
            contentUrl: row.contentUrl,
            actions: <RenderAction selectedItem={row} />,
          };
        });

        return {
          name: item.name,
          ar_name: item.ar_name,
          sequence: item.sequence,
          // parent: item.parent?.label,
          contentUrl: item.contentUrl,
          subRows: subRow,
          actions: <RenderAction selectedItem={item} />,
        };
      }),
    [menu?.data],
  );

  // if (isLoading || isFetching) {
  //   return "loading...";
  // }

  if (isError) {
    return <SomethingWrong />;
  }

  return (
    <TableExpand
      columns={columns}
      data={dataMemoized || []}
      isLoading={isLoading || isFetching}
      meta={menu?.meta}
      handlePagination={handlePagination}
    />
  );
};

export default MenuTable;
