import React, { Fragment } from "react";
import Layout, { LayoutBody } from "../../../components/layout/client";
import {
  Box,
  Grid,
  GridItem,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NoFavoriteResults from "../property/components/NoFavoriteResults";
import PropertyItem from "../../../components/client/PropertyItem";

export const FavoritePage = () => {
  const { t } = useTranslation();

  const templateColumnsResponsive = useBreakpointValue(
    {
      base: "repeat(1, 1fr)",
      lg: "repeat(5, 1fr)",
    },
    { ssr: false },
  );

  const { listFavorite } = useSelector((state) => state?.favorites);

  return (
    <Layout>
      <LayoutBody>
        <Stack
          direction={["column", "column", "row"]}
          justifyContent="space-between"
          alignItems={["start", "start", "center"]}
          flexWrap="wrap"
          mb="8"
        >
          <Box mb="4">
            <Text fontSize="3xl" fontWeight="bold">
              {t("common:label:my_favorites")}
            </Text>
          </Box>
        </Stack>

        <Grid templateColumns={templateColumnsResponsive} gap={8}>
          <GridItem colSpan={6}>
            {listFavorite.length === 0 && <NoFavoriteResults />}

            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 2, xl: 3 }}
              spacing={8}
              w="full"
            >
              {listFavorite.length !== 0 && (
                <Fragment>
                  {listFavorite.map((item, index) => (
                    <GridItem
                      key={index}
                      onClick={() =>
                        window.open(`/c/property/${item.objectID}`, "_self")
                      }
                    >
                      <PropertyItem data={item} />
                    </GridItem>
                  ))}
                </Fragment>
              )}
            </SimpleGrid>
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};
