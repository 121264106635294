import React, { memo } from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';

const TableView = ({ viewOptions = [], callback }) => {
  const [activeView, setActiveView] = React.useState(
    viewOptions[0]?.value || ''
  );

  const handleViewChange = (value) => {
    setActiveView(value);
    callback(value);
  };

  if (!viewOptions.length) return null;

  return (
    <HStack>
      {viewOptions.map((view) => (
        <Button
          key={view.value}
          size="sm"
          variant={activeView === view.value ? 'solid' : 'ghost'}
          onClick={() => handleViewChange(view.value)}
        >
          {view.label}
        </Button>
      ))}
    </HStack>
  );
};

export default memo(TableView);
