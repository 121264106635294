import { Button, Flex, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectField } from 'components/field';
import Paper from 'components/paper';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  nextStep,
  selectForm,
  updateForm,
} from '../../../../redux/registerStepSlice';
import * as yup from 'yup';

const Step1 = () => {
  const { t } = useTranslation(['register', 'validation']);
  const dispatch = useDispatch();
  const form = useSelector(selectForm);

  const schema = yup.object().shape({
    role: yup.string().required(t('validation:common.required')),
  });

  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      role: form.role || '',
    },
  });

  const registerRoleOptions = useMemo(
    () => [
      {
        label: t('register:service_office_provider'),
        value: 'service_office_provider',
      },
      { label: t('register:property_agent'), value: 'property_agent' },
      { label: t('register:property_owner'), value: 'property_owner' },
      { label: t('register:personal'), value: 'personal' },
    ],
    []
  );

  const onSubmit = async (values) => {
    const payload = {
      ...form,
      ...values,
    };
    dispatch(updateForm(payload));
    dispatch(nextStep());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper
        p={{ base: 6, md: 8, lg: 10 }}
        boxShadow="lg"
        mt="12"
        borderRadius="2xl"
      >
        <VStack spacing="6" align="start">
          <SelectField
            name="role"
            label={t('register:account_type')}
            placeholder={t('register:choose_account_type')}
            control={control}
            options={registerRoleOptions}
            isRequired
          />
          <Flex width="100%" justify="flex-end">
            <Button type="submit" colorScheme="primary">
              {t('register:next')}
            </Button>
          </Flex>
        </VStack>
      </Paper>
    </form>
  );
};

export default Step1;
