import React from "react";
import PropTypes from "prop-types";
import { useTable } from "react-table";
import {
  Button,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  chakra,
} from "@chakra-ui/react";
import TableEmpty from "../components/TableEmpty";
import TableLoader from "../components/TableLoader";

const TableV1 = ({
  columns,
  data = [],
  isLoading = false,
  meta = {},
  handlePagination,
  eventRowClick = () => {},
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const handlePrev = () => {
    handlePagination(meta.prevOffset);
  };

  const handleNext = () => {
    handlePagination(meta.nextOffset);
  };

  return (
    <Box position="relative">
      <Box overflowX="auto">
        {isLoading && <TableLoader />}
        <Table {...getTableProps()} size="sm">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    key={index}
                    isNumeric={column.isNumeric}
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {column.render("Header")}
                    <chakra.span pl="4" />
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!isLoading && rows.length === 0 && (
              <TableEmpty columns={columns} emptyText="No Data" />
            )}
            {rows.length !== 0 &&
              rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    key={index}
                    {...row.getRowProps()}
                    _hover={{ bg: "gray.50" }}
                    onClick={() => eventRowClick(row)}
                  >
                    {row.cells.map((cell, index) => (
                      <Td
                        cursor="pointer"
                        key={index}
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        isNumeric={cell.column.isNumeric}
                      >
                        {cell.render("Cell")}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>

      <Box
        mt="6"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {/*<HStack>*/}
          {/*  <Text color="gray.500" fontWeight="medium">*/}
          {/*    Total Records:*/}
          {/*  </Text>*/}
          {/*  <Text color="gray.700" fontWeight="medium">*/}
          {/*    {meta.totalRecords}*/}
          {/*  </Text>*/}
          {/*</HStack>*/}
        </Box>
        <HStack spacing="4">
          <Button onClick={handlePrev} isDisabled={!meta.hasPrev || isLoading}>
            Prev
          </Button>
          <Button onClick={handleNext} isDisabled={!meta.hasNext || isLoading}>
            Next
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

TableV1.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  handlePagination: PropTypes.func,
  eventRowClick: PropTypes.func,
};

export default TableV1;
