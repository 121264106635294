import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Text, Image, Box, VStack, HStack } from "@chakra-ui/react";

const PropertyCell = ({
  imageSrc = "",
  propertyName = "Project 300",
  propertyPrice = "1000",
  propertyPriceTime = "month"
}) => {
  return (
    <VStack spacing="8">
      <Image w="335px" h="210px" objectFit="cover" src={imageSrc} />
      <Box>
        <Text
          fontWeight="semibold"
          fontSize="xl"
          align="center"
          mb="4"
          textTransform="capitalize"
          noOfLines={1}
        >
          {propertyName}
        </Text>
        <HStack justify="center">
          <Text fontWeight="semibold">{propertyPrice} SAR</Text>
          <Text fontSize="sm" fontWeight="semibold" textTransform="lowercase">
            / {propertyPriceTime}
          </Text>
        </HStack>
      </Box>
    </VStack>
  );
};

PropertyCell.propTypes = {
  imageSrc: PropTypes.string,
  propertyName: PropTypes.string,
  propertyPrice: PropTypes.string,
  propertyPriceTime: PropTypes.string
};

export default PropertyCell;
