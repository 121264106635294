import { Button, Stack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  DatePicker,
  InputField,
  PhoneField,
  TextareaField,
  TimeRangeField,
} from "components/field";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAddTourRequestMutation } from "services/tour-request.services";
import { emailSchema, stringSchema } from "utils/sharedSchema";
import * as yup from "yup";

const schema = yup.object().shape({
  clientName: stringSchema,
  specialRequest: stringSchema,
  email: emailSchema,
  phoneNumber: stringSchema.required("Phone number is required"),
});

const RequestTourForm = ({ property }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();

  // get user from userSelector
  const { me } = useSelector((state) => state?.auth);
  const { displayName, companyName, email, phoneNumber } = me || [];

  // services
  const [addTourRequest, { isLoading }] = useAddTourRequestMutation();

  const defaultValues = {
    clientName: displayName || "",
    companyName: companyName || "",
    email: email || "",
    phoneNumber: phoneNumber || "",
    preferredVisitingDate: new Date(),
    preferredVisitingTime: [new Date(), new Date()],
    specialRequest: "",
  };

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  // to change the time-range based on selected date time.
  const handleDateChange = (value) => {
    setValue("preferredVisitingTime", [value, value], {
      shouldValidate: true,
    });
  };

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      // responsible: property?.responsible,
      propertyAgent: property?.addedBy,
      inquiredProperty: {
        label: property?.title,
        value: property?.id,
      },
      customerLanguage: i18n.language,
    };

    try {
      // add tour request
      await addTourRequest(payload).unwrap();

      toast({
        title: "Sent successfully",
        description:
          "Your tour request has been sent successfully. Project 300 team will contact you via email or phone shortly.",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing="6" align="start">
        {!me && (
          <Stack direction={{ base: "column", md: "row" }} w="full" spacing="6">
            <InputField
              name="clientName"
              label={t("common:label.name")}
              control={control}
              isRequired
            />
            <InputField
              name="companyName"
              label={t("common:label.company")}
              control={control}
            />
          </Stack>
        )}

        {!me && (
          <Stack direction={{ base: "column", md: "row" }} w="full" spacing="6">
            <InputField
              name="email"
              type="email"
              label={t("common:label.email_address")}
              control={control}
              isRequired
            />
            <PhoneField
              name="phoneNumber"
              label={t("common:label.phone_number")}
              isGeneral
              control={control}
              isRequired
            />
          </Stack>
        )}

        <Stack direction={{ base: "column", md: "row" }} w="full" spacing="6">
          <DatePicker
            name="preferredVisitingDate"
            label={t("common:label.preferred_visiting_date")}
            control={control}
            dateChange={handleDateChange}
            dateFormat="MMMM d, yyyy"
          />
          <TimeRangeField
            name="preferredVisitingTime"
            label={t("common:label.preferred_visiting_time")}
            control={control}
          />
        </Stack>

        <TextareaField
          isRequired
          name="specialRequest"
          label={t("common:label.special_request")}
          control={control}
        />

        <Button
          type="submit"
          width={{ base: "full", lg: "200px" }}
          colorScheme="primary"
          isLoading={isLoading}
        >
          {t("common:button.send")}
        </Button>
      </Stack>
    </form>
  );
};
RequestTourForm.propTypes = {
  property: PropTypes.object,
};
export default RequestTourForm;
