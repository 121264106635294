import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Icon, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import { debounce } from "lodash";
import { useForm, useWatch } from "react-hook-form";

const SearchField = ({
  placeholder,
  callback = () => {},
  debounceTime = 700,
  width = "xs",
  size = "md",
}) => {
  const { register, control, reset, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      search: "",
    },
  });
  const [readOnly, setReadOnly] = useState(true);
  const search = useWatch({ control, name: "search" });

  const handleChange = (e) => {
    const { value } = e.target;
    setValue("search", value, true);
    debouncedChange(value);
  };

  const debouncedChange = useMemo(() => {
    return debounce((value) => {
      callback(value);
    }, debounceTime);
  }, [callback, debounceTime]);

  const handleReset = () => {
    reset();
    callback("");
  };

  const handleFocus = () => {
    setReadOnly(false);
  };

  const handleBlur = () => {
    setReadOnly(true);
  };

  return (
    <InputGroup maxW={width} size={size}>
      <Input
        name="search"
        autoComplete="off"
        placeholder={placeholder}
        isReadOnly={readOnly} // fixing bug can't deactivate autofill from browser
        {...register("search")}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <InputRightElement>
        {search.length > 0 ? (
          <Icon
            w={5}
            h={5}
            onClick={handleReset}
            as={IoMdCloseCircle}
            color="gray.500"
            cursor="pointer"
          />
        ) : (
          <Icon as={BsSearch} color="gray.500" />
        )}
      </InputRightElement>
    </InputGroup>
  );
};

SearchField.propTypes = {
  placeholder: PropTypes.string,
  callback: PropTypes.func,
  debounceTime: PropTypes.number,
};

export default SearchField;
