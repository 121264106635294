import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import { Select } from "chakra-react-select";
import { withAsyncPaginate, AsyncPaginate } from "react-select-async-paginate";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton,
  Stack,
} from "@chakra-ui/react";

const CustomAsyncPaginate = withAsyncPaginate(Select);

const AutocompletePaginateField = ({
  name,
  placeholder = "Type something",
  control,
  label,
  eventInputChange = () => {},
  isRequired = false,
  isDisabled = false,
  isReadOnly = false,
  options = [],
  callback = () => {},
  isClearable = false,
  ...props
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: null,
    rules: { required: isRequired },
  });

  const handleRemoveValue = (value) => {
    if (!inputProps.onChange) return;

    inputProps.onChange(inputProps.value.filter((val) => val.value !== value));
  };

  const renderOuterSelected = () => {
    if (props?.isMulti && inputProps.value?.length > 0) {
      return (
        <Stack direction="row" mt="3" flexWrap="wrap">
          {inputProps.value?.map((item) => (
            <Tag
              key={item.label}
              sx={{
                _hover: {
                  bg: "gray.200",
                },
              }}
            >
              <TagLabel textTransform="capitalize">{item.label}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveValue(item.value)} />
            </Tag>
          ))}
        </Stack>
      );
    }
  };

  const customStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        textTransform: "capitalize",
        _selected: {
          bg: props.isMulti ? "white" : "gray.200",
        },
      };
    },
    control: (provided) => ({
      ...provided,
      textTransform: "capitalize",
      color: "gray.700",
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: "gray.500",
      fontSize: "0.8rem",
      textTransform: "uppercase",
    }),
    menu: (provided) => ({
      ...provided,
      overflow: "hidden", // fix for long options list that overflow the container scroll
      background: "white",
      border: "1px solid",
      borderColor: "gray.200",
      borderRadius: "md",
    }),
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <CustomAsyncPaginate
        useBasicStyles
        closeMenuOnSelect={!props?.isMulti}
        controlShouldRenderValue={!props?.isMulti}
        selectedOptionStyle={props?.isMulti ? "check" : "color"}
        hideSelectedOptions={false}
        chakraStyles={customStyles}
        debounceTimeout={300}
        selectRef={ref}
        name={name}
        placeholder={`${placeholder}...`}
        isClearable={isClearable}
        isSearchable={!isReadOnly}
        isDisabled={isDisabled}
        menuIsOpen={isReadOnly ? false : undefined}
        {...inputProps}
        {...props}
      />

      {renderOuterSelected()}

      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

AutocompletePaginateField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isClearable: PropTypes.bool,
  eventInputChange: PropTypes.func,
  callback: PropTypes.func,
  options: PropTypes.array,
};
export default AutocompletePaginateField;
