import React from "react";
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { AutocompleteAgentsByRole } from "../../../components/shared";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaDelegate } from "../constant";
import { useHistory, useParams } from "react-router-dom";
import { delegateProperty } from "../../../services/properties.service";
import { messageSuccessUpdate } from "../../../components/messages";

const ModalDelegate = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaDelegate),
  });
  const { loadingDelegate } = useSelector((state) => state?.properties);

  const onDelegate = async (values) => {
    const payload = {
      id,
      status: "delegated",
      responsible: {
        label: values.delegate.label,
        value: values.delegate.value,
      },
      ...values,
    };

    const res = await dispatch(delegateProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/properties");
      messageSuccessUpdate();
      reset();
    }
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Delegate
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delegate Property</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="6" color="gray.500">
              This submitted property will be sent to the selected Website Agent
              to process it.
            </Text>
            <AutocompleteAgentsByRole
              name="delegate"
              label="Website Agent"
              placeholder="Select Website Agent"
              control={control}
              role="website_agent"
              isRequired
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleSubmit(onDelegate)}
              isLoading={loadingDelegate}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDelegate;
