import * as yup from "yup";
import { emailSchema, stringSchema } from "../../utils/sharedSchema";

export const schema = yup.object().shape({
  clientName: stringSchema.default(""),
  email: emailSchema.default(""),
  status: stringSchema.default(""),
  phoneNumber: stringSchema.required("Phone number is required"),
});


export const defaultValues = {
  id: "",
  clientName: "",
  companyName: "",
  phoneNumber: "",
  email: "",
  note: "",
  spaceArea: 0,
  preferredLocation: "",
  rentalPeriod: "",
  preferredVisitingDate: new Date()
};
