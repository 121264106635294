// TODO: IF PROPERTY IS REJECTED BY ADMIN OR WEBSITE AGENT
import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { TextareaField } from "../../../components/field";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { stringSchema } from "../../../utils/sharedSchema";
import { updateProperty } from "../../../services/properties.service";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const schema = yup.object().shape({
  objection: stringSchema
});

const ModalObjection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      objection: ""
    }
  });

  const { property, loadingUpdate } = useSelector(state => state?.properties);

  const handleCancel = () => {
    reset();
    onClose();
  };

  const onSubmit = async values => {
    const payload = {
      ...values,
      id: id,
      objectionUpdatedAt: new Date().toISOString(),
      objectionStatus: "new"
    };
    const res = await dispatch(updateProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      onClose();
      reset();
      history.push("/properties");
      toast({
        position: "top-right",
        title: "Send successfully.",
        description:
          "Your objection was successfully sent. please wait for further processing.",
        status: "success",
        duration: 4000,
        isClosable: true
      });
    }
  };

  return (
    <>
      {property?.objectionStatus === "new" && (
        <Text fontWeight="bold" color="yellow.700">
          Waiting Objection Confirmation
        </Text>
      )}

      {property?.objectionStatus !== "new" && (
        <Button onClick={onOpen} colorScheme="orange">
          Send an Objection
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send an Objection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="4" color="gray.500">
              Please type your objection below to re-process your property
              again.
            </Text>
            <TextareaField
              name="objection"
              label="Objection"
              control={control}
              isRequired
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleSubmit(onSubmit)}
              isLoading={loadingUpdate}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalObjection;
