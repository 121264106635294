import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForwardComplaintMutation } from "services/complaint.services";

const ModalForwardComplaint = ({ complaint, refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const [forwardComplaint, { isLoading }] = useForwardComplaintMutation();

  const handleSubmit = async () => {
    const payload = {
      id: complaint.id,
      propertyOwnerID: complaint.propertyOwnerID,
    };

    try {
      const waiting = new Promise(async (resolve, reject) => {
        try {
          await forwardComplaint({ ...payload }).unwrap();
          onClose();
          resolve(200);
          refetch();
        } catch (error) {
          reject(error);
        }
      });

      toast.promise(waiting, {
        success: {
          position: "top-right",
          title: "Forward complaint success",
          description: "we have send the complaint to service office provider",
        },
        error: {
          position: "top-right",
          title: "Forward complaint failed",
          description:
            "We have some problem when sending forward complaint, please try again",
        },
        loading: {
          position: "top-right",
          title: "Forwarding complaint",
          description: "Please wait",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button size="sm" colorScheme="gray" onClick={onOpen}>
        Forward
      </Button>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg">Forward Complaint</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {complaint?.statusByAdmin === "forwarded" && (
              <Text fontSize="md">
                This complaint has forwarded to&nbsp;
                <b>{complaint?.propertyOwnerName}</b>, who serves as the service
                office provider.
              </Text>
            )}

            {complaint?.statusByAdmin !== "forwarded" && (
              <Text fontSize="md">
                This action will forward the complaint to&nbsp;
                <b>{complaint?.propertyOwnerName}</b>, who serves as the service
                office provider.
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            {complaint?.statusByAdmin !== "forwarded" && (
              <>
                <Button variant="ghost" size="sm" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="primary"
                  size="sm"
                  isLoading={isLoading}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalForwardComplaint;
