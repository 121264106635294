import React from "react";
import PropTypes from "prop-types";
import { Box, HStack, IconButton } from "@chakra-ui/react";
import { TableFilters, TablePagination } from "components/table";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { StatusCell } from "components/cell";
import { useGetSpecialRequestsQuery } from "services/specialRequest.services";
import { FaEye } from "react-icons/fa6";

const sortOptions = [
  { label: "Created", value: "createdAt", isDefault: true, direction: "desc" },
];

const SpecialRequestsTable = () => {
  const history = useHistory();
  const { me } = useSelector((state) => state?.auth);
  const role = me?.role;

  const [queryValue, setQueryValue] = React.useState({
    nextCursor: null,
    search: "",
    sort: "",
  });

  const {
    data: specialRequests,
    isLoading,
    isFetching,
    isError,
  } = useGetSpecialRequestsQuery({
    nextCursor: queryValue.nextCursor,
    search: queryValue.search,
    sort: queryValue.sort,
    pageSize: 20,
  });

  const handleNext = (cursor) => {
    setQueryValue({
      ...queryValue,
      nextCursor: cursor,
    });
  };

  const handleSearch = (value) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      search: value,
    });
  };
  const handleSorting = ({ field, direction }) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      sort: `${field} ${direction}`,
    });
  };

  const RenderAction = ({ selectedItem }) => (
    <HStack justifyContent="flex-end">
      <IconButton
        onClick={() => history.push(`/special-request/${selectedItem.id}`)}
        colorScheme="primary"
        aria-label="Detail"
        size="sm"
        icon={<FaEye />}
      />
    </HStack>
  );

  const columns = React.useMemo(
    () => [
      { Header: "Request Id", accessor: "generatedID" },
      { Header: "Client Name", accessor: "clientName" },
      { Header: "Company Name", accessor: "companyName" },
      { Header: "Phone Number", accessor: "phoneNumber" },
      { Header: "Email", accessor: "email" },
      { Header: "Status", accessor: "status", Cell: StatusCell },
      { Header: "", accessor: "action", isNumeric: true },
    ],
    [],
  );

  const dataMemoized = React.useMemo(
    () =>
      specialRequests?.data.map((item) => {
        return {
          id: item.id,
          generatedID: item.generatedID,
          clientName: item.clientName,
          companyName: item.companyName,
          phoneNumber: item.phoneNumber,
          email: item.email,
          status: role === "admin" ? item.statusByAdmin : item.statusByAgent,
          action: <RenderAction selectedItem={item} />,
        };
      }),
    [specialRequests?.data],
  );

  return (
    <Box>
      <TableFilters
        sortOptions={sortOptions}
        handleSearch={handleSearch}
        searchPlaceholder="Search request id..."
        handleSorting={handleSorting}
      />

      <TablePagination
        columns={columns}
        data={dataMemoized || []}
        isLoading={isLoading || isFetching}
        isError={isError}
        meta={specialRequests?.meta}
        handleNextFn={handleNext}
      />
    </Box>
  );
};

SpecialRequestsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  loadingDelete: PropTypes.bool,
  meta: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onPagination: PropTypes.func,
};

export default SpecialRequestsTable;
