import React from "react";
import { Box, Center, Spinner, VStack, Text } from "@chakra-ui/react";

const TableLoader = () => (
  <Box>
    <Box
      bg="white"
      opacity=".5"
      position="absolute"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="9"
    >
      <Center mt="12">
        <VStack>
          <Spinner color="brand.dark" />
          <Text fontSize="sm">Loading...</Text>
        </VStack>
      </Center>
    </Box>
  </Box>
);

export default TableLoader;
