import React from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import {
  Button,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import TableLoader from '../components/TableLoader';
import TableError from '../components/TableError';
import { TableEmptyData } from '../components';

import { useVirtualizer } from '@tanstack/react-virtual';

const TablePagination = ({
  columns,
  data = [],
  isLoading = false,
  isError = false,
  meta = {},
  handleNextFn,
  eventRowClick,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const parentRef = React.useRef(null);

  const virtualized = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 49, // height of each row
    paddingEnd: 41,
    overscan: 10,
  });

  if (!isLoading && rows.length === 0) {
    return <TableEmptyData />;
  }

  if (isError) {
    return <TableError />;
  }

  return (
    <Box position="relative">
      {isLoading && <TableLoader />}
      <Box height="calc(100vh - 370px)" overflowY="auto">
        <TableContainer
          ref={parentRef}
          style={{ height: `${virtualized.getTotalSize()}px` }}
        >
          <Table {...getTableProps()} size="sm" variant="simple">
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        isNumeric={header.isNumeric}
                        py="3"
                        bg="gray.50"
                      >
                        {header.render('Header')}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {virtualized.getVirtualItems().map((virtualRow, index) => {
                const row = rows[virtualRow.index];
                prepareRow(row);

                return (
                  <Tr
                    key={virtualRow.key}
                    onClick={eventRowClick}
                    ref={virtualRow.measureElement}
                    style={{
                      minHeight: `${virtualRow.size}px`,
                      transform: `translateY(${
                        virtualRow.start - index * virtualRow.size
                      }px)`,
                    }}
                    _hover={{ bg: 'gray.50' }}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => (
                      <Td
                        key={cell.id}
                        isNumeric={cell.column.isNumeric}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {meta.nextCursor && (
        <Box
          display="flex"
          justifyContent="center"
          py="2"
          bg="gray.50"
          borderBottomLeftRadius="md"
          borderBottomRightRadius="md"
          borderTop="1px solid"
          borderColor="gray.200"
        >
          <Button
            onClick={() => handleNextFn(meta.nextCursor)}
            colorScheme="gray"
            isLoading={isLoading}
            size="sm"
          >
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

TablePagination.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  meta: PropTypes.object,
  handleNextFn: PropTypes.func,
  eventRowClick: PropTypes.func,
};

export default React.memo(TablePagination);
