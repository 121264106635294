import React from "react";
import { HStack, useToast } from "@chakra-ui/react";
import { TableV1 } from "components/table";
import { ConfirmDelete } from "components/alert";
import PopupEdit from "./PopupEdit";
import {
  useGetTypesQuery,
  useDeleteTypeMutation,
} from "services/typesProperty.services";
import SomethingWrong from "components/error/SomethingWrong";

const TypesTable = () => {
  const toast = useToast();
  const [offset, setOffset] = React.useState(0);

  const {
    data: types,
    isLoading,
    isFetching,
    isError,
  } = useGetTypesQuery({
    offset: offset,
  });

  const [deleteType, { isLoading: loadingDelete }] = useDeleteTypeMutation();

  const handlePagination = (internalOffset) => {
    setOffset(internalOffset);
  };

  const handleDelete = async (id) => {
    try {
      await deleteType(id).unwrap();
      toast({
        description: `Type deleted successfully!`,
        status: "success",
        position: "top-right",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const RenderAction = ({ selectedItem }) => (
    <HStack justifyContent="flex-end">
      <PopupEdit data={selectedItem} />
      <ConfirmDelete
        iconOnly
        headerText={`Delete '${selectedItem.name}' `}
        bodyText="Are you sure? You can't undo this action afterwards."
        eventClick={() => handleDelete(selectedItem.id)}
        isLoading={loadingDelete}
      />
    </HStack>
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Property Type",
        accessor: "name",
      },
      {
        Header: "Arabic Name",
        accessor: "ar_name",
      },
      {
        Header: "Actions",
        accessor: "actions",
        isNumeric: true,
      },
    ],
    [],
  );

  const dataMemoized = React.useMemo(
    () =>
      types?.data.map((item) => {
        return {
          name: item.name,
          ar_name: item.ar_name,
          actions: <RenderAction selectedItem={item} />,
        };
      }),
    [types?.data],
  );

  if (isError) {
    return <SomethingWrong />;
  }

  return (
    <TableV1
      columns={columns}
      data={dataMemoized}
      isLoading={isLoading || isFetching}
      meta={types?.meta}
      handlePagination={handlePagination}
    />
  );
};

export default TypesTable;
