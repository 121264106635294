import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

import { setupListeners } from '@reduxjs/toolkit/query';

// NEW RTK QUERY
import { api } from '../services/api';

import layoutSlice from './layoutSlice';

import agentsSlice from './agentsSlice';
import propertiesSlice from './propertiesSlice';

import authSlice from './authSlice';
import typesPropertySlice from './typesPropertySlice';
import featuresPropertySlice from './featuresPropertySlice';
import tagsPropertySlice from './tagsPropertySlice';

// LOCATION
import locationsSlice from './location/locationsSlice';

import contractsSlice from './contractsSlice';
import menuSlice from './menuSlice';

// CLIENT
import clientPropertiesSlice from './client/clientPropertiesSlice';
import compareSlice from './compareSlice';
import favoriteSlice from './favoriteSlice';

import registerStepSlice from './registerStepSlice';

const reducers = combineReducers({
  layout: layoutSlice,
  registerStep: registerStepSlice,
  menu: menuSlice,
  compare: compareSlice,
  favorites: favoriteSlice,
  typesProperty: typesPropertySlice,

  // TODO: WILL MIGRATE TO RTK-QUERY
  auth: authSlice,
  agents: agentsSlice,
  properties: propertiesSlice,

  featuresProperty: featuresPropertySlice,
  tagsProperty: tagsPropertySlice,
  locations: locationsSlice,
  contracts: contractsSlice,
  clientProperties: clientPropertiesSlice,

  // RTK QUERY
  [api.reducerPath]: api.reducer,
});

const persistConfig = {
  key: 'root',
  storage: storage('p300'),
  whitelist: [
    'auth',
    'registerStep',
    'layout',
    'compare',
    'typesProperty',
    'locations',
    'favorites',
    'menu',
  ],
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false }).concat(
      api.middleware
    ),
});

setupListeners(store.dispatch);

export default store;
// export { store };
