import { getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";

const getUserToken = async () => {
  return new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(getAuth(), async (user) => {
      if (user) {
        const token = await getIdToken(user);
        resolve(token);
      } else {
        resolve(null);
      }
      unsub();
    });
  });
};

export default getUserToken;
