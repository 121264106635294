import React from 'react';
import { Box, Button, Heading, HStack, Link, Text } from '@chakra-ui/react';
import Layout from 'components/layout/auth';
import { Link as ReachLink, useHistory } from 'react-router-dom';
import LoginForm from 'components/form/LoginForm';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetAll, selectActiveStep } from '../../redux/registerStepSlice';
import { FaArrowLeft } from 'react-icons/fa';
import { useQuerySearch } from 'hooks';

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation('login');
  const ARABIC = i18n.language === 'ar';
  const activeStep = useSelector(selectActiveStep);
  const querySearch = useQuerySearch();

  const redirectFromProperty = querySearch.redirect?.includes('/c/property/');

  const goRegister = () => {
    // this will reset step form registration if activeStep is 3
    if (activeStep >= 3) {
      // reset step counter and form value
      dispatch(resetAll());
    }
    history.push('/auth/register');
  };

  return (
    <Layout>
      <Box maxW="md" mx="auto">
        <Heading
          color="white"
          textAlign="center"
          size="xl"
          fontWeight="extrabold"
        >
          {t('login:welcome_to')}
          <Link ml="2" as={ReachLink} to="/">
            {t('login:project_300')}
          </Link>
        </Heading>

        <HStack
          mt="4"
          mb="12"
          justify="center"
          maxW="md"
          fontWeight="medium"
          spacing="1"
        >
          <Text color="white">{t('login:dont_have_account')}</Text>
          <Text
            onClick={goRegister}
            color="primary.300"
            _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {t('login:create_account')}
          </Text>
        </HStack>

        <Box
          p={{ base: 6, md: 8, lg: 10 }}
          bg="white"
          borderRadius="2xl"
          border="1px"
          borderColor="gray.200"
          boxShadow="lg"
        >
          <HStack mb="6" justifyContent="space-between">
            <Heading size="lg" fontWeight="extrabold">
              {t('login:sign_in')}
            </Heading>
          </HStack>
          <LoginForm />

          {redirectFromProperty && (
            <Button
              width={{ base: 'full', md: 'auto' }}
              mt="6"
              mb={{ base: 2, md: 0 }}
              variant="link"
              leftIcon={ARABIC ? null : <FaArrowLeft />}
              rightIcon={ARABIC ? <FaArrowLeft /> : null}
              onClick={() => history.push(querySearch['redirect'])}
            >
              {t('back_to_property')}
            </Button>
          )}
        </Box>

        <Text mt="4" mb="8" fontWeight="medium">
          <Text color="white" as="span" mx="2">
            {t('login:dont_see_email')}
          </Text>
          <Link as={ReachLink} color="primary.300" to="/auth/verify">
            {t('login:verify_email_address')}
          </Link>
        </Text>
      </Box>
    </Layout>
  );
};

export default LoginPage;
