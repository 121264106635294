import React, { memo } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { AutocompleteAgentsByRole } from '../../../components/shared';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaDelegated } from '../constant';
import { useParams } from 'react-router-dom';
import { useDelegatedTourRequestMutation } from 'services/tour-request.services';
import { messageSuccessUpdate } from 'components/messages';

const defaultValues = {
  websiteAgent: null,
};

const ModalDelegate = ({ refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaDelegated),
    defaultValues,
  });

  // service
  const [delegatedTourRequest, { isLoading }] =
    useDelegatedTourRequestMutation();

  const onSubmit = async (values) => {
    const payload = {
      id,
      responsible: {
        label: values.websiteAgent.label,
        value: values.websiteAgent.value,
      },
    };

    try {
      await delegatedTourRequest(payload).unwrap();
      messageSuccessUpdate({
        title: 'Success',
        description: 'Tour request has been delegated',
      });
      reset(defaultValues);
      onClose();
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Delegate
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delegate Tour Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AutocompleteAgentsByRole
              name="websiteAgent"
              label="Website Agent"
              placeholder="Select Website Agent"
              control={control}
              role="website_agent"
              isRequired
              isClearable
            />

            <Text mt="4" color="gray.500" fontSize="sm">
              Note:
            </Text>
            <UnorderedList mt="2" fontSize="sm" color="gray.500">
              <ListItem>
                Submitted tour request will be sent to the selected Website
                Agent to process it. And website agent will be responsible for
                this tour request.
              </ListItem>
              <ListItem>
                When you change the website agent, previous website agent
                can&apos;t access this tour request anymore.
              </ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(ModalDelegate);
