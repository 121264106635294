import React from "react";
import Layout, { LayoutBody } from "../../../components/layout/client";
import { Box, Text } from "@chakra-ui/react";
import PropertyTypes from "./components/PropertyTypes";
import Workspaces from "./components/Workspaces";
import Regions from "./components/Regions";
import Banner from "components/client/Banner";
import HomeFilter from "components/client/HomeFilter";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Banner />
      <HomeFilter />
      <LayoutBody>
        <Box my="12">
          <PropertyTypes />
        </Box>
        <Box my="24">
          <Text fontSize="3xl" mb="8" fontWeight="semibold" align="center">
            {t("browse_by_region")}
          </Text>
          <Regions />
        </Box>
        <Box my="24">
          <Workspaces />
        </Box>
      </LayoutBody>
    </Layout>
  );
};

export default HomePage;
