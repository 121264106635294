import React, { memo } from 'react';
import { Stack } from '@chakra-ui/react';
import { SearchField } from '../../field';
import TableSorting from './TableSorting';
import TableView from './TableView';

const TableFilters = ({
  // sorting
  handleSorting,
  sortOptions,

  // search
  handleSearch,
  searchPlaceholder,

  // view
  handleView,
  viewOptions,
}) => {
  return (
    <Stack
      direction="row"
      p="3"
      w="full"
      justifyContent="space-between"
      borderBottom="1px solid"
      borderColor="gray.300"
      borderTopRadius="md"
      position="sticky"
      top="sticky.md"
      bg="white"
      zIndex="1"
    >
      {/* VIEW or TABS */}
      <Stack direction="row" justifyContent="flex-start" w="full">
        <TableView viewOptions={viewOptions} callback={handleView} />
      </Stack>

      <Stack direction="row" justifyContent="flex-end" w="full">
        {/* SEARCH */}
        <SearchField
          placeholder={searchPlaceholder || 'Search...'}
          callback={handleSearch}
          width="full"
          size="sm"
        />

        {/* SORTING */}
        <TableSorting sortOptions={sortOptions} callback={handleSorting} />
      </Stack>
    </Stack>
  );
};

export default memo(TableFilters);
