import React from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { TextareaField } from "components/field";
import { useRespondSpecialRequestMutation } from "services/specialRequest.services";
import { useSendMailMutation } from "services/mail.services";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schemaRespond = yup.object().shape({
  message: yup.string().required("Message can't be blank.").default(""),
});

const DialogRespondRequest = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { control, handleSubmit, reset, clearErrors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaRespond),
    defaultValues: { complaintRespond: "" },
  });

  const [respondSpecialRequest, { isLoading }] =
    useRespondSpecialRequestMutation();

  const [sendMail, { isLoading: isLoadingMail }] = useSendMailMutation();

  const onSubmit = async ({ message }) => {
    const payload = {
      id: data.id,
      message: message,
    };

    try {
      const waitingSendMail = new Promise(async (resolve, reject) => {
        try {
          await sendMail({
            email: data.email,
            displayName: data.clientName,
            message: message,
            template: "special-request/respond",
          }).unwrap();

          await respondSpecialRequest({ ...payload }).unwrap();

          resolve(200);

          onClose();
          reset();
        } catch (error) {
          reject(error);
        }
      });

      toast.promise(waitingSendMail, {
        success: {
          position: "top-right",
          title: "Send mail success",
          description: "we have send mail to client",
        },
        error: {
          position: "top-right",
          title: "Send mail failed",
          description:
            "We have some problem when sending mail, please try again",
        },
        loading: {
          position: "top-right",
          title: "Sending mail",
          description: "Please wait",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
    clearErrors();
    reset();
  };

  return (
    <>
      <Button
        size="sm"
        colorScheme="gray"
        onClick={onOpen}
        isDisabled={data?.statusByAdmin === "hold"}
      >
        Respond
      </Button>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg">Special Request Respond</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TextareaField
              name="message"
              label="Message"
              control={control}
              isRequired
            />
            <Text fontSize="sm" color="gray" mt="1">
              This message will be sent via email to the client.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" size="sm" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              size="sm"
              isLoading={isLoading || isLoadingMail}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DialogRespondRequest;
