import React from "react";
import PropTypes from "prop-types";
import { Center, Td, Text, Tr } from "@chakra-ui/react";

const TableEmpty = ({ columns = [], emptyText }) => (
  <Tr>
    <Td colSpan={columns.length || 0}>
      <Center p="12">
        <Text color="gray.400">{emptyText}</Text>
      </Center>
    </Td>
  </Tr>
);

TableEmpty.propTypes = {
  columns: PropTypes.array,
  emptyText: PropTypes.string
};

export default TableEmpty;
