import React, { useState } from "react";
import { Box, Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { Menu, MenuGroup, MenuItem } from "./menu";
import { useDeviceDetected } from "hooks";
import menuData from "../../config/menuData";

const MenuContent = ({ role }) => {
  const { path } = useRouteMatch();
  const splitPath = path.split("/");
  const [activeGroup, setActiveGroup] = useState(splitPath[1] || "");

  const handleActiveGroup = (val) => {
    setActiveGroup(activeGroup === val ? "" : val);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      w="xs"
      bg="primary.600"
      p="6"
      pb="24"
      position="fixed"
      top={{ base: "0", lg: "67px" }}
      h="full"
      zIndex="docked"
      overflowY="auto"
    >
      <Menu>
        {menuData.map((menuItem) => {
          if (menuItem.roles.includes(role) || menuItem.roles.length === 0) {
            return menuItem.subMenu ? (
              <MenuGroup
                key={menuItem.title}
                title={menuItem.title}
                icon={menuItem.icon}
                onClick={() => handleActiveGroup(menuItem.key)}
                isActive={activeGroup === menuItem.key}
              >
                {menuItem.subMenu.map((subMenuItem) => {
                  if (
                    subMenuItem.roles.includes(role) ||
                    subMenuItem.roles.length === 0
                  ) {
                    return (
                      <MenuItem
                        key={subMenuItem.title}
                        title={subMenuItem.title}
                        url={subMenuItem.url}
                      />
                    );
                  }
                  return null;
                })}
              </MenuGroup>
            ) : (
              <MenuItem
                key={menuItem.title}
                title={menuItem.title}
                icon={menuItem.icon}
                url={menuItem.url}
              />
            );
          }
          return null;
        })}
      </Menu>
    </Box>
  );
};

MenuContent.propTypes = {
  role: PropTypes.string,
  group: PropTypes.string,
  setGroup: PropTypes.func,
};

const Sidebar = ({ isOpen, handleClose }) => {
  const { isMobile } = useDeviceDetected();
  const { me } = useSelector((state) => state?.auth);
  const role = me?.role;

  if (isMobile) {
    return (
      <Drawer isOpen={isOpen} onClose={handleClose} placement="left">
        <DrawerOverlay>
          <DrawerContent>
            <MenuContent role={role} />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  } else {
    return <MenuContent role={role} />;
  }
};

export default Sidebar;
