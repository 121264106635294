import React, { useMemo } from 'react';
import {
  Button,
  Flex,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Step, Steps } from 'chakra-ui-steps';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetAll, selectActiveStep } from '../../../redux/registerStepSlice';
import Paper from '../../paper';
import EmailOpenedImage from './image/email_opened.svg';
import { Step1, Step2, Step3 } from './step';
import { useHistory } from 'react-router-dom';

const MultipleStepForm = () => {
  const StepperSize = useBreakpointValue(
    { base: 'sm', md: 'md' },
    { ssr: false }
  );

  const history = useHistory();
  const { t } = useTranslation('register');
  const dispatch = useDispatch();
  const activeStep = useSelector(selectActiveStep);

  const steps = useMemo(
    () => [
      { index: 1, label: t('register:step_1'), content: <Step1 /> },
      { index: 2, label: t('register:step_2'), content: <Step2 /> },
      { index: 3, label: t('register:step_3'), content: <Step3 /> },
    ],
    []
  );

  const handleGoHomepage = () => {
    dispatch(resetAll());
    history.push('/');
  };

  return (
    <Flex flexDir="column" width="100%">
      <Steps
        activeStep={activeStep}
        responsive={false}
        size={StepperSize}
        colorScheme="primary"
      >
        {steps.map(({ label, content, index }) => (
          <Step label={label} key={index}>
            {content}
          </Step>
        ))}
      </Steps>
      {activeStep === steps.length && (
        <Paper mt="12" p="12">
          <Flex width="100%" justify="center" align="center" direction="column">
            <Image src={EmailOpenedImage} w="240px" />
            <Heading mt="12">{t('register:account_created')}</Heading>

            <Text fontSize="lg" mt="2" mb="12">
              {t('register:we_have_sent')}
            </Text>

            <Button
              type="submit"
              colorScheme="primary"
              size="md"
              fontSize="md"
              onClick={handleGoHomepage}
            >
              {t('register:return_to_homepage')}
            </Button>
          </Flex>
        </Paper>
      )}
    </Flex>
  );
};

export default MultipleStepForm;
