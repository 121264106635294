import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";

const LogoutPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  // clear user session
  dispatch(logout());

  // redirect to login page
  history.push("/auth/login");

  return <div>Logout...</div>;
};

export default LogoutPage;
