import React from "react";
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useParams } from "react-router-dom";
import { updateProperty } from "../../../services/properties.service";
import { messageSuccessUpdate } from "../../../components/messages";
import { TextareaField } from "../../../components/field";
import * as yup from "yup";
import { stringSchema } from "../../../utils/sharedSchema";

const schemaRejection = yup.object().shape({
  rejectedReasons: stringSchema,
});

const ModalRejection = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaRejection),
  });
  const { me } = useSelector((state) => state?.auth);
  const { loadingUpdate } = useSelector((state) => state?.properties);

  const onReject = async (values) => {
    const payload = {
      id,
      status: "rejected",
      responsible: {
        label: me?.displayName,
        value: me?.uid,
      },
      ...values,
    };

    const res = await dispatch(updateProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/properties");
      messageSuccessUpdate();
      reset();
    }
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Reject
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject Property</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb="6" color="gray.500">
              This rejected property will be notify to the agent.
            </Text>
            <TextareaField
              isRequired
              name="rejectedReasons"
              label="Reason"
              control={control}
              placeholder="Give reasons for rejection."
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleSubmit(onReject)}
              isLoading={loadingUpdate}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalRejection;
