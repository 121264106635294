import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import PinMap from "../../maps/PinMap";

const PinMapCell = location => {
  return (
    <Box>
      <PinMap location={location} height="300px" />
    </Box>
  );
};

PinMapCell.propTypes = {
  location: PropTypes.object
};

export default PinMapCell;
