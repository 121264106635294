import React, { memo } from 'react';
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { messageSuccessUpdate } from '../../../components/messages';
import { useAcceptInquiryMutation } from 'services/inquiries.services';

const ModalAccept = ({ refetch, inquiry }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();

  // service
  const [acceptInquiry, { isLoading }] = useAcceptInquiryMutation();

  const isAccepted = inquiry?.data?.statusByAgent === 'accepted';

  const handleAccept = async () => {
    try {
      const res = await acceptInquiry({ id }).unwrap();
      messageSuccessUpdate({
        title: 'Success',
        description: res.message,
      });

      refetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button onClick={onOpen} size="sm" isDisabled={isAccepted}>
        Accept
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Accept Inquiry</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to accept this inquiry?</Text>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleAccept}
              isLoading={isLoading}
              size="sm"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(ModalAccept);
