import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NoResults = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const handleResetFilter = () => {
    history.push({
      pathname: location.pathname,
    });
  };

  return (
    <Flex
      w="full"
      alignItems="center"
      direction={"column"}
      justify="center"
      pt="8"
    >
      <Text fontSize="2xl" color="gray.700" align="center">
        {t("common:label.no_results")}
      </Text>
      <Text fontSize="xl" color="gray.600" mb="6" align="center">
        {t("common:label.try_adjusting_your_search_by_changing_your_filters")}
      </Text>
      <Button
        bg="gray.800"
        color="gray.50"
        _hover={{ bg: "gray.700" }}
        _active={{ bg: "gray.700" }}
        onClick={handleResetFilter}
      >
        {t("common:button.clear_all_filters")}
      </Button>
    </Flex>
  );
};

export default NoResults;
