import React, { Fragment, useRef, useMemo, memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Container, Heading, HStack, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

// import Swiper core and required modules
import SwiperCore, { Autoplay } from 'swiper/core';
import 'swiper/swiper.min.css';

// install Swiper modules
SwiperCore.use([Autoplay]);

// modules styles
// import "swiper/components/lazy/lazy.min.css";

const styleSlide = {
  h: '100vh',
  bgSize: 'cover',
  bgRepeat: 'no-repeat',
  p: '16rem 0 3rem',
  position: 'relative',
  _before: {
    content: '""',
    bgColor: 'rgba(0,0,0,0.5)',
    position: 'absolute',
    left: 0,
    top: 0,
    w: '100%',
    h: '100vh',
  },
};

const Banner = () => {
  const { t, i18n } = useTranslation('banner');
  const dir = i18n.dir();
  const swiperRef = useRef(null);

  const { PUBLIC_URL } = process.env;
  const BANNER_PATH = PUBLIC_URL + '/home-banner';

  const slidesData = useMemo(() => {
    return [
      {
        id: 1,
        price: 'SAR 770,000',
        title: `${t('banner:property_1.title')}`,
        label: `${t('banner:property_1.label')}`,
        imageUrl: BANNER_PATH + '/slide1.jpg',
      },
      {
        id: 2,
        price: 'SAR 1.690,000',
        title: `${t('banner:property_2.title')}`,
        label: `${t('banner:property_2.label')}`,
        imageUrl: BANNER_PATH + '/slide2.jpg',
      },
      {
        id: 3,
        price: 'SAR 770,000',
        title: `${t('banner:property_3.title')}`,
        label: `${t('banner:property_3.label')}`,
        imageUrl: BANNER_PATH + '/slide3.jpg',
      },
    ];
  }, [BANNER_PATH, t]);

  return (
    <Fragment>
      <Box
        h="100vh"
        bg="#161616"
        position="relative"
        mt="-90px"
        userSelect="none"
      >
        <Swiper
          key={dir} // without key when switching language swiper image will blank
          dir={dir}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          ref={swiperRef}
        >
          {slidesData.map((slide, index) => (
            <SwiperSlide key={slide.id}>
              <Box {...styleSlide} bgImg={`url(${slide.imageUrl})`}>
                <Container maxW={'container.xl'}>
                  <Box position="absolute">
                    <Heading
                      fontWeight="semibold"
                      size="3xl"
                      maxW="2xl"
                      mt="6"
                      color="white"
                    >
                      {slide.title}
                    </Heading>
                  </Box>
                </Container>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Container maxW="container.xl" userSelect="none">
        <Box position="absolute" zIndex="1" mt="-15rem">
          <HStack spacing="4">
            {slidesData.map((slide) => (
              <Image
                key={slide.id}
                boxSize="80px"
                objectFit="cover"
                src={slide.imageUrl}
                onClick={() => swiperRef.current.swiper.slideTo(slide.id)}
                cursor="pointer"
                _hover={{
                  border: '1px',
                  borderColor: 'red.800',
                }}
              />
            ))}
          </HStack>
        </Box>
      </Container>
    </Fragment>
  );
};

export default memo(Banner);
