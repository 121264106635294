import React from 'react';
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from 'components/layout';
import { Grid, GridItem, Heading } from '@chakra-ui/react';
import { TextHeader } from 'components/layout/header';
import Paper from 'components/paper';
import CityForm from './components/CityForm';
import CitiesTable from './components/CitiesTable';
import PaperTable from '../../../components/paper-table';

const CitiesPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Cities" />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <Grid templateColumns={['repeat(12, 1fr)']} gap={6}>
          <GridItem colSpan={[12, 12, 12, 12, 4]}>
            <Paper top="sticky.lg" position="sticky">
              <Heading fontSize="lg" mb="8">
                Add New City
              </Heading>
              <CityForm />
            </Paper>
          </GridItem>
          <GridItem colSpan={[12, 12, 12, 12, 8]}>
            <PaperTable>
              <CitiesTable />
            </PaperTable>
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};

export default CitiesPage;
