import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Link,
  Button,
  useToast,
  Stack,
  Flex,
} from '@chakra-ui/react';
import qs from 'qs';
import Layout from 'components/layout/auth';
import { Link as ReachLink, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import messages from '../../config/messages';
import { InputField } from '../../components/field';
import { useDispatch, useSelector } from 'react-redux';
import { verify } from '../../services/auth.service';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email format')
    .required(messages.required),
});

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const { t } = useTranslation('verify');
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });

  // const {loading} = useSelector(state => state?.auth);
  const { email } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const sendVerify = async (payload) => {
    setIsLoading(true);
    const res = await dispatch(verify(payload));
    setIsLoading(false);
    if (res.meta.requestStatus === 'fulfilled') {
      reset();
      toast({
        position: 'top-right',
        description: "We've send email verification to your email address.",
        status: 'success',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (email) {
      reset({
        email: email,
      });
    }
  }, [email]);

  const onSubmit = async (values) => {
    await sendVerify(values);
  };

  return (
    <Layout>
      <Box maxW="xl" mx="auto">
        <Flex
          textAlign="center"
          justify="center"
          align="center"
          direction="column"
        >
          <Heading color="white" size="xl" fontWeight="extrabold">
            {t('verify:title')}
          </Heading>
          <Text
            color="white"
            mt="4"
            mb="12"
            maxW="sm"
            fontWeight="medium"
            fontSize="lg"
          >
            {t('verify:sub_title')}
          </Text>
        </Flex>

        <Box
          p={{ base: 6, md: 8, lg: 10 }}
          bg="white"
          borderRadius="2xl"
          border="1px"
          borderColor="gray.200"
          boxShadow="lg"
        >
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack spacing="6">
              <InputField
                name="email"
                type="email"
                label={t('verify:email')}
                control={control}
                isRequired
              />
              <Button
                type="submit"
                colorScheme="primary"
                size="lg"
                fontSize="md"
                isLoading={isLoading}
              >
                {t('verify:resend_verification')}
              </Button>
            </Stack>
          </form>
        </Box>
        <Text mt="4" mb="8" fontWeight="medium">
          <Text color="white" as="span" mr="2">
            {t('verify:have_account')}
          </Text>
          <Link as={ReachLink} color="primary.300" to="/auth/login">
            {t('verify:login_to_hub')}
          </Link>
        </Text>
      </Box>
    </Layout>
  );
};

export default VerifyEmail;
