import React from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import SpecialRequestsTable from "./components/SpecialRequestsTable";
import PaperTable from "../../components/paper-table";

const SpecialRequestPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Special Request" />
        </LayoutHeaderLeft>
      </LayoutHeader>

      <LayoutBody>
        <PaperTable>
          <SpecialRequestsTable />
        </PaperTable>
      </LayoutBody>
    </Layout>
  );
};

export default SpecialRequestPage;
