import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  useDisclosure,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useHoldComplaintPropertyMutation } from "services/complaint.services";

const DialogHoldProperty = ({ complaint }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();

  const [holdComplaintProperty, { isLoading }] =
    useHoldComplaintPropertyMutation();

  const handleHold = async () => {
    await holdComplaintProperty(complaint?.id).unwrap();
    toast({
      title: "Data Updated",
      description: "Hold property successfully.",
      position: "top-right",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Hold
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg">Hold Property</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text mb="1">Are you sure you want to hold this property?</Text>
            <Link
              color="blue.500"
              href={`/property/${complaint?.complaintProperty?.value}`}
              isExternal
            >
              {complaint?.complaintProperty?.label}
            </Link>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} size="sm">
              No
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              size="sm"
              onClick={handleHold}
              isLoading={isLoading}
            >
              Yes, hold this property
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DialogHoldProperty;
