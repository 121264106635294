import React from 'react';
import Layout, { LayoutBody } from '../../../../components/layout/client';
import {
  Box,
  Text,
  Container,
  UnorderedList,
  ListItem,
  Link,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const AgentsPage = () => {
  const { t } = useTranslation('agents');

  return (
    <Layout>
      <LayoutBody>
        <Container maxW={'container.lg'}>
          <Box>
            <Text fontSize="3xl" fontWeight="bold" mb="8">
              {t('agents:title')}
            </Text>
          </Box>

          <Box mb="12">
            <Box mb="6">
              <Text fontSize="xl" fontWeight="bold" mb="2">
                {t('agents:be_one_of.title')}
              </Text>
              <Text>
                {t('agents:be_one_of.paragraph_1')}
                <br />
                <br />
                {t('agents:be_one_of.paragraph_2')}
              </Text>
            </Box>
            <Box mb="6">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:be_one_of.operators.title')}
              </Text>
              <Text mb="2">
                {t('agents:be_one_of.operators.paragraph_1')}
                <br />
                <br />
                {t('agents:be_one_of.operators.paragraph_2')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t('agents:be_one_of.operators.ordered_list.1')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.ordered_list.2')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.ordered_list.3')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.ordered_list.4')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.ordered_list.5')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.ordered_list.6')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.ordered_list.7')}
                </ListItem>
              </UnorderedList>
              <Text>
                {t('agents:be_one_of.operators.paragraph_3')}
                <br />
                {t('agents:be_one_of.operators.paragraph_4')}
              </Text>
            </Box>
            <Box mb="6">
              <Text mb="2">
                {t('agents:be_one_of.operators.benefits.title')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t('agents:be_one_of.operators.benefits.ordered_list.1')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.benefits.ordered_list.2')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.benefits.ordered_list.3')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.benefits.ordered_list.4')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.benefits.ordered_list.5')}
                </ListItem>
              </UnorderedList>
              <Text mb="4">
                {t('agents:be_one_of.operators.benefits.paragraph_1')}
              </Text>
              <Text>
                {t('agents:be_one_of.operators.benefits.paragraph_2')}
              </Text>
            </Box>
            <Box mb="6">
              <Text mb="2">
                {t('agents:be_one_of.operators.we_can_help.title')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t('agents:be_one_of.operators.we_can_help.ordered_list.1')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.we_can_help.ordered_list.2')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.we_can_help.ordered_list.3')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.we_can_help.ordered_list.4')}
                </ListItem>
                <ListItem>
                  {t('agents:be_one_of.operators.we_can_help.ordered_list.5')}
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>

          <Box mb="12">
            <Box mb="6">
              <Text fontSize="xl" fontWeight="bold" mb="2">
                {t('agents:operators.title')}
              </Text>
              <Text>
                {t('agents:operators.paragraph_1')}
                <br />
                {t('agents:operators.paragraph_2')}
                <br />
                <br />
                {t('agents:operators.paragraph_3')}
                <br />
                <br />
                {t('agents:operators.paragraph_4')}
              </Text>
            </Box>
            <Box mb="6">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:cooperate_with_project.title')}
              </Text>
              <Text mb="2">
                {t('agents:cooperate_with_project.paragraph_1')}
                <br />
                <br />
                {t('agents:cooperate_with_project.paragraph_2')}
                <br />
                <br />
                {t('agents:cooperate_with_project.paragraph_3')}
              </Text>
            </Box>

            <Box mb="6">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:how_can_you.title')}
              </Text>
              <Text mb="2">{t('agents:how_can_you.paragraph_1')}</Text>
            </Box>

            <Box mb="6">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:what_are_the.title')}
              </Text>
              <Text mb="2">{t('agents:what_are_the.paragraph_1')}</Text>
              <UnorderedList mb="6" pl="8" spacing={3}>
                <ListItem>{t('agents:what_are_the.ordered_list.1')}</ListItem>
                <ListItem>{t('agents:what_are_the.ordered_list.2')}</ListItem>
                <ListItem>{t('agents:what_are_the.ordered_list.3')}</ListItem>
                <ListItem>{t('agents:what_are_the.ordered_list.4')}</ListItem>
                <ListItem>{t('agents:what_are_the.ordered_list.5')}</ListItem>
                <ListItem>{t('agents:what_are_the.ordered_list.6')}</ListItem>
                <ListItem>{t('agents:what_are_the.ordered_list.7')}</ListItem>
                <ListItem>{t('agents:what_are_the.ordered_list.8')}</ListItem>
                <ListItem>{t('agents:what_are_the.ordered_list.9')}</ListItem>
              </UnorderedList>

              <Link color="primary.500" href={'/auth/register'}>
                <Text pl="8" fontSize="md" fontWeight="semibold">
                  {t('agents:what_are_the.link_register')}
                </Text>
              </Link>
            </Box>
          </Box>

          <Box mb="12">
            <Box mb="8">
              <Text fontSize="2xl" fontWeight="bold" mb="2">
                {t('agents:agents.title')}
              </Text>
              <Text>
                {t('agents:agents.paragraph_1')}
                <br />
                <br />
                {t('agents:agents.paragraph_2')}
              </Text>
            </Box>

            <Box mb="8">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:agents.finding_flexible_work.title')}
              </Text>
              <Text>
                {t('agents:agents.finding_flexible_work.paragraph_1')}
              </Text>
            </Box>

            <Box mb="8">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:agents.work_with_us.title')}
              </Text>
              <Text mb="2">{t('agents:agents.work_with_us.paragraph_1')}</Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t('agents:agents.work_with_us.ordered_list_1.1')}
                </ListItem>
                <ListItem>
                  {t('agents:agents.work_with_us.ordered_list_1.2')}
                </ListItem>
                <ListItem>
                  {t('agents:agents.work_with_us.ordered_list_1.3')}
                </ListItem>
                <ListItem>
                  {t('agents:agents.work_with_us.ordered_list_1.4')}
                </ListItem>
              </UnorderedList>
              <Text mb="2">{t('agents:agents.work_with_us.paragraph_2')}</Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t('agents:agents.work_with_us.ordered_list_2.1')}
                </ListItem>
                <ListItem>
                  {t('agents:agents.work_with_us.ordered_list_2.2')}
                </ListItem>
                <ListItem>
                  {t('agents:agents.work_with_us.ordered_list_2.3')}
                </ListItem>
                <ListItem>
                  {t('agents:agents.work_with_us.ordered_list_2.4')}
                </ListItem>
              </UnorderedList>
            </Box>

            <Box mb="8">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:agents.get_requests_of.title')}
              </Text>
              <Text>{t('agents:agents.get_requests_of.paragraph_1')}</Text>
            </Box>

            <Box mb="8">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:agents.advice_for_management.title')}
              </Text>
              <Text>
                {t('agents:agents.advice_for_management.paragraph_1')}
                <br />
                <br />
                {t('agents:agents.advice_for_management.paragraph_2')}
              </Text>
            </Box>

            <Box mb="8">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:agents.advertise_your_space.title')}
              </Text>
              <Text>
                {t('agents:agents.advertise_your_space.paragraph_1')}
                <br />
                <br />
                {t('agents:agents.advertise_your_space.paragraph_2')}
              </Text>
            </Box>

            <Box mb="8">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:agents.be_a_partner.title')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t('agents:agents.be_a_partner.ordered_list.1')}
                </ListItem>
                <ListItem>
                  {t('agents:agents.be_a_partner.ordered_list.2')}
                </ListItem>
                <ListItem>
                  {t('agents:agents.be_a_partner.ordered_list.3')}
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>

          <Box>
            <Box mb="8">
              <Text fontSize="2xl" fontWeight="bold" mb="2">
                {t('agents:partnerships.title')}
              </Text>
              <Text>
                {t('agents:partnerships.paragraph_1')}
                <br />
                {t('agents:partnerships.paragraph_2')}
              </Text>
            </Box>

            <Box mb="8">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:partnerships.information_base_of.title')}
              </Text>
              <Text mb="2">
                {t('agents:partnerships.information_base_of.paragraph_1')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t('agents:partnerships.information_base_of.ordered_list.1')}
                </ListItem>
                <ListItem>
                  {t('agents:partnerships.information_base_of.ordered_list.2')}
                </ListItem>
                <ListItem>
                  {t('agents:partnerships.information_base_of.ordered_list.3')}
                </ListItem>
                <ListItem>
                  {t('agents:partnerships.information_base_of.ordered_list.4')}
                </ListItem>
              </UnorderedList>
            </Box>

            <Box mb="8">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('agents:partnerships.get_more_information.title')}
              </Text>
              <Text mb="4">
                {t('agents:partnerships.get_more_information.paragraph_1')}
              </Text>
              <Link color="primary.500" href={'/c/contact-us'}>
                <Text fontSize="md" fontWeight="semibold">
                  {t('agents:partnerships.get_more_information.contact_us')}
                </Text>
              </Link>
            </Box>
          </Box>
        </Container>
      </LayoutBody>
    </Layout>
  );
};

export default AgentsPage;
