import React, { useEffect, Fragment } from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight
} from "../../../components/layout";
import {
  SimpleGrid,
  Avatar,
  Button,
  HStack,
  VStack,
  Text
} from "@chakra-ui/react";
import Paper, { PaperHeader } from "../../../components/paper";
import { TextOnly } from "../../../components/field";
import { useDispatch, useSelector } from "react-redux";
import Result from "../../../components/Result";
import { fetchAgentById } from "../../../services/agents.service";
import { useHistory, useParams } from "react-router-dom";
import { ROLE_LABEL } from "../../../config/role";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);

const AgentView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { me } = useSelector(state => state?.auth);
  const { agent, loading } = useSelector(state => state?.agents);

  const loadData = async () => {
    await dispatch(fetchAgentById(id));
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const handleEdit = () => {
    history.push(`/agent/${id}`);
  };

  return (
    <Layout>
      <Result isFound={!!agent} isLoaded={!loading}>
        <LayoutHeader>
          <LayoutHeaderLeft>
            <HStack spacing="4">
              <Avatar size="lg" src={agent?.imageURL} />
              <VStack align="start" spacing="0">
                <Text
                  textTransform="capitalize"
                  fontSize="2xl"
                  fontWeight="bold"
                >
                  {agent?.displayName}
                </Text>
                <HStack>
                  <Text color="gray.500" fontWeight="medium">
                    Registered as
                  </Text>
                  <Text
                    textTransform="capitalize"
                    color="gray.700"
                    fontWeight="medium"
                  >
                    {ROLE_LABEL[(agent?.role)] || "-"}
                  </Text>
                  <Text color="gray.500" fontWeight="medium">
                    on {dayjs(agent?.createdAt).format("LL")}
                  </Text>
                </HStack>
              </VStack>
            </HStack>
          </LayoutHeaderLeft>
          <LayoutHeaderRight>
            {me?.role === "admin" && (
              <Button onClick={handleEdit}>Edit Agent</Button>
            )}
          </LayoutHeaderRight>
        </LayoutHeader>

        <LayoutBody>
          <Paper>
            <PaperHeader>
              <Text color="gray.700" fontSize="xl" fontWeight="semibold">
                Agent Information
              </Text>
            </PaperHeader>
            <SimpleGrid columns={2} spacing={10}>
              <TextOnly label="username" value={agent?.username || "-"} />
              <TextOnly label="email" value={agent?.email || "-"} />
              <TextOnly
                label="phone number"
                value={agent?.phoneNumber || "-"}
              />
              <TextOnly
                label="company name"
                value={agent?.companyName || "-"}
              />
              <TextOnly label="city" value={agent?.city?.label || "-"} />
              <TextOnly label="bio" value={agent?.bio || "-"} />
            </SimpleGrid>
          </Paper>
        </LayoutBody>
      </Result>
    </Layout>
  );
};

export default AgentView;
