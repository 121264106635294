import React, { useEffect, useState } from "react";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { CompareIcon, FavoriteIcon } from "../../icon";
import { useTranslation } from "react-i18next";
import { VscAccount } from "react-icons/vsc";

const styleBox = {
  justifyContent: "center",
  flexDir: "column",
  alignItems: "center",
  maxW: "20%",
  minW: "0px",
  flex: "1 1 0",
};

const Index = () => {
  const { t } = useTranslation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  const { me } = useSelector((state) => state?.auth);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  const handleClick = (menuPath) => {
    // IF USER LOGGED WILL SHOW DASHBOARD MENU
    if (me && menuPath === "/auth/login") {
      history.push("/dashboard");
      return;
    }
    history.push(menuPath);
  };

  const css = {
    borderTop: "1px solid #EBEBEB",
    bg: "white",
    height: "125px",
    w: "full",
    zIndex: "1",
    position: "fixed",
    bottom: "-60px",
    pb: "60px",
    transition:
      "transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), visibility 0.2s",
    transform: visible ? undefined : "translateY(100%)",
    visibility: visible ? undefined : "hidden",
  };

  return (
    <Flex {...css}>
      <Flex display="flex" justifyContent="center" flex="1 0 auto">
        <Flex onClick={() => handleClick("/")} {...styleBox}>
          <Flex boxSize="32px" justifyContent="center" alignItems="center">
            <Icon
              as={AiOutlineSearch}
              w="24px"
              h="24px"
              color={pathname === "/" ? "red" : "brand.dark"}
            />
          </Flex>
          <Text fontSize="12px" color="brand.dark">
            {t("explore")}
          </Text>
        </Flex>

        <Flex onClick={() => handleClick("/c/my-favorites")} {...styleBox}>
          <Flex boxSize="32px" justifyContent="center" alignItems="center">
            <Icon
              as={FavoriteIcon}
              w="22px"
              h="22px"
              fillColor="transparent"
              strokeColor={
                pathname === "/c/my-favorites" ? "red" : "brand.dark"
              }
            />
          </Flex>
          <Text fontSize="12px" color="brand.dark">
            {t("favorite")}
          </Text>
        </Flex>

        <Flex
          onClick={() => handleClick("/c/compare-properties")}
          {...styleBox}
        >
          <Flex boxSize="32px" justifyContent="center" alignItems="center">
            <Icon
              as={CompareIcon}
              w="24px"
              h="24px"
              fillColor="transparent"
              strokeColor={
                pathname === "/c/compare-properties" ? "red" : "brand.dark"
              }
            />
          </Flex>
          <Text fontSize="12px" color="brand.dark">
            {t("compare")}
          </Text>
        </Flex>

        <Flex onClick={() => handleClick("/auth/login")} {...styleBox}>
          <Flex boxSize="32px" justifyContent="center" alignItems="center">
            <Icon
              as={VscAccount}
              w="22px"
              h="22px"
              color={pathname === "/auth/login" ? "red" : "brand.dark"}
            />
          </Flex>
          <Text fontSize="12px" color="brand.dark">
            {me ? `${t("dashboard")}` : `${t("login")}`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Index;
