import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { unwrapResult } from "@reduxjs/toolkit";
import { Fragment, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import Result from "../../components/Result";
import { ConfirmDelete } from "../../components/alert";
import {
  AutoComplete,
  ImageUpload,
  InputField,
  PhoneField,
  SelectField,
  TextareaField,
} from "../../components/field";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "../../components/layout";
import { TextHeader } from "../../components/layout/header";
import { messageSuccessUpdate } from "../../components/messages";
import Paper from "../../components/paper";
import {
  deleteAgent,
  fetchAgentById,
  updateAgent,
  upload,
} from "../../services/agents.service";
import { useLazyAutocompleteCitiesQuery } from "services/location/cities.services";
import {
  emailSchema,
  phoneSchema,
  stringSchema,
} from "../../utils/sharedSchema";
import {
  categoryOptions,
  defaultValues,
  fullCategoryOptions,
  onlyNewOptions,
} from "./constant";

const schema = yup.object().shape({
  displayName: stringSchema,
  role: stringSchema,
  status: stringSchema,
  email: emailSchema,
  phoneNumber: phoneSchema,
});

const AgentDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [getAutocompleteCities, { data: cities, isLoading: isLoadingCities }] =
    useLazyAutocompleteCitiesQuery();

  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);

  const { me } = useSelector((state) => state?.auth);

  const { loadingUpdate, loadingUpload, loadingDelete, agent, loading } =
    useSelector((state) => state?.agents);

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const watchStatus = useWatch({
    name: "status",
    control: control,
  });

  const disabled = watchStatus === "new";
  const isRejectedAgent = watchStatus === "rejected";

  const getStatusOptions = () => {
    if (isRejectedAgent) {
      return [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
        { label: "Rejected", value: "rejected" },
      ];
    } else {
      return [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ];
    }
  };

  const loadData = async () => {
    const { data } = unwrapResult(await dispatch(fetchAgentById(id)));
    const defaultValues = {
      username: data.username,
      displayName: data.displayName,
      role: data.role,
      companyName: data.companyName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      bio: data.bio,
      city: data.city,
      imageURL: data.imageURL,
      status: data.status,
    };

    reset(defaultValues);
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const handleInputChange = (inputValue) => {
    getAutocompleteCities({
      search: inputValue,
    });
  };

  // update agent
  const onUpdate = async (values) => {
    const payload = {
      ...values,
      id: id,
    };

    //TODO: UPDATE IMAGE TO REDUX
    const res = await dispatch(updateAgent(payload));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/agents");
      messageSuccessUpdate();
    }
  };

  // Approve
  const onApproveAgent = async (values) => {
    const payload = {
      ...values,
      status: "active",
      id: id,
    };

    setLoadingApprove(true);
    const res = await dispatch(updateAgent(payload));
    if (res.meta.requestStatus === "fulfilled") {
      messageSuccessUpdate();
      await dispatch(fetchAgentById(id));
      setLoadingApprove(false);
      reset(res.payload.data);
    }
  };

  // Reject
  const onRejectAgent = async (values) => {
    const payload = {
      ...values,
      status: "rejected",
      id: id,
    };

    setLoadingReject(true);
    const res = await dispatch(updateAgent(payload));
    if (res.meta.requestStatus === "fulfilled") {
      messageSuccessUpdate();
      await dispatch(fetchAgentById(id));
      setLoadingReject(false);
      reset(payload);
    }
  };

  const handleUpload = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      data: file,
    };
    const res = await dispatch(upload(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData(res.payload.imageURL);

      // save thumbnail image
      setValue("thumbnailImageURL", res.payload.thumbnailImageURL);
    }
  };

  const handleDelete = async () => {
    const res = await dispatch(deleteAgent(id));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/agents");
    }
  };

  return (
    <Layout>
      <Result isFound={!!agent} isLoaded={!loading}>
        <LayoutHeader>
          <LayoutHeaderLeft>
            <TextHeader text="Agent Details" />
          </LayoutHeaderLeft>
          <LayoutHeaderRight>
            {agent?.status !== "new" && agent?.status !== "waiting" && (
              <Button
                colorScheme="primary"
                onClick={handleSubmit(onUpdate)}
                isLoading={loadingUpdate}
              >
                Save Changes
              </Button>
            )}
            {agent?.status === "new" && (
              <Fragment>
                <Button
                  colorScheme="green"
                  isLoading={loadingApprove}
                  onClick={handleSubmit(onApproveAgent)}
                >
                  Approve Agent
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={loadingReject}
                  onClick={handleSubmit(onRejectAgent)}
                >
                  Reject Agent
                </Button>
              </Fragment>
            )}
          </LayoutHeaderRight>
        </LayoutHeader>

        <LayoutBody>
          {disabled && (
            <Box mb="4">
              <Alert status="warning">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Data is not editable!</AlertTitle>
                  <AlertDescription display="block">
                    You can change the data after <b>Approved</b> this agent.
                  </AlertDescription>
                </Box>
              </Alert>
            </Box>
          )}

          {agent?.status === "waiting" && (
            <Box mb="4">
              <Alert status="warning">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Waiting!</AlertTitle>
                  <AlertDescription display="block">
                    waiting confirmation from the user.
                  </AlertDescription>
                </Box>
              </Alert>
            </Box>
          )}

          <Grid
            templateColumns={{ base: "repeat(3, 1fr)", lg: "repeat(6, 1fr)" }}
            gap={6}
          >
            <GridItem colSpan="4">
              <Paper>
                <VStack spacing="6">
                  <HStack w="full" spacing="6">
                    <InputField
                      name="username"
                      label="Username"
                      control={control}
                      isDisabled
                    />
                    <InputField
                      name="displayName"
                      label="Agent Name"
                      control={control}
                      isRequired
                      isReadOnly={disabled}
                    />
                  </HStack>
                  <HStack w="full" spacing="6">
                    <SelectField
                      w={["full", "full", "calc(50% - 12px)"]}
                      name="role"
                      label="Agent Category"
                      control={control}
                      options={
                        me?.role === "admin"
                          ? fullCategoryOptions
                          : categoryOptions
                      }
                      isRequired
                      isReadOnly={disabled}
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <InputField
                      name="companyName"
                      label="Company Name"
                      control={control}
                      isReadOnly={disabled}
                    />
                    <AutoComplete
                      name="city"
                      label="City"
                      placeholder="Select City"
                      control={control}
                      options={cities ? cities.data : []}
                      eventInputChange={handleInputChange}
                      isLoading={isLoadingCities}
                      isReadOnly={disabled}
                    />
                  </HStack>
                  <TextareaField
                    name="bio"
                    label="Bio"
                    control={control}
                    isReadOnly={disabled}
                  />
                  <HStack w="full" spacing="6">
                    <InputField
                      name="email"
                      type="email"
                      label="Email"
                      control={control}
                      isRequired
                      isReadOnly={disabled}
                    />
                    <PhoneField
                      name="phoneNumber"
                      label="Phone Number"
                      control={control}
                      isReadOnly={disabled}
                    />
                  </HStack>
                  <ImageUpload
                    name="imageURL"
                    label={"Image"}
                    isLoading={loadingUpload}
                    control={control}
                    callback={handleUpload}
                    isDisabled={disabled}
                  />
                </VStack>
              </Paper>
            </GridItem>

            <GridItem colSpan={{ base: 4, lg: 2 }}>
              <Paper mb="8">
                <VStack spacing="6" align="start">
                  <SelectField
                    name="status"
                    label="Status"
                    placeholder="Select Status"
                    options={disabled ? onlyNewOptions : getStatusOptions()}
                    allowedUnregistered
                    control={control}
                    isDisabled={disabled || watchStatus === "waiting"}
                    isRequired
                  />
                </VStack>
              </Paper>

              {!disabled && (
                <Paper>
                  <VStack spacing="2" align="start">
                    <Text fontWeight="bold">Danger zone</Text>
                    <Text pb="4">
                      Once you delete this <strong>agent</strong>, there is no
                      going back. Please be certain.
                    </Text>
                    <ConfirmDelete
                      headerText="Delete Agent"
                      bodyText="Are you sure you want delete this agent?"
                      buttonText="Remove this agent"
                      eventClick={handleDelete}
                      isLoading={loadingDelete}
                      isDisabled={agent?.role === "admin"}
                    />
                  </VStack>
                </Paper>
              )}
            </GridItem>
          </Grid>
        </LayoutBody>
      </Result>
    </Layout>
  );
};

export default AgentDetailsPage;
