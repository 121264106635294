import React from "react";
import { useMedia } from "react-use";

const useDeviceDetected = () => {
  const isMobile = useMedia("(max-width: 992px)");

  return React.useMemo(
    () => ({
      isMobile,
    }),
    [isMobile],
  );
};

export default useDeviceDetected;
