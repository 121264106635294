import ComplaintPage from "../../pages/complaint";
import ComplaintDetails from "../../pages/complaint/details";

const complaintRoutes = [
  {
    path: "/complaints",
    component: ComplaintPage,
    exact: true,
  },
  { path: "/complaints/:id", component: ComplaintDetails, exact: true },
];

export default complaintRoutes;
