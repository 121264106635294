import React from "react";
import PropTypes from "prop-types";
import { Button, VStack } from "@chakra-ui/react";
import { InputField } from "components/field";
import {
  useAddAccountTypeMutation,
  useUpdateAccountTypeMutation,
} from "services/accountType.services";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import messages from "config/messages";
import { catchError } from "utils/catchError";

const schema = yup.object().shape({
  name: yup.string().required(messages.required),
  ar_name: yup.string().required(messages.required),
});

const AccountTypeForm = ({ data, onClose }) => {
  const [addAccountType, { isLoading: isLoadingAdd }] =
    useAddAccountTypeMutation();
  const [updateAccountType, { isLoading: isLoadingUpdate }] =
    useUpdateAccountTypeMutation();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.name || "",
      ar_name: data?.ar_name || "",
    },
  });

  const handlingUpdateData = async (values) => {
    const payload = {
      ...values,
      id: data.id,
    };

    try {
      await updateAccountType({ ...payload }).unwrap();
      onClose();
    } catch (error) {
      console.error(catchError(error));
    }
  };

  const handlingAddData = async (values) => {
    // remove data from edit form, because we use same component
    if (values.data) delete values.data;

    try {
      await addAccountType({ ...values }).unwrap();
      reset();
      onClose();
    } catch (error) {
      console.error(catchError(error));
    }
  };

  const onSubmit = async (values) => {
    // EDIT DATA
    if (data) {
      await handlingUpdateData(values);
      return;
    }

    // ADD DATA
    await handlingAddData(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing="6" alignItems="flex-start">
        <InputField name="name" label="Name" control={control} isRequired />
        <InputField
          isRTL
          name="ar_name"
          label="Arabic Name"
          control={control}
          isRequired
        />

        <Button
          type="submit"
          colorScheme="primary"
          isLoading={data ? isLoadingUpdate : isLoadingAdd}
        >
          {data ? "Update" : "Add New"}
        </Button>
      </VStack>
    </form>
  );
};

AccountTypeForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  // onUpdate: PropTypes.func,
};

export default AccountTypeForm;
