import { api, invalidateOn } from "./api";
import { savePersisted } from "../redux/typesPropertySlice";

const endpoint = "/property-types";

const typePropertyApi = api.injectEndpoints({
  tagTypes: ["TypeProperty"],
  endpoints: (build) => ({
    autocompleteTypes: build.query({
      query: (payload) => ({
        url: `${endpoint}/autocomplete`,
        method: "GET",
        params: {
          offset: payload?.offset || 0,
          limit: 30,
          ...payload,
        },
      }),
      providesTags: ["TypeProperty"],
    }),

    getTypes: build.query({
      query: (payload) => ({
        url: `${endpoint}`,
        method: "GET",
        params: {
          offset: payload?.offset || 0,
          limit: 6,
          ...payload,
        },
      }),
      providesTags: ["TypeProperty"],
    }),

    getTypesExplore: build.query({
      query: () => ({
        url: `${endpoint}/explore`,
        method: "GET",
      }),
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        dispatch(savePersisted(data));
      },
      providesTags: ["TypeProperty"],
    }),

    getType: build.query({
      query: (payload) => ({
        url: `${endpoint}/${id}`,
        method: "GET",
      }),
    }),

    addType: build.mutation({
      query: (data) => ({
        url: `${endpoint}`,
        method: "POST",
        data: data,
      }),
      invalidatesTags: invalidateOn({ success: ["TypeProperty"] }),
    }),

    updateType: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: invalidateOn({ success: ["TypeProperty"] }),
    }),

    deleteType: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TypeProperty"],
    }),
  }),
});

export const {
  useLazyAutocompleteTypesQuery,
  useGetTypesQuery,
  useGetTypesExploreQuery,
  useGetTypeQuery,
  useAddTypeMutation,
  useUpdateTypeMutation,
  useDeleteTypeMutation,
} = typePropertyApi;
