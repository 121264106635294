import React from "react";
import PropTypes from "prop-types";
import { Text, Tooltip } from "@chakra-ui/react";

const AttentionCell = ({ row: { original }, value }) => {
  if (original.statusByAgent === "rejected") {
    return (
      <Tooltip label="Rejected By Agent" aria-label="A tooltip">
        <Text color="red.500">{value}</Text>
      </Tooltip>
    );
  } else if (original.statusByAgent === "new") {
    return (
      <Tooltip label="Waiting Responsible from Agent" aria-label="A tooltip">
        <Text color="yellow.700">{value}</Text>
      </Tooltip>
    );
  } else {
    return <Text>{value}</Text>;
  }
};

AttentionCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object
};

export default AttentionCell;
