//NOTE: DON"T USE REACT-SELECT Async, because bug with react-hook form
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tag,
  TagLabel,
  TagCloseButton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useController } from "react-hook-form";
import { Select } from "chakra-react-select";

const AutoComplete = ({
  name,
  placeholder = "Type something",
  control,
  label,
  eventInputChange = () => {},
  isRequired = false,
  isDisabled = false,
  isReadOnly = false,
  options = [],
  callback = () => {},
  isClearable = false,
  ...props
}) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: null,
    rules: { required: isRequired },
  });

  const debouncedChange = useMemo(() => {
    return debounce((inputValue) => eventInputChange(inputValue), 700);
  }, [eventInputChange]);

  const handleInput = (inputValue, event) => {
    // custom change to handle only event is input-change
    if (event.action === "input-change") {
      debouncedChange(inputValue);
    }
  };

  const handleChange = (selectedOption) => {
    onChange(selectedOption);

    // callback only call when value is different with before
    if (selectedOption !== value) {
      callback(selectedOption);
    }
  };

  const onMenuOpen = () => {
    // request data to server when pop is open and user still not selected data.
    eventInputChange("");
  };

  const handleRemoveValue = (selected) => {
    if (!onChange) return;

    onChange(value.filter((val) => val.value !== selected));
  };

  const renderOuterSelected = () => {
    if (props?.isMulti && value?.length > 0) {
      return (
        <Stack direction="row" mt="3" flexWrap="wrap">
          {value?.map((item) => (
            <Tag
              key={item.label}
              sx={{
                _hover: {
                  bg: "gray.200",
                },
              }}
            >
              <TagLabel textTransform="capitalize">{item.label}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveValue(item.value)} />
            </Tag>
          ))}
        </Stack>
      );
    }
  };

  const customStyles = {
    option: (provided) => {
      return {
        ...provided,
        textTransform: "capitalize",
        _selected: {
          bg: props.isMulti ? "white" : "gray.200",
        },
      };
    },
    control: (provided) => ({
      ...provided,
      textTransform: "capitalize",
    }),
    groupHeading: (provided) => ({
      ...provided,
      color: "gray.500",
      fontSize: "0.8rem",
      textTransform: "uppercase",
    }),
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Select
        useBasicStyles
        controlShouldRenderValue={!props?.isMulti}
        selectedOptionStyle={props?.isMulti ? "check" : "color"}
        hideSelectedOptions={false}
        chakraStyles={customStyles}
        ref={ref}
        name={name}
        placeholder={`${placeholder}...`}
        value={value}
        onMenuOpen={onMenuOpen}
        onInputChange={handleInput}
        isClearable={isClearable}
        isSearchable={!isReadOnly}
        isDisabled={isDisabled}
        menuIsOpen={isReadOnly ? false : undefined}
        options={options}
        onChange={handleChange}
        {...inputProps}
        {...props}
      />
      {renderOuterSelected()}
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

AutoComplete.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isClearable: PropTypes.bool,
  eventInputChange: PropTypes.func,
  callback: PropTypes.func,
  options: PropTypes.array,
};
export default AutoComplete;
