import React from "react";
import Layout, { LayoutBody } from "../../../components/layout/client";
import {
  Button,
  Box,
  Text,
  VStack,
  Grid,
  GridItem,
  SimpleGrid,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import {
  InputField,
  DatePicker,
  SelectField,
  TextareaField,
  NumberField,
  PhoneField,
  CheckBoxField,
} from "../../../components/field";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  emailSchema,
  numberGteZeroSchema,
  stringSchema,
  phoneRequiredSchema,
} from "../../../utils/sharedSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAddSpecialRequestMutation } from "../../../services/specialRequest.services";
import { defaultValues } from "../../specialRequest/constant";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  clientName: stringSchema,
  email: emailSchema,
  rentalPeriod: stringSchema,
  preferredLocation: stringSchema,
  spaceArea: numberGteZeroSchema,
  phoneNumber: phoneRequiredSchema,
  numberOfPeople: numberGteZeroSchema,
  numberOfOffices: numberGteZeroSchema,
});

const SpecialSpacePage = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const formatArea = (val) => val + ` Sq m`;

  //Add special request
  const [addSpecialRequest, { isLoading }] = useAddSpecialRequestMutation();

  const { control, handleSubmit, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (values) => {
    const { term } = values;

    if (!term) {
      toast({
        description: `${t("checked_term")}`,
        position: "top-right",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const payload = {
      ...values,
    };

    await addSpecialRequest(payload).unwrap();

    toast({
      title: `${t("sent_successfully")}`,
      description: `${t("your_special_request_has_been_sent_successfully")}`,
      position: "top-right",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
    reset();
  };

  const options = [
    { label: `${t("common:period.1_months")}`, value: "1 months" },
    { label: `${t("common:period.3_months")}`, value: "3 months" },
    { label: `${t("common:period.6_months")}`, value: "6 months" },
    { label: `${t("common:period.12_months")}`, value: "12 months" },
    { label: `${t("common:period.long_term")}`, value: "long term" },
  ];

  return (
    <Layout>
      <LayoutBody>
        <Box>
          <Text fontSize="3xl">
            {t("common:label.can_not_find_what_you_are_looking_for")}
          </Text>
          <Text>{t("common:label.send_us_your_special_request")}</Text>
        </Box>
        <Box my="8">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid templateColumns={["repeat(12, 1fr)"]} gap={6}>
              <GridItem colSpan={[12, 12, 8]}>
                <VStack spacing="12" align="start">
                  <SimpleGrid
                    columns={{ base: 1, xl: 2 }}
                    spacing={12}
                    w="full"
                  >
                    <InputField
                      name="clientName"
                      label={t("common:label.name")}
                      control={control}
                      isRequired
                    />
                    <InputField
                      name="companyName"
                      label={t("common:label.company_name")}
                      control={control}
                    />
                    <InputField
                      name="email"
                      type="email"
                      label={t("common:label.email_address")}
                      control={control}
                      isRequired
                    />
                    <PhoneField
                      name="phoneNumber"
                      label={t("common:label.phone_number")}
                      control={control}
                      isRequired
                    />
                  </SimpleGrid>

                  <TextareaField
                    name="note"
                    label={t("common:label.what_are_you_looking_for")}
                    control={control}
                  />

                  <SimpleGrid
                    columns={{ base: 1, xl: 2 }}
                    spacing={12}
                    w="full"
                  >
                    <NumberField
                      name="spaceArea"
                      label={t("common:label.space_area")}
                      masking={formatArea}
                      control={control}
                      isRequired
                    />
                    <InputField
                      name="preferredLocation"
                      label={t("common:label.preferred_location")}
                      control={control}
                      isRequired
                    />
                    <SelectField
                      name="rentalPeriod"
                      label={t("common:label.rental_period")}
                      placeholder={t("common:placeholder.select_period")}
                      control={control}
                      options={options}
                      isRequired
                    />
                    <DatePicker
                      name="preferredVisitingDate"
                      label={t(
                        "common:label.when_do_you_want_to_get_the_space",
                      )}
                      control={control}
                      dateFormat="MMMM d, yyyy"
                    />

                    <NumberField
                      name="numberOfPeople"
                      label={t("common:label.number_of_people")}
                      control={control}
                    />
                    <NumberField
                      name="numberOfOffices"
                      label={t("common:label.number_of_offices")}
                      control={control}
                    />
                  </SimpleGrid>

                  <CheckBoxField
                    name="term"
                    colorScheme="primary"
                    control={control}
                    label={t("term_label")}
                  />

                  <Button
                    type="submit"
                    colorScheme="primary"
                    isLoading={isLoading}
                  >
                    {t("common:button.send")}
                  </Button>
                </VStack>
              </GridItem>
            </Grid>
          </form>
        </Box>
      </LayoutBody>
    </Layout>
  );
};

export default SpecialSpacePage;
