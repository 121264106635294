import React from "react";

import PropTypes from "prop-types";
import {
  Link,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

const LinkField = ({
  name,
  control,
  label,
  helperText = "",
  isRequired = false,
  baseURL = "",
  type = "link",
  ...props
}) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: { required: isRequired },
  });

  const handleShowLink = () => {
    window.open(`${baseURL}/${value?.value}`, "_blank");
  };

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>

      {type === "link" &&
        (value?.label ? (
          <Link color="blue.500" href={`${baseURL}/${value?.value}`} isExternal>
            {`${value?.label}`}
          </Link>
        ) : (
          "-"
        ))}

      {type === "input" && (
        <InputGroup size="md">
          <Input
            ref={ref}
            name={name}
            id={name}
            pr="4.5rem"
            value={value?.label || ""}
            isReadOnly
            {...inputProps}
            {...props}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleShowLink}>
              show
            </Button>
          </InputRightElement>
        </InputGroup>
      )}
    </FormControl>
  );
};

LinkField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  baseURL: PropTypes.string,
};

export default LinkField;
