import React from "react";
import { get } from "lodash";
import { SimpleGrid, Box, HStack, Text, Skeleton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { paymentPeriodic } from "../../../../../utils/constant";

const boxStyle = {
  bg: "white",
  px: "4",
  py: "4",
  border: "1px solid",
  borderColor: "gray.300",
  borderRadius: "md",
  _hover: { boxShadow: "md" },
};

const PriceSection = () => {
  const { t, i18n } = useTranslation();

  const { property, loadingSingle } = useSelector(
    (state) => state?.clientProperties,
  );

  return (
    <SimpleGrid mb="12" columns={{ base: 1, md: 3 }} spacing={6}>
      <Skeleton isLoaded={!loadingSingle}>
        <Box {...boxStyle}>
          <HStack justify="space-between">
            <Text fontSize="lg">{t("common:label.price")}</Text>
            <HStack>
              <Text fontSize="lg" color="gray.700" fontWeight="semibold">
                {`${get(property, "price", 0)} SAR`}
              </Text>
              {/*<Text>/ {t("common:label.month")}</Text>*/}
              <Text>/ {paymentPeriodic[property?.payment]}</Text>
            </HStack>
          </HStack>
        </Box>
      </Skeleton>

      <Skeleton isLoaded={!loadingSingle}>
        <Box {...boxStyle}>
          <HStack justify="space-between">
            <Text fontSize="lg">{t("common:label.number_of_people")}</Text>
            <Text fontSize="lg" color="gray.700" fontWeight="semibold">
              {get(property, "numberOfPeople", 0)}
            </Text>
          </HStack>
        </Box>
      </Skeleton>

      <Skeleton isLoaded={!loadingSingle}>
        <Box {...boxStyle}>
          <HStack justify="space-between">
            <Text fontSize="lg">{t("common:label.area")}</Text>
            <Text fontSize="lg" color="gray.700" fontWeight="semibold">
              {`${get(property, "area", 0)} ${t("common:label.sq_m")}`}
            </Text>
          </HStack>
        </Box>
      </Skeleton>
    </SimpleGrid>
  );
};

export default PriceSection;
