import { axiosInstance } from "./fetchApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchError } from "../utils/catchError";

const endpoint = "/agents";

export const checkUsername = createAsyncThunk(
  `${endpoint}/check`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(`${endpoint}/check`, payload);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const fetchAutocompleteAgentsByRole = createAsyncThunk(
  `${endpoint}/autocompleteByRole`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/autocomplete`, {
        params: {
          search: payload?.search || "",
          role: payload?.role || "",
        },
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const fetchAutocompleteAgents = createAsyncThunk(
  `${endpoint}/autocomplete`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/autocomplete`, {
        params: {
          offset: payload?.offset || 0,
          search: payload?.search || "",
          role: payload?.role || "",
          limit: 6,
        },
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const fetchAgents = createAsyncThunk(
  `${endpoint}/fetchAll`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(endpoint, {
        params: {
          offset: payload?.offset || 0,
          search: payload?.search || "",
          limit: 6,
          ...payload,
        },
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const fetchAgentById = createAsyncThunk(
  `${endpoint}/fetchById`,
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const createAgent = createAsyncThunk(
  `${endpoint}/create`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(endpoint, payload);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const updateAgent = createAsyncThunk(
  `${endpoint}/update`,
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`${endpoint}/${id}`, {
        ...payload,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteAgent = createAsyncThunk(
  `${endpoint}/delete`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const upload = createAsyncThunk(
  `/v1/upload/agent-profile-picture`,
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("image", payload?.data);
      const response = await axiosInstance.post(
        `/v1/upload/agent-profile-picture`,
        formData,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
