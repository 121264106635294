import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Checkbox
} from "@chakra-ui/react";
import {useController, useWatch} from "react-hook-form";

const CheckBoxField = ({
   name,
   control,
   label,
   helperText = "",
   isRequired = false,
   eventChange = () => {
   },
   ...props
 }) => {
  const {
    field: {ref, value, onChange, ...inputProps},
    fieldState: {invalid, isTouched, isDirty, error}
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: {required: isRequired}
  });

  const handleChange = value => {
    eventChange(value);
    onChange(value);
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <Checkbox
        ref={ref}
        name={name}
        id={name}
        isChecked={value}
        onChange={handleChange}
        {...props}
        {...inputProps}
      >
        {label}
      </Checkbox>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

CheckBoxField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  eventChange: PropTypes.func
};

export default CheckBoxField;
