import React from "react";
import { createStandaloneToast } from "@chakra-ui/react";
const { toast } = createStandaloneToast();

const messageSuccessSave = (options) => {
  toast({
    position: "top-right",
    description: options?.description || "Successfully saved data.",
    status: "success",
    duration: 3000,
    isClosable: true,
  });
};

const messageSuccessUpdate = (options) => {
  toast({
    position: "top-right",
    title: options?.title || "Success",
    description: options?.description || "Successfully updated data.",
    status: "success",
    duration: 3000,
    isClosable: true,
  });
};

const messageSuccessDelete = (options) => {
  toast({
    position: "top-right",
    description: options?.description || "Successfully deleted data.",
    status: "success",
    duration: 3000,
    isClosable: true,
  });
};
export { messageSuccessSave, messageSuccessUpdate, messageSuccessDelete };
