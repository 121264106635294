import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Checkbox, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { CheckBoxGroupField } from "components/field";
import { useTranslation } from "react-i18next";
import { useGetTypesExploreQuery } from "services/typesProperty.services";

const FilterSpaceType = ({ control, name, eventChange = () => {} }) => {
  const { i18n } = useTranslation();
  const ARABIC = i18n.language === "ar";

  useGetTypesExploreQuery();

  const { persistedTypes } = useSelector((state) => state?.typesProperty);

  return (
    <Box>
      <CheckBoxGroupField
        name={name}
        control={control}
        colorScheme="primary"
        itemDirections={["column"]}
        eventChange={eventChange}
      >
        {persistedTypes &&
          persistedTypes.map((item, index) => (
            <Fragment key={index}>
              <Checkbox value={item.name} colorScheme={"primary"}>
                <Text textTransform="capitalize">
                  {ARABIC ? item.ar_name : item.name}
                </Text>
              </Checkbox>
            </Fragment>
          ))}
      </CheckBoxGroupField>
    </Box>
  );
};

FilterSpaceType.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  eventChange: PropTypes.func,
};

export default FilterSpaceType;
