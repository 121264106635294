import React from "react";
import {
  Box,
  Heading,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IconWithFallback } from "../../../../../components/base";
import PinMap from "components/maps/PinMap";
import Paper from "components/paper";
import RequestTourForm from "../form/RequestTourForm";
import RaiseComplaintForm from "../form/RaiseComplaintForm";

const DetailSection = () => {
  const { t, i18n } = useTranslation();
  const ARABIC = i18n.language === "ar";

  const { property, loadingSingle } = useSelector(
    (state) => state?.clientProperties,
  );

  return (
    <>
      <Box mb="8" borderBottom="1px" pb="8" borderColor="gray.300">
        <Heading mb="4" fontSize="xl" fontWeight="semibold">
          {t("common:label.description")}
        </Heading>
        <Skeleton isLoaded={!loadingSingle}>
          <Text>{property?.description}</Text>
        </Skeleton>
      </Box>

      <Box mb="8" borderBottom="1px" pb="8" borderColor="gray.300">
        <Heading mb="4" fontSize="xl" fontWeight="semibold">
          {t("common:label.features")}
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb="6">
          {property?.features.map((item) => (
            <Box key={item.value}>
              <HStack>
                <IconWithFallback src={item.icon} />
                <Text textTransform="capitalize">
                  {ARABIC ? item.ar_label : item.label}
                </Text>
              </HStack>
            </Box>
          ))}
        </SimpleGrid>
      </Box>

      <Box mb="8" borderBottom="1px" pb="8" borderColor="gray.300">
        <Heading mb="4" fontSize="xl" fontWeight="semibold">
          {t("common:label.capacity_of_conference_rooms")}
        </Heading>
        <Skeleton isLoaded={!loadingSingle}>
          <Text>{property?.numberOfConferenceRooms}</Text>
        </Skeleton>
      </Box>

      <Box
        id="pin-map-location"
        mb="8"
        borderBottom="1px"
        pb="8"
        borderColor="gray.300"
      >
        <Heading mb="4" fontSize="xl" fontWeight="semibold">
          {t("common:label.location")}
        </Heading>
        <Skeleton isLoaded={!loadingSingle}>
          <PinMap location={property?.pinMapLocation} />
        </Skeleton>
      </Box>

      <Box
        id="request-tour"
        mb="8"
        borderBottom="1px"
        pb="8"
        borderColor="gray.300"
      >
        <Heading fontSize="xl" fontWeight="semibold" mb="6">
          {t("common:label.request_a_tour")}
        </Heading>
        <Paper border="1px" borderColor="gray.300" boxShadow="lg">
          <RequestTourForm property={property} />
        </Paper>
      </Box>

      <Box id="raise-complaint">
        <Heading fontSize="xl" fontWeight="semibold" mb="6">
          {t("common:label.raise_a_complaint")}
        </Heading>

        <Paper border="1px" borderColor="gray.300" boxShadow="lg">
          <RaiseComplaintForm property={property} />
        </Paper>
      </Box>
    </>
  );
};
export default DetailSection;
