import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCO6pVTLXh4hYkvTkA-YijE4EvjI3VwXHA',
  authDomain: 'p300-e2855.firebaseapp.com',
  projectId: 'p300-e2855',
  storageBucket: 'p300-bucket', // custom bucket, created manually
  messagingSenderId: '813847637767',
  appId: '1:813847637767:web:7bff664fa6fc727d439fa2',
  measurementId: 'G-F1SRJDRH85',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

if (location.hostname === 'localhost') {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
}

export { auth };
