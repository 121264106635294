import React from "react";
import PropTypes from "prop-types";
import { Text, FormControl, FormLabel } from "@chakra-ui/react";

const TextOnly = ({ label, value }) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Text color="gray.500">{value}</Text>
    </FormControl>
  );
};

TextOnly.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

export default TextOnly;
