import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Image } from "@chakra-ui/react";
import SwiperCore, { Autoplay, Thumbs } from "swiper/core";
import "swiper/swiper-bundle.css";

import "./ImageSlider.style.css";
import ReactBnbGallery from "react-bnb-gallery";

// install Swiper modules
SwiperCore.use([Autoplay, Thumbs]);

const ImageSlider = ({ slidesData = [] }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [openGallery, setOpenGallery] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const handleOpenGallery = (index) => {
    setPhotoIndex(index);
    setOpenGallery(true);
  };

  return (
    <Fragment>
      <Swiper
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <Image
              src={slide?.largeImageURL || slide}
              objectFit="cover"
              h="375px"
              w="full"
              fallbackSrc={
                process.env.PUBLIC_URL + "/images/image-placeholder-swipe.webp"
              }
              onClick={() => handleOpenGallery(index)}
              cursor="pointer"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box mt="4">
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={8}
          freeMode={true}
          watchSlidesVisibility={true}
          watchSlidesProgress={true}
          className="swiper-thumb"
        >
          {slidesData.map((slide, index) => (
            <SwiperSlide key={index}>
              <Image
                src={slide?.thumbnailImageURL || slide}
                objectFit="cover"
                boxSize="80px"
                fallbackSrc={
                  process.env.PUBLIC_URL + "/images/image-placeholder-80.webp"
                }
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <ReactBnbGallery
        activePhotoIndex={photoIndex}
        show={openGallery}
        photos={slidesData.map((slide) => ({
          photo: slide.largeImageURL || slide,
          thumbnail: slide.thumbnailImageURL || slide,
        }))}
        onClose={() => setOpenGallery(false)}
      />
    </Fragment>
  );
};

ImageSlider.propTypes = {
  slidesData: PropTypes.array,
};

export default ImageSlider;
