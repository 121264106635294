import React from "react";
import { HStack, Box, IconButton } from "@chakra-ui/react";
import { TablePagination, TableFilters } from "components/table";
import { FaEye } from "react-icons/fa6";

import { useGetComplaintsQuery } from "../../../services/complaint.services";
import { StatusCell } from "../../../components/cell";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const sortOptions = [
  { label: "Created", value: "createdAt", isDefault: true, direction: "desc" },
];

const ComplaintTable = () => {
  const history = useHistory();
  const { me } = useSelector((state) => state?.auth);
  const role = me?.role;

  const [queryValue, setQueryValue] = React.useState({
    nextCursor: null,
    search: "",
    sort: "",
  });

  const {
    data: complaints,
    isLoading,
    isFetching,
    isError,
  } = useGetComplaintsQuery({
    nextCursor: queryValue.nextCursor,
    search: queryValue.search,
    sort: queryValue.sort,
    pageSize: 20,
  });

  const handleNext = (cursor) => {
    setQueryValue({
      ...queryValue,
      nextCursor: cursor,
    });
  };

  const handleSearch = (value) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      search: value,
    });
  };
  const handleSorting = ({ field, direction }) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      sort: `${field} ${direction}`,
    });
  };

  const RenderAction = ({ selectedItem }) => (
    <HStack justifyContent="flex-end">
      <IconButton
        onClick={() => history.push(`/complaints/${selectedItem.id}`)}
        colorScheme="primary"
        aria-label="Detail"
        size="sm"
        icon={<FaEye />}
      />
    </HStack>
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Complaint",
        accessor: "complaint",
      },
      {
        Header: "Complaint Details",
        accessor: "complaintDetailsTrimmed",
      },
      {
        Header: "Client Name",
        accessor: "clientName",
      },
      {
        Header: "Complained Property",
        accessor: "complaintProperty",
      },
      { Header: "Status", accessor: "status", Cell: StatusCell },
      { Header: "", accessor: "action", isNumeric: true },
    ],
    [],
  );

  const dataMemoized = React.useMemo(
    () =>
      complaints?.data.map((item) => {
        return {
          complaint: item.complaint,
          complaintDetailsTrimmed: item.complaintDetailsTrimmed,
          clientName: item.clientName,
          complaintProperty: item.complaintProperty?.label,
          status:
            role === "admin" ? item.statusByAdmin : item.statusByPropertyOwner,
          action: <RenderAction selectedItem={item} />,
        };
      }),
    [complaints?.data],
  );

  return (
    <Box>
      <TableFilters
        sortOptions={sortOptions}
        handleSearch={handleSearch}
        searchPlaceholder="Search complained property..."
        handleSorting={handleSorting}
      />

      <Box>
        <TablePagination
          columns={columns}
          data={dataMemoized || []}
          isLoading={isLoading || isFetching}
          isError={isError}
          meta={complaints?.meta}
          handleNextFn={handleNext}
        />
      </Box>
    </Box>
  );
};

export default ComplaintTable;
