import { ComparePage as ComparePageClient } from "../../pages/client/compare";
import { FavoritePage as FavoritePageClient } from "../../pages/client/favorite";
import AboutProjectPage from "../../pages/client/footer/about-project";
import AgentsPage from "../../pages/client/footer/agents";
import ContactUsPage from "../../pages/client/footer/contact-us";
import NeedHelpPage from "../../pages/client/footer/need-help";
import TermsPage from "../../pages/client/footer/terms";
import WhyProjectPage from "../../pages/client/footer/why-project";
import HomePage from "../../pages/client/home";
import { PropertyDetailsPage as PropertyDetailsPageClient } from "../../pages/client/property/details";
import PropertiesClientPage from "../../pages/client/property/properties";
import SpecialSpacePage from "../../pages/client/specialSpace";

const clientRoutes = [
  { path: "/", component: HomePage, exact: true },
  { path: "/home", component: HomePage },
  { path: "/c/special-spaces", component: SpecialSpacePage },
  { path: "/c/property/:id", component: PropertyDetailsPageClient },
  { path: "/c/properties", component: PropertiesClientPage },
  { path: "/c/compare-properties", component: ComparePageClient },
  { path: "/c/my-favorites", component: FavoritePageClient },
  { path: "/c/contact-us", component: ContactUsPage },
  { path: "/c/terms", component: TermsPage },
  { path: "/c/need-help", component: NeedHelpPage },
  { path: "/c/agents", component: AgentsPage },
  { path: "/c/why-project-300", component: WhyProjectPage },
  { path: "/c/about-project-300", component: AboutProjectPage },
];

export default clientRoutes;
