import { Box, Heading, Link, Text } from '@chakra-ui/react';
import Layout from 'components/layout/auth';
import { useTranslation } from 'react-i18next';
import { Link as ReachLink } from 'react-router-dom';
import MultipleStepForm from '../../components/form/register/MultipleStepForm';

const RegisterPage = () => {
  const { t } = useTranslation('register');

  return (
    <Layout>
      <Box maxW="xl" mx="auto">
        <Heading
          color="white"
          textAlign="center"
          mb="12"
          size="xl"
          fontWeight="extrabold"
        >
          {t('register:welcome_to')}
        </Heading>
        <MultipleStepForm />
        <Text mt="4" mb="8" fontWeight="medium">
          <Text color="white" as="span" mr="2">
            {t('register:have_account')}
          </Text>
          <Link as={ReachLink} color="primary.300" to="/auth/login">
            {t('register:login_to_hub')}
          </Link>
        </Text>
      </Box>
    </Layout>
  );
};

export default RegisterPage;
