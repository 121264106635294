export const periodOptions = [
  { label: "1 Months", value: "1 months" },
  { label: "3 Months", value: "3 months" },
  { label: "6 Months", value: "6 months" },
  { label: "12 Months", value: "12 months" },
  { label: "Long term", value: "long term" },
];

export const paymentPeriodic = {
  hourly: "Hour",
  monthly: "Month",
  yearly: "Year",
};
