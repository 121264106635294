import { useState } from 'react';
import { Button, Stack, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import messages from '../../config/messages';
import { sessionLogin, me } from '../../services/auth.service';
import { InputField, PasswordField } from '../field';
import { useQuerySearch } from 'hooks';

import { auth } from 'firebase-config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

const schema = yup.object().shape({
  email: yup.string().email().required(messages.required),
  password: yup.string().required(messages.required),
});

const LoginForm = () => {
  const { t } = useTranslation('login');
  const history = useHistory();
  const toast = useToast();
  const querySearch = useQuerySearch();
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  const handleErrorFirebase = (error) => {
    let message;
    const errorCode = error.code;

    // getting error code only from firebase,
    // otherwise, error handling is already handled by the Axios error interceptor
    if (errorCode) {
      switch (errorCode) {
        case 'auth/user-not-found':
          message = 'User not found';
          break;
        case 'auth/invalid-credential':
          message = 'Invalid user credential';
          break;
        case 'auth/wrong-password':
          message = 'The password is invalid.';
          break;
        case 'auth/internal-error':
          message = error.message;
          break;
        default:
          message = error.message;
          break;
      }

      toast({
        title: 'An error occurred.',
        description: message,
        position: 'top-right',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async ({ user }) => {
        await dispatch(sessionLogin()).unwrap();
        await dispatch(me()).unwrap();

        // check if login have redirect URL
        if (querySearch['redirect']) {
          history.push(querySearch['redirect']);
          return;
        }

        history.push('/');
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleErrorFirebase(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="6">
        <InputField
          name="email"
          type="email"
          label={t('login:email_address')}
          control={control}
        />
        <PasswordField
          name="password"
          label={t('login:password')}
          forgotText={t('login:forgot_password')}
          control={control}
          withForgot
          disableAutoComplete={false}
        />
        <Button
          type="submit"
          colorScheme="primary"
          size="lg"
          fontSize="md"
          isLoading={isLoading}
        >
          {t('login:sign_in')}
        </Button>
      </Stack>
    </form>
  );
};

export default LoginForm;
