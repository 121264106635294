import React from 'react';
import { SimpleGrid, GridItem, Button, Box, Text } from '@chakra-ui/react';
import NoResults from './NoResults';
import PropertyItemSkeleton from 'components/client/Skeleton/PropertyItem.skeleton';
import PropertyItem from 'components/client/PropertyItem';
import { useQuerySearch } from 'hooks';
import { useGetElasticPropertiesQuery } from 'services/client/properties.client.services';
import { useTranslation } from 'react-i18next';

const PropertiesClientList = () => {
  const { t } = useTranslation();
  console.log('PropertiesClientList');
  const querySearch = useQuerySearch();

  const [page, setPage] = React.useState(0);

  const {
    data: properties,
    isLoading,
    isFetching,
  } = useGetElasticPropertiesQuery({
    ...querySearch,
    page: page,
  });

  React.useEffect(() => {
    // reset page when filtering is changing
    setPage(0);
  }, [querySearch]);

  const handleShowNext = () => {
    window.scrollTo({ top: 0 });
    setPage((prevState) => prevState + 1);
  };

  const handleShowPrev = () => {
    window.scrollTo({ top: 0 });
    setPage((prevState) => prevState - 1);
  };

  if (isLoading || isFetching) {
    return (
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, xl: 3 }}
        spacing={8}
        w="full"
      >
        <PropertyItemSkeleton total={18} />
      </SimpleGrid>
    );
  }

  if (!isLoading && properties && properties.data.length === 0) {
    return <NoResults />;
  }

  return (
    <React.Fragment>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, xl: 3 }}
        spacing={8}
        w="full"
      >
        {!isLoading && properties && properties.data.length !== 0 && (
          <React.Fragment>
            {properties &&
              properties.data.map((item, index) => (
                <GridItem
                  key={index}
                  onClick={() =>
                    window.open(`/c/property/${item.objectID}`, '_self')
                  }
                >
                  <PropertyItem data={item} />
                </GridItem>
              ))}
          </React.Fragment>
        )}
      </SimpleGrid>
      <Box mt="16" display="flex" justifyContent="center">
        <Box>
          <Button
            onClick={handleShowPrev}
            size="lg"
            mr="8"
            isDisabled={properties?.meta.page <= 0}
          >
            {t('common:button:prev_page')}
          </Button>

          <Button
            onClick={handleShowNext}
            colorScheme="primary"
            size="lg"
            isLoading={isLoading}
            isDisabled={properties?.meta.page >= properties?.meta.nbPages - 1}
          >
            {t('common:button:next_page')}
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default React.memo(PropertiesClientList);
