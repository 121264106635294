import AgentsPage from "../../pages/agent/agents";
import AgentDetailsPage from "../../pages/agent/details";
import AddNewAgentPage from "../../pages/agent/addNewAgent";
import AgentView from "../../pages/agent/view";

const agentRoutes = [
  { path: "/agents", component: AgentsPage, exact: true },
  { path: "/agent/:id", component: AgentDetailsPage, exact: true },
  { path: "/agents/new", component: AddNewAgentPage },
  { path: "/v/agent/:id", component: AgentView, exact: true }
];

export const agentViewRoutes = [
  { path: "/v/agent/:id", component: AgentView, exact: true }
];

export default agentRoutes;
