import { createSlice } from "@reduxjs/toolkit";

import {
  fetchElasticProperties,
  fetchClientPropertyById,
} from "../../services/client/properties.services";

const initialState = {
  properties: [],
  property: null,
  meta: {
    total: 0,
  },
  loading: false,
  loadingSingle: false,
  error: null,
};

const clientPropertiesSlice = createSlice({
  name: "clientProperties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // FETCH ELASTIC
    builder.addCase(fetchElasticProperties.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchElasticProperties.fulfilled, (state, action) => {
      state.loading = false;
      state.meta = action.payload.meta;
      state.properties = action.payload.data;
    });

    builder.addCase(fetchElasticProperties.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // FETCH BY ID
    builder.addCase(fetchClientPropertyById.pending, (state) => {
      state.loadingSingle = true;
    });

    builder.addCase(fetchClientPropertyById.fulfilled, (state, action) => {
      state.loadingSingle = false;
      state.meta = action.payload.meta;
      state.property = action.payload.data;
    });

    builder.addCase(fetchClientPropertyById.rejected, (state, action) => {
      state.loadingSingle = false;
      state.error = action.payload;
    });
  },
});

export default clientPropertiesSlice.reducer;
