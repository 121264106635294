import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Define the order of language detection
const options = {
  order: ['querystring', 'cookie', 'localStorage'],
};

// Initialize i18n
i18n
  // Load translation using http -> see /public/locales
  // Learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // Detect user language
  // Learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // Initialize i18next
  // For all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: options,
    ns: ['translation', 'common'],
    defaultNS: 'translation',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // Not needed for react as it escapes by default
    },
  });

// Export the i18n instance
export default i18n;
