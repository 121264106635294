import MenuPage from "../../pages/manage-content/menu";
import AccountTypePage from "../../pages/manage-content/account-type";

const manageContentRoutes = [
  {
    path: "/manage-content/menu",
    component: MenuPage,
    exact: true,
  },
  {
    path: "/manage-content/account-type",
    component: AccountTypePage,
    exact: true,
  },
];

export default manageContentRoutes;
