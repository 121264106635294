import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import Layout, {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutBody,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import Paper from "components/paper";
import TypesTable from "./components/TypesTable";
import PopupAdd from "./components/PopupAdd";

const PropertyFeaturesPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Property Types" />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <PopupAdd />
        </LayoutHeaderRight>
      </LayoutHeader>
      <LayoutBody>
        <Grid>
          <GridItem>
            <Paper>
              <TypesTable />
            </Paper>
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};

export default PropertyFeaturesPage;
