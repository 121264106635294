import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Portal,
  Box,
  useToken,
  HStack,
  Text,
  Icon,
} from "@chakra-ui/react";
import Select from "react-select";
import { BsSearch } from "react-icons/bs";
import { FiChevronDown } from "react-icons/fi";
import { useController } from "react-hook-form";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

const selectStyles = {
  control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
};

const PopOutFiled = ({
  name = "",
  control,
  placeholder = "Search...",
  label = "",
  eventInputChange = () => {},
  options = [],
  callback = () => {},
  placement = "bottom-start",
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: null,
    rules: { required: false },
  });

  const [primary200, primary500] = useToken("colors", [
    "primary.200",
    "primary.500",
  ]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    initRequest();
  };

  const close = () => {
    setIsOpen(false);
  };

  // const debouncedChange = useCallback(inputValue => {
  //   return debounce(() => eventInputChange(inputValue), 700);
  // }, []);

  const debouncedChange = useMemo(() => {
    return debounce((inputValue) => eventInputChange(inputValue), 700);
  }, [eventInputChange]);

  const handleInput = (inputValue, event) => {
    // custom change to handle only event is input-change
    if (event.action === "input-change") {
      debouncedChange(inputValue);
    }
  };

  const handleChange = (selectedOption) => {
    toggleOpen();
    onChange(selectedOption);

    // callback only call when value is different with before
    if (selectedOption !== value) {
      callback(selectedOption);
    }
  };

  const initRequest = () => {
    // cached options when user not typing
    if (!Array.isArray(options)) return;
    if (options.length !== 0) return;

    // request data to server when pop is open and user still not selected data.
    eventInputChange("");
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const capitalizeEachWord = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const customOptionRenderer = ({ value, label }) => {
    return capitalizeEachWord(label);
  };

  return (
    <Popover placement={placement} isOpen={isOpen} onClose={close} isLazy>
      <PopoverTrigger>
        <Box
          cursor="pointer"
          onClick={toggleOpen}
          w="full"
          border="2px"
          borderColor="gray.200"
          borderRadius="md"
          bg="white"
          p="2"
          _hover={{
            borderColor: "primary.500",
          }}
        >
          <HStack justifyContent="space-between">
            <Text userSelect="none">
              {value
                ? `${label}: ${
                    i18n.language === "ar"
                      ? value?.ar_label
                      : capitalizeEachWord(value?.label)
                  }`
                : capitalizeEachWord(label)}
            </Text>
            <Icon as={FiChevronDown} />
          </HStack>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverBody p="0" boxShadow="lg">
            <Select
              autoFocus
              ref={ref}
              name={name}
              value={value}
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onInputChange={handleInput}
              onChange={handleChange}
              getOptionLabel={(option) =>
                i18n.language === "ar" ? option.ar_label : option.label
              }
              formatOptionLabel={customOptionRenderer}
              getOptionValue={(option) => option.value}
              options={options}
              placeholder={placeholder}
              styles={selectStyles}
              tabSelectsValue={false}
              noOptionsMessage={() => t("common:label.no_results")}
              loadingMessage={() => t("common:placeholder.searching")}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: primary200,
                  primary: primary500,
                },
              })}
              {...inputProps}
              {...props}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const DropdownIndicator = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    h="32px"
    w="32px"
    color="gray.400"
  >
    <Icon as={BsSearch} />
  </Box>
);

PopOutFiled.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  placement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  eventInputChange: PropTypes.func,
  callback: PropTypes.func,
  options: PropTypes.array,
};

export default PopOutFiled;
