import { api } from "../api";

const endpoint = "/locations";

export const locationsApi = api.injectEndpoints({
  tagTypes: ["Location"],
  endpoints: (build) => ({
    getLocations: build.query({
      query: (payload) => ({
        url: `${endpoint}`,
        method: "GET",
        params: {
          limit: 6,
          ...payload,
        },
      }),
    }),
  }),
});

export const { useGetLocationsQuery } = locationsApi;
