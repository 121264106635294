import React from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import {
  Button,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableContainer,
} from '@chakra-ui/react';
import TableLoader from '../components/TableLoader';
import TableError from '../components/TableError';
import { TableEmptyData } from '../components';

const IndexTable = ({
  columns,
  data = [],
  isLoading = false,
  isError = false,
  meta = {},
  handleNextFn,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  if (!isLoading && rows.length === 0) {
    return <TableEmptyData />;
  }

  if (isError) {
    return <TableError />;
  }

  return (
    <Box position="relative">
      {isLoading && <TableLoader />}
      <Box minH="300px">
        <TableContainer>
          <Table {...getTableProps()} size="sm" variant="simple">
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        isNumeric={header.isNumeric}
                        py="3"
                        bg="gray.50"
                      >
                        {header.render('Header')}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, _) => {
                console.log('row', row);
                prepareRow(row);
                return (
                  <Tr
                    key={row.id}
                    {...row.getRowProps()}
                    // onClick={() => eventRowClick(row.original)}
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}
                  >
                    {row.cells.map((cell, index) => {
                      console.log(cell.row.id);
                      return (
                        <Td
                          key={index}
                          isNumeric={cell.column.isNumeric}
                          py="2"
                        >
                          {cell.render('Cell')}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {meta.nextCursor && (
        <Box
          position="sticky"
          bottom="0"
          display="flex"
          justifyContent="center"
          py="2"
          bg="gray.50"
          borderBottomLeftRadius="md"
          borderBottomRightRadius="md"
          borderTop="1px solid"
          borderColor="gray.200"
        >
          <Button
            onClick={() => handleNextFn(meta.nextCursor)}
            colorScheme="gray"
            isLoading={isLoading}
            size="sm"
          >
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

IndexTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  meta: PropTypes.object,
  handleNextFn: PropTypes.func,
  eventRowClick: PropTypes.func,
};

export default React.memo(IndexTable);
