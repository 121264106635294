import React from "react";
import PropTypes from "prop-types";
import ROLE from "../../config/role";
import { Tag, TagLabel } from "@chakra-ui/react";

const style = {
  borderRadius: "full",
  textTransform: "capitalize",
};

const RoleCell = ({ value }) => {
  switch (String(value).toLowerCase()) {
    case ROLE.ADMIN:
      return (
        <Tag {...style} colorScheme="green">
          <TagLabel fontSize="xs">Admin</TagLabel>
        </Tag>
      );
    case ROLE.WEBSITE_AGENT:
      return (
        <Tag {...style} colorScheme="purple">
          <TagLabel fontSize="xs">Website Agent</TagLabel>
        </Tag>
      );
    case ROLE.PROPERTY_AGENT:
      return (
        <Tag {...style} colorScheme="teal">
          <TagLabel fontSize="xs">Property Agent</TagLabel>
        </Tag>
      );
    case ROLE.SERVICE_OFFICE_PROVIDER:
      return (
        <Tag {...style} colorScheme="blue">
          <TagLabel fontSize="xs">Service Office Provider</TagLabel>
        </Tag>
      );
    default:
      return (
        <Tag {...style} colorScheme="blackAlpha">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
  }
};

RoleCell.propTypes = {
  value: PropTypes.string,
};

export default RoleCell;
