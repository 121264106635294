import { axiosInstance } from "./fetchApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchError } from "../utils/catchError";

const endpoint = "/features";

export const fetchAutocompleteFeature = createAsyncThunk(
  "features/autocomplete",
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/autocomplete`, {
        params: {
          offset: payload?.offset || 0,
          limit: 6,
          ...payload
        }
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const fetchFeature = createAsyncThunk(
  "features/fetchAll",
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(endpoint, {
        params: {
          offset: payload?.offset || 0,
          limit: 6,
          ...payload
        }
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const fetchFeatureById = createAsyncThunk(
  "features/fetchById",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const createFeature = createAsyncThunk(
  "features/create",
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(endpoint, payload);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const updateFeature = createAsyncThunk(
  "features/update",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`${endpoint}/${payload.id}`, {
        name: payload?.name,
        ar_name: payload?.ar_name,
        icon: payload?.icon
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteFeature = createAsyncThunk(
  "features/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadIcon = createAsyncThunk(
  `${endpoint}/upload-icon`,
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("image", payload?.data);
      const response = await axiosInstance.post(
        `${endpoint}/upload-icon`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
