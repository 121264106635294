import React from "react";
import PropTypes from "prop-types";
import { Image, Icon, Tooltip } from "@chakra-ui/react";

const FallBack = () => (
  <Tooltip label="No Icon">
    <Icon boxSize="24px" color="gray.400" />
  </Tooltip>
);

const IconWithFallback = ({ src, ...rest }) => {
  return <Image src={src} boxSize="24px" fallback={<FallBack />} {...rest} />;
};

IconWithFallback.propTypes = {
  src: PropTypes.string
};

export default IconWithFallback;
