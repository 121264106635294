import { api } from './api';
import { isEqual } from 'lodash';

const endpoint = '/inquiries';

export const inquiryApi = api.injectEndpoints({
  tagTypes: ['Inquiry'],
  endpoints: (build) => ({
    getInquiries: build.query({
      query: (payload) => ({
        url: endpoint,
        method: 'GET',
        params: {
          ...payload,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (currentCache.data && arg?.nextCursor) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['Inquiry'],
    }),

    // Get single inquiry
    getInquiry: build.query({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['Inquiry'],
    }),

    // Add inquiry
    addInquiry: build.mutation({
      query: (payload) => ({
        url: endpoint,
        method: 'POST',
        data: payload,
      }),
    }),

    // Update inquiry
    updateInquiry: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['Inquiry'],
    }),

    // Delete inquiry
    deleteInquiry: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Inquiry'],
    }),

    // Transfer inquiry
    transferInquiry: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/transfer`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['Inquiry'],
    }),

    // Approve inquiry
    approveInquiry: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/approve`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['Inquiry'],
    }),

    // Accept inquiry
    acceptInquiry: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/accept`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['Inquiry'],
    }),

    // Reject inquiry
    rejectInquiry: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/reject`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['Inquiry'],
    }),

    // Rejection inquiry
    rejectionInquiry: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/rejection`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['Inquiry'],
    }),

    // Delegate inquiry
    delegateInquiry: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/delegate`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['Inquiry'],
    }),
  }),
});

export const {
  useGetInquiriesQuery,
  useGetInquiryQuery,
  useAddInquiryMutation,
  useUpdateInquiryMutation,
  useDeleteInquiryMutation,
  useTransferInquiryMutation,
  useApproveInquiryMutation,
  useAcceptInquiryMutation,
  useRejectInquiryMutation,
  useRejectionInquiryMutation,
  useDelegateInquiryMutation,
} = inquiryApi;
