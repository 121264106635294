import { api } from "./api";
import isEqual from "lodash/isEqual";

const endpoint = "/special-request";

export const specialRequestApi = api.injectEndpoints({
  tagTypes: ["SpecialRequest"],
  endpoints: (build) => ({
    // Get all special requests
    getSpecialRequests: build.query({
      query: (payload) => ({
        url: `${endpoint}`,
        method: "GET",
        params: {
          ...payload,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (currentCache.data && arg?.nextCursor) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        // return currentArg !== previousArg;
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ["SpecialRequest"],
    }),

    // Get single special request
    getSpecialRequest: build.query({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["SpecialRequest"],
    }),

    // Add special request
    addSpecialRequest: build.mutation({
      query: (payload) => ({
        url: `${endpoint}`,
        method: "POST",
        data: payload,
      }),
    }),

    // Process special request
    processSpecialRequest: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}/process-request`,
        method: "PATCH",
      }),
      invalidatesTags: ["SpecialRequest"],
    }),

    // Forward special request
    forwardSpecialRequest: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}/forward-request`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: ["SpecialRequest"],
    }),

    // Hold special request
    holdSpecialRequest: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}/hold-request`,
        method: "PATCH",
      }),
      invalidatesTags: ["SpecialRequest"],
    }),

    // Reopen special request
    reopenSpecialRequest: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}/reopen-request`,
        method: "PATCH",
      }),
      invalidatesTags: ["SpecialRequest"],
    }),

    // Complete special request
    completeSpecialRequest: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}/complete-request`,
        method: "PATCH",
      }),
      invalidatesTags: ["SpecialRequest"],
    }),

    // Respond special request
    respondSpecialRequest: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}/respond-request`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: ["SpecialRequest"],
    }),
  }),
});

export const {
  useGetSpecialRequestsQuery,
  useGetSpecialRequestQuery,
  useAddSpecialRequestMutation,
  useProcessSpecialRequestMutation,
  useForwardSpecialRequestMutation,
  useHoldSpecialRequestMutation,
  useRespondSpecialRequestMutation,
  useReopenSpecialRequestMutation,
  useCompleteSpecialRequestMutation,
} = specialRequestApi;
