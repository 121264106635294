import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from "@chakra-ui/react";
import { FIELD_VARIANT } from "../../config/preset";
import { useController } from "react-hook-form";

const NumberField = ({
  name,
  placeholder,
  control,
  label,
  isRequired = false,
  precision = 0,
  masking,
  max = 999999999,
  ...props
}) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error }
  } = useController({
    name,
    control,
    defaultValue: 0,
    rules: { required: isRequired }
  });

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <NumberInput
        ref={ref}
        variant={FIELD_VARIANT.default}
        min={0}
        max={max}
        precision={precision}
        value={masking ? masking(value) : value}
        placeholder={placeholder}
        {...inputProps}
        {...props}
      >
        <NumberInputField name={name} id={name} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

NumberField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  precision: PropTypes.number,
  max: PropTypes.number,
  masking: PropTypes.func
};

export default NumberField;
