import { api } from './api';
import isEqual from 'lodash/isEqual';

const endpoint = '/tour-request';

export const tourRequestApi = api.injectEndpoints({
  tagTypes: ['TourRequest'],
  endpoints: (build) => ({
    // Get all tour requests
    getTourRequests: build.query({
      query: (payload) => ({
        url: endpoint,
        method: 'GET',
        params: {
          ...payload,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (currentCache.data && arg?.nextCursor) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['TourRequest'],
    }),

    // Get single tour request
    getTourRequest: build.query({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['TourRequest'],
    }),

    // Add tour request
    addTourRequest: build.mutation({
      query: (payload) => ({
        url: endpoint,
        method: 'POST',
        data: payload,
      }),
    }),

    // Update tour request
    updateTourRequest: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['TourRequest'],
    }),

    // Delete tour request
    deleteTourRequest: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TourRequest'],
    }),

    // Transfer tour request
    transferTourRequest: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/transfer`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['TourRequest'],
    }),

    // Approve tour request
    approveTourRequest: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/approve`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['TourRequest'],
    }),

    // Accept tour request
    acceptTourRequest: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/accept`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['TourRequest'],
    }),

    // Reject tour request
    rejectTourRequest: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/reject`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['TourRequest'],
    }),

    // Rejection tour request
    rejectionTourRequest: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/rejection`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['TourRequest'],
    }),

    // Delegated tour request
    delegatedTourRequest: build.mutation({
      query: ({ id, ...payload }) => ({
        url: `${endpoint}/${id}/delegate`,
        method: 'PATCH',
        data: payload,
      }),
      invalidatesTags: ['TourRequest'],
    }),
  }),
});

export const {
  useGetTourRequestsQuery,
  useGetTourRequestQuery,
  useAddTourRequestMutation,
  useUpdateTourRequestMutation,
  useDeleteTourRequestMutation,
  useTransferTourRequestMutation,
  useDelegatedTourRequestMutation,
  useApproveTourRequestMutation,
  useAcceptTourRequestMutation,
  useRejectTourRequestMutation,
  useRejectionTourRequestMutation,
} = tourRequestApi;
