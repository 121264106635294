import React from "react";
import Layout, { LayoutBody } from "components/layout/client";
import { Box, Text, GridItem, Stack, Grid, HStack } from "@chakra-ui/react";
import SortBy from "components/client/SortBy";
import SideFilter from "components/client/SideFilter";
import MobileFilter from "components/client/MobileFilter";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import PropertiesClientList from "./components/PropertiesClientList";
import { useDeviceDetected, useQuerySearch } from "hooks";
import { useGetElasticPropertiesQuery } from "services/client/properties.client.services";

const PropertiesClientPage = () => {
  const { t } = useTranslation();
  const querySearch = useQuerySearch();

  // don't use useBreakpointValue from chakra-ui, because will trigger multiple rendering
  const { isMobile } = useDeviceDetected();

  const { data: properties } = useGetElasticPropertiesQuery({
    ...querySearch,
    page: 0,
  });

  const total = get(properties, "meta.nbHits", 0);

  return (
    <Layout>
      <LayoutBody>
        <Stack
          direction={["column", "column", "row"]}
          justifyContent="space-between"
          alignItems={["start", "start", "center"]}
          flexWrap="wrap"
          mb="8"
        >
          <Box mb="4">
            <Text fontSize="3xl" fontWeight="bold">
              {t("common:label:all_properties")}
            </Text>
            <Text>{t("common:label:found_properties", { total: total })}</Text>
          </Box>

          <HStack>
            {isMobile && <MobileFilter />}
            <SortBy />
          </HStack>
        </Stack>

        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            lg: "repeat(5, 1fr)",
          }}
          gap={8}
        >
          <GridItem
            rowSpan={2}
            colSpan={1}
            display={{ base: "none", lg: "block" }}
          >
            <SideFilter />
          </GridItem>
          <GridItem colSpan={4}>
            <PropertiesClientList />
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};
export default React.memo(PropertiesClientPage);
