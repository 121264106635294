import { createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../fetchApi";
import axiosBaseQuery from "./axiosBaseQuery";

const api = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: () => ({}),
});

export default api;
