import React from "react";
import PropTypes from "prop-types";
import { Tag, TagLabel } from "@chakra-ui/react";

const style = {
  borderRadius: "full",
  variant: "solid",
  textTransform: "capitalize",
};

const StatusCell = ({ value }) => {
  switch (String(value).toLowerCase()) {
    case "accepted":
      return (
        <Tag {...style} colorScheme="green">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    case "published":
      return (
        <Tag {...style} colorScheme="green">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    case "approved":
      return (
        <Tag {...style} colorScheme="green">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    case "active":
      return (
        <Tag {...style} colorScheme="green">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    case "inactive":
      return (
        <Tag {...style} colorScheme="yellow">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    case "waiting":
      return (
        <Tag {...style} colorScheme="yellow">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    case "new":
      return (
        <Tag {...style} colorScheme="blackAlpha">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    case "rejected":
      return (
        <Tag {...style} colorScheme="red">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );

    case "resolved":
      return (
        <Tag {...style} colorScheme="green">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );

    case "completed":
      return (
        <Tag {...style} colorScheme="green">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    default:
      return value ? (
        <Tag {...style}>
          <TagLabel fontSize="xs">{value}</TagLabel>{" "}
        </Tag>
      ) : (
        "-"
      );
  }
};

StatusCell.propTypes = {
  value: PropTypes.string,
};

export default StatusCell;
