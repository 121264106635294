import React from "react";
import PropTypes from "prop-types";
import { AutoComplete } from "../field";
import { useLazyAutocompleteRegionsQuery } from "services/location/regions.services";
import { useWatch } from "react-hook-form";

const AutocompleteRegions = ({
  control,
  name = "region",
  label = "Region",
  basedOn = "",
  ...props
}) => {
  const [trigger, { data: regions, isLoading }] =
    useLazyAutocompleteRegionsQuery();

  const watchBased = useWatch({
    control,
    name: basedOn || "",
    defaultValue: null,
  });

  const handleAutocomplete = (inputValue) => {
    trigger({
      search: inputValue,
      based: watchBased?.value || basedOn,
    });
  };

  return (
    <AutoComplete
      name={name}
      label={label}
      control={control}
      options={regions ? regions.data : []}
      eventInputChange={handleAutocomplete}
      isLoading={isLoading}
      {...props}
    />
  );
};
AutocompleteRegions.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  basedOn: PropTypes.string,
};

export default AutocompleteRegions;
