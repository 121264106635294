import React from "react";
import PropTypes from "prop-types";
import { Text, Image, Box, HStack, Icon, Flex } from "@chakra-ui/react";

const FeaturesCell = (features, translated) => {
  return (
    <Flex alignItems="center" flexDirection="column">
      {features.map(item => (
        <Box key={item.value} mb="4">
          <HStack>
            {item.icon && (
              <Image
                src={item.icon}
                boxSize="24px"
                fallback={<Icon boxSize="24px" />}
              />
            )}
            <Text textTransform="capitalize">{item[translated]}</Text>
          </HStack>
        </Box>
      ))}
    </Flex>
  );
};

FeaturesCell.propTypes = {
  features: PropTypes.array
};

export default FeaturesCell;
