import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { TableV1 } from "../../../components/table";
import {
  DateTimeCell,
  TruncateCell,
  PropertiesNameCell,
  ImageCell,
  StatusCell,
  ObjectionCell,
  PriceCell
} from "../../../components/cell";

const PropertiesTable = ({
  data = [],
  isLoading,
  meta,
  onPagination = () => {}
}) => {
  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        Cell: PropertiesNameCell,
        minWidth: 300
      },
      { Header: "Image", accessor: "featureImage", Cell: ImageCell },
      { Header: "City", accessor: "city" },
      { Header: "Type", accessor: "type" },
      { Header: "Price", accessor: "price", Cell: PriceCell },
      { Header: "Added By", accessor: "addedBy" },
      { Header: "Status", accessor: "status", Cell: StatusCell },
      { Header: "Objection", accessor: "objectionStatus", Cell: ObjectionCell }
    ],
    []
  );

  const dataMemoized = React.useMemo(
    () =>
      data.map(item => {
        return {
          id: item.id,
          title: item.title,
          featureImage: item.featureImage,
          city: item.city?.label || item.city,
          type: item.type?.label || item.type,
          price: item.price,
          addedBy: item.addedBy?.label || item.addedBy,
          status: item.status,
          objectionStatus: item.objectionStatus
        };
      }),
    [data]
  );

  const handleRowClick = row => {
    history.push(`/property/${row.original.id}`);
  };

  return (
    <TableV1
      columns={columns}
      data={dataMemoized}
      isLoading={isLoading}
      meta={meta}
      handlePagination={onPagination}
      eventRowClick={handleRowClick}
    />
  );
};

PropertiesTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  loadingDelete: PropTypes.bool,
  meta: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onPagination: PropTypes.func
};

export default PropertiesTable;
