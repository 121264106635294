const MapsConfig = {
  // key: "AIzaSyD0zGViscF-EcMcA93ibKlLtHa19MvYGM4",
  key: "AIzaSyDlP2U2efGuvm0Ek-XhPmE7ukgrSSK-_VM",
  zoom: 10,
  defaultCenter: {
    lat: 24.613168696487946,
    lng: 46.68480036977018,
  },
};

export default MapsConfig;
