import PropTypes from "prop-types";
import { useLazyAutocompleteTypesQuery } from "services/typesProperty.services";
import { AutoComplete } from "../field";

const AutocompleteTypes = ({
  control,
  name = "types",
  label = "Type",
  whoCanAccess = "",
  ...props
}) => {
  const [getAutocompleteTypes, { data: types, isLoading }] =
    useLazyAutocompleteTypesQuery();

  const handleAutocomplete = (inputValue) => {
    getAutocompleteTypes({
      search: inputValue,
      whoCanAccess,
    });
  };

  return (
    <AutoComplete
      name={name}
      label={label}
      control={control}
      options={types ? types.data : []}
      eventInputChange={handleAutocomplete}
      isLoading={isLoading}
      {...props}
    />
  );
};

AutocompleteTypes.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default AutocompleteTypes;
