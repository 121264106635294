import React from "react";
import PropTypes from "prop-types";
import { useTable, useExpanded } from "react-table";
import {
  Button,
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  Text,
  chakra,
} from "@chakra-ui/react";
import TableEmpty from "../components/TableEmpty";
import TableLoader from "../components/TableLoader";

const TableExpand = ({
  columns,
  data = [],
  isLoading = false,
  meta = {},
  handlePagination,
  eventRowClick = () => {},
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded,
  );

  const handlePrev = () => {
    handlePagination(meta.prevOffset);
  };

  const handleNext = () => {
    handlePagination(meta.nextOffset);
  };

  return (
    <Box position="relative">
      <Box overflowX="auto">
        {isLoading && <TableLoader />}
        <Table {...getTableProps()} size="sm">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                    key={index}
                    isNumeric={column.isNumeric}
                  >
                    {column.render("Header")}
                    <chakra.span pl="4" />
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!isLoading && rows.length === 0 && (
              <TableEmpty columns={columns} emptyText="No Data" />
            )}
            {rows.length !== 0 &&
              rows.map((row, index) => {
                prepareRow(row);
                return (
                  <React.Fragment key={index}>
                    <Tr
                      {...row.getRowProps()}
                      _hover={{ bg: "gray.50" }}
                      onClick={() => eventRowClick(row)}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <Td
                            key={index}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                              },
                            })}
                            cursor="pointer"
                            isNumeric={cell.column.isNumeric}
                          >
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                  </React.Fragment>
                );
              })}
          </Tbody>
        </Table>
      </Box>

      <Box
        mt="6"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box></Box>
        <HStack spacing="4">
          <Button onClick={handlePrev} isDisabled={!meta.hasPrev || isLoading}>
            Prev
          </Button>
          <Button onClick={handleNext} isDisabled={!meta.hasNext || isLoading}>
            Next
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

TableExpand.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  handlePagination: PropTypes.func,
  eventRowClick: PropTypes.func,
};

export default TableExpand;
