import React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { TableFilters, IndexTable } from 'components/table';
import { ConfirmDelete } from 'components/alert';
import PopupEdit from './PopupEdit';
import {
  useDeleteRegionMutation,
  useGetRegionsQuery,
} from 'services/location/regions.services';
import { catchError } from 'utils/catchError';
import { messageSuccessDelete } from 'components/messages';

const sortOptions = [
  { label: 'Region', value: 'name' },
  { label: 'Country', value: 'country.label' },
  { label: 'Created', value: 'createdAt', isDefault: true, direction: 'desc' },
];

const CountriesTable = () => {
  const [queryValue, setQueryValue] = React.useState({
    nextCursor: null,
    search: '',
    sort: '',
  });

  const {
    data: regions,
    isLoading,
    isFetching,
    isError,
  } = useGetRegionsQuery({
    nextCursor: queryValue.nextCursor,
    search: queryValue.search,
    sort: queryValue.sort,
  });

  const [deleteRegion, { isLoading: loadingDelete }] =
    useDeleteRegionMutation();

  const handleNext = (cursor) => {
    setQueryValue({
      ...queryValue,
      nextCursor: cursor,
    });
  };

  const handleSearch = (value) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      search: value,
    });
  };
  const handleSorting = ({ field, direction }) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      sort: `${field} ${direction}`,
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteRegion(id).unwrap();
      messageSuccessDelete();
    } catch (error) {
      console.error(catchError(error));
    }
  };

  const RenderAction = ({ selectedItem }) => (
    <HStack justifyContent="flex-end">
      <PopupEdit data={selectedItem} />
      <ConfirmDelete
        iconOnly
        headerText={`Delete '${selectedItem.name}' Region`}
        bodyText="Are you sure? You can't undo this action afterwards."
        eventClick={() => handleDelete(selectedItem.id)}
        isLoading={loadingDelete}
      />
    </HStack>
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Region',
        accessor: 'name',
      },
      {
        Header: 'Country',
        accessor: 'country',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        isNumeric: true,
      },
    ],
    []
  );

  const dataMemoized = React.useMemo(
    () =>
      regions?.data.map((item) => {
        return {
          name: item.name,
          country: item.country.label || item.country,
          actions: <RenderAction selectedItem={item} />,
        };
      }),
    [regions?.data]
  );

  return (
    <Box>
      <TableFilters
        sortOptions={sortOptions}
        searchPlaceholder="Search region..."
        handleSearch={handleSearch}
        handleSorting={handleSorting}
      />

      <Box>
        <IndexTable
          columns={columns}
          data={dataMemoized || []}
          isLoading={isLoading || isFetching}
          isError={isError}
          meta={regions?.meta}
          handleNextFn={handleNext}
        />
      </Box>
    </Box>
  );
};

export default CountriesTable;
