export const registerDefaultValues = {
  username: "",
  displayName: "",
  role: "",
  companyName: "",
  city: null,
  bio: "",
  email: "",
  phoneNumber: "",
  imageURL: "",
  password: "",
  status: "",
};
export const defaultValues = {
  displayName: "",
  role: "",
  companyName: "",
  phoneNumber: "",
  email: "",
  bio: "",
  city: null,
  imageURL: "",
  status: "",
};

export const metaTab = {
  ALL: 0,
  REJECTED: 1,
};

export const onlyNewOptions = [{ label: "New", value: "new" }];

export const statusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const statusNewOptions = [
  { label: "New", value: "new" },
  { label: "Active", value: "active" },
  { label: "Rejected", value: "rejected" },
  { label: "Inactive", value: "inactive" },
];

export const fullCategoryOptions = [
  { label: "Admin", value: "admin" },
  { label: "Website Agent", value: "website_agent" },
  { label: "Property Agent", value: "property_agent" },
  { label: "Service Office provider", value: "service_office_provider" },
  { label: "Property Broker", value: "property_broker" },
  { label: "Property Owner", value: "property_owner" },
  { label: "Personal", value: "personal" },
];

export const categoryOptions = [
  { label: "Website Agent", value: "website_agent" },
  { label: "Property Agent", value: "property_agent" },
  { label: "Service Office provider", value: "service_office_provider" },
  { label: "Property Broker", value: "property_broker" },
  { label: "Property Owner", value: "property_owner" },
  { label: "Personal", value: "personal" },
];

export const processNewRadioOptions = [
  { label: "Approve", value: "approve" },
  { label: "Reject", value: "reject" },
];
