import React from 'react';
import Layout, { LayoutBody } from '../../../../components/layout/client';
import {
  Box,
  Text,
  Container,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const NeedHelpPage = () => {
  const { t } = useTranslation('needHelp');

  return (
    <Layout>
      <LayoutBody>
        <Container maxW={'container.lg'}>
          <Box>
            <Text fontSize="3xl" fontWeight="bold" mb="8">
              {t('needHelp:title')}
            </Text>
          </Box>

          <Box mb="12">
            <Box mb="6">
              <Text fontSize="2xl" fontWeight="bold" mb="2">
                {t('needHelp:how_can_we.title')}
              </Text>
              <Text>{t('needHelp:how_can_we.paragraph_1')}</Text>
            </Box>

            <Box mb="6">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('needHelp:how_can_we.why_work_spaces.title')}
              </Text>
              <Text>
                {t('needHelp:how_can_we.why_work_spaces.paragraph_1')}
              </Text>
            </Box>

            <Box>
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('needHelp:how_can_we.why_work_spaces.key_benefits.title')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t(
                    'needHelp:how_can_we.why_work_spaces.key_benefits.ordered_list.1'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'needHelp:how_can_we.why_work_spaces.key_benefits.ordered_list.2'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'needHelp:how_can_we.why_work_spaces.key_benefits.ordered_list.3'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'needHelp:how_can_we.why_work_spaces.key_benefits.ordered_list.4'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'needHelp:how_can_we.why_work_spaces.key_benefits.ordered_list.5'
                  )}
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>

          <Box mb="12">
            <Text fontSize="xl" fontWeight="bold" mb="2">
              {t('needHelp:how_can_we.why_common_facilities.title')}
            </Text>
            <Text mb="2">
              {t('needHelp:how_can_we.why_common_facilities.paragraph_1')}
              <br />
              <br />
              {t('needHelp:how_can_we.why_common_facilities.paragraph_2')}
            </Text>

            <UnorderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t('needHelp:how_can_we.why_common_facilities.ordered_list.1')}
              </ListItem>
              <ListItem>
                {t('needHelp:how_can_we.why_common_facilities.ordered_list.2')}
              </ListItem>
              <ListItem>
                {t('needHelp:how_can_we.why_common_facilities.ordered_list.3')}
              </ListItem>
              <ListItem>
                {t('needHelp:how_can_we.why_common_facilities.ordered_list.4')}
              </ListItem>
            </UnorderedList>
          </Box>

          <Box mb="12">
            <Text fontSize="xl" fontWeight="bold" mb="2">
              {t('needHelp:how_can_we.choose_where_you.title')}
            </Text>
            <Text mb="2">
              {t('needHelp:how_can_we.choose_where_you.paragraph_1')}
              <br />
              <br />
              {t('needHelp:how_can_we.choose_where_you.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontSize="xl" fontWeight="bold" mb="2">
              {t('needHelp:how_can_we.all_what_you.title')}
            </Text>
            <Text mb="2">
              {t('needHelp:how_can_we.all_what_you.paragraph_1')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontSize="xl" fontWeight="bold" mb="2">
              {t('needHelp:how_can_we.flexible_monthly_budget.title')}
            </Text>
            <Text mb="2">
              {t('needHelp:how_can_we.flexible_monthly_budget.paragraph_1')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontSize="xl" fontWeight="bold" mb="2">
              {t('needHelp:how_can_we.rapid_solution.title')}
            </Text>
            <Text mb="2">
              {t('needHelp:how_can_we.rapid_solution.paragraph_1')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontSize="xl" fontWeight="bold" mb="2">
              {t('needHelp:how_can_we.provide_more_flexibility.title')}
            </Text>
            <Text mb="2">
              {t('needHelp:how_can_we.provide_more_flexibility.paragraph_1')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontSize="xl" fontWeight="bold" mb="2">
              {t('needHelp:how_can_we.how_can_project.title')}
            </Text>
            <Text mb="2">
              {t('needHelp:how_can_we.how_can_project.paragraph_1')}
              <br />
              <br />
              {t('needHelp:how_can_we.how_can_project.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontSize="2xl" fontWeight="bold" mb="4">
              {t('needHelp:start_search_for.title')}
            </Text>
            <Box mb="6">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('needHelp:start_search_for.do_you_search.title')}
              </Text>
              <Text mb="2">
                {t('needHelp:start_search_for.do_you_search.paragraph_1')}
              </Text>
            </Box>
            <Box mb="6">
              <Text fontSize="lg" fontWeight="bold" mb="2">
                {t('needHelp:start_search_for.we_have_great.title')}
              </Text>
              <Text mb="2">
                {t('needHelp:start_search_for.we_have_great.paragraph_1')}
              </Text>
            </Box>
          </Box>
        </Container>
      </LayoutBody>
    </Layout>
  );
};

export default NeedHelpPage;
