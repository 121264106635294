import React, { useState } from "react";
import PropTypes from "prop-types";
import { Badge, Button, HStack, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { TextareaField } from "../../../components/field";
import Paper from "../../../components/paper";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { updateProperty } from "../../../services/properties.service";
import { messageSuccessUpdate } from "../../../components/messages";
dayjs.extend(LocalizedFormat);

const ProcessObjection = ({ control }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingIgnore, setLoadingIgnore] = useState(false);
  const [loadingProcess, setLoadingProcess] = useState(false);
  const { property } = useSelector(state => state?.properties);

  const handleProcess = async () => {
    const payload = {
      id: id,
      status: "new",
      objectionStatus: ""
    };
    setLoadingProcess(true);
    const res = await dispatch(updateProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      setLoadingProcess(false);
      history.push("/properties");
      messageSuccessUpdate();
    } else {
      setLoadingProcess(false);
    }
  };

  const handleIgnore = async () => {
    const payload = {
      id: id,
      objectionUpdatedAt: new Date().toISOString(),
      objectionStatus: "ignored"
    };
    setLoadingIgnore(true);
    const res = await dispatch(updateProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      setLoadingIgnore(false);
      history.push("/properties");
      messageSuccessUpdate();
    } else {
      setLoadingIgnore(false);
    }
  };

  return (
    <Paper>
      <Wrap justify="space-between">
        <WrapItem>
          <HStack mb="6">
            <Text fontWeight="semibold" fontSize="xl">
              Process the Objection
            </Text>
            <Badge colorScheme="green">New</Badge>
          </HStack>
        </WrapItem>
        <WrapItem>
          <Text>{dayjs(property?.objectionUpdatedAt).format("LLL")}</Text>
        </WrapItem>
      </Wrap>

      <TextareaField
        name="objection"
        label="Objection"
        control={control}
        isReadOnly
      />
      <HStack my="2">
        <Button
          colorScheme="green"
          onClick={handleProcess}
          isLoading={loadingProcess}
        >
          Re-process the property
        </Button>
        <Button
          colorScheme="orange"
          onClick={handleIgnore}
          isLoading={loadingIgnore}
        >
          Ignore
        </Button>
      </HStack>
    </Paper>
  );
};

ProcessObjection.propTypes = {
  control: PropTypes.object
};

export default ProcessObjection;
