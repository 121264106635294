import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, VStack } from "@chakra-ui/react";
import { AutoComplete, InputField, SwitchField } from "components/field";

import {
  useAddMenuMutation,
  useUpdateMenuMutation,
  useAutocompleteMenuQuery,
} from "services/menu.service";

import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import messages from "config/messages";
import { catchError } from "utils/catchError";
import { useSelector } from "react-redux";

const schema = yup.object().shape({
  name: yup.string().required(messages.required),
  ar_name: yup.string().required(messages.required),
});

const MenuForm = ({ data, onClose }) => {
  const [search, setSearch] = useState("");

  const [addMenu, { isLoading: loadingCreate }] = useAddMenuMutation();
  const [updateMenu, { isLoading: loadingUpdate }] = useUpdateMenuMutation();

  const {
    data: menuAutocomplete,
    isLoading,
    isFetching,
  } = useAutocompleteMenuQuery({
    search: search,
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.name || "",
      ar_name: data?.ar_name || "",
      isActive: data?.isActive || false,
      isParent: data?.isParent || false,
      parent: data?.parent || "",
      contentUrl: data?.contentUrl || "",
      sequence: data?.sequence || "",
    },
  });

  const isParent = useWatch({
    control,
    name: "isParent", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });

  const { autocompleteTypeParent, loadingAutocompleteTypeParent } = useSelector(
    (state) => state?.typesProperty,
  );

  const handleChange = (inputValue) => {
    setSearch(inputValue);
  };

  const handlingUpdateData = async (values) => {
    const payload = {
      ...values,
      id: data.id,
    };

    try {
      await updateMenu({ ...payload }).unwrap();
      onClose();
    } catch (error) {
      console.error(catchError(error));
    }
  };

  const handlingAddData = async (values) => {
    // remove data from edit form, because we use same component
    if (values.data) delete values.data;

    try {
      await addMenu({ ...values }).unwrap();
      reset();
      onClose();
    } catch (error) {
      console.error(catchError(error));
    }
  };

  const onSubmit = async (values) => {
    // EDIT DATA
    if (data) {
      await handlingUpdateData(values);
      return;
    }

    // ADD DATA
    await handlingAddData(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing="6" alignItems="flex-start">
        <InputField name="name" label="Name" control={control} isRequired />
        <InputField
          isRTL
          name="ar_name"
          label="Arabic Name"
          control={control}
          isRequired
        />

        <SwitchField name="isActive" label="Active menu" control={control} />

        <SwitchField
          name="isParent"
          label="Enable parent menu"
          control={control}
        />

        {!isParent && (
          <AutoComplete
            isClearable
            name="parent"
            label="Parent"
            options={menuAutocomplete?.data}
            control={control}
            eventInputChange={handleChange}
            isLoading={isLoading || isFetching}
          />
        )}

        <InputField name="contentUrl" label="Content URL" control={control} />

        <InputField name="sequence" label="Sequence" control={control} />
        <Button
          type="submit"
          colorScheme="primary"
          isLoading={loadingUpdate || loadingCreate}
        >
          {data ? "Update" : "Add New"}
        </Button>
      </VStack>
    </form>
  );
};

MenuForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default MenuForm;
