import React, { Fragment, memo } from "react";
import { Link as ReachLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { has } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MenuWrapper = ({ children }) => {
  return (
    <Box
      bg="white"
      w="full"
      position="fixed"
      zIndex="docked"
      transition="100ms"
    >
      <Stack>
        <Center>
          <Stack
            direction={{ base: "column", lg: "row" }}
            alignItems="start"
            mt={{ base: 8, lg: 0 }}
            spacing={{ base: 4, lg: 12 }}
          >
            {children}
          </Stack>
        </Center>
      </Stack>
    </Box>
  );
};

const MenuWrapperItem = ({ text, href, children, onClick }) => (
  <Box
    cursor={href ? "pointer" : "default"}
    py="2"
    fontSize="sm"
    onClick={onClick}
  >
    {text ? (
      <Link
        to={href}
        as={ReachLink}
        textTransform="uppercase"
        fontWeight="bold"
        fontSize="15px"
        color="black"
      >
        {text}
      </Link>
    ) : (
      children
    )}
  </Box>
);

const MobileMenu = ({ isOpen, onClose = () => {}, menuItems }) => {
  const { t, i18n } = useTranslation();
  const ARABIC = i18n.language === "ar";
  const history = useHistory();

  const handleCloseMenu = () => {
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="left">
      <DrawerOverlay>
        <DrawerContent>
          <MenuWrapper>
            <MenuWrapperItem text={t("common:menu.home")} href="/" />
            {menuItems.map((item) => (
              <Fragment key={item.name}>
                {item.sub_menu.length !== 0 ? (
                  <MenuWrapperItem>
                    <Text
                      textTransform="uppercase"
                      fontWeight="bold"
                      fontSize="15px"
                      color="gray.500"
                    >
                      {ARABIC ? item.ar_name : item.name}
                    </Text>
                  </MenuWrapperItem>
                ) : (
                  <MenuWrapperItem
                    text={ARABIC ? item.ar_name : item.name}
                    href={item.contentUrl}
                    onClick={handleCloseMenu}
                  />
                )}

                {has(item, "sub_menu") &&
                  item.sub_menu.map((subItem, subIndex) => (
                    <Box key={subIndex} pl="8">
                      <MenuWrapperItem
                        text={ARABIC ? subItem.ar_name : subItem.name}
                        href={subItem.contentUrl}
                        onClick={handleCloseMenu}
                      />
                    </Box>
                  ))}
              </Fragment>
            ))}

            <MenuWrapperItem>
              <Button
                onClick={() => history.push("/c/special-spaces")}
                size="sm"
                bg="red.600"
                color="white"
                borderRadius="0"
                _hover={{
                  bg: "red.700",
                }}
              >
                {t("common:menu.special_spaces")}
              </Button>
            </MenuWrapperItem>
          </MenuWrapper>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

MenuWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

MenuWrapperItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  text: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  menuItems: PropTypes.array,
};

export default memo(MobileMenu);
