import { HStack, Icon, Link, Stack, Text, Collapse } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link as RouteLink, useRouteMatch } from "react-router-dom";

const Menu = ({ children }) => {
  return <Stack userSelect="none">{children}</Stack>;
};

const MenuGroup = ({ children, title, icon, isActive, onClick }) => {
  return (
    <Fragment>
      <HStack
        onClick={onClick}
        color="white"
        py="2"
        px="3"
        borderRadius="md"
        fontWeight="medium"
        _hover={{ cursor: "pointer" }}
        _focus={{ bg: "primary.100" }}
      >
        <HStack w="full">
          <Icon w="20px" h="20px" as={icon} />
          <Text pl="2">{title}</Text>
        </HStack>
        <Icon as={isActive ? FaChevronUp : FaChevronDown} />
      </HStack>
      <Stack
        borderRadius="8px"
        ml="4"
        px="2"
        py="3"
        bg="primary.700"
        display={isActive ? "flex" : "none"}
      >
        {children}
      </Stack>
    </Fragment>
  );
};

const MenuItem = ({ title, icon, url }) => {
  const { path } = useRouteMatch();

  return (
    <Link
      as={RouteLink}
      to={url}
      bg={path === url ? "primary.800" : "transparent"}
      color="white"
      textDecoration="none"
      fontWeight="medium"
      py="2"
      px="3"
      borderRadius="md"
      _hover={{ textDecoration: "none" }}
    >
      <HStack>
        {icon && <Icon w="20px" h="20px" as={icon} />}
        <Text pl="2">{title}</Text>
      </HStack>
    </Link>
  );
};

Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

MenuGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string,
  icon: PropTypes.func,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

MenuItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.func,
  url: PropTypes.string,
};

export { Menu, MenuGroup, MenuItem };
