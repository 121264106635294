import React from "react";
import { HStack, useToast } from "@chakra-ui/react";
import { TableV1 } from "components/table";
import { ConfirmDelete } from "components/alert";
import SomethingWrong from "components/error/SomethingWrong";
import PopupEdit from "./PopupEdit";
import {
  useGetAccountTypeQuery,
  useDeleteAccountTypeMutation,
} from "services/accountType.services";

const AccountTypesTable = () => {
  const toast = useToast();
  const [offset, setOffset] = React.useState(0);

  const {
    data: accountTypes,
    isLoading,
    isFetching,
    isError,
  } = useGetAccountTypeQuery({
    offset: offset,
  });

  const [deleteAccountType, { isLoading: loadingDelete }] =
    useDeleteAccountTypeMutation();

  const handlePagination = (internalOffset) => {
    setOffset(internalOffset);
  };

  const handleDelete = async (id) => {
    try {
      await deleteAccountType(id).unwrap();
      toast({
        description: `Account type deleted successfully!`,
        status: "success",
        position: "top-right",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const RenderAction = ({ selectedItem }) => {
    return (
      <HStack justifyContent="flex-end">
        <PopupEdit data={selectedItem} />
        <ConfirmDelete
          iconOnly
          headerText={`Delete '${selectedItem.name}'`}
          bodyText="Are you sure? You can't undo this action afterwards."
          eventClick={() => handleDelete(selectedItem.id)}
          loadingDelete={loadingDelete}
        />
      </HStack>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Account Type",
        accessor: "propertyType",
      },
      {
        Header: "Arabic Name",
        accessor: "propertyTypeArabic",
      },
      {
        Header: "Actions",
        accessor: "actions",
        isNumeric: true,
      },
    ],
    [],
  );

  const dataMemoized = React.useMemo(
    () =>
      accountTypes?.data.map((item) => {
        return {
          propertyType: item.name,
          propertyTypeArabic: item.ar_name,
          actions: <RenderAction selectedItem={item} />,
        };
      }),
    [accountTypes?.data],
  );

  if (isError) {
    return <SomethingWrong />;
  }

  return (
    <TableV1
      columns={columns}
      data={dataMemoized}
      isLoading={isLoading || isFetching}
      meta={accountTypes?.meta}
      handlePagination={handlePagination}
    />
  );
};

export default AccountTypesTable;
