import React, { useState } from 'react';
import Paper from 'components/paper';
import { InputField, InputNumberField, PasswordField } from 'components/field';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Flex, Stack, VStack } from '@chakra-ui/react';
import {
  nextStep,
  prevStep,
  selectForm,
  updateForm,
} from '../../../../redux/registerStepSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { checkUsername } from '../../../../services/agents.service';
import messages from '../../../../config/messages';

const schema = yup.object().shape({
  identificationNumber: yup
    .string()
    .max(10, 'Max characters allowed 10')
    .required('Identification number is required'),
});

const advertiserIDSchema = yup.object().shape({
  advertiserID: yup.string().required('Advertiser ID is required'),
});

const serviceIDProviderSchema = yup.object().shape({
  username: yup
    .string()
    .min(8, 'Characters limit allowed 8-50')
    .max(50, 'Characters limit allowed 8-50')
    .required('Username is required')
    .matches(
      /^\s*([0-9a-zA-Z]+)\s*$/,
      'Allowed English letters and numbers only'
    ),
  password: yup.string().required('Password is required'),
  // .matches(
  //   /[a-zA-Z0-9]{6}[0-9]{3}/,
  //   "Allowed characters 6 letters and 3 digits"
  // ),
  confirmPassword: yup
    .string()
    .required(messages.required)
    .oneOf([yup.ref('password'), null], "Passwords don't match"),
});

const Step2 = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('register');
  const dispatch = useDispatch();
  const form = useSelector(selectForm);

  const generateBuildSchema = () => {
    switch (form.role) {
      case 'personal':
        return schema;
      case 'service_office_provider':
        return serviceIDProviderSchema;
      default:
        return schema.concat(advertiserIDSchema);
    }
  };

  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(generateBuildSchema()),
    defaultValues: {
      identificationNumber: form.identificationNumber || '',
      advertiserID: form.advertiserID || '',
    },
  });

  const onSubmit = async (values) => {
    const payload = {
      ...form,
      ...values,
    };

    // check username for service office provider
    if (form.role === 'service_office_provider') {
      setLoading(true);

      const res = await dispatch(checkUsername({ username: values.username }));

      setLoading(false);

      if (res.meta.requestStatus !== 'fulfilled') return;
    }

    dispatch(updateForm(payload));
    dispatch(nextStep());
  };

  // const onSubmit = async (values) => {
  //   setLoading(true);
  //   const payload = {
  //     ...form,
  //     ...values
  //   };
  //
  //   const res = await dispatch(
  //     verifyPost({
  //       typeId: typeIds.find((type) => type.value === form.role).id,
  //       iDNumber: values.identificationNumber,
  //       adNumber: values.advertiserID
  //     })
  //   );
  //   setLoading(false);
  //   if (res.meta.requestStatus === 'fulfilled') {
  //     dispatch(updateForm(payload));
  //     dispatch(nextStep());
  //   }
  // };

  const renderForm = () => {
    switch (form.role) {
      case 'personal':
        return (
          <InputNumberField
            name="identificationNumber"
            label={t('register:identification_number')}
            control={control}
            isRequired
          />
        );
      case 'service_office_provider':
        return (
          <>
            <InputField
              name="username"
              label={t('register:username')}
              control={control}
              isRequired
            />

            <Stack direction={['column', 'row']} w="full" spacing="6">
              <PasswordField
                name="password"
                label={t('register:password')}
                control={control}
                isRequired
              />
              <PasswordField
                name="confirmPassword"
                label={t('register:confirm_password')}
                control={control}
                isRequired
              />
            </Stack>
          </>
        );
      default:
        return (
          <>
            <InputNumberField
              name="identificationNumber"
              label={t('register:identification_number')}
              control={control}
              isRequired
            />
            <InputField
              name="advertiserID"
              label={t('register:advertiser_id')}
              control={control}
              isRequired
            />
          </>
        );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Paper
        p={{ base: 6, md: 8, lg: 10 }}
        boxShadow="lg"
        mt="12"
        borderRadius="2xl"
      >
        <VStack spacing="6" align="start">
          {renderForm()}
          <Flex width="100%" justify="flex-end">
            <Button mr={4} onClick={() => dispatch(prevStep())}>
              {t('register:back')}
            </Button>
            <Button isLoading={loading} type="submit" colorScheme="primary">
              {t('register:next')}
            </Button>
          </Flex>
        </VStack>
      </Paper>
    </form>
  );
};

export default Step2;
