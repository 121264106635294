import { createSlice } from "@reduxjs/toolkit";
import { createStandaloneToast } from "@chakra-ui/react";
const { toast } = createStandaloneToast();
import i18next from "i18next";

const favoriteSlice = createSlice({
  name: "favorites",
  initialState: {
    listFavorite: [],
  },
  reducers: {
    addFavorite(state, action) {
      state.listFavorite.push(action.payload);
      toast({
        title: `${i18next.t("added_to_favorite")}`,
        status: "success",
        duration: 2000,
        position: "top-right",
      });
    },
    removeFavorite(state, action) {
      state.listFavorite = state.listFavorite.filter(
        (item) => item.objectID !== action.payload.objectID,
      );
      toast({
        title: `${i18next.t("removed_from_favorite")}`,
        status: "error",
        duration: 2000,
        position: "top-right",
      });
    },
  },
});

export const { addFavorite, removeFavorite } = favoriteSlice.actions;

export default favoriteSlice.reducer;
