import React from "react";
import PropTypes from "prop-types";
import { IconButton, Link } from "@chakra-ui/react";

const SocialButton = ({ href, icon, ...props }) => (
  <Link href={href}>
    <IconButton
      {...props}
      variant="link"
      color="white"
      _hover={{
        color: "primary.500"
      }}
      icon={icon}
    />
  </Link>
);

SocialButton.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.node
};

export default SocialButton;
