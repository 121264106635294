import React, { Fragment } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import SideFilter from "./SideFilter";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import { useGetElasticPropertiesQuery } from "services/client/properties.client.services";
import { useQuerySearch } from "../../hooks";

const MobileFilter = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const querySearch = useQuerySearch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: properties } = useGetElasticPropertiesQuery({ ...querySearch });

  const totalFoundProperties = get(properties, "meta.nbHits", 0);

  const handleClearAll = () => {
    history.push("/c/properties");
    onClose();
  };

  return (
    <Fragment>
      <Box
        onClick={onOpen}
        cursor="pointer"
        borderWidth="2px"
        py="2"
        px="4"
        borderRadius="md"
        _hover={{
          borderColor: "primary.500",
        }}
      >
        {t("common:label.filters")}
      </Box>
      <Drawer
        isFullHeight
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
        motionPreset={"slideInRight"}
        blockScrollOnMount
      >
        <DrawerOverlay />
        <DrawerContent
          height="100%" // fixed bug on mobile browser
        >
          <DrawerCloseButton />
          <DrawerHeader
            py="2"
            borderTopWidth="1px"
            borderColor="gray.300"
            boxShadow="md"
          >
            {t("common:label.filters")}
          </DrawerHeader>
          <DrawerBody px="2">
            <SideFilter />
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px" borderColor="gray.300" py="2">
            <Stack
              direction="row"
              w="full"
              spacing="24px"
              justify="space-between"
            >
              <Button
                variant="link"
                color="brand.dark"
                onClick={handleClearAll}
              >
                {t("clear_all")}
              </Button>
              <Button colorScheme="primary" onClick={onClose}>
                {totalFoundProperties === 0
                  ? t("no_exact_matches")
                  : `${t("show")} ${totalFoundProperties >= 300 ? "300+" : totalFoundProperties} ${t("spaces")}`}
              </Button>
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Fragment>
  );
};

export default MobileFilter;
