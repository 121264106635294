import React from "react";
import PropTypes from "prop-types";
import { HStack, Button } from "@chakra-ui/react";
import ButtonDark from "../../button/ButtonDark";

const ActionCell = ({
  onDetail = () => {},
  onDelete = () => {},
  detailLabel = "Detail",
  deleteLabel = "Remove"
}) => {
  return (
    <HStack justifyContent="center">
      <Button colorScheme="primary" onClick={onDetail}>
        {detailLabel}
      </Button>
      <ButtonDark onClick={onDelete}>{deleteLabel}</ButtonDark>
    </HStack>
  );
};

ActionCell.propTypes = {
  onDetail: PropTypes.func,
  onDelete: PropTypes.func,
  detailLabel: PropTypes.string,
  deleteLabel: PropTypes.string
};

export default ActionCell;
