import React, { Fragment } from "react";
import { Box, Skeleton, HStack } from "@chakra-ui/react";

const PropertyItemSkeleton = () => {
  return (
    <Fragment>
      <Box>
        <Skeleton height="370px" mb="4" />
        <HStack>
          <Skeleton w="80px" height="80px" />
          <Skeleton w="80px" height="80px" />
          <Skeleton w="80px" height="80px" />
          <Skeleton w="80px" height="80px" />
        </HStack>
      </Box>
    </Fragment>
  );
};

export default PropertyItemSkeleton;
