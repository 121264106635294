import {
  Avatar,
  Box,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Divider,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { BiMenu } from "react-icons/bi";
import { CiMenuKebab } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logout } from "../../redux/authSlice";
import HeaderInfo from "./HeaderInfo";

const Header = ({ toggleMobileMenu }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { me } = useSelector((state) => state?.auth);

  const handleLogout = () => {
    dispatch(logout());
    history.push("/");
    // clear all redux cache, because redux data still cached data if browser is not reloaded.
    // window.location.reload();
  };
  const goAccount = () => {
    history.push("/account");
  };

  return (
    <Box
      as="header"
      bg="brand.dark"
      borderBottom="1px"
      borderColor="brand.dark"
      position="fixed"
      w="full"
      zIndex="docked"
    >
      <Box m="auto" px={{ base: "4", lg: "12" }} py="3">
        <Box as="nav" display="flex" justifyContent="space-between">
          <HStack>
            <Icon
              onClick={toggleMobileMenu}
              as={BiMenu}
              fontSize="24px"
              ml="2"
              color="primary.500"
              display={{ base: "block", lg: "none" }}
            />
            <Text
              cursor="pointer"
              fontSize="xl"
              fontWeight="bold"
              display={{ base: "none", lg: "block" }}
            >
              <Link to={"/"}>
                <Image
                  cursor="pointer"
                  src={process.env.PUBLIC_URL + "/images/logo.png"}
                  ignoreFallback
                  w={"7rem"}
                  height="40px"
                />
              </Link>
            </Text>
          </HStack>
          {/* BOX RIGHT */}
          <Box display="flex">
            <HStack>
              <Menu>
                <MenuButton
                  as={Box}
                  bg="gray.100"
                  p="1"
                  border="1px"
                  borderRadius="full"
                  borderColor="gray.300"
                  cursor="pointer"
                  _hover={{ boxShadow: "md" }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Avatar h="32px" w="32px" ml="2" src={me.imageURL} />
                    <Text mx="2">Hi, {me?.displayName}</Text>
                    <Icon
                      as={CiMenuKebab}
                      fontSize="20px"
                      mx="1"
                      color="gray.500"
                    />
                  </Box>
                </MenuButton>
                <MenuList
                  zIndex="dropdown"
                  rootProps={{ style: { right: 0 } }}
                  width="270px"
                >
                  <HeaderInfo />
                  <MenuItem
                    fontWeight="semibold"
                    onClick={() => history.push("/")}
                  >
                    Home
                  </MenuItem>
                  <MenuItem fontWeight="semibold" onClick={goAccount}>
                    My Account
                  </MenuItem>
                  <Divider />
                  <MenuItem fontWeight="semibold" onClick={handleLogout}>
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  toggleMobileMenu: PropTypes.func,
};

export default Header;
