import { createSlice } from "@reduxjs/toolkit";
import {
  register,
  sessionLogin,
  me,
  forgot,
  verify,
  updatePassword,
  updateProfile,
} from "../services/auth.service";

const initialState = {
  data: {
    idToken: null,
  },
  me: null,
  loading: false,
  loadingUpdatePassword: false,
  loadingUpdateProfile: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.me = initialState.me;
      state.data = initialState.data;
    },
    saveToken: (state, action) => {
      state.data.idToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // REGISTER
    builder.addCase(register.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(register.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(register.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // LOGIN
    builder.addCase(sessionLogin.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(sessionLogin.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(sessionLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // ME
    builder.addCase(me.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(me.fulfilled, (state, action) => {
      state.loading = false;
      state.me = action.payload;
    });

    builder.addCase(me.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // FORGOT
    builder.addCase(forgot.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(forgot.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(forgot.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE PASSWORD
    builder.addCase(updatePassword.pending, (state) => {
      state.loadingUpdatePassword = true;
    });

    builder.addCase(updatePassword.fulfilled, (state) => {
      state.loadingUpdatePassword = false;
    });

    builder.addCase(updatePassword.rejected, (state, action) => {
      state.loadingUpdatePassword = false;
      state.error = action.payload;
    });

    // UPDATE PROFILE
    builder.addCase(updateProfile.pending, (state) => {
      state.loadingUpdateProfile = true;
    });

    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.loadingUpdateProfile = false;
      state.me = action.payload.data;
    });

    builder.addCase(updateProfile.rejected, (state, action) => {
      state.loadingUpdateProfile = false;
      state.error = action.payload;
    });

    // VERIFY
    builder.addCase(verify.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(verify.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(verify.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { logout, saveToken } = authSlice.actions;

export default authSlice.reducer;
