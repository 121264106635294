import React from "react";
import PropTypes from "prop-types";
import { Button, VStack } from "@chakra-ui/react";
import { InputField } from "components/field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { stringSchema } from "utils/sharedSchema";
import * as yup from "yup";
import {
  useAddCountryMutation,
  useUpdateCountryMutation,
} from "services/location/countries.services";
import { catchError } from "../../../../utils/catchError";

const schema = yup.object().shape({
  name: stringSchema,
  ar_name: stringSchema,
});

const CountryForm = ({ data, onClose }) => {
  const [addCountry, { isLoading: isLoadingAdd }] = useAddCountryMutation();

  const [updateCountry, { isLoading: isLoadingUpdate }] =
    useUpdateCountryMutation();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.name || "",
      ar_name: data?.ar_name || "",
    },
  });

  const handlingUpdateData = async (values) => {
    const payload = {
      ...values,
      id: data.id,
    };

    try {
      await updateCountry({ ...payload }).unwrap();
      onClose();
    } catch (error) {
      console.error(catchError(error));
    }
  };

  const handlingAddData = async (values) => {
    // remove data from edit form, because we use same component
    if (values.data) delete values.data;

    try {
      await addCountry({ ...values }).unwrap();
      reset();
    } catch (error) {
      console.error(catchError(error));
    }
  };

  const onSubmit = async (values) => {
    // EDIT DATA
    if (data) {
      await handlingUpdateData(values);
      return;
    }

    // ADD DATA
    await handlingAddData(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing="6" alignItems="flex-start">
        <InputField
          name="name"
          label="Country Name"
          control={control}
          isRequired
        />
        <InputField
          isRTL
          name="ar_name"
          label="Arabic Country Name"
          control={control}
          isRequired
        />
        <Button
          type="submit"
          colorScheme="primary"
          isLoading={data ? isLoadingUpdate : isLoadingAdd}
        >
          {data ? "Update" : "Add New"}
        </Button>
      </VStack>
    </form>
  );
};

CountryForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default CountryForm;
