import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

const TableEmptyData = () => (
  <Flex
    height="calc(100vh - 370px)"
    borderRadius="md"
    direction="column"
    bg="white"
    p="12"
    justifyContent="center"
    alignItems="center"
  >
    <Text fontSize="2xl" fontWeight="bold" my="2">
      No Data Found
    </Text>
    <Text color="grey.500">Data is empty or Try adjusting your filter.</Text>
  </Flex>
);

export default TableEmptyData;
