import React from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "components/layout";
import { Grid, GridItem, Heading } from "@chakra-ui/react";
import { TextHeader } from "components/layout/header";
import Paper from "components/paper";
import CountryForm from "./components/CountryForm";
import CountriesTable from "./components/CountriesTable";
import PaperTable from "../../../components/paper-table";

const CountriesPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Countries" />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <Grid templateColumns={["repeat(12, 1fr)"]} gap={6}>
          <GridItem colSpan={[12, 12, 12, 12, 4]}>
            <Paper>
              <Heading fontSize="lg" mb="8">
                Add New Country
              </Heading>
              <CountryForm />
            </Paper>
          </GridItem>
          <GridItem colSpan={[12, 12, 12, 12, 8]}>
            <PaperTable>
              <CountriesTable />
            </PaperTable>
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};

export default CountriesPage;
