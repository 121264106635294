import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FIELD_VARIANT } from "../../config/preset";
import { useController } from "react-hook-form";

const InputNumberField = ({
  name,
  placeholder,
  control,
  label,
  isRequired = false,
  ...props
}) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: { required: isRequired },
  });

  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      onChange(e);
    }
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        type="tel"
        ref={ref}
        name={name}
        id={name}
        variant={FIELD_VARIANT.default}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        {...inputProps}
        {...props}
      />
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

InputNumberField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default InputNumberField;
