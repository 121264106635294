import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShrunk: false,
  bgHeader: "brand.header",
  navbarLinkColor: "black"
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setShrunk(state, action) {
      if (state.isShrunk !== action.payload) {
        state.isShrunk = action.payload;
      }
    },
    setBgHeader(state, action) {
      state.bgHeader = action.payload;
    },
    setNavbarLinkColor(state, action) {
      state.navbarLinkColor = action.payload;
    },
    setLayout(state, action) {
      const { payload } = action;
      state.isShrunk = payload.isShrunk;
      state.bgHeader = payload.bgHeader;
      state.navbarLinkColor = payload.navbarLinkColor;
    }
  }
});

export const {
  setShrunk,
  setBgHeader,
  setNavbarLinkColor,
  setLayout
} = layoutSlice.actions;
export default layoutSlice.reducer;
