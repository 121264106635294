import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(LocalizedFormat);

const DateTimeCell = ({ value }) => {
  return dayjs(value).format("LL");
};

DateTimeCell.propTypes = {
  value: PropTypes.string
};

export default DateTimeCell;
