import React, { Fragment, memo, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import MapsConfig from "config/maps";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

const PinMap = ({ location, height }) => {
  const containerStyle = useMemo(
    () => ({
      width: "100%",
      height: height
    }),
    [height]
  );

  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: MapsConfig.key
  });

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={location ? 16 : MapsConfig.zoom}
        center={location || MapsConfig.defaultCenter}
      >
        <Marker position={location} />
      </GoogleMap>
    );
  };

  return isLoaded ? renderMap() : "loading....";
};

PinMap.propTypes = {
  location: PropTypes.object,
  height: PropTypes.string
};

PinMap.defaultProps = {
  location: undefined,
  height: "400px"
};

export default memo(PinMap);
