import React, { useEffect } from "react";
import { Box, Button, Divider, Stack, Text } from "@chakra-ui/react";
import { PriceRangeFilterField } from "components/field";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { stringify } from "query-string";
import FilterSpaceTypeExplore from "./Standalone/FilterSpaceTypeExplore";
import FilterLocation from "./Standalone/FilterLocation";
import { useTranslation } from "react-i18next";
import { useQuerySearch } from "hooks";

const SideFilter = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const querySearch = useQuerySearch();
  const { control, handleSubmit, reset } = useForm();

  useEffect(() => {
    const valueFormQuery = {
      price: [
        querySearch.price_min ? querySearch.price_min : 1,
        querySearch.price_max ? querySearch.price_max : 10000,
      ],
      type: querySearch.property_type
        ? querySearch.property_type.split(",")
        : [],
    };

    // set value filter from query when pathname change
    reset(valueFormQuery);
  }, [location.pathname, location.search]);

  const handleUpdatePrice = async (values) => {
    const params = {
      ...querySearch,
      price_min: values.price[0],
    };

    if (values.price[1] !== 10000) {
      params.price_max = values.price[1];
    } else {
      delete params.price_max;
    }

    history.push({
      pathname: location.pathname,
      search: stringify(params),
    });
  };

  const handleChangeType = (selected) => {
    // convert from array to string => [1, 2, 3] to "1,2,3"
    const stringifySelected = selected.toString();

    const params = {
      ...querySearch,
      property_type: stringifySelected,
    };

    if (selected && selected.length === 0) {
      delete params.property_type;
    }

    history.push({
      pathname: location.pathname,
      search: stringify(params),
    });
  };

  return (
    <Box
      border={{ base: "0", lg: "1px" }}
      borderColor={{ base: "gray.300", lg: "gray.300" }}
      boxShadow={{ base: "none", lg: "md" }}
      minW="270px"
    >
      <Box p="4">
        <Box>
          <Box mb="2">
            <Text fontWeight="semibold">{t("common:label.space_type")}</Text>
          </Box>
          <FilterSpaceTypeExplore
            name="type"
            control={control}
            eventChange={handleChangeType}
          />
        </Box>
      </Box>
      <Divider />
      <Box p="4">
        <form onSubmit={handleSubmit(handleUpdatePrice)}>
          <Box mb="2">
            <Text fontWeight="semibold">{t("common:label.price_range")}</Text>
          </Box>
          <Stack
            direction="column"
            spacing="6"
            align={{ base: "flex-end", lg: "stretch" }}
          >
            <PriceRangeFilterField name="price" control={control} />
            <Button colorScheme="primary" type="submit">
              {t("common:button.update")}
            </Button>
          </Stack>
        </form>
      </Box>
      <Divider color="red.100" />
      <Box p="4">
        <Box>
          <Box mb="2">
            <Text fontWeight="semibold">{t("common:label.location")}</Text>
          </Box>
          <FilterLocation />
        </Box>
      </Box>
    </Box>
  );
};

export default SideFilter;
