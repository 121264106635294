import React from "react";
import Layout, { LayoutBody } from "../../../components/layout/client";
import { Box, Text } from "@chakra-ui/react";
import TableCompare from "components/TableCompare";
import {
  TypeCell,
  DescriptionCell,
  LocationCell,
  ActionCell,
  PropertyCell,
  FeaturesCell,
  PinMapCell,
} from "components/TableCompare/cell";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { removeCompare } from "../../../redux/compareSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export const ComparePage = () => {
  const { t, i18n } = useTranslation();
  const ARABIC = i18n.language === "ar";
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state?.compare);

  const handleDetail = (item) => {
    window.open(`/c/property/${item.objectID}`, "_self");
  };

  const handleDelete = (item) => {
    dispatch(removeCompare(item));
  };

  const prepareDate = items.map((item) => {
    return {
      id: item.id,
      property: PropertyCell({
        imageSrc: item.featureImage,
        propertyPrice: item.price,
        propertyName: get(item, "title", "-"),
        propertyPriceTime: get(item, "payment", "-"),
      }),
      type: TypeCell(
        get(item, `${ARABIC ? "type.ar_label" : "type.label"}`, "-"),
      ),
      location: LocationCell(
        `
        ${get(item, "country.label", "-")}, 
        ${get(item, "region.label", "-")}, 
        ${get(item, "city.label", "-")}
        `,
      ),
      description: DescriptionCell(get(item, "description", "-")),
      features: FeaturesCell(
        get(item, "features", []),
        ARABIC ? "ar_label" : "label",
      ),
      map: PinMapCell(get(item, "pinMapLocation", null)),
      action: ActionCell({
        onDetail: () => handleDetail(item),
        onDelete: () => handleDelete(item),
        detailLabel: `${t("common:button.detail")}`,
        deleteLabel: `${t("common:button.remove")}`,
      }),
    };
  });

  return (
    <Layout>
      <LayoutBody>
        <Box>
          <Text fontSize="3xl" fontWeight="bold" mb="4">
            {t("common:label.compare")}
          </Text>
        </Box>

        <TableCompare
          data={prepareDate}
          features={[
            "property",
            "type",
            "description",
            "features",
            "location",
            "map",
            "action",
          ]}
          header={[
            "",
            `${t("common:label.type")}`,
            `${t("common:label.description")}`,
            `${t("common:label.features")}`,
            `${t("common:label.location")}`,
            "",
            "",
          ]}
        />
      </LayoutBody>
    </Layout>
  );
};
