import React from "react";
import PropTypes from "prop-types";
import { useModalForm } from "components/hooks/useModalForm";
import { FaPen } from "react-icons/fa";
import RegionForm from "./RegionForm";

const PopupEdit = ({ data }) => {
  const { ModalForm, IconButtonTrigger, onClose } = useModalForm();

  return (
    <>
      <IconButtonTrigger icon={<FaPen />} />
      <ModalForm title="Edit Region">
        <RegionForm data={data} onClose={onClose} />
      </ModalForm>
    </>
  );
};

PopupEdit.propTypes = {
  data: PropTypes.object,
};
export default React.memo(PopupEdit);
