import { axiosInstance } from "./fetchApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchError } from "../utils/catchError";

const endpoint = "/properties";
const uploadEndpointV1 = "/v1/upload";

export const fetchAutocompleteProperties = createAsyncThunk(
  `${endpoint}/autocomplete`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/autocomplete`, {
        params: {
          offset: payload?.offset || 0,
          search: payload?.search || "",
          limit: 6,
          ...payload,
        },
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const fetchProperties = createAsyncThunk(
  `${endpoint}/fetchAll`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(endpoint, {
        params: {
          offset: payload?.offset || 0,
          search: payload?.search || "",
          limit: 6,
          ...payload,
        },
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const fetchPropertyById = createAsyncThunk(
  `${endpoint}/fetchById`,
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const createProperty = createAsyncThunk(
  `${endpoint}/create`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(endpoint, payload);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const updateProperty = createAsyncThunk(
  `${endpoint}/update`,
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`${endpoint}/${id}`, {
        ...payload,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const holdProperty = createAsyncThunk(
  `${endpoint}/hold`,
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`${endpoint}/${id}/hold`, {
        ...payload,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const delegateProperty = createAsyncThunk(
  `${endpoint}/delegate`,
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`${endpoint}/${id}/delegate`, {
        ...payload,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteProperty = createAsyncThunk(
  `${endpoint}/delete`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const upload = createAsyncThunk(
  `${uploadEndpointV1}/property-picture`,
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("image", payload?.data);
      const response = await axiosInstance.post(
        `${uploadEndpointV1}/property-picture/${payload?.propertyId}`,
        formData,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const uploadGallery = createAsyncThunk(
  `${uploadEndpointV1}/property-gallery`,
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < payload?.data.length; i++) {
        formData.append("images", payload?.data[i]);
      }

      // formData.append("id", payload?.id);

      const response = await axiosInstance.post(
        `${uploadEndpointV1}/property-gallery/${payload?.propertyId}`,
        formData,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
