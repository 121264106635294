import React, { useState, memo } from 'react';
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { TextareaField } from '../../../components/field';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaRejection } from '../constant';
import { useParams } from 'react-router-dom';
import { messageSuccessUpdate } from '../../../components/messages';
import { useRejectionInquiryMutation } from 'services/inquiries.services';

const ModalRejection = ({ refetch, inquiry }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaRejection),
  });

  // service
  const [rejectionInquiry, { isLoading }] = useRejectionInquiryMutation();

  const onSubmit = async (values) => {
    try {
      const payload = {
        id,
        inquiredProperty: inquiry?.inquiredProperty,
        ...values,
      };

      const res = await rejectionInquiry(payload).unwrap();
      messageSuccessUpdate({
        title: 'Success',
        description: res.message,
      });
      reset();
      refetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Reject
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Reject Inquiry</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TextareaField
              name="rejectionReason"
              label="Reason"
              control={control}
              isRequired
            />
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
              size="sm"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(ModalRejection);
