import React, { Fragment, useEffect, useState } from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import {
  Badge,
  Button,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import Paper from "../../components/paper";
import { ConfirmDelete } from "../../components/alert";
import {
  ImageUpload,
  InputField,
  LinkField,
  MultipleImageUpload,
  NumberField,
  PinMapField,
  SelectField,
  TextareaField,
} from "../../components/field";
import { useForm } from "react-hook-form";
import {
  AutocompleteCities,
  AutocompleteCountries,
  AutocompleteFeatures,
  AutocompleteRegions,
  AutocompleteTags,
  AutocompleteTypes,
} from "../../components/shared";
import {
  parkingOptions,
  paymentOptions,
  schema,
  statusOptionsByRole,
} from "./constant";
import ModalObjection from "./components/ModalObjection";
import ModalDelegate from "./components/ModalDelegate";
import ModalRejection from "./components/ModalRejection";
import { useDispatch, useSelector } from "react-redux";
import ROLE from "../../config/role";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  deleteProperty,
  fetchPropertyById,
  updateProperty,
  upload,
  uploadGallery,
} from "../../services/properties.service";
import { useHistory, useParams } from "react-router-dom";
import {
  messageSuccessDelete,
  messageSuccessUpdate,
} from "../../components/messages";
import ProcessObjection from "./components/ProcessObjection";

const PropertyDetailsPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(),
  });

  const format = (val) => val + ` SAR`;
  const formatArea = (val) => val + ` Sq m`;

  const { me } = useSelector((state) => state?.auth);

  const {
    property,
    loadingSingle,
    loadingUpdate,
    loadingUpload,
    loadingDelete,
    loadingUploadGallery,
  } = useSelector((state) => state?.properties);

  const loadData = async () => {
    const res = await dispatch(fetchPropertyById(id));

    if (res.meta.requestStatus === "fulfilled") {
      const { data } = res.payload;

      const defaultValues = {
        title: data.title,
        type: data.type,
        numberOfPeople: data.numberOfPeople,
        price: data.price,
        payment: data.payment,
        area: data.area,
        country: data.country,
        region: data.region,
        city: data.city,
        description: data.description,
        featureImage: data.featureImage,
        propertyGallery: data.propertyGallery,
        features: data.features,
        tags: data.tags,
        parking: data.parking,
        numberOfConferenceRooms: data.numberOfConferenceRooms,
        addedBy: data.addedBy,
        responsible: data.responsible,
        pinMapLocation: data.pinMapLocation,
        status: data.status,
        rejectedReasons: data.rejectedReasons,
        // OBJECTION
        objection: data.objection,
      };

      reset(defaultValues);
    } else {
      history.push("/properties/not-found");
    }
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const handleUpload = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      data: file,
      propertyId: id,
    };

    const res = await dispatch(upload(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData(res.payload.imageURL);

      // save large version
      setValue("featureImageLarge", res.payload?.largeImageURL);

      // save thumbnail version
      setValue("featureImageThumb", res.payload.thumbnailImageURL);
    }
  };

  const handleUploadGallery = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      data: file,
      propertyId: id,
    };

    const res = await dispatch(uploadGallery(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData(res.payload.data);
    }
  };

  const onUpdate = async (values) => {
    const payload = {
      id: id,
      ...values,
    };

    const res = await dispatch(updateProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/properties");
      messageSuccessUpdate();
    }
  };

  const onAction = async (values, action) => {
    const payload = {
      id: id,
      ...values,
    };

    if (action === "approve") {
      payload.status = "published";
      setLoadingApprove(true);
    } else if (action === "review") {
      payload.status = "new";
      setLoadingReview(true);
    }

    // set responsible
    payload.responsible = {
      label: me?.displayName,
      value: me?.uid,
    };

    const res = await dispatch(updateProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      setLoadingApprove(false);
      setLoadingReview(false);
      history.push("/properties");
      messageSuccessUpdate();
    } else {
      setLoadingReview(false);
      setLoadingApprove(false);
    }
  };

  const handleDelete = async () => {
    const res = await dispatch(deleteProperty(id));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/properties");
      messageSuccessDelete();
    }
  };

  const handleCallbackCountries = () => {
    setValue("region", null);
    setValue("city", null);
  };

  const handleCallbackRegion = () => {
    setValue("city", null);
  };

  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <HStack>
            <TextHeader text="Property Details" />
            {property?.status === "new" && !loadingSingle && (
              <Badge colorScheme="green">New</Badge>
            )}
          </HStack>
        </LayoutHeaderLeft>
        <Skeleton isLoaded={!loadingSingle}>
          <LayoutHeaderRight>
            {(me?.role === ROLE.ADMIN || me?.role === ROLE.WEBSITE_AGENT) && (
              <Fragment>
                {(property?.status === "new" ||
                  property?.status === "delegated") && (
                  <Fragment>
                    <Button
                      onClick={handleSubmit((val) => onAction(val, "approve"))}
                      size="sm"
                      isLoading={loadingApprove}
                    >
                      Approve
                    </Button>

                    {/* REJECT ACTION */}
                    <ModalRejection />

                    {/* DELEGATE ACTION */}
                    {/* website agent is not able to delegate properties */}
                    {me?.role === ROLE.ADMIN && <ModalDelegate />}
                  </Fragment>
                )}

                {property?.status !== "new" &&
                  property?.status !== "delegated" && (
                    <Button
                      onClick={handleSubmit(onUpdate)}
                      colorScheme="primary"
                      isLoading={loadingUpdate}
                      isDisabled={property?.objectionStatus === "new"}
                    >
                      Save Changes
                    </Button>
                  )}
              </Fragment>
            )}

            {[ROLE.SERVICE_OFFICE_PROVIDER, ROLE.PROPERTY_AGENT].includes(
              me?.role
            ) && (
              <Fragment>
                {!["new", "rejected", "delegated", "hold"].includes(
                  property?.status
                ) && (
                  <Button
                    onClick={handleSubmit(onUpdate)}
                    colorScheme={
                      property?.status !== "draft" ? "primary" : "gray"
                    }
                    isLoading={loadingUpdate && !loadingReview}
                  >
                    Save Changes
                  </Button>
                )}

                {["draft", "hold"].includes(property?.status) && (
                  <Button
                    onClick={handleSubmit((val) => onAction(val, "review"))}
                    colorScheme="primary"
                    isLoading={loadingReview}
                  >
                    Submit for Review
                  </Button>
                )}

                {["delegated", "new"].includes(property?.status) && (
                  <Text fontWeight="bold" color="yellow.700">
                    Waiting Confirmation
                  </Text>
                )}

                {property?.status === "rejected" && <ModalObjection />}
              </Fragment>
            )}
          </LayoutHeaderRight>
        </Skeleton>
      </LayoutHeader>

      <LayoutBody>
        <Grid templateColumns={["repeat(12, 1fr)"]} gap={6}>
          {(me?.role === ROLE.ADMIN || me?.role === ROLE.WEBSITE_AGENT) && (
            <Fragment>
              {property?.objectionStatus === "new" && !loadingSingle && (
                <GridItem colSpan={12}>
                  <ProcessObjection control={control} />
                </GridItem>
              )}
            </Fragment>
          )}
          <GridItem colSpan={[12, 12, 8]}>
            <Skeleton isLoaded={!loadingSingle}>
              <Paper>
                <VStack spacing="6">
                  <InputField
                    name="title"
                    label="Title"
                    control={control}
                    isRequired
                  />
                  <HStack w="full" spacing="6">
                    <AutocompleteTypes
                      name="type"
                      label="Type"
                      control={control}
                      whoCanAccess={me?.role}
                      isRequired
                    />
                    <NumberField
                      name="numberOfPeople"
                      label="Number Of People"
                      control={control}
                      isRequired
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <NumberField
                      name="price"
                      label="Price"
                      masking={format}
                      control={control}
                      isRequired
                    />
                    <SelectField
                      name="payment"
                      label="Payment"
                      placeholder="Select Payment"
                      control={control}
                      options={paymentOptions}
                      allowedUnregistered
                      isRequired
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <NumberField
                      name="area"
                      label="Area"
                      masking={formatArea}
                      control={control}
                      isRequired
                    />
                    <AutocompleteCountries
                      name="country"
                      control={control}
                      callback={handleCallbackCountries}
                      isRequired
                    />
                  </HStack>
                  <HStack w="full" spacing="6">
                    <AutocompleteRegions
                      name="region"
                      basedOn="country"
                      control={control}
                      callback={handleCallbackRegion}
                      isRequired
                    />
                    <AutocompleteCities
                      name="city"
                      basedOn="region"
                      control={control}
                      isRequired
                    />
                  </HStack>

                  <TextareaField
                    name="description"
                    label="Description"
                    control={control}
                    isRequired
                  />

                  <ImageUpload
                    name="featureImage"
                    label="Feature Image"
                    control={control}
                    isLoading={loadingUpload}
                    callback={handleUpload}
                    isRequired
                  />

                  <MultipleImageUpload
                    name="propertyGallery"
                    label="Property Gallery"
                    control={control}
                    callback={handleUploadGallery}
                    isLoading={loadingUploadGallery}
                    isRequired
                  />

                  <HStack w="full" spacing="6">
                    <AutocompleteFeatures
                      name="features"
                      isMulti
                      control={control}
                      isRequired
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <AutocompleteTags
                      name="tags"
                      isMulti
                      control={control}
                      isRequired
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <SelectField
                      name="parking"
                      label="Parking"
                      placeholder="Select Parking"
                      control={control}
                      options={parkingOptions}
                      isRequired
                    />
                    <NumberField
                      name="numberOfConferenceRooms"
                      label="Capacity of Conference Rooms"
                      control={control}
                      isRequired
                    />
                  </HStack>
                  <HStack w="full" spacing="6">
                    <PinMapField
                      name="pinMapLocation"
                      label="Map Location"
                      control={control}
                      helperText="*click on the map to ADD or CHANGE marker location"
                      isRequired
                    />
                  </HStack>
                </VStack>
              </Paper>
            </Skeleton>
          </GridItem>

          <GridItem colSpan={[12, 12, 4]}>
            <Skeleton isLoaded={!loadingSingle}>
              <Paper mb="6">
                <VStack spacing="6" align="start">
                  <SelectField
                    name="status"
                    label="Status"
                    placeholder="Select Status"
                    control={control}
                    options={statusOptionsByRole(me?.role)}
                    isDisabled={
                      property?.status === "new" ||
                      property?.status === "rejected" ||
                      property?.status === "delegated" ||
                      (me?.role === ROLE.PROPERTY_AGENT &&
                        property?.status === "draft") ||
                      (me?.role === ROLE.PROPERTY_AGENT &&
                        property?.status === "delegated") ||
                      (me?.role === ROLE.SERVICE_OFFICE_PROVIDER &&
                        property?.status === "draft") ||
                      (me?.role === ROLE.SERVICE_OFFICE_PROVIDER &&
                        property?.status === "delegated")
                    }
                    allowedUnregistered
                    isRequired
                  />

                  {property?.status === "rejected" && (
                    <TextareaField
                      isReadOnly
                      name="rejectedReasons"
                      label="Rejected Reasons"
                      control={control}
                    />
                  )}

                  <LinkField
                    name="addedBy"
                    label="Added By"
                    control={control}
                    baseURL="/v/agent"
                  />

                  {/*Feedback from user: Delete Responsible in property*/}
                  {/*{property?.status !== "new" && (*/}
                  {/*  <AutocompleteAgentsByRole*/}
                  {/*    name="responsible"*/}
                  {/*    label="Responsible"*/}
                  {/*    placeholder="Select Responsible"*/}
                  {/*    control={control}*/}
                  {/*    role="admin,website_agent"*/}
                  {/*    isDisabled={*/}
                  {/*      property?.status === "new" ||*/}
                  {/*      property?.status === "delegated" ||*/}
                  {/*      me?.role !== ROLE.ADMIN*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*)}*/}
                </VStack>
              </Paper>
            </Skeleton>

            <Skeleton isLoaded={!loadingSingle}>
              <Paper>
                <VStack spacing="2" align="start">
                  <Text fontWeight="bold">Danger zone</Text>
                  <Text pb="4">
                    Once you delete this <strong>property</strong>, there is no
                    going back. Please be certain.
                  </Text>
                  <ConfirmDelete
                    headerText="Delete Property"
                    bodyText="Are you sure you want delete this property?"
                    buttonText="Remove this property"
                    eventClick={handleDelete}
                    isLoading={loadingDelete}
                  />
                </VStack>
              </Paper>
            </Skeleton>
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};

export default PropertyDetailsPage;
