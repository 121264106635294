import ScrollToTop from "components/ScrollToTop";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import ROLE from "../src/config/role";
import Page404 from "../src/pages/page/404";

import routes, {
  routesAdmin,
  routesPropertyAgent,
  routesWebsiteAgent,
} from "./config/routes";

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

function App() {
  const { me } = useSelector((state) => state?.auth);
  const role = me?.role;
  const location = useLocation();

  return (
    <Fragment>
      <ScrollToTop />
      <Switch location={location} key={location.pathname}>
        {routes.map((route) => (
          <RouteWithSubRoutes key={route.path} {...route} />
        ))}

        {role === ROLE.ADMIN &&
          routesAdmin.map((route) => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}

        {role === ROLE.WEBSITE_AGENT &&
          routesWebsiteAgent.map((route) => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}

        {[
          ROLE.PROPERTY_AGENT,
          ROLE.SERVICE_OFFICE_PROVIDER,
          ROLE.PROPERTY_OWNER,
          ROLE.PROPERTY_BROKER,
          ROLE.PERSONAL,
        ].includes(role) &&
          routesPropertyAgent.map((route) => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}

        {/* RENDER 404 PAGE */}
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>
    </Fragment>
  );
}

export default App;
