import React from "react";
import PropTypes from "prop-types";

const PriceCell = ({ value }) => {
  return `${value} SAR`;
};

PriceCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PriceCell;
