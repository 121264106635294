import React from 'react';
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from 'components/layout';
import { TextHeader } from 'components/layout/header';
import TourRequestsTable from './components/TourRequestsTable';

const TourRequestPagePage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Tour Request" />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <TourRequestsTable />
      </LayoutBody>
    </Layout>
  );
};

export default TourRequestPagePage;
