import ROLE from "./role";

import {
  accountRoutes,
  agentRoutes,
  agentViewRoutes,
  authRoutes,
  clientRoutes,
  complaintRoutes,
  contractRoutes,
  globalRoutes,
  inquireRoutes,
  locationRoutes,
  manageContentRoutes,
  propertyRoutes,
  specialRequestRoutes,
  tourRequestRoutes,
} from "./routeModule";

const generateRoutesByRole = (role) => {
  switch (role) {
    case ROLE.ADMIN:
      return [
        ...accountRoutes,
        ...agentRoutes,
        ...authRoutes,
        ...clientRoutes,
        ...contractRoutes,
        ...inquireRoutes,
        ...locationRoutes,
        ...propertyRoutes,
        ...specialRequestRoutes,
        ...tourRequestRoutes,
        ...complaintRoutes,
        ...manageContentRoutes,
        ...globalRoutes,
      ];
    case ROLE.WEBSITE_AGENT:
      return [
        ...accountRoutes,
        ...agentViewRoutes,
        ...propertyRoutes,
        ...inquireRoutes,
        ...tourRequestRoutes,
        ...specialRequestRoutes,
        ...contractRoutes,
        ...complaintRoutes,
        ...authRoutes,
        ...globalRoutes,
      ];
    case ROLE.PROPERTY_AGENT:
      return [
        ...accountRoutes,
        ...agentViewRoutes,
        ...propertyRoutes,
        ...inquireRoutes,
        ...tourRequestRoutes,
        ...contractRoutes,
        ...complaintRoutes,
        ...authRoutes,
        ...globalRoutes,
      ];
    default:
      return [...clientRoutes, ...authRoutes];
  }
};

const routes = generateRoutesByRole(ROLE.CLIENT);
const routesAdmin = generateRoutesByRole(ROLE.ADMIN);
const routesPropertyAgent = generateRoutesByRole(ROLE.PROPERTY_AGENT);
const routesWebsiteAgent = generateRoutesByRole(ROLE.WEBSITE_AGENT);

export { routesAdmin, routesPropertyAgent, routesWebsiteAgent };

export default routes;
