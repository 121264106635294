import React, { Fragment, useMemo, memo } from "react";
import { Box, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import { useGetRegionsQuery } from "../../../../services/location/regions.services";
import CardCoverSkeleton from "../../../../components/client/Skeleton/CardCover.skeleton";
import CardCover from "../../../../components/overlay/CardCover";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const Regions = () => {
  const { t, i18n } = useTranslation();
  const Arabic = i18n.language === "ar";
  const history = useHistory();
  const location = useLocation();

  const { data: regions, isLoading } = useGetRegionsQuery();
  const query = queryString.parse(location.search);

  const { PUBLIC_URL } = process.env;
  const REGIONS_PATH = PUBLIC_URL + "/images/regions";

  const imageCover = useMemo(() => {
    return {
      "western regions": REGIONS_PATH + "/west.webp",
      "southern regions": REGIONS_PATH + "/south.webp",
      "central regions": REGIONS_PATH + "/central.webp",
      "northern regions": REGIONS_PATH + "/north.webp",
      "eastern regions": REGIONS_PATH + "/eastern.webp",
    };
  }, [REGIONS_PATH]);

  const handleFilterRegion = (value) => {
    const params = {
      ...query,
      region: value,
    };

    history.push({
      pathname: "/c/properties",
      search: queryString.stringify(params),
    });
  };

  if (isLoading) {
    return (
      <>
        <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={6} w="full" mb="6">
          <CardCoverSkeleton total={3} />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} w="full">
          <CardCoverSkeleton total={2} />
        </SimpleGrid>
      </>
    );
  }

  if (!isLoading && regions && regions.data.length === 0) {
    return (
      <Box>
        <Text fontSize="xl" color="gray.400">
          {t("common:label.we_cant_find_data")}
        </Text>
      </Box>
    );
  }

  return (
    <>
      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={6} w="full" mb="6">
        {regions && regions.data.length !== 0 && (
          <Fragment>
            {regions &&
              regions.data.slice(0, 3).map((item, index) => (
                <GridItem key={index} cursor="pointer">
                  <CardCover
                    imgCover={`${imageCover[item.name]}`}
                    text={Arabic ? item.ar_name : item.name}
                    onClick={() => handleFilterRegion(item.name)}
                  />
                </GridItem>
              ))}
          </Fragment>
        )}
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6} w="full">
        {regions && regions.data.length !== 0 && (
          <Fragment>
            {regions &&
              regions.data.slice(3, 5).map((item, index) => (
                <GridItem key={index} cursor="pointer">
                  <CardCover
                    imgCover={`${imageCover[item.name]}`}
                    text={Arabic ? item.ar_name : item.name}
                    onClick={() => handleFilterRegion(item.name)}
                  />
                </GridItem>
              ))}
          </Fragment>
        )}
      </SimpleGrid>
    </>
  );
};

export default memo(Regions);
