import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import { BsSearch } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const HeaderSearch = ({
  isMobile,
  searchVisible,
  toggleVisible = () => {},
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      search: query?.search || '',
    },
  });

  useEffect(() => {
    if (!query.search) {
      setValue('search', '');
    }
  }, [query, setValue]);

  const onSubmit = (value) => {
    const params = {
      ...query,
      ...value,
    };
    history.push({
      pathname: '/c/properties',
      search: queryString.stringify(params),
    });
  };

  return (
    <Box
      display={{
        base: searchVisible ? 'block' : 'none',
        lg: 'block',
      }}
      w={isMobile ? 'full' : 'auto'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <HStack spacing={isMobile ? 6 : 2}>
          <InputGroup
            width={{
              lg: '250px',
              xl: '350px',
              '2xl': '400px',
            }}
          >
            <InputLeftElement pointerEvents="none">
              <Icon as={BsSearch} color="gray.300" />
            </InputLeftElement>
            <Input
              type="search"
              {...register('search')}
              placeholder={t('common:placeholder.type_workspace_name')}
              bg="brand.cultured"
              borderWidth="2px"
              borderColor="brand.platinum"
            />
          </InputGroup>

          {!isMobile && (
            <Button
              type="submit"
              colorScheme="primary"
              color="white"
              size="md"
              px={{
                base: 4,
                xl: 8,
              }}
            >
              {t('common:button.search')}
            </Button>
          )}
          {isMobile && (
            <Button
              variant="link"
              type="reset"
              color="white"
              px="8"
              onClick={toggleVisible}
            >
              {t('common:button.cancel')}
            </Button>
          )}
        </HStack>
      </form>
    </Box>
  );
};

HeaderSearch.propTypes = {
  isMobile: PropTypes.bool,
  searchVisible: PropTypes.bool,
  toggleVisible: PropTypes.func,
};

export default memo(HeaderSearch);
