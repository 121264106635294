import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { AutoComplete } from "../field";
import { useLazyAutocompleteCitiesQuery } from "services/location/cities.services";
import { useWatch } from "react-hook-form";

const AutocompleteCities = ({
  control,
  name = "city",
  label = "City",
  basedOn = "",
  setValue = () => {},
  ...props
}) => {
  const [getAutocompleteCities, { data: cities, isLoading: isLoadingCities }] =
    useLazyAutocompleteCitiesQuery();

  const watchBased = useWatch({
    control,
    name: basedOn || "",
    defaultValue: null,
  });

  useEffect(() => {
    // Reset when based changed
    setValue(name, null);
  }, [name, setValue, watchBased]);

  const handleAutocomplete = (inputValue) => {
    getAutocompleteCities({
      search: inputValue,
      based: watchBased?.value || basedOn,
      pageSize: 50,
    });
  };

  return (
    <AutoComplete
      name={name}
      label={label}
      control={control}
      // loadOptions={loadOptions}
      options={cities ? cities.data : []}
      eventInputChange={handleAutocomplete}
      isLoading={isLoadingCities}
      {...props}
    />
  );
};
AutocompleteCities.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  basedOn: PropTypes.string,
  setValue: PropTypes.func,
};

export default AutocompleteCities;
