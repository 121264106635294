import { Text, Alert, AlertIcon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { LayoutHeader, LayoutHeaderLeft } from 'components/layout/client';
import { TextHeader } from 'components/layout/header';
import Layout, { LayoutBody } from '../../components/layout';

const DashboardPage = () => {
  const { i18n } = useTranslation();
  const dir = i18n.dir();

  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Dashboard" />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        {dir === 'rtl' && (
          <Alert status="warning">
            <AlertIcon />
            Admin dashboard is not available in RTL mode.
          </Alert>
        )}
        <Text fontSize="xl" color="gray.700" mt={dir === 'rtl' ? '6' : '0'}>
          Welcome, please select menu in left side.
        </Text>
      </LayoutBody>
    </Layout>
  );
};

export default DashboardPage;
