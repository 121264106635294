import { axiosInstance } from "../fetchApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchError } from "../../utils/catchError";

const endpoint = "/properties/public";
const endpointElastic = "/properties/elastic";

export const fetchElasticProperties = createAsyncThunk(
  `${endpointElastic}/fetchElastic`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(endpointElastic, {
        params: {
          search: payload?.search || "",
          ...payload,
        },
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const fetchClientProperties = createAsyncThunk(
  `${endpoint}/clientFetchAll`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(endpoint, {
        params: {
          offset: payload?.offset || 0,
          search: payload?.search || "",
          limit: 6,
          ...payload,
        },
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);

export const fetchClientPropertyById = createAsyncThunk(
  `${endpoint}/clientFetchById`,
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  },
);
