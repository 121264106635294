import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Stack,
  Link
} from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import { useController } from "react-hook-form";

const FileUpload = ({
  name,
  acceptedFileTypes = [
    "text/plain",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ],
  control,
  label,
  callback = () => {},
  isDisabled = false,
  isReadOnly = false,
  isRequired = false
}) => {
  const inputRef = useRef();
  //
  const [uploadData, setUploadData] = useState({
    documentURL: "",
    documentFileName: ""
  });

  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error }
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: { required: isRequired }
  });

  useEffect(() => {
    setUploadData(value);
  }, [value]);

  const handleChange = e => {
    if (e.target.files.length >= 1) {
      callback(e.target.files[0], uploadedData => {
        onChange(uploadedData);
        setUploadData(uploadedData);
      });
    } else {
      onChange("");
    }
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <input
        type="file"
        accept={acceptedFileTypes}
        name={name}
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleChange}
        {...inputProps}
      />
      <Stack direction={["row", "column"]} spacing="2">
        <Stack direction={["column", "row"]}>
          <Button
            leftIcon={<FiFile />}
            onClick={() => inputRef.current.click()}
            isDisabled={isDisabled || isReadOnly}
          >
            Upload
          </Button>
        </Stack>
        {(isDirty || value?.documentURL) && (
          <Link color="primary.500" href={uploadData.documentURL} isExternal>
            {uploadData.documentFileName}
          </Link>
        )}
      </Stack>
      {!isDirty && (
        <FormHelperText>File support pdf, doc, docx, txt</FormHelperText>
      )}
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

FileUpload.propTypes = {
  name: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  callback: PropTypes.func
};

export default FileUpload;
