import { axiosInstance } from "./fetchApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchError } from "../utils/catchError";

const endpoint = "/contracts";

export const fetchAutocompleteContracts = createAsyncThunk(
  `${endpoint}/autocomplete`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(endpoint, {
        params: {
          offset: payload?.offset || 0,
          search: payload?.search || "",
          limit: 6
        }
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const fetchContracts = createAsyncThunk(
  `${endpoint}/fetchAll`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(endpoint, {
        params: {
          offset: payload?.offset || 0,
          limit: 6,
          ...payload
        }
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const fetchContractById = createAsyncThunk(
  `${endpoint}/fetchById`,
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const createContract = createAsyncThunk(
  `${endpoint}/create`,
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(endpoint, payload);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const updateContract = createAsyncThunk(
  `${endpoint}/update`,
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`${endpoint}/${id}`, {
        ...payload
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteContract = createAsyncThunk(
  `${endpoint}/delete`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const uploadContract = createAsyncThunk(
  `${endpoint}/upload`,
  async (payload, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("document", payload?.data);
      const response = await axiosInstance.post(`${endpoint}/upload`, formData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
