export const catchError = (e) => {
  let message = "Unexpected error";

  // catch error from axios
  if (e.isAxiosError) {
    message = e.response.data.message;
  }

  // catch error apart from axios
  if (e.data) {
    message = e.data.message;
  }

  return message;
};
