import { ModalBylaw } from "components/modal/ModalBylaw";
import ConfirmContextProvider from "context/ConfirmContextProvider";
import AddNew from "./AddNew";

export default function AdvertisementNumber() {
  return (
    <ConfirmContextProvider>
      <AddNew />
      <ModalBylaw />
    </ConfirmContextProvider>
  );
}
