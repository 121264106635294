import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { TextareaField } from "components/field";
import { useRespondComplaintMutation } from "services/complaint.services";
import { useSendMailMutation } from "services/mail.services";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schemaRespond = yup.object().shape({
  complaintRespond: yup
    .string()
    .required("Message can't be blank.")
    .default(""),
});

const ModalRespondComplaint = ({ complaint }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { control, handleSubmit, reset, clearErrors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schemaRespond),
    defaultValues: { complaintRespond: "" },
  });

  const [respondComplaint, { isLoading }] = useRespondComplaintMutation();

  const [sendMail, { isLoading: isLoadingMail }] = useSendMailMutation();

  const onSubmit = async ({ complaintRespond }) => {
    const payload = {
      id: complaint.id,
      complaintRespond: complaintRespond,
    };

    try {
      const waitingSendMail = new Promise(async (resolve, reject) => {
        try {
          await sendMail({
            email: complaint.clientEmail,
            complaintID: complaint.id,
            displayName: complaint.clientName,
            complaintRespond: complaintRespond,
            template: "complaint/customer/new-complaint",
          }).unwrap();

          await respondComplaint({ ...payload }).unwrap();

          resolve(200);

          onClose();
          reset();
        } catch (error) {
          reject(error);
        }
      });

      toast.promise(waitingSendMail, {
        success: {
          position: "top-right",
          title: "Send mail success",
          description: "we have send mail to complainee",
        },
        error: {
          position: "top-right",
          title: "Send mail failed",
          description:
            "We have some problem when sending mail, please try again",
        },
        loading: {
          position: "top-right",
          title: "Sending mail",
          description: "Please wait",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
    clearErrors();
    reset();
  };

  return (
    <>
      <Button size="sm" colorScheme="gray" onClick={onOpen}>
        Respond
      </Button>

      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        size="xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg">Complaint Respond</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TextareaField
              name="complaintRespond"
              label="Explaining the problem"
              control={control}
              isRequired
            />
            <Text fontSize="sm" color="gray" mt="1">
              This message will be sent via email to the complainee.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" size="sm" mr={3} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              size="sm"
              isLoading={isLoading || isLoadingMail}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalRespondComplaint;
