import { Grid, GridItem, HStack, VStack } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  ImageUpload,
  InputField,
  LinkField,
  MultipleImageUpload,
  NumberField,
  PinMapField,
  SelectField,
  TextareaField,
} from "../../../components/field";
import Paper from "../../../components/paper";
import {
  AutocompleteAgentsByRole,
  AutocompleteCities,
  AutocompleteCountries,
  AutocompleteFeatures,
  AutocompleteRegions,
  AutocompleteTags,
  AutocompleteTypes,
} from "../../../components/shared";
import ROLE from "../../../config/role";
import { upload, uploadGallery } from "../../../services/properties.service";
import {
  parkingOptions,
  paymentOptions,
  statusOptionsByRole,
} from "../constant";

const PropertyForm = ({ control, setValue = () => {}, prepareID }) => {
  const dispatch = useDispatch();

  const formatSAR = (val) => val + ` SAR`;

  const formatArea = (val) => val + ` Sq m`;

  const { loadingUpload, loadingUploadGallery } = useSelector(
    (state) => state?.properties,
  );
  const { me } = useSelector((state) => state?.auth);

  const handleUpload = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      propertyId: prepareID,
      data: file,
    };

    const res = await dispatch(upload(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData(res.payload.imageURL);

      // save large version
      setValue("featureImageLarge", res.payload?.largeImageURL);

      // save thumbnail version
      setValue("featureImageThumb", res.payload.thumbnailImageURL);
    }
  };

  const handleUploadGallery = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      propertyId: prepareID,
      data: file,
    };

    const res = await dispatch(uploadGallery(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData(res.payload.data);
    }
  };

  const handleCallbackCountries = (value) => {
    setValue("region", null);
    setValue("city", null);
  };

  const handleCallbackRegion = (value) => {
    setValue("city", null);
  };

  return (
    <Grid
      templateColumns={{ base: "repeat(3, 1fr)", lg: "repeat(6, 1fr)" }}
      gap={6}
    >
      <GridItem colSpan="4">
        <Paper>
          <VStack spacing="6">
            <InputField
              name="title"
              label="Title"
              control={control}
              isRequired
            />
            <HStack w="full" spacing="6">
              <AutocompleteTypes
                name="type"
                label="Type"
                control={control}
                whoCanAccess={me?.role}
                isRequired
              />
              <NumberField
                name="numberOfPeople"
                label="Number Of People"
                control={control}
                isRequired
              />
            </HStack>
            <HStack w="full" spacing="6">
              <NumberField
                name="price"
                label="Price"
                masking={formatSAR}
                control={control}
                isRequired
              />
              <SelectField
                name="payment"
                label="Payment"
                placeholder="Select Payment"
                control={control}
                options={paymentOptions}
                isRequired
              />
            </HStack>
            <HStack w="full" spacing="6">
              <NumberField
                name="area"
                label="Area"
                masking={formatArea}
                control={control}
                isRequired
              />
              <AutocompleteCountries
                name="country"
                control={control}
                callback={handleCallbackCountries}
                isRequired
              />
            </HStack>
            <HStack w="full" spacing="6">
              <AutocompleteRegions
                name="region"
                basedOn="country"
                control={control}
                callback={handleCallbackRegion}
                isRequired
              />
              <AutocompleteCities
                name="city"
                basedOn="region"
                control={control}
                isRequired
              />
            </HStack>
            <TextareaField
              name="description"
              label="Description"
              control={control}
              isRequired
            />
            <ImageUpload
              name="featureImage"
              label="Feature Image"
              isLoading={loadingUpload}
              control={control}
              callback={handleUpload}
              isRequired
            />
            <MultipleImageUpload
              name="propertyGallery"
              label="Property Gallery"
              control={control}
              callback={handleUploadGallery}
              isLoading={loadingUploadGallery}
              isRequired
            />
            <HStack w="full" spacing="6">
              <AutocompleteFeatures
                name="features"
                isMulti
                control={control}
                isRequired
              />
            </HStack>

            <HStack w="full" spacing="6">
              <AutocompleteTags
                name="tags"
                isMulti
                control={control}
                isRequired
              />
            </HStack>

            <HStack w="full" spacing="6">
              <SelectField
                name="parking"
                label="Parking"
                placeholder="Select Parking"
                control={control}
                options={parkingOptions}
                isRequired
              />
              <NumberField
                name="numberOfConferenceRooms"
                label="Capacity of Conference Rooms"
                control={control}
                isRequired
              />
            </HStack>
            <HStack w="full" spacing="6">
              <PinMapField
                name="pinMapLocation"
                label="Map Location"
                control={control}
                helperText="*click on the map to ADD or CHANGE marker location"
                isRequired
              />
            </HStack>
          </VStack>
        </Paper>
      </GridItem>
      <GridItem colSpan={{ base: 4, lg: 2 }}>
        <Paper>
          <VStack spacing="6">
            <SelectField
              name="status"
              label="Status"
              placeholder="Select Status"
              control={control}
              options={statusOptionsByRole(me?.role)}
              isDisabled={me?.role === ROLE.PROPERTY_AGENT}
              isRequired
            />

            <LinkField
              name="addedBy"
              label="Added By"
              control={control}
              baseURL="/v/agent"
            />

            {/* Feedback from user: Delete Responsible in property */}
            {me?.role === ROLE.ADMIN && (
              <AutocompleteAgentsByRole
                name="responsible"
                label="Responsible"
                placeholder="Select Responsible"
                control={control}
                role="admin,website_agent"
                isRequired
              />
            )}
          </VStack>
        </Paper>
      </GridItem>
    </Grid>
  );
};

PropertyForm.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
  prepareID: PropTypes.string,
};
export default PropertyForm;
