import { api } from "./api";

const agentApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateLanguage: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/agents/update-language`,
        method: "PATCH",
        data: patch,
      }),
    }),
  }),
});

export const { useUpdateLanguageMutation } = agentApi;
