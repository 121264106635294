import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { memo } from "react";
import { useController } from "react-hook-form";
import { FIELD_VARIANT } from "../../config/preset";

const InputField = ({
  name,
  type = "text",
  placeholder,
  control,
  trigger = async () => {},
  label,
  helperText = "",
  isRequired = false,
  isRTL = false,
  ...props
}) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: { required: isRequired },
  });

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        ref={ref}
        name={name}
        id={name}
        type={type}
        value={value}
        autoComplete="off"
        placeholder={placeholder}
        dir={isRTL ? "rtl" : undefined}
        variant={FIELD_VARIANT.default}
        {...inputProps}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

InputField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isRTL: PropTypes.bool,
  type: PropTypes.string,
};

export default memo(InputField);
