import React from 'react';
import Layout, { LayoutBody } from '../../../../components/layout/client';
import { Box, Container, ListItem, OrderedList, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TermsPage = () => {
  const { t } = useTranslation('terms');

  return (
    <Layout>
      <LayoutBody>
        <Container maxW={'container.xl'}>
          <Box>
            <Text fontSize="3xl" fontWeight="bold" mb="8">
              {t('terms:title')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:agreement_to_terms.title')}
            </Text>
            <Text>
              {t('terms:agreement_to_terms.paragraph_1')}
              <br />
              <br />
              {t('terms:agreement_to_terms.paragraph_2')}
              <br />
              <br />
              {t('terms:agreement_to_terms.paragraph_3')}
              <br />
              <br />
              {t('terms:agreement_to_terms.paragraph_4')}
              <br />
              <br />
              {t('terms:agreement_to_terms.paragraph_5')}
              <br />
              <br />
              {t('terms:agreement_to_terms.paragraph_6')}
              <br />
              <br />
              {t('terms:agreement_to_terms.paragraph_7')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:intellectual_property_rights.title')}
            </Text>
            <Text>
              {t('terms:intellectual_property_rights.paragraph_1')}
              <br />
              <br />
              {t('terms:intellectual_property_rights.paragraph_2')}
              <br />
              <br />
              {t('terms:intellectual_property_rights.paragraph_3')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:user_representations.title')}
            </Text>

            <Text mb="4">{t('terms:user_representations.paragraph_1')}</Text>

            <OrderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t('terms:user_representations.ordered_list.1')}
              </ListItem>
              <ListItem>
                {t('terms:user_representations.ordered_list.2')}
              </ListItem>
              <ListItem>
                {t('terms:user_representations.ordered_list.3')}
              </ListItem>
              <ListItem>
                {t('terms:user_representations.ordered_list.4')}
              </ListItem>
              <ListItem>
                {t('terms:user_representations.ordered_list.5')}
              </ListItem>
              <ListItem>
                {t('terms:user_representations.ordered_list.6')}
              </ListItem>
              <ListItem>
                {t('terms:user_representations.ordered_list.7')}
              </ListItem>
              <ListItem>
                {t('terms:user_representations.ordered_list.8')}
              </ListItem>
            </OrderedList>

            <Text>{t('terms:user_representations.paragraph_2')}</Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:user_registration.title')}
            </Text>
            <Text>{t('terms:user_registration.paragraph_1')}</Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:prohibited_activities.title')}
            </Text>
            <Text mb="4">
              {t('terms:prohibited_activities.paragraph_1')}
              <br />
              <br />
              {t('terms:prohibited_activities.paragraph_2')}
            </Text>
            <OrderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.1')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.2')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.3')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.4')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.5')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.6')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.7')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.8')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.9')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.10')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.11')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.12')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.13')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.14')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.15')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.16')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.17')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.18')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.19')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.20')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.21')}
              </ListItem>
              <ListItem>
                {t('terms:prohibited_activities.ordered_list.22')}
              </ListItem>
            </OrderedList>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:user_generated_contributions.title')}
            </Text>
            <Text mb="4">
              {t('terms:user_generated_contributions.paragraph_1')}
              <br />
              <br />
              {t('terms:user_generated_contributions.paragraph_2')}
            </Text>
            <OrderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.1')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.2')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.3')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.4')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.5')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.6')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.7')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.8')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.9')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.10')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.11')}
              </ListItem>
              <ListItem>
                {t('terms:user_generated_contributions.ordered_list.12')}
              </ListItem>
            </OrderedList>

            <Text>{t('terms:user_generated_contributions.paragraph_3')}</Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:contribution_license.title')}
            </Text>
            <Text mb="4">
              {t('terms:contribution_license.paragraph_1')}
              <br />
              <br />
              {t('terms:contribution_license.paragraph_2')}
              <br />
              <br />
              {t('terms:contribution_license.paragraph_3')}
              <br />
              <br />
              {t('terms:contribution_license.paragraph_4')}
              <br />
              <br />
              {t('terms:contribution_license.paragraph_5')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:guidelines_for_reviews.title')}
            </Text>
            <Text mb="4">{t('terms:guidelines_for_reviews.paragraph_1')}</Text>
            <OrderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t('terms:guidelines_for_reviews.ordered_list.1')}
              </ListItem>
              <ListItem>
                {t('terms:guidelines_for_reviews.ordered_list.2')}
              </ListItem>
              <ListItem>
                {t('terms:guidelines_for_reviews.ordered_list.3')}
              </ListItem>
              <ListItem>
                {t('terms:guidelines_for_reviews.ordered_list.4')}
              </ListItem>
              <ListItem>
                {t('terms:guidelines_for_reviews.ordered_list.5')}
              </ListItem>
              <ListItem>
                {t('terms:guidelines_for_reviews.ordered_list.6')}
              </ListItem>
              <ListItem>
                {t('terms:guidelines_for_reviews.ordered_list.7')}
              </ListItem>
              <ListItem>
                {t('terms:guidelines_for_reviews.ordered_list.8')}
              </ListItem>
            </OrderedList>
            <Text>
              {t('terms:guidelines_for_reviews.paragraph_2')}
              <br />
              <br />
              {t('terms:guidelines_for_reviews.paragraph_3')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="4" fontSize="18">
              {t('terms:mobile_application_license.title')}
            </Text>
            <Text mb="2" fontWeight="bold">
              {t('terms:mobile_application_license.use_license.title')}
            </Text>
            <Text mb="4">
              {t('terms:mobile_application_license.use_license.paragraph_1')}
              <br />
              <br />
              {t('terms:mobile_application_license.use_license.paragraph_2')}
            </Text>
            <OrderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.1'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.2'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.3'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.4'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.5'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.6'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.7'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.8'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.use_license.ordered_list.9'
                )}
              </ListItem>
            </OrderedList>
            <Text mb="2" fontWeight="bold">
              {t(
                'terms:mobile_application_license.apple_and_android_devices.title'
              )}
            </Text>
            <Text mb="4">
              {t(
                'terms:mobile_application_license.apple_and_android_devices.paragraph_1'
              )}
            </Text>
            <OrderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t(
                  'terms:mobile_application_license.apple_and_android_devices.ordered_list.1'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.apple_and_android_devices.ordered_list.2'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.apple_and_android_devices.ordered_list.3'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.apple_and_android_devices.ordered_list.4'
                )}
              </ListItem>
              <ListItem>
                {t(
                  'terms:mobile_application_license.apple_and_android_devices.ordered_list.5'
                )}
              </ListItem>
            </OrderedList>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:social_media.title')}
            </Text>
            <Text mb="4">
              {t('terms:social_media.paragraph_1')}
              <br />
              <br />
              {t('terms:social_media.paragraph_2')}
              <br />
              <br />
              {t('terms:social_media.paragraph_3')}
              <br />
              <br />
              {t('terms:social_media.paragraph_4')}
              <br />
              <br />
              {t('terms:social_media.paragraph_5')}
              <br />
              <br />
              {t('terms:social_media.paragraph_6')}
              <br />
              <br />
              {t('terms:social_media.paragraph_7')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:submissions.title')}
            </Text>
            <Text mb="4">
              {t('terms:submissions.paragraph_1')}
              <br />
              <br />
              {t('terms:submissions.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:third_party_websites_and_content.title')}
            </Text>
            <Text mb="4">
              {t('terms:third_party_websites_and_content.paragraph_1')}
              <br />
              <br />
              {t('terms:third_party_websites_and_content.paragraph_2')}
              <br />
              <br />
              {t('terms:third_party_websites_and_content.paragraph_3')}
              <br />
              <br />
              {t('terms:third_party_websites_and_content.paragraph_4')}
              <br />
              <br />
              {t('terms:third_party_websites_and_content.paragraph_5')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:advertisers.title')}
            </Text>
            <Text mb="4">
              {t('terms:advertisers.paragraph_1')}
              <br />
              <br />
              {t('terms:advertisers.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:site_management.title')}
            </Text>
            <Text mb="4">{t('terms:site_management.paragraph_1')}</Text>
            <OrderedList mb="8" pl="8" spacing={3}>
              <ListItem>{t('terms:site_management.ordered_list.1')}</ListItem>
              <ListItem>{t('terms:site_management.ordered_list.2')}</ListItem>
              <ListItem>{t('terms:site_management.ordered_list.3')}</ListItem>
              <ListItem>{t('terms:site_management.ordered_list.4')}</ListItem>
              <ListItem>{t('terms:site_management.ordered_list.5')}</ListItem>
            </OrderedList>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:privacy_policy.title')}
            </Text>
            <Text mb="4">
              {t('terms:privacy_policy.paragraph_1')}
              <br />
              <br />
              {t('terms:privacy_policy.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:copyright_infringements.title')}
            </Text>
            <Text mb="4">
              {t('terms:copyright_infringements.paragraph_1')}
              <br />
              <br />
              {t('terms:copyright_infringements.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:term_and_termination.title')}
            </Text>
            <Text mb="4">
              {t('terms:term_and_termination.paragraph_1')}
              <br />
              <br />
              {t('terms:term_and_termination.paragraph_2')}
              <br />
              <br />
              {t('terms:term_and_termination.paragraph_3')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:modifications_and_interruptions.title')}
            </Text>
            <Text mb="4">
              {t('terms:modifications_and_interruptions.paragraph_1')}
              <br />
              <br />
              {t('terms:modifications_and_interruptions.paragraph_2')}
              <br />
              <br />
              {t('terms:modifications_and_interruptions.paragraph_3')}
              <br />
              <br />
              {t('terms:modifications_and_interruptions.paragraph_4')}
              <br />
              <br />
              {t('terms:modifications_and_interruptions.paragraph_5')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:governing_law.title')}
            </Text>
            <Text mb="4">{t('terms:governing_law.paragraph_1')}</Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="4" fontSize="18">
              {t('terms:dispute_resolution.title')}
            </Text>
            <Text mb="8">
              <b>{t('terms:dispute_resolution.option_1.title')}</b>
              {t('terms:dispute_resolution.option_1.paragraph_1')}
              <br />
              <br />
              {t('terms:dispute_resolution.option_1.paragraph_2')}
            </Text>
            <Text fontWeight="bold" mb="2">
              {t('terms:dispute_resolution.option_2.title')}
            </Text>
            <Text mb="8">
              {t('terms:dispute_resolution.option_2.paragraph_1')}
            </Text>
            <Text fontWeight="bold" mb="2">
              {t('terms:dispute_resolution.binding_arbitration.title')}
            </Text>
            <Text mb="4">
              {t('terms:dispute_resolution.binding_arbitration.paragraph_1')}
              <br />
              <br />
              {t('terms:dispute_resolution.binding_arbitration.paragraph_2')}
              <br />
              <br />
              {t('terms:dispute_resolution.binding_arbitration.paragraph_3')}
              <br />
              <br />
              {t('terms:dispute_resolution.binding_arbitration.paragraph_4')}
              <br />
              <br />
              {t('terms:dispute_resolution.binding_arbitration.paragraph_5')}
              <br />
              <br />
              {t('terms:dispute_resolution.binding_arbitration.paragraph_6')}
              <br />
              <br />
              {t('terms:dispute_resolution.binding_arbitration.paragraph_7')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:corrections.title')}
            </Text>
            <Text mb="4">{t('terms:corrections.paragraph_1')}</Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:disclaimer.title')}
            </Text>
            <Text mb="4">
              {t('terms:disclaimer.paragraph_1')}
              <br />
              <br />
              {t('terms:disclaimer.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:indemnification.title')}
            </Text>
            <Text mb="4">
              {t('terms:indemnification.paragraph_1')}
              <br />
              <br />
              {t('terms:indemnification.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:user_data.title')}
            </Text>
            <Text mb="4">
              {t('terms:user_data.paragraph_1')}
              <br />
              <br />
              {t('terms:user_data.paragraph_2')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:electronic_communications.title')}
            </Text>
            <Text mb="4">
              {t('terms:electronic_communications.paragraph_1')}
              <br />
              <br />
              {t('terms:electronic_communications.paragraph_2')}
              <br />
              <br />
              {t('terms:electronic_communications.paragraph_3')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('terms:miscellaneous.title')}
            </Text>
            <Text mb="4">
              {t('terms:miscellaneous.paragraph_1')}
              <br />
              <br />
              {t('terms:miscellaneous.paragraph_2')}
              <br />
              <br />
              {t('terms:miscellaneous.paragraph_3')}
              <br />
              <br />
              {t('terms:miscellaneous.paragraph_4')}
            </Text>
          </Box>
        </Container>
      </LayoutBody>
    </Layout>
  );
};

export default TermsPage;
