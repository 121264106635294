import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Container,
  Stack,
  useColorModeValue as mode,
  useDisclosure,
} from "@chakra-ui/react";
import Header from "components/header/client";
import Navbar from "components/navbar";
import Footer from "components/footer/client";
import NavigationFooterMobile from "../footer/NavigationFooterMobile";
import { RtlProvider } from "../RtlProvider";
import { useDeviceDetected } from "hooks";

const Layout = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isMobile } = useDeviceDetected();

  return (
    <RtlProvider>
      <Box bg={mode("white", "inherit")} minH="100vh" h="auto">
        <Header toggleMobileMenu={onOpen} />
        <Navbar isOpen={isOpen} handleClose={onClose} />
        {children}
      </Box>
      <Footer />
      {isMobile && <NavigationFooterMobile />}
    </RtlProvider>
  );
};

export const LayoutHeader = ({ children }) => (
  <Stack direction="row" justifyContent="space-between">
    {children}
  </Stack>
);

export const LayoutHeaderLeft = ({ children }) => (
  <Stack direction="column">{children}</Stack>
);

export const LayoutHeaderRight = ({ children }) => (
  <Stack direction="row" justifyContent="flex-end" flex="1 1 0%">
    {children}
  </Stack>
);

export const LayoutBody = ({ children, isCard }) => (
  <Box
    mt={{ base: 0, lg: 8 }}
    py={{ base: 4, md: 6, lg: 12 }}
    __css={
      isCard && { bg: "white", p: "6", borderRadius: "md", boxShadow: "md" }
    }
  >
    <Container maxW="container.xl">{children}</Container>
  </Box>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LayoutHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LayoutHeaderLeft.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LayoutHeaderRight.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LayoutBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isCard: PropTypes.bool,
};

export default Layout;
