import React from "react";
import PropTypes from "prop-types";
import { Button, VStack } from "@chakra-ui/react";
import { InputField } from "../../../../components/field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import messages from "../../../../config/messages";
import {
  createTag,
  fetchTag,
  updateTag
} from "../../../../services/tagsProperty.service";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const schema = yup.object().shape({
  name: yup.string().required(messages.required),
  ar_name: yup.string().required(messages.required)
});

const PropertyTagForm = ({ data, onClose }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.data.name || "",
      ar_name: data?.data.ar_name || ""
    }
  });

  const { loadingCreate, loadingUpdate } = useSelector(
    state => state?.tagsProperty
  );
  const dispatch = useDispatch();

  const onSubmit = async values => {
    if (data) {
      const payload = {
        ...values,
        id: data.data.id
      };

      unwrapResult(await dispatch(updateTag(payload)));
      onClose();
      dispatch(fetchTag());
    } else {
      delete values.data; // remove data from edit form, bacause we use same component
      const resultAction = await dispatch(createTag(values));
      const result = unwrapResult(resultAction); // after success will execute next code.
      reset(result);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing="6" alignItems="flex-start">
        <InputField name="name" label="Name" control={control} isRequired />
        <InputField
          isRTL
          name="ar_name"
          label="Arabic Name"
          control={control}
          isRequired
        />
        <Button
          type="submit"
          colorScheme="primary"
          isLoading={data ? loadingUpdate : loadingCreate}
        >
          {data ? "Update" : "Add New"}
        </Button>
      </VStack>
    </form>
  );
};

PropertyTagForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func
};

export default PropertyTagForm;
