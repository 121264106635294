import React, { useEffect } from 'react';
import Layout, {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutBody,
} from 'components/layout';
import { TextHeader } from 'components/layout/header';
import InquiriesTable from './components/InquiriesTable';

const InquiriesPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Inquiries" />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <InquiriesTable />
      </LayoutBody>
    </Layout>
  );
};

export default InquiriesPage;
