import * as yup from 'yup';

export const complaintDefaultValues = {
  id: '',
  complaint: '',
  complaintCode: '',
  complaintDetails: '',

  clientName: '',
  clientEmail: '',
  phoneNumber: '',

  complaintProperty: '',
};
