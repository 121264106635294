import React from "react";
import { Box, HStack } from "@chakra-ui/react";
import { TableFilters, TablePagination } from "components/table";
import { ConfirmDelete } from "components/alert";
import PopupEdit from "./PopupEdit";
import {
  useDeleteCountryMutation,
  useGetCountriesQuery,
} from "services/location/countries.services";
import { catchError } from "utils/catchError";
import { messageSuccessDelete } from "components/messages";

const sortOptions = [
  { label: "Country", value: "name" },
  { label: "Created", value: "createdAt", isDefault: true, direction: "desc" },
];

const CountriesTable = () => {
  const [queryValue, setQueryValue] = React.useState({
    nextCursor: null,
    search: "",
    sort: "",
  });

  const {
    data: countries,
    isLoading,
    isFetching,
    isError,
  } = useGetCountriesQuery({
    nextCursor: queryValue.nextCursor,
    search: queryValue.search,
    sort: queryValue.sort,
  });

  const [deleteCountry, { isLoading: isLoadingDelete }] =
    useDeleteCountryMutation();

  const handleNext = (cursor) => {
    setQueryValue({
      ...queryValue,
      nextCursor: cursor,
    });
  };

  const handleSearch = (value) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      search: value,
    });
  };

  const handleSorting = ({ field, direction }) => {
    setQueryValue({
      ...queryValue,
      nextCursor: null,
      sort: `${field} ${direction}`,
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteCountry(id).unwrap();
      messageSuccessDelete();
    } catch (error) {
      console.error(catchError(error));
    }
  };

  const RenderAction = ({ selectedItem }) => (
    <HStack justifyContent="flex-end">
      <PopupEdit data={selectedItem} />
      <ConfirmDelete
        iconOnly
        headerText={`Delete '${selectedItem.name}' Country`}
        bodyText="Are you sure? You can't undo this action afterwards."
        eventClick={() => handleDelete(selectedItem.id)}
        isLoading={isLoadingDelete}
      />
    </HStack>
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Country",
        accessor: "country",
      },
      {
        Header: "Arabic Name",
        accessor: "ar_name",
      },
      {
        Header: "Actions",
        accessor: "actions",
        isNumeric: true,
      },
    ],
    [],
  );

  const dataMemoized = React.useMemo(
    () =>
      countries?.data.map((item) => {
        return {
          country: item.name,
          ar_name: item.ar_name,
          actions: <RenderAction selectedItem={item} />,
        };
      }),
    [countries?.data],
  );

  return (
    <Box>
      <TableFilters
        sortOptions={sortOptions}
        searchPlaceholder="Search country..."
        handleSearch={handleSearch}
        handleSorting={handleSorting}
      />

      <Box>
        <TablePagination
          columns={columns}
          data={dataMemoized || []}
          isLoading={isLoading || isFetching}
          isError={isError}
          meta={countries?.meta}
          handleNextFn={handleNext}
        />
      </Box>
    </Box>
  );
};

export default CountriesTable;
