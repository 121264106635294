import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { HStack, IconButton } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ROLE from '../../../config/role';
import { StatusCell, AttentionCell, FreeTextCell } from 'components/cell';
import { useGetInquiriesQuery } from 'services/inquiries.services';
import { FaEye } from 'react-icons/fa6';
import { TableFilters, TablePagination } from 'components/table';
import PaperTable from 'components/paper-table';

const sortOptions = [
  { label: 'Created', value: 'createdAt', isDefault: true, direction: 'desc' },
];

const viewOptions = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
];

const InquiriesTable = () => {
  const history = useHistory();
  const { me } = useSelector((state) => state?.auth);
  const role = me?.role;

  const [queryValue, setQueryValue] = useState({
    nextCursor: null,
    search: '',
    sort: '',
    status: '',
  });

  // service
  const {
    data: inquiry,
    isLoading,
    isFetching,
    isError,
  } = useGetInquiriesQuery({
    nextCursor: queryValue.nextCursor,
    search: queryValue.search,
    sort: queryValue.sort,
    status: queryValue.status,
    pageSize: 20,
  });

  // table function operation
  const handleNext = useCallback((cursor) => {
    setQueryValue((prev) => ({
      ...prev,
      nextCursor: cursor,
    }));
  }, []);

  const handleSearch = useCallback((value) => {
    setQueryValue((prev) => ({
      ...prev,
      search: value,
      nextCursor: null,
    }));
  }, []);

  const handleSorting = useCallback(({ field, direction }) => {
    setQueryValue((prev) => ({
      ...prev,
      sort: `${field} ${direction}`,
      nextCursor: null,
    }));
  }, []);

  const handleView = useCallback((value) => {
    setQueryValue((prev) => ({
      ...prev,
      status: value,
      nextCursor: null,
    }));
  }, []);

  const RenderAction = ({ selectedItem }) => {
    return (
      <HStack justifyContent="flex-end">
        <IconButton
          onClick={() => history.push(`/inquire/${selectedItem.id}`)}
          colorScheme="primary"
          aria-label="Detail"
          size="sm"
          icon={<FaEye />}
        />
      </HStack>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Request Id',
        accessor: 'generatedID',
        Cell: role === ROLE.PROPERTY_AGENT ? FreeTextCell : AttentionCell,
      },
      { Header: 'Client Name', accessor: 'clientName' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Phone Number', accessor: 'phoneNumber' },
      { Header: 'Inquired Property', accessor: 'inquiredProperty' },
      { Header: 'Agent In Charge', accessor: 'agentInCharge' },
      { Header: 'Status', accessor: 'status', Cell: StatusCell },
      { Header: '', accessor: 'action', isNumeric: true },
    ],
    []
  );

  const dataMemoized = useMemo(
    () =>
      inquiry?.data.map((item) => {
        return {
          id: item.id,
          generatedID: item.generatedID,
          clientName: item.clientName,
          companyName: item.companyName,
          phoneNumber: item.phoneNumber,
          email: item.email,
          inquiredProperty: get(item.inquiredProperty, 'label', '-'),
          agentInCharge: get(item.agentInCharge, 'label', '-'),
          statusByAgent: item.statusByAgent,
          status: item.status,
          action: <RenderAction selectedItem={item} />,
        };
      }),
    [inquiry?.data]
  );

  return (
    <PaperTable>
      <TableFilters
        sortOptions={sortOptions}
        handleSorting={handleSorting}
        handleView={handleView}
        viewOptions={viewOptions}
        handleSearch={handleSearch}
        searchPlaceholder="Search request id..."
      />

      <TablePagination
        columns={columns}
        data={dataMemoized || []}
        isLoading={isLoading || isFetching}
        isError={isError}
        meta={inquiry?.meta}
        handleNextFn={handleNext}
      />
    </PaperTable>
  );
};

InquiriesTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  loadingDelete: PropTypes.bool,
  meta: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onPagination: PropTypes.func,
  role: PropTypes.string,
};

export default InquiriesTable;
