import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link as ReachLink } from "react-router-dom";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  Image,
  VStack,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const NoResult = () => {
  const { t } = useTranslation();

  return (
    <VStack py="8">
      <Image
        ignoreFallback
        src={process.env.PUBLIC_URL + "/illustration/empty-compare.svg"}
        boxSize="380"
      />
      <VStack spacing="6">
        <Text
          align="center"
          fontSize={{ base: "xl", md: "2xl" }}
          color="gray.600"
        >
          {t("common:label.please_select_property_first_to_do_compare")}
        </Text>

        <Button as={ReachLink} to="/c/properties" colorScheme="primary">
          {t("common:button.select_property")}
        </Button>
      </VStack>
    </VStack>
  );
};

const TableCompare = ({ header = [], features = [], data = [] }) => {
  const generateBody = (data) => {
    if (data.length <= 0) {
      return <NoResult />;
    } else {
      return (
        <Tbody borderColor="#e7e4e4">
          {features.map((feature, index) =>
            productComparisonFeature(header[index], feature, data),
          )}
        </Tbody>
      );
    }
  };

  const productComparisonFeature = (header, feature, data) => {
    if (data.find((item) => item[feature] !== "N/A")) {
      return (
        <Tr key={feature}>
          <Td
            position="sticky"
            left="-1px"
            zIndex="1"
            bg="brand.cultured"
            borderBottom="1px"
            borderRight="1px"
            borderRightColor="brand.platinum"
            borderBottomColor="brand.platinum"
            boxShadow="2px 2px 20px -20px red"
            minW="150px"
          >
            <Text fontSize="lg" fontWeight="semibold">
              {header}
            </Text>
          </Td>
          {data.map((product, index) =>
            getFeatureForProduct(product, feature, index),
          )}
        </Tr>
      );
    }
  };

  const getFeatureForProduct = (product, feature, index) => {
    return (
      <Td
        minW="360px"
        key={`${feature}-${index}`}
        borderBottom="1px"
        borderRight="1px"
        borderRightColor="brand.platinum"
        borderBottomColor="brand.platinum"
        style={{
          borderCollapse: "separate",
        }}
      >
        {product[feature]}
      </Td>
    );
  };

  return (
    <Fragment>
      <Box maxW="container.xl" overflow="auto" position="relative">
        <Table
          bg="brand.cultured"
          borderWidth="1px"
          borderColor="brand.platinum"
        >
          {generateBody(data)}
        </Table>
      </Box>
    </Fragment>
  );
};

TableCompare.propTypes = {
  features: PropTypes.array,
  data: PropTypes.array,
  header: PropTypes.array,
};

export default TableCompare;
