import React from "react";
import PropTypes from "prop-types";
import { Button, VStack } from "@chakra-ui/react";
import { InputField, IconUpload } from "../../../../components/field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import messages from "../../../../config/messages";
import {
  createFeature,
  updateFeature,
  fetchFeature,
  uploadIcon
} from "../../../../services/featuresProperty.service";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const schema = yup.object().shape({
  name: yup.string().required(messages.required),
  ar_name: yup.string().required(messages.required)
});

const PropertyFeatureForm = ({ data, onClose }) => {
  const dispatch = useDispatch();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.data.name || "",
      ar_name: data?.data.ar_name || "",
      icon: data?.data.icon || ""
    }
  });

  const { loadingCreate, loadingUpdate, loadingUpload } = useSelector(
    state => state?.featuresProperty
  );

  const handleUpload = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      data: file
    };
    const res = await dispatch(uploadIcon(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData(res.payload.imageURL);
    }
  };

  const onSubmit = async values => {
    if (data) {
      const payload = {
        ...values,
        id: data.data.id
      };

      unwrapResult(await dispatch(updateFeature(payload)));
      onClose();
      dispatch(fetchFeature());
    } else {
      delete values.data; // remove data from edit form, bacause we use same component
      const resultAction = await dispatch(createFeature(values));
      unwrapResult(resultAction); // after success will execute next code.
      onClose();
      reset({
        name: "",
        ar_name: "",
        icon: ""
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing="6" alignItems="flex-start">
        <InputField name="name" label="Name" control={control} isRequired />
        <InputField
          isRTL
          name="ar_name"
          label="Arabic Name"
          control={control}
          isRequired
        />
        <IconUpload
          name="icon"
          label="Icon"
          control={control}
          isLoading={loadingUpload}
          callback={handleUpload}
        />
        <Button
          type="submit"
          colorScheme="primary"
          isLoading={data ? loadingUpdate : loadingCreate}
        >
          {data ? "Update" : "Add New"}
        </Button>
      </VStack>
    </form>
  );
};

PropertyFeatureForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func
};

export default PropertyFeatureForm;
