import React, { memo } from 'react';
import { Text, SimpleGrid, GridItem } from '@chakra-ui/react';
import CardCover from 'components/overlay/CardCover';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PropertyTypes = () => {
  const { t } = useTranslation(['spaceType']);
  // const { t } = useTranslation(['translation', 'spaceType']);
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const { PUBLIC_URL } = process.env;
  const SPACES_PATH = PUBLIC_URL + 'images/spaces';

  const handleFilterType = (value) => {
    const params = {
      ...query,
      property_type: value,
    };

    history.push({
      pathname: '/c/properties',
      search: queryString.stringify(params),
    });
  };

  return (
    <>
      <Text fontSize="3xl" mb="8" fontWeight="semibold" align="center">
        {t('translation:looking_for_a_workspace')}
      </Text>

      <SimpleGrid columns={[1, 1, 1, 2, 3]} spacing={6} w="full">
        <GridItem cursor="pointer">
          <CardCover
            imgCover={`${SPACES_PATH + '/serviced_office.webp'}`}
            text={t('spaceType:serviced_offices')}
            onClick={() => handleFilterType('serviced offices')}
          />
        </GridItem>
        <GridItem cursor="pointer">
          <CardCover
            imgCover={`${SPACES_PATH + '/virtual_office.webp'}`}
            text={t('spaceType:virtual_offices')}
            onClick={() => handleFilterType('virtual offices')}
          />
        </GridItem>
        <GridItem cursor="pointer">
          <CardCover
            imgCover={`${SPACES_PATH + '/coworking_space.webp'}`}
            text={t('spaceType:coworking_space')}
            onClick={() => handleFilterType('co-working')}
          />
        </GridItem>
      </SimpleGrid>
    </>
  );
};

export default memo(PropertyTypes);
