import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { AutoComplete } from "../field";
import { useDispatch } from "react-redux";
import { fetchAutocompleteAgentsByRole } from "../../services/agents.service";

const AutocompleteAgentsByRole = ({
  control,
  name = "autocomplete",
  label = "Autocomplete",
  role = "", // can multiple roles separated by comma without space eg: "admin,website_agent"
  isRequired = false,
  isDisabled = false,
  placeholder,
  ...props
}) => {
  const [autocomplete, setAutocomplete] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleAutocomplete = async (inputValue) => {
    const payload = {
      search: inputValue,
      role: role,
    };
    setLoading(true);
    const result = await dispatch(fetchAutocompleteAgentsByRole(payload));
    if (result.meta.requestStatus === "fulfilled") {
      setAutocomplete(result.payload.data);
      setLoading(false);
    }
  };

  // Group options by role
  const groupByRole = (options) => {
    return options.reduce((groups, option) => {
      const role = option.role;
      if (!groups[role]) {
        groups[role] = [];
      }
      groups[role].push(option);
      return groups;
    }, {});
  };

  // Convert groups into array
  const groupsToArray = (groups) => {
    return Object.keys(groups).map((role) => ({
      label: role.replace(/_/g, " "),
      options: groups[role],
    }));
  };

  const groupedOptions = groupsToArray(groupByRole(autocomplete));

  return (
    <Fragment>
      <AutoComplete
        name={name}
        label={label}
        control={control}
        options={groupedOptions}
        eventInputChange={handleAutocomplete}
        isLoading={loading}
        isRequired={isRequired}
        isDisabled={isDisabled}
        placeholder={placeholder}
        {...props}
      />
    </Fragment>
  );
};

AutocompleteAgentsByRole.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  role: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default AutocompleteAgentsByRole;
