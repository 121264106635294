import React from 'react';
import Layout, { LayoutBody } from '../../../../components/layout/client';
import {
  Box,
  Text,
  Container,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const AboutProjectPage = () => {
  const { t } = useTranslation('aboutProject');

  return (
    <Layout>
      <LayoutBody>
        <Container maxW={'container.lg'}>
          <Box>
            <Text fontSize="3xl" fontWeight="bold" mb="8">
              {t('aboutProject:title')}
            </Text>
          </Box>

          <Box>
            <Text mb="8" fontSize="lg">
              {t('aboutProject:paragraph_1')}
            </Text>

            <Text mb="4">{t('aboutProject:paragraph_2')}</Text>

            <UnorderedList mb="8" pl="8" spacing={3}>
              <ListItem>{t('aboutProject:ordered_list.1')}</ListItem>
              <ListItem>{t('aboutProject:ordered_list.2')}</ListItem>
              <ListItem>{t('aboutProject:ordered_list.3')}</ListItem>
              <ListItem>{t('aboutProject:ordered_list.4')}</ListItem>
              <ListItem>{t('aboutProject:ordered_list.5')}</ListItem>
            </UnorderedList>
          </Box>
        </Container>
      </LayoutBody>
    </Layout>
  );
};

export default AboutProjectPage;
