import { Translation } from 'react-i18next';

const messages = {
  required: 'Field is required',
  requireds: (
    <Translation ns={'schema'}>{(t) => t('requiredField')}</Translation>
  ),
  phone: {
    required: 'Phone number is required',
  },
  image: {
    required: 'Image is required',
  },
};

export default messages;
