import React, { memo } from 'react';
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { AutocompleteAgentsByRole } from 'components/shared';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaDelegate } from '../constant';
import { messageSuccessUpdate } from 'components/messages';
import { useDelegateInquiryMutation } from 'services/inquiries.services';

const defaultValues = {
  websiteAgent: null,
};

const ModalDelegate = ({ refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaDelegate),
  });

  // service
  const [delegateInquiry, { isLoading }] = useDelegateInquiryMutation();

  const onSubmit = async (values) => {
    const payload = {
      id,
      responsible: {
        label: values.websiteAgent.label,
        value: values.websiteAgent.value,
      },
      ...values,
    };

    try {
      const res = await delegateInquiry(payload).unwrap();
      messageSuccessUpdate({
        title: 'Success',
        description: res.message,
      });
      reset(defaultValues);
      onClose();
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Delegate
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delegate Inquiry</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AutocompleteAgentsByRole
              name="websiteAgent"
              label="Website Agent"
              placeholder="Select Website Agent"
              control={control}
              role="website_agent"
              isRequired
              isClearable
            />

            <Text mt="4" color="gray.500" fontSize="sm">
              Note:
            </Text>
            <UnorderedList mt="2" fontSize="sm" color="gray.500">
              <ListItem>
                Submitted inquiry will be sent to the selected Website Agent to
                process it. And website agent will be responsible for this
                inquiry.
              </ListItem>
              <ListItem>
                When you change the website agent, previous website agent
                can&apos;t access this inquiry anymore.
              </ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(ModalDelegate);
