import React from "react";
import { Button, HStack, useBreakpointValue } from "@chakra-ui/react";
import { Link } from "react-scroll";
import { BiCommentAdd } from "react-icons/bi";
import { useTranslation } from "react-i18next";

import {
  FaHeart,
  FaRegCalendarAlt,
  FaRegHeart,
  FaRegStar,
  FaStar,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorite,
  removeFavorite,
} from "../../../../../redux/favoriteSlice";
import { addCompare, removeCompare } from "../../../../../redux/compareSlice";

const ActionButtonSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const device = useBreakpointValue(
    { base: "mobile", lg: "desktop" },
    { ssr: false },
  );

  const { property } = useSelector((state) => state?.clientProperties);

  const { items } = useSelector((state) => state?.compare);
  const { listFavorite } = useSelector((state) => state?.favorites);

  const idxCompare = items.findIndex((item) => item.objectID === property?.id);

  const idxFavorite = listFavorite.findIndex(
    (item) => item.objectID === property?.id,
  );

  const handleFavorite = () => {
    if (idxFavorite === -1) {
      dispatch(addFavorite({ ...property, objectID: property.id }));
    } else {
      dispatch(removeFavorite({ ...property, objectID: property.id }));
    }
  };

  const handleCompare = () => {
    if (idxCompare === -1) {
      dispatch(addCompare({ ...property, objectID: property.id }));
    } else {
      dispatch(removeCompare({ ...property, objectID: property.id }));
    }
  };

  return (
    <HStack spacing="6">
      {device !== "mobile" && (
        <Button
          as={Link}
          to="raise-complaint"
          spy={true}
          smooth={true}
          leftIcon={<BiCommentAdd />}
          color="gray.700"
          variant="link"
          textDecoration="underline"
          cursor="pointer"
        >
          Raise Complain
        </Button>
      )}

      <Button
        onClick={handleFavorite}
        color="gray.700"
        leftIcon={idxFavorite === -1 ? <FaRegHeart /> : <FaHeart />}
        variant="link"
        textDecoration="underline"
      >
        {t("common:button.favorite")}
      </Button>
      <Button
        onClick={handleCompare}
        color="gray.700"
        leftIcon={idxCompare === -1 ? <FaRegStar /> : <FaStar />}
        variant="link"
        textDecoration="underline"
      >
        {t("common:button.compare")}
      </Button>

      {device !== "mobile" && (
        <Button
          as={Link}
          to="request-tour"
          spy={true}
          smooth={true}
          color="gray.700"
          leftIcon={<FaRegCalendarAlt />}
          variant="link"
          textDecoration="underline"
          cursor="pointer"
        >
          {t("common:button.request_tour")}
        </Button>
      )}
    </HStack>
  );
};

export default ActionButtonSection;
