import React, { useState } from "react";
import { Button, Grid, SimpleGrid, useToast } from "@chakra-ui/react";
import { InputField } from "../../../components/field";
import { useForm } from "react-hook-form";
import { AutocompleteCities } from "../../../components/shared";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../services/auth.service";

const CompanyForm = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { me } = useSelector((state) => state?.auth);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      companyName: me?.companyName,
      city: me?.city,
    },
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const res = await dispatch(updateProfile(values));
    if (res.meta.requestStatus === "fulfilled") {
      toast({
        title: "Company Info Updated",
        description: "Company info updated successfully.",
        position: "top-right",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Grid spacing="6" gap={6}>
        <SimpleGrid columns={2} spacing="12px">
          <InputField
            name="companyName"
            label="Company Name"
            control={control}
          />
          <AutocompleteCities name="city" label="City" control={control} />
        </SimpleGrid>
        <Button
          type="submit"
          width="fit-content"
          colorScheme="primary"
          size="sm"
          isLoading={loading}
        >
          Save Changes
        </Button>
      </Grid>
    </form>
  );
};

export default CompanyForm;
