import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  HStack,
  Image,
  Text,
  Icon,
  IconButton,
  Tooltip,
  Tag,
} from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FavoriteIcon, CompareIcon } from "../icon";

import { useDispatch, useSelector } from "react-redux";
import { addCompare, removeCompare } from "../../redux/compareSlice";
import { addFavorite, removeFavorite } from "../../redux/favoriteSlice";
import { useTranslation } from "react-i18next";
import { paymentPeriodic } from "utils/constant";

const PropertyItem = ({ data }) => {
  const { t, i18n } = useTranslation();
  const ARABIC = i18n.language === "ar";
  const dispatch = useDispatch();

  const { items } = useSelector((state) => state?.compare);
  const { listFavorite } = useSelector((state) => state?.favorites);

  const idx = items.findIndex((item) => item.objectID === data.objectID);

  const idxFavorite = listFavorite.findIndex(
    (item) => item.objectID === data.objectID,
  );

  const handleCompare = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (idx === -1) {
      dispatch(addCompare(data));
    } else {
      dispatch(removeCompare(data));
    }
  };

  const handleFavorite = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (idxFavorite === -1) {
      dispatch(addFavorite(data));
    } else {
      dispatch(removeFavorite(data));
    }
  };

  return (
    <Box cursor="pointer" bg="white" borderBottomRadius="8px">
      <Box position="relative" mb="1">
        <Box position="absolute" zIndex="1" top="8px" px="2" w="full">
          <HStack justify="space-between" align="start">
            <Box bg="white" px="3" py="1" borderRadius="full" boxShadow="md">
              <Text
                fontWeight="semibold"
                fontSize="sm"
                letterSpacing={"wider"}
                color="brand.dark"
                textTransform="capitalize"
              >
                {ARABIC ? data.type.ar_label : data.type.label}
              </Text>
            </Box>
            <Tooltip
              hasArrow
              label={
                idxFavorite === -1
                  ? `${t("add_to_favorite")}`
                  : `${t("remove_from_favorite")}`
              }
              placement="bottom-end"
            >
              <Box>
                <IconButton
                  aria-label="Favorite"
                  size="sm"
                  icon={
                    <FavoriteIcon
                      w={6}
                      h={6}
                      fillColor={
                        idxFavorite === -1 ? "rgba(0, 0, 0, 0.5)" : "#FF385C"
                      }
                    />
                  }
                  onClick={handleFavorite}
                  bg="transparent"
                  _hover={{
                    bg: "transparent",
                  }}
                />
              </Box>
            </Tooltip>
          </HStack>
        </Box>

        <Box position="absolute" zIndex="1" bottom=".5rem" px="2" w="full">
          <HStack justify="space-between">
            <HStack>
              <Icon as={FaMapMarkerAlt} color="white" />
              <Text
                color="white"
                fontWeight="semibold"
                textTransform="capitalize"
                noOfLines={[1, 2, 1]}
              >
                {`${data?.country.label}, ${data?.region.label}, ${data?.city.label}`}
              </Text>
            </HStack>
            <Tooltip
              hasArrow
              label={
                idx === -1
                  ? `${t("add_to_compare")}`
                  : `${t("remove_from_compare")}`
              }
              placement="bottom-end"
            >
              <IconButton
                aria-label="Compare"
                size="sm"
                icon={
                  <CompareIcon
                    w={7}
                    h={7}
                    fillColor={idx === -1 ? "rgba(0, 0, 0, 0.5)" : "#FF385C"}
                  />
                }
                onClick={handleCompare}
                bg="transparent"
                _hover={{
                  bg: "transparent",
                }}
              />
            </Tooltip>
          </HStack>
        </Box>
        <Box
          _hover={{
            _before: {
              bgColor: "rgba(0,0,0,0.1)",
            },
          }}
          _before={{
            content: '""',
            bgColor: "rgba(0,0,0,0.3)",
            position: "absolute",
            left: 0,
            top: 0,
            w: "100%",
            h: "100%",
            transition: "250ms ease-in-out",
            borderRadius: "8px",
          }}
        >
          <Image
            w="full"
            h={["300px", null, null, "244px"]}
            objectFit="cover"
            src={data.featureImageThumb || data.featureImage}
            borderRadius="8px"
            fallbackSrc={
              process.env.PUBLIC_URL + "/images/image-placeholder-300.webp"
            }
          />
        </Box>
      </Box>
      <Box py="1">
        <Text
          color="gray.800"
          fontSize={["md", "lg"]}
          textTransform="capitalize"
          noOfLines={1}
          fontWeight="semibold"
        >
          {data.title}
        </Text>

        <Text color="gray.500" noOfLines={1} fontSize="md">
          {data.description}
        </Text>

        <HStack pt="1">
          <Text fontWeight="semibold" fontSize="md">
            {data.price} SAR
          </Text>
          <Text>/ {paymentPeriodic[data.payment]}</Text>
        </HStack>
      </Box>
    </Box>
  );
};

PropertyItem.propTypes = {
  data: PropTypes.object,
};

export default PropertyItem;
