import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { InputField } from "../../../components/field";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailSchema } from "../../../utils/sharedSchema";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../services/auth.service";

const schema = yup.object().shape({
  email: emailSchema
});

const ModalUpdateEmail = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { me, loadingUpdateProfile } = useSelector(state => state?.auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: me?.email
    }
  });

  useEffect(() => {
    const initValue = {
      email: me?.email
    };
    reset(initValue);
  }, [isOpen]);

  const onUpdate = async values => {
    await dispatch(updateProfile(values));
    onClose();

    toast({
      title: "Email Updated",
      description: "Email updated successfully.",
      position: "top-right",
      status: "success",
      duration: 4000,
      isClosable: true
    });
  };

  return (
    <>
      <Button size="sm" onClick={onOpen}>
        Change Email
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid spacing="6">
              <InputField
                name="email"
                type="email"
                label="Email"
                control={control}
                isRequired
              />
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleSubmit(onUpdate)}
              isLoading={loadingUpdateProfile}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalUpdateEmail;
