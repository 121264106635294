import React from "react";
import PropTypes from "prop-types";
import { Tag, TagLabel } from "@chakra-ui/react";

const style = {
  borderRadius: "full",
  textTransform: "capitalize"
};

const Objection = ({ value }) => {
  switch (String(value).toLowerCase()) {
    case "new":
      return (
        <Tag {...style} colorScheme="green">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    case "ignored":
      return (
        <Tag {...style} colorScheme="yellow">
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      );
    default:
      return value ? (
        <Tag {...style}>
          <TagLabel fontSize="xs">{value}</TagLabel>
        </Tag>
      ) : (
        "-"
      );
  }
};

Objection.propTypes = {
  value: PropTypes.string
};

export default Objection;
