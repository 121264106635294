import React from "react";
import PropTypes from "prop-types";
import { Image } from "@chakra-ui/react";

const ImageCell = ({ value }) => {
  return (
    <Image
      src={value}
      boxSize="62px"
      fallbackSrc={process.env.PUBLIC_URL + "/images/image-placeholder-80.webp"}
    />
  );
};

ImageCell.propTypes = {
  value: PropTypes.string
};

export default ImageCell;
