import React from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import { Grid, GridItem } from "@chakra-ui/react";
import Paper from "../../../components/paper";
import FeaturesTable from "./components/FeaturesTable";
import PopupAdd from "./components/PopupAdd";

const PropertyFeaturesPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Features" />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <PopupAdd />
        </LayoutHeaderRight>
      </LayoutHeader>
      <LayoutBody>
        <Grid>
          <GridItem>
            <Paper>
              <FeaturesTable />
            </Paper>
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};

export default PropertyFeaturesPage;
