import AddNewPropertyPage from "../../pages/property/addNewProperty";
import AdvertisementNumberPage from "../../pages/property/AdvertisementNumber";
import PropertyDetailsPage from "../../pages/property/details";
import PropertyFeaturesPage from "../../pages/property/features";
import PropertiesPage from "../../pages/property/properties";
import PropertyTagsPage from "../../pages/property/tags";
import PropertyTypesPage from "../../pages/property/types";
import PropertyNotFound from "../../pages/property/not-found";

const propertyRoutes = [
  { path: "/properties", component: PropertiesPage, exact: true },
  { path: "/property/:id", component: PropertyDetailsPage },
  { path: "/properties/not-found", component: PropertyNotFound },
  { path: "/properties/new", component: AddNewPropertyPage },
  {
    path: "/properties/advertisement-number",
    component: AdvertisementNumberPage,
  },
  { path: "/properties/types", component: PropertyTypesPage },
  { path: "/properties/features", component: PropertyFeaturesPage },
  { path: "/properties/tags", component: PropertyTagsPage },
];

export default propertyRoutes;
