import React from "react";
import PropTypes from "prop-types";
import {
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

const PriceRangeFilterField = ({ name, control, onUpdate = () => {} }) => {
  const { t } = useTranslation();

  const initValue = {
    priceMin: 1,
    priceMax: 10000,
  };

  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: [initValue.priceMin, initValue.priceMax],
    rules: { required: false },
  });

  const handleChangeMin = (val) => {
    // if (val > 999) {
    //   onChange([999, value[1]]);
    //   return;
    // }
    onChange([val, value[1]]);
  };

  const handleChangeMax = (val) => {
    // if (val > 1000) {
    //   onChange([value[0], 1000]);
    //   return;
    // }
    onChange([value[0], val]);
  };

  return (
    <HStack>
      <VStack align="start">
        <Text fontSize="sm" ml="1">
          {t("common:label.minimum")}
        </Text>
        <NumberInput value={value[0]} min={0} onChange={handleChangeMin}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>

      <Text pt="24px">-</Text>

      <VStack align="start">
        <Text fontSize="sm" ml="1">
          {t("common:label.maximum")}
        </Text>
        <NumberInput value={value[1]} min={0} onChange={handleChangeMax}>
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>
    </HStack>
  );
};

PriceRangeFilterField.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

export default PriceRangeFilterField;
