import React, { useEffect, useRef, useMemo, useState } from "react";
import {
  SimpleGrid,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
  HStack,
  Icon,
  Image,
  Text,
  IconButton,
  Code,
  useToast,
} from "@chakra-ui/react";
import { BsImages } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";

const MultipleImageUpload = ({
  name,
  acceptedFileTypes = "image/*",
  control,
  label,
  alt,
  callback = () => {},
  isLoading = false,
  isRequired = false,
}) => {
  const [localImageList, setLocalImageList] = useState([]);
  const inputRef = useRef();
  const toast = useToast();

  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: [],
    rules: { required: isRequired },
  });

  const handleChange = (e) => {
    if (e.target.files.length >= 1) {
      // onChange(e.target.files);
      callback(e.target.files, (uploadedData) => {
        onChange([...value, ...uploadedData]);
        setLocalImageList(e.target.files);
      });
    } else {
      onChange([]);
    }
  };

  const handleDelete = (index) => {
    const val = Object.values(value);
    val.splice(index, 1);
    onChange(val);
  };

  const ButtonDelete = ({ ...props }) => (
    <IconButton
      {...props}
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      position="absolute"
      colorScheme="red"
      aria-label="delete image"
      icon={<FaTrash />}
      visibility="hidden"
    />
  );

  const variantColor = invalid ? "red.500" : "gray.400";
  const variantBorder = invalid ? "red.500" : "gray.300";
  const fileInputKey = value ? value : +new Date();

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Box w="full">
        <Box
          ref={ref}
          bg="gray.100"
          borderRadius="2px"
          p="4"
          mb="4"
          border="2px dashed"
          color={variantBorder}
        >
          {(value?.length || []) <= 0 && (
            <VStack m="12">
              <Icon as={BsImages} boxSize={24} color={variantColor} />
              <Text fontWeight="semibold" color="gray.500">
                Multiple Image Upload
              </Text>
              <Text mt="3" color="gray.600" fontSize="sm">
                Mac: <Code color="gray.500">Hold Command + click</Code>, Win:{" "}
                <Code color="gray.500">Hold Ctrl + click</Code>
              </Text>
              <Text mt="3" color="gray.500" fontSize="sm">
                File Support: .jpg, .gif, or .png
              </Text>
            </VStack>
          )}

          {(value?.length || []) >= 1 && (
            <SimpleGrid columns={{ base: 2, xl: 4 }} spacing={4}>
              {value.map((item, index) => (
                <Box
                  key={index}
                  position="relative"
                  _hover={{
                    "> button": {
                      visibility: "visible",
                    },
                  }}
                >
                  <ButtonDelete onClick={() => handleDelete(index)} />
                  <Image
                    width="full"
                    height="180px"
                    border="1px"
                    borderRadius="4"
                    color="gray.200"
                    objectFit="cover"
                    src={item?.thumbnailImageURL || item}
                    alt={alt || label}
                    ignoreFallback
                  />
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Box>
        {isLoading ? (
          <Button isDisabled>Uploading...</Button>
        ) : (
          <Button onClick={() => inputRef.current.click()}>Add Image</Button>
        )}
      </Box>

      <input
        key={fileInputKey}
        type="file"
        accept={acceptedFileTypes}
        name={name}
        ref={inputRef}
        {...inputProps}
        style={{ display: "none" }}
        onChange={handleChange}
        multiple
      />
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

MultipleImageUpload.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  alt: PropTypes.string,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  callback: PropTypes.func,
};

export default MultipleImageUpload;
