import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
import { useGetMenuRawQuery } from "../../services/menu.service";
import { useDeviceDetected } from "../../hooks";

const Navbar = ({ isOpen, handleClose }) => {
  const { isMobile } = useDeviceDetected();

  // Request menu
  useGetMenuRawQuery();

  const { isShrunk } = useSelector((state) => state?.layout);

  // get persisted menu
  const { rawPersistedMenu } = useSelector((state) => state?.menu);

  return (
    <Box>
      {isMobile && (
        <Box pt={isShrunk ? "62px" : "70px"}>
          <MobileMenu
            isOpen={isOpen}
            onClose={handleClose}
            menuItems={rawPersistedMenu || []}
          />
        </Box>
      )}
      {!isMobile && (
        <Box pt={isShrunk ? "75px" : "90px"} transition="250ms">
          <DesktopMenu menuItems={rawPersistedMenu || []} />
        </Box>
      )}
    </Box>
  );
};

Navbar.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default memo(Navbar);
