import AccountPage from "../../pages/account";

const accountRoutes = [
  { path: "/account", component: AccountPage, exact: true }
  // { path: "/agent/:id", component: AgentDetailsPage, exact: true },
  // { path: "/agents/new", component: AddNewAgentPage },
  // { path: "/v/agent/:id", component: AgentView, exact: true }
];

export default accountRoutes;
