import React, { useEffect, useRef, useState, memo } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
} from "@chakra-ui/react";
import ReactDatePicker from "react-datepicker";
import { useController } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import "../DatePicker/date-picker.css";

const TimeRangeField = ({
  name,
  control,
  label,
  isRequired = false,
  isDisabled = false,
  isReadOnly = false,
  isClearable = true,
  ...props
}) => {
  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty },
  } = useController({
    name,
    control,
    defaultValue: [new Date(), new Date()],
    rules: { required: isRequired },
  });

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const inputRef = useRef();

  useEffect(() => {
    const time = new Date();

    if (!Array.isArray(value)) {
      onChange([time, time]);
      return console.error("Time range value must array.");
    }

    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);

    if (startDate.getTime() >= endDate.getTime()) {
      // adding one hour in end time when initial.
      endDate.setHours(startDate.getHours() + 1);
    }

    setStart(new Date(startDate) || time);
    setEnd(new Date(endDate) || time);

    // Dont add onchange as a dependency, will cause infinity loop
  }, [value]);

  const filterPassedTime = (time) => {
    try {
      const currentDate = start;
      const selectedDate = new Date(time);

      return currentDate.getTime() < selectedDate.getTime();
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeStart = (value) => {
    try {
      if (value.getTime() >= end.getTime()) {
        // adding 1 hour
        const futureDate = new Date(value);
        futureDate.setHours(value.getHours() + 1);

        setEnd(futureDate);
        onChange([value, futureDate]);
      } else {
        setStart(value);
        onChange([value, end]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeEnd = (value) => {
    setEnd(value);
    onChange([start, value]);
  };

  const timeProps = {
    showPopperArrow: false,
    onKeyDown: (e) => e.preventDefault(),
    autoComplete: "off",
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeCaption: "Time",
    dateFormat: "h:mm aa",
    ...inputProps,
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <HStack>
        <Box>
          <ReactDatePicker
            name={name}
            ref={inputRef}
            onChange={handleChangeStart}
            selected={start || new Date()}
            disabled={isDisabled}
            readOnly={isReadOnly}
            wrapperClassName={isDisabled ? "wrapper-disable" : ""}
            {...timeProps}
          />
        </Box>
        <Box>
          <ReactDatePicker
            name={name}
            ref={inputRef}
            onChange={handleChangeEnd}
            selected={end || new Date()}
            filterTime={filterPassedTime}
            disabled={isDisabled}
            readOnly={isReadOnly}
            wrapperClassName={isDisabled ? "wrapper-disable" : ""}
            {...timeProps}
          />
        </Box>
      </HStack>
      <FormErrorMessage>{invalid}</FormErrorMessage>
    </FormControl>
  );
};

TimeRangeField.propTypes = {
  name: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
};

export default memo(TimeRangeField);
