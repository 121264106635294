import { customAlphabet } from "nanoid";

const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const fullAlphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const number = "0123456789";

const nanoid = customAlphabet(alphabet, 10);
export const microid = customAlphabet(fullAlphabet, 12);
export const macroid = customAlphabet(number, 18);
export const numid = customAlphabet(number, 7);

export default nanoid;
