import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { TableV1 } from '../../../components/table';
import {
  DateTimeCell,
  PriceCell,
  StatusCell,
  TruncateCell,
} from '../../../components/cell';
import { useSelector } from 'react-redux';
import ROLE from '../../../config/role';

const ContractsTable = ({
  data = [],
  isLoading,
  meta,
  onPagination = () => {},
}) => {
  const history = useHistory();
  const { me } = useSelector((state) => state?.auth);

  const dynamicStatus =
    me?.role === ROLE.PROPERTY_AGENT ? 'statusByAgent' : 'status';

  const columns = React.useMemo(
    () => [
      {
        Header: 'Contract Id',
        accessor: 'generatedID',
        minWidth: 180,
      },
      { Header: 'Property Agent', accessor: 'propertyAgent', minWidth: 200 },
      {
        Header: 'Property',
        accessor: 'property',
        Cell: TruncateCell,
        minWidth: 200,
      },
      { Header: 'Contract Period', accessor: 'contractPeriod', minWidth: 180 },
      {
        Header: 'Starting Date',
        accessor: 'contractStartingDate',
        minWidth: 200,
        Cell: DateTimeCell,
      },
      {
        Header: 'Contract Cost',
        accessor: 'contractCost',
        minWidth: 170,
        Cell: PriceCell,
      },
      { Header: 'Status', accessor: dynamicStatus, Cell: StatusCell },
    ],
    []
  );

  const dataMemoized = React.useMemo(
    () =>
      data.map((item) => {
        return {
          id: item.id,
          generatedID: item.generatedID,
          propertyAgent: item.propertyAgent?.label || item.propertyAgent,
          property: item.property?.label || item.property,
          contractPeriod: item.contractPeriod,
          contractStartingDate: item.contractStartingDate,
          contractCost: item.contractCost,
          statusByAgent: item.statusByAgent,
          status: item.status,
        };
      }),
    [data]
  );

  const handleRowClick = (row) => {
    history.push(`/contract/${row.original.id}`);
  };

  return (
    <TableV1
      columns={columns}
      data={dataMemoized}
      isLoading={isLoading}
      meta={meta}
      handlePagination={onPagination}
      eventRowClick={handleRowClick}
    />
  );
};

ContractsTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  loadingDelete: PropTypes.bool,
  meta: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onPagination: PropTypes.func,
};

export default ContractsTable;
