import { Button, Stack, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { TextareaField, SelectField } from 'components/field';
import { complainOptions } from 'pages/property/trans-constant';
import { useHistory, useLocation } from 'react-router-dom';
import { stringSchema } from 'utils/sharedSchema';
import { useSelector } from 'react-redux';
import { useAddComplaintMutation } from 'services/complaint.services';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const schema = yup.object().shape({
  complaint: stringSchema,
  complaintDetails: stringSchema,
});

const RaiseComplaintForm = ({ property }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const toast = useToast();

  // get user from userSelector
  const { me } = useSelector((state) => state?.auth);
  const { displayName, username, email, phoneNumber } = me || [];

  // ADD
  const [addComplaint, { isLoading: isLoadingAdd }] = useAddComplaintMutation();

  const defaultValues = {
    clientName: displayName || '',
    clientEmail: email || '',
    clientPhoneNumber: phoneNumber || '',
    complaint: '',
    complaintDetails: '',
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (values) => {
    if (!email) {
      history.push(`/auth/login?redirect=${pathname}`);
      return;
    }

    const payload = {
      ...values,
      clientUsername: username,
      complaintProperty: {
        label: property?.title,
        value: property?.id,
      },
      propertyOwnerID: property?.addedBy.value,
      propertyOwnerName: property?.addedBy.label,
    };

    await addComplaint(payload).unwrap();

    toast({
      title: 'Sent successfully',
      description:
        'Your complaint has been sent successfully. Project 300 team will contact you via email or phone shortly.',
      position: 'top-right',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing="6" align="start">
        <SelectField
          name="complaint"
          label={t('common:label:complaint')}
          placeholder={t('common:label:raise_a_complaint')}
          control={control}
          options={complainOptions}
          isRequired
        />

        <TextareaField
          name="complaintDetails"
          label={t('common:label:complaint_details')}
          control={control}
          isRequired
        />

        <Button
          width={{ base: 'full', lg: '200px' }}
          type="submit"
          colorScheme="primary"
          mt="12"
          isDisabled={!property}
          isLoading={isLoadingAdd}
        >
          {t('common:button:send_complaint')}
        </Button>
      </Stack>
    </form>
  );
};

RaiseComplaintForm.propTypes = {
  property: PropTypes.object,
};
export default RaiseComplaintForm;
