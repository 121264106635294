import React from "react";
import {
  Box,
  Divider,
  Text,
  Container,
  VStack,
  HStack,
  Heading,
  Avatar,
  Button,
  useToast,
} from "@chakra-ui/react";
import Paper from "../../components/paper";
import Layout, { LayoutBody } from "../../components/layout";
import ModalUpdateAvatar from "./components/ModalUpdateAvatar";
import ModalUpdateEmail from "./components/ModalUpdateEmail";
import ModalUpdateName from "./components/ModalUpdateName";
import PersonalForm from "./components/PersonalForm";
import CompanyForm from "./components/CompanyForm";
import AccountTypeForm from "./components/AccountTypeForm";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../services/auth.service";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const AccountPage = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { me, loadingUpdatePassword } = useSelector((state) => state?.auth);

  const handleUpdatePassword = async () => {
    const res = await dispatch(updatePassword());
    unwrapResult(res);

    toast({
      title: "Request Change Password Successfully",
      description: "We've send link reset password to your email address.",
      position: "top-right",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  };

  return (
    <Layout>
      <Container>
        <LayoutBody>
          <Box>
            <Heading fontWeight="semibold" fontSize="xl">
              Account Settings
            </Heading>
            <Text mb="4" mt="1" color="gray.600">
              Change your profile.
            </Text>
            <Paper>
              <Box>
                <Box mb="6">
                  <Text fontSize="lg" fontWeight="semibold" mb="1">
                    Name & Avatar
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    Change your name and profile picture
                  </Text>
                </Box>

                <HStack my="4">
                  <Avatar boxSize="62" mr="2" src={me?.imageURL} />
                  <VStack align="start" spacing="0">
                    <Text>{me?.displayName}</Text>
                    <Text color="gray.500" fontSize="sm">
                      Joined {dayjs(me?.createdAt).format("LL")}
                    </Text>
                  </VStack>
                </HStack>
                <HStack mb="6">
                  <ModalUpdateName />
                  <ModalUpdateAvatar />
                </HStack>
              </Box>
              <Divider my="6" />
              <Box>
                <Box mb="6">
                  <Text fontSize="lg" fontWeight="semibold" mb="1">
                    Login details
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    Change your email and password
                  </Text>
                </Box>
                <Text>{me?.email}</Text>
                <HStack mt="6">
                  <ModalUpdateEmail />
                  <Button
                    onClick={handleUpdatePassword}
                    size="sm"
                    isLoading={loadingUpdatePassword}
                  >
                    Request Change Password
                  </Button>
                </HStack>
              </Box>
              <Divider my="6" />
              <Box>
                <Box mb="6">
                  <Text fontSize="lg" fontWeight="semibold" mb="1">
                    Personal Info
                  </Text>
                  <Text fontSize="sm" color="gray.600">
                    Change your personal info.
                  </Text>
                </Box>
                <PersonalForm />
              </Box>
            </Paper>
          </Box>
        </LayoutBody>
        <Divider my="6" />
        <LayoutBody>
          <Box id="account-type">
            <Heading fontWeight="semibold" fontSize="xl">
              Account Type
            </Heading>
            <Text mb="4" mt="1" color="gray.600">
              Change your account type.
            </Text>
            <Paper>
              <AccountTypeForm />
            </Paper>
          </Box>
        </LayoutBody>
        <Divider my="6" />
        <LayoutBody>
          <Box>
            <Heading fontWeight="semibold" fontSize="xl">
              Company Settings
            </Heading>
            <Text mb="4" mt="1" color="gray.600">
              Change your company profile.
            </Text>
            <Paper>
              <CompanyForm />
            </Paper>
          </Box>
        </LayoutBody>
      </Container>
    </Layout>
  );
};

export default AccountPage;
