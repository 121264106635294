import React from "react";
import PropTypes from "prop-types";
import { Heading } from "@chakra-ui/react";

const TextHeader = ({ text }) => (
  <Heading fontSize="xl" textTransform="capitalize" color="gray.700">
    {text}
  </Heading>
);

TextHeader.propTypes = {
  text: PropTypes.string,
};

export default TextHeader;
