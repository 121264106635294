import React from 'react';
import { Button, IconButton } from '@chakra-ui/react';

export function useButtonTrigger(api) {
  const ButtonTrigger = (props) => {
    const { onOpen } = ButtonTrigger.api;

    return (
      <Button onClick={onOpen} size="sm" colorScheme="primary" {...props} />
    );
  };

  ButtonTrigger.api = api;

  return ButtonTrigger;
}

export function useIconButtonTrigger(api) {
  const IconButtonTrigger = (props) => {
    const { onOpen } = IconButtonTrigger.api;

    return (
      <IconButton onClick={onOpen} size="sm" colorScheme="primary" {...props} />
    );
  };

  IconButtonTrigger.api = api;

  return IconButtonTrigger;
}
