import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const NoFavoriteResults = () => {
  const { t } = useTranslation();

  return (
    <Flex
      w="full"
      alignItems="center"
      direction={"column"}
      justify="center"
      pt="8"
    >
      <Image
        ignoreFallback
        src={process.env.PUBLIC_URL + "/illustration/empty-favorite.svg"}
        boxSize="300"
      />
      <Text fontSize="2xl" color="gray.700" align="center">
        {t("common:label.no_results")}
      </Text>
      <Text fontSize="xl" color="gray.600" mb="6" align="center">
        {t("common:label.you_dont_have_a_favorite_list")}
      </Text>
    </Flex>
  );
};

export default NoFavoriteResults;
