import {
  Box,
  Center,
  Container,
  HStack,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import ComplainModal from 'pages/client/property/components/ComplainModal';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Link as ReachLink } from 'react-router-dom';
import SocialButton from '../button/SocialButton';

const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('footer');

  const currentTime = new Date();
  const year = currentTime.getFullYear();

  return (
    <Box bg="brand.footer" color="white">
      <Container maxW={'6xl'}>
        <SimpleGrid columns={{ base: 1, md: 3, xl: 4 }} spacing={8} py="8">
          <Stack align={{ base: 'flex-start', md: 'flex-start' }} spacing={4}>
            <Link as={ReachLink} to={'/c/terms'}>
              {t('footer:terms_conditions')}
            </Link>
            <Link as={ReachLink} to={'/c/need-help'}>
              {t('footer:need_help')}
            </Link>
            <Link as={ReachLink} to={'/c/agents'}>
              {t('footer:agents')}
            </Link>
          </Stack>
          <Stack align={{ base: 'flex-start', md: 'flex-start' }} spacing={4}>
            <Link as={ReachLink} to={'/c/why-project-300'}>
              {t('footer:why_Project_300')}
            </Link>
            <Link as={ReachLink} to={'/c/about-project-300'}>
              {t('footer:about_project_300')}
            </Link>
          </Stack>
          <Stack align={{ base: 'flex-start', md: 'flex-start' }} spacing={4}>
            <Link as={ReachLink} to={'/c/contact-us'}>
              {t('footer:contact_us')}
            </Link>
            <VStack align={{ base: 'flex-start', md: 'flex-start' }}>
              <Text fontSize="sm">{t('footer:follow_us')}</Text>
              <HStack align={'flex-start'} spacing="1">
                <SocialButton
                  ml="-12px"
                  icon={<FaTwitter />}
                  href="https://twitter.com/p300sa"
                />
                <SocialButton
                  icon={<FaInstagram />}
                  href="https://www.instagram.com/project300sa/"
                />
                <SocialButton
                  icon={<FaLinkedinIn />}
                  href="https://www.linkedin.com/company/project-300-company/"
                />
              </HStack>
            </VStack>
          </Stack>

          <Stack align={{ base: 'flex-start', md: 'flex-start' }}>
            <VStack
              align={{ base: 'flex-start', md: 'flex-start' }}
              spacing="2"
            >
              <Text fontSize="sm">{t('footer:this_platform_is')}</Text>
              <Text fontSize="sm">{t('footer:fal_number')} 1200017542</Text>
              <Link
                isExternal
                href={
                  'https://rega.gov.sa/%D8%A7%D9%84%D9%85%D9%83%D8%AA%D8%A8%D8%A9/%D8%A7%D9%84%D8%A3%D9%86%D8%B8%D9%85%D8%A9-%D9%88%D8%A7%D9%84%D9%84%D9%88%D8%A7%D8%A6%D8%AD-%D9%88%D8%A7%D9%84%D8%A3%D8%AF%D9%84%D8%A9/#:~:text=%D8%A7%D9%84%D9%87%D9%8A%D8%A6%D8%A9%20%D8%A7%D9%84%D8%B9%D8%A7%D9%85%D8%A9%20%D9%84%D9%84%D8%B9%D9%82%D8%A7%D8%B1-,%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D9%88%D8%B3%D8%A7%D8%B7%D8%A9%20%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A%D8%A9,-%D9%86%D8%B8%D8%A7%D9%85%20%D8%A7%D9%84%D9%85%D8%B3%D8%A7%D9%87%D9%85%D8%A7%D8%AA%20%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A%D8%A9'
                }
                color="primary.500"
              >
                {t('footer:view_bylaw')}
              </Link>
            </VStack>
          </Stack>
        </SimpleGrid>
        <Center pb="4" pt="8">
          <Text>
            {t('footer:project_300')}
            &nbsp; &#169; &nbsp;
            {t('common:footer.all_right_reserved')} {year}
          </Text>
        </Center>
      </Container>
      <ComplainModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default memo(Footer);
