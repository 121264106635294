import SpecialRequestPage from "../../pages/specialRequest";
import SpecialRequestDetailsPage from "../../pages/specialRequest/details";

const specialRequestRoutes = [
  {
    path: "/special-request",
    component: SpecialRequestPage,
    exact: true
  },
  {
    path: "/special-request/:id",
    component: SpecialRequestDetailsPage,
    exact: true
  }
];

export default specialRequestRoutes;
