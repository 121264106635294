import React from "react";
import PropTypes from "prop-types";
import { Text, Tooltip, Box } from "@chakra-ui/react";

const TruncateCell = (props) => {
  return (
    <Text noOfLines={1}>
      <Tooltip label={props.value} aria-label="tooltip">
        <Text>{props.value}</Text>
      </Tooltip>
    </Text>
  );
};

TruncateCell.propTypes = {
  value: PropTypes.string,
};

export default TruncateCell;
