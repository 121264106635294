import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeStep: 0,
  form: {}
};

const registerStepSlice = createSlice({
  name: "registerStep",
  initialState,
  reducers: {
    nextStep(state) {
      state.activeStep += 1;
    },
    prevStep(state) {
      state.activeStep -= 1;
    },
    setStep(state, { payload }) {
      state.activeStep = payload;
    },
    resetStep(state) {
      state.activeStep = initialState.activeStep;
    },
    resetAll(state) {
      state.activeStep = initialState.activeStep;
      state.form = initialState.form;
    },
    updateForm: (state, { payload }) => {
      state.form = { ...payload };
    },
    resetForm: state => {
      state.form = initialState.form;
    }
  }
});
export const {
  nextStep,
  prevStep,
  setStep,
  resetStep,
  resetForm,
  resetAll,
  updateForm
} = registerStepSlice.actions;
export const selectActiveStep = state => state.registerStep.activeStep;
export const selectForm = state => state.registerStep.form;
export default registerStepSlice.reducer;
