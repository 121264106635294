import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  GridItem,
  SimpleGrid,
  Stack,
  VStack,
  Text,
  AlertIcon,
  Alert,
  Box
} from "@chakra-ui/react";
import {
  AutoComplete,
  DatePicker,
  FileUpload,
  InputField,
  NumberField,
  SelectField
} from "../../../components/field";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmDelete } from "../../../components/alert";
import { fetchAutocompleteAgentsByRole } from "../../../services/agents.service";
import { fetchAutocompleteProperties } from "../../../services/properties.service";
import { uploadContract } from "../../../services/contracts.service";
import Paper from "../../../components/paper";
import { periodOptions } from "../../../utils/constant";
import ROLE from "../../../config/role";

const statusOptions = [
  { label: "Sent", value: "sent" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" }
];

const ContractForm = ({
  control,
  handleDelete,
  readOnlyAll,
  editMode = false,
  addMode = false,
  isLoaded = false
}) => {
  const dispatch = useDispatch();
  const format = val => val + ` SAR`;

  const handleChangeAgent = inputValue => {
    dispatch(
      fetchAutocompleteAgentsByRole({
        search: inputValue,
        role: "property_agent"
      })
    );
  };

  const handleChangeProperty = inputValue => {
    dispatch(fetchAutocompleteProperties({ search: inputValue }));
  };

  const { me } = useSelector(state => state?.auth);

  // AGENTS
  const agents = useSelector(state => state?.agents);

  // PROPERTY
  const properties = useSelector(state => state?.properties);

  // CONTRACTS
  const { loadingUpload, loadingDelete } = useSelector(
    state => state?.contracts
  );

  const handleCallback = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      data: file
    };
    const res = await dispatch(uploadContract(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData({
        documentURL: res.payload.documentURL,
        documentFileName: res.payload.documentFileName
      });
    }
  };

  const dynamicStatus =
    me?.role === ROLE.PROPERTY_AGENT ? "statusByAgent" : "status";

  return (
    <Box>
      {editMode && (
        <Alert status="warning" mb="8">
          <AlertIcon />
          Contract information cannot be changed
        </Alert>
      )}

      <Grid templateColumns="repeat(6, 1fr)" gap={6}>
        <GridItem colSpan={[6, 6, 6, 6, 4]}>
          <Paper w="full">
            <Stack spacing={6}>
              {editMode && (
                <SimpleGrid columns={1} spacing={6}>
                  <InputField
                    name="generatedID"
                    label="Contract Id"
                    control={control}
                    isDisabled={readOnlyAll}
                  />
                </SimpleGrid>
              )}
              <SimpleGrid columns={[1, 1, 2]} spacing={6}>
                <AutoComplete
                  name="propertyAgent"
                  label="Property Agent"
                  placeholder="Type Property Agent"
                  control={control}
                  options={agents.autocompleteByRole}
                  eventInputChange={handleChangeAgent}
                  isLoading={agents.loadingAutocompleteByRole}
                  isRequired={!editMode}
                  isDisabled={readOnlyAll}
                />
                <AutoComplete
                  name="property"
                  label="Property"
                  placeholder="Type Property"
                  control={control}
                  options={properties.autocomplete}
                  eventInputChange={handleChangeProperty}
                  isLoading={properties.loadingAutocomplete}
                  isRequired={!editMode}
                  isDisabled={readOnlyAll}
                />
              </SimpleGrid>
              <SimpleGrid columns={[1, 1, 2]} spacing={6}>
                <NumberField
                  name="numberOfOffices"
                  label="Number of Offices"
                  control={control}
                  isRequired={!editMode}
                  isDisabled={readOnlyAll}
                />
                <NumberField
                  name="numberOfPeople"
                  label="Number Of People"
                  control={control}
                  isRequired={!editMode}
                  isDisabled={readOnlyAll}
                />
              </SimpleGrid>
              <SimpleGrid columns={[1, 1, 2]} spacing={6}>
                <SelectField
                  name="contractPeriod"
                  label="Contract Period"
                  control={control}
                  options={periodOptions}
                  isRequired={!editMode}
                  isDisabled={readOnlyAll}
                />
                <DatePicker
                  label="Contract Starting Date"
                  name="contractStartingDate"
                  control={control}
                  dateFormat="MMMM d, yyyy"
                  isRequired={!editMode}
                  isDisabled={readOnlyAll}
                />
              </SimpleGrid>
              <SimpleGrid columns={[1, 1, 2]} spacing={6}>
                <NumberField
                  name="contractCost"
                  label="Contract Cost"
                  masking={format}
                  control={control}
                  isRequired={!editMode}
                  isDisabled={readOnlyAll}
                />
              </SimpleGrid>
              <SimpleGrid columns={1} spacing={6}>
                <FileUpload
                  name="document"
                  label={loadingUpload ? "Uploading..." : "Upload Contract "}
                  control={control}
                  callback={handleCallback}
                  isRequired={!editMode}
                  isDisabled={readOnlyAll}
                />
              </SimpleGrid>
            </Stack>
          </Paper>
        </GridItem>
        <GridItem colSpan={[6, 6, 6, 6, 2]}>
          <Paper>
            <VStack spacing="6">
              <SelectField
                name={dynamicStatus}
                label="Status"
                control={control}
                options={statusOptions}
                isRequired={!editMode}
                allowedUnregistered
                isDisabled={readOnlyAll || addMode}
              />
            </VStack>
          </Paper>

          {editMode && (
            <Paper mt="6">
              <VStack spacing="2" align="start">
                <Text fontWeight="bold">Danger zone</Text>
                <Text pb="4">
                  Once you delete this <strong>contract</strong>, there is no
                  going back. Please be certain.
                </Text>
                <ConfirmDelete
                  isLoading={loadingDelete}
                  eventClick={handleDelete}
                  headerText="Delete Contract"
                  bodyText="Are you sure you want delete this contract?"
                  buttonText="Remove this contract"
                />
              </VStack>
            </Paper>
          )}
        </GridItem>
      </Grid>
    </Box>
  );
};

ContractForm.propTypes = {
  control: PropTypes.object,
  editMode: PropTypes.bool,
  addMode: PropTypes.bool,
  handleDelete: PropTypes.func,
  readOnlyAll: PropTypes.bool,
  isLoaded: PropTypes.bool
};

export default ContractForm;
