import React from "react";
import PropTypes from "prop-types";
import { Box, Text, Tooltip } from "@chakra-ui/react";

const PropertiesNameCell = ({ row: { original } }) => {
  return (
    <Box>
      <Text fontSize="xs">{original.title}</Text>
      <Text color="gray.500" fontSize="xs" mt="2">
        ID: {original.id}
      </Text>
    </Box>
  );
};

PropertiesNameCell.propTypes = {
  row: PropTypes.object,
  original: PropTypes.object
};

export default PropertiesNameCell;
