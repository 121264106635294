import React, { Fragment, memo } from "react";
import {
  Box,
  Button,
  Center,
  GridItem,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import PropertyItem from "components/client/PropertyItem";
import PropertyItemSkeleton from "components/client/Skeleton/PropertyItem.skeleton";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetElasticPropertiesQuery } from "services/client/properties.client.services";

const Workspaces = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: properties, isLoading } = useGetElasticPropertiesQuery({
    hitsPerPage: 3,
  });

  return (
    <>
      <Text fontSize="3xl" mb="8" fontWeight="semibold" align="center">
        {t("latest_workspaces")}
      </Text>

      {!isLoading && properties && properties.data.length === 0 && (
        <Box w="full">
          <Text fontSize="xl" color="gray.400">
            Sorry, we can&apos;t find workspace for you.
          </Text>
        </Box>
      )}

      <SimpleGrid columns={[1, 1, 1, 2, 3, 3]} spacing={8} w="full">
        {isLoading && <PropertyItemSkeleton total={3} />}

        {!isLoading && properties && properties.data.length !== 0 && (
          <Fragment>
            {properties &&
              properties.data.map((item, index) => (
                <GridItem
                  key={index}
                  onClick={() =>
                    window.open(`/c/property/${item.objectID}`, "_self")
                  }
                >
                  <PropertyItem data={item} />
                </GridItem>
              ))}
          </Fragment>
        )}
      </SimpleGrid>

      <Center mt="12">
        <Button
          colorScheme="primary"
          px="12"
          onClick={() => history.push("/c/properties")}
        >
          {t("common:button.browse_all_spaces")}
        </Button>
      </Center>
    </>
  );
};

export default memo(Workspaces);
