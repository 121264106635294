import React from "react";
import { Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import Paper from "../../../../../components/paper";
import ContactForm from "../form/ContactForm";

const ContactUsSection = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Heading fontSize="xl" fontWeight="semibold">
        {t("common:label.are_you_interested_in_this_space")}
      </Heading>
      <Text mb="6" mt="1" color="gray.600">
        {t("common:label.contact_us")}
      </Text>
      <Paper border="1px" borderColor="gray.300" boxShadow="lg">
        <ContactForm />
      </Paper>
    </>
  );
};

export default ContactUsSection;
