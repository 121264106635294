import React from "react";
import PropTypes from "prop-types";
import { Box, Text, HStack } from "@chakra-ui/react";

const CombineCell = ({ row: { original }, accessor, subText }) => {
  return (
    <Box>
      <Text fontSize="sm">{original[accessor[0]]}</Text>
      <HStack mt="1">
        <Text color="gray.400" fontSize="xs">
          {subText}:
        </Text>
        <Text color="gray.500" fontSize="xs">
          {original[accessor[1]]}
        </Text>
      </HStack>
    </Box>
  );
};

CombineCell.propTypes = {
  subText: PropTypes.string,
  row: PropTypes.object,
  original: PropTypes.object,
  accessor: PropTypes.array
};
CombineCell.displayName = "CombineCell";
export default CombineCell;
