import React from "react";
import {
  Flex,
  Grid,
  GridItem,
  VStack,
  HStack,
  Skeleton,
  Text,
  Button,
} from "@chakra-ui/react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout";
import {
  BackButtonHeader,
  BadgeStatusHeader,
  TextHeader,
} from "components/layout/header";
import Paper, { PaperHeader, PaperTitle } from "components/paper";
import {
  InputField,
  LinkField,
  SelectField,
  TextareaField,
} from "../../components/field";
import { useForm } from "react-hook-form";
import {
  useGetComplaintQuery,
  useResolveComplaintMutation,
} from "../../services/complaint.services";
import { useHistory, useParams } from "react-router-dom";
import { complaintDefaultValues } from "./constant";
import { messageSuccessUpdate } from "../../components/messages";
import { ConfirmDialog } from "components/alert";
import ModalRespondComplaint from "./components/ModalRespondComplaint";
import DialogHoldProperty from "./components/DialogHoldProperty";
import ModalDeleteProperty from "./components/ModalDeleteProperty";
import ModalForwardComplaint from "./components/ModalForwardComplaint";
import { useSelector } from "react-redux";

const ComplaintDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const { me } = useSelector((state) => state?.auth);
  const role = me?.role;

  const {
    data: complaint,
    error,
    isLoading,
    refetch,
  } = useGetComplaintQuery(id);

  const statusByRole =
    role === "admin"
      ? complaint?.data?.statusByAdmin
      : complaint?.data?.statusByPropertyOwner;

  const [resolveComplaint, { isLoading: isLoadingResolve }] =
    useResolveComplaintMutation();

  const { control, handleSubmit, reset, setError, clearErrors, setValue } =
    useForm({
      mode: "onChange",
      defaultValues: complaintDefaultValues,
    });

  React.useEffect(() => {
    if (complaint) {
      reset(complaint?.data);
    }
  }, [complaint, reset]);

  const handleResolve = async () => {
    await resolveComplaint(id).unwrap();
    refetch();
    history.push("/complaints");
    messageSuccessUpdate();
  };

  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <Flex alignItems="center">
            <BackButtonHeader route="/complaints" />
            <TextHeader text="Complaint Details" />
            <BadgeStatusHeader value={statusByRole} />
          </Flex>
        </LayoutHeaderLeft>
        {me?.role === "admin" && (
          <LayoutHeaderRight>
            <Skeleton isLoaded={!isLoading}>
              <ModalRespondComplaint complaint={complaint?.data} />
              <ModalForwardComplaint
                complaint={complaint?.data}
                refetch={refetch}
              />
              <ModalDeleteProperty complaint={complaint?.data} />
              <DialogHoldProperty complaint={complaint?.data} />
            </Skeleton>
          </LayoutHeaderRight>
        )}
      </LayoutHeader>
      <LayoutBody>
        <Skeleton isLoaded={!isLoading}>
          <Grid
            templateColumns={{ base: "repeat(3, 1fr)", xl: "repeat(6, 1fr)" }}
            gap={6}
          >
            <GridItem colSpan="4">
              <Paper>
                <VStack spacing="6">
                  <HStack w="full" spacing="6">
                    <InputField
                      name="complaint"
                      label="Complaint"
                      control={control}
                      isReadOnly
                    />
                    <InputField
                      name="complaintCode"
                      label="Complaint Code"
                      control={control}
                      isReadOnly
                    />
                  </HStack>

                  <TextareaField
                    name="complaintDetails"
                    label="Complaint Details"
                    control={control}
                    isReadOnly
                  />
                </VStack>
              </Paper>
              <Paper mt="6">
                <PaperHeader>
                  <PaperTitle>Client Details</PaperTitle>
                </PaperHeader>
                <VStack spacing="6">
                  <HStack w="full" spacing="6">
                    <InputField
                      name="clientName"
                      label="Name"
                      control={control}
                      isReadOnly
                    />
                    <InputField
                      name="clientEmail"
                      label="Email"
                      control={control}
                      isReadOnly
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <InputField
                      w={["full", "full", "calc(50% - 12px)"]}
                      name="clientPhoneNumber"
                      label="Phone Number"
                      control={control}
                      isReadOnly
                    />
                  </HStack>
                </VStack>
              </Paper>
              <Paper mt="6">
                <PaperHeader>
                  <PaperTitle>Property Details</PaperTitle>
                  <Text fontSize="sm" color="gray.500">
                    details of the property complained about
                  </Text>
                </PaperHeader>
                <VStack spacing="6">
                  <HStack w="full" spacing="6">
                    <InputField
                      name="propertyOwnerName"
                      label="Owner Name"
                      control={control}
                      isReadOnly
                    />
                    <InputField
                      name="propertyOwnerEmail"
                      label="Email"
                      control={control}
                      isReadOnly
                    />
                  </HStack>
                  <HStack w="full" spacing="6">
                    <InputField
                      w={["full", "full", "calc(50% - 12px)"]}
                      name="propertyOwnerPhoneNumber"
                      label="Phone Number"
                      control={control}
                      isReadOnly
                    />
                  </HStack>

                  <LinkField
                    type="input"
                    label="Property"
                    name="complaintProperty"
                    control={control}
                    baseURL="/property"
                  />
                </VStack>
              </Paper>
            </GridItem>

            {me?.role === "admin" && (
              <GridItem colSpan={{ base: 4, xl: 2 }}>
                <Paper>
                  <VStack spacing="2" align="start">
                    <Text fontWeight="bold">Resolve Complaint</Text>
                    <Text pb="4">
                      Once you resolve this <strong>Complaint</strong>, there is
                      no going back. Please be certain.
                    </Text>
                    <ConfirmDialog
                      headerText="Resolve Complaint"
                      bodyText="Are you sure you want resolve this complaint?"
                      buttonText="Resolve this complaint"
                      eventClick={handleResolve}
                      isLoading={isLoadingResolve}
                    />
                  </VStack>
                </Paper>
              </GridItem>
            )}

            {/*{me?.role !== "admin" && (*/}
            {/*  <GridItem colSpan={{ base: 4, xl: 2 }}>*/}
            {/*    <Paper>*/}
            {/*      <VStack spacing="2" align="start">*/}
            {/*        <Text fontWeight="bold">Need Action</Text>*/}
            {/*        <Text pb="4">*/}
            {/*          This property is currently on hold. Please take action*/}
            {/*          immediately.*/}
            {/*        </Text>*/}
            {/*        <Button size="sm">Edit Property</Button>*/}
            {/*      </VStack>*/}
            {/*    </Paper>*/}
            {/*  </GridItem>*/}
            {/*)}*/}
          </Grid>
        </Skeleton>
      </LayoutBody>
    </Layout>
  );
};

export default ComplaintDetails;
