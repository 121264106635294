import { Button, Stack, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DatePicker,
  InputField,
  PhoneField,
  TimeRangeField,
} from 'components/field';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAddInquiryMutation } from 'services/inquiries.services';
import { emailSchema, stringSchema } from 'utils/sharedSchema';
import * as yup from 'yup';

const schema = yup.object().shape({
  clientName: stringSchema,
  companyName: stringSchema,
  email: emailSchema,
  phoneNumber: stringSchema.required('Phone number is required'),
});

const ContactForm = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const { me } = useSelector((state) => state?.auth);
  const { displayName, email, companyName, phoneNumber } = me || [];

  const [addInquiry, { isLoading }] = useAddInquiryMutation();

  const defaultValues = {
    clientName: displayName || '',
    companyName: companyName || '',
    email: email || '',
    phoneNumber: phoneNumber || '',
    preferredVisitingDate: new Date(),
    preferredVisitingTime: [new Date(), new Date()],
    status: '',
  };

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { property } = useSelector((state) => state?.clientProperties);

  // to change the time-range based on selected date time.
  const handleDateChange = (value) => {
    setValue('preferredVisitingTime', [value, value], {
      shouldValidate: true,
    });
  };

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      responsible: property?.responsible,
      propertyAgent: property?.addedBy,
      inquiredProperty: {
        label: property?.title,
        value: property?.id,
      },
      status: 'new',
    };

    try {
      await addInquiry(payload).unwrap();

      reset();
      toast({
        title: 'Sent successfully',
        description:
          'Your inquiry has been sent successfully. Project 300 team will contact you via email or phone shortly.',
        position: 'top-right',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing="6">
        {!me && (
          <InputField
            name="clientName"
            label={t('common:label.name')}
            control={control}
            isRequired
          />
        )}

        {!me && (
          <InputField
            name="companyName"
            label={t('common:label.company')}
            control={control}
            isRequired
          />
        )}

        {!me && (
          <InputField
            name="email"
            type="email"
            label={t('common:label.email_address')}
            control={control}
            isRequired
          />
        )}

        {!me && (
          <PhoneField
            name="phoneNumber"
            label={t('common:label.phone_number')}
            isGeneral
            control={control}
            isRequired
          />
        )}

        <DatePicker
          name="preferredVisitingDate"
          label={t('common:label.preferred_visiting_date')}
          control={control}
          dateChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
        />
        <TimeRangeField
          name="preferredVisitingTime"
          label={t('common:label.preferred_visiting_time')}
          control={control}
        />
        <Button colorScheme="primary" type="submit" isLoading={isLoading}>
          {t('common:button.send')}
        </Button>
      </Stack>
    </form>
  );
};

export default ContactForm;
