import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Skeleton, SkeletonText } from "@chakra-ui/react";

const PropertyItemSkeleton = ({ total = 1 }) => {
  return (
    <Fragment>
      {Array(total)
        .fill()
        .map((item, index) => (
          <Box key={index}>
            <Skeleton height="200px" mb="4" borderRadius="md" />
            <Skeleton w="150px" height="20px" mb="2" />
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          </Box>
        ))}
    </Fragment>
  );
};

PropertyItemSkeleton.propTypes = {
  total: PropTypes.number
};

export default PropertyItemSkeleton;
