import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@chakra-ui/react";

const CardCoverSkeleton = ({ total = 1, height = "244px" }) => {
  return (
    <Fragment>
      {Array(total)
        .fill("")
        .map((item, index) => (
          <Skeleton key={index} height={height} borderRadius="md" />
        ))}
    </Fragment>
  );
};

CardCoverSkeleton.propTypes = {
  total: PropTypes.number,
  height: PropTypes.string
};

export default CardCoverSkeleton;
