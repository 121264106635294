import React, { Fragment, useEffect, useState } from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import {
  Badge,
  Button,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
  Flex,
} from "@chakra-ui/react";
import Paper from "../../components/paper";

const PropertyNotFound = () => {
  return (
    <Layout>
      <LayoutBody>
        <Flex
          direction="column"
          bg="white"
          p="12"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontSize="2xl" fontWeight="bold" my="2">
            No Property Found
          </Text>
          <Text color="grey.500">
            We can&apos;t find the property or the property has been deleted.
          </Text>
        </Flex>
      </LayoutBody>
    </Layout>
  );
};

export default PropertyNotFound;
