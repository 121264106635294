import ContractsPage from "../../pages/contract/contracts";
import AddNewContractPage from "../../pages/contract/addNewContract";
import ContractDetailPage from "../../pages/contract/details";

const contractRoutes = [
  { path: "/contracts", component: ContractsPage },
  { path: "/contract/new", component: AddNewContractPage },
  { path: "/contract/:id", component: ContractDetailPage }
];

export default contractRoutes;
