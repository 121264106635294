import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useModalComponent } from './ModalComponent';
import { useButtonTrigger, useIconButtonTrigger } from './ButtonTrigger';

export const useModalForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const api = {
    isOpen,
    onOpen,
    onClose,
  };

  const ModalForm = useModalComponent({ ...api });

  const ButtonTrigger = useButtonTrigger({ ...api });

  const IconButtonTrigger = useIconButtonTrigger({ ...api });

  return {
    ...api,
    ModalForm,
    ButtonTrigger,
    IconButtonTrigger,
  };
};
