import { Button } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextHeader } from "components/layout/header";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout";
import { messageSuccessSave } from "components/messages";
import ROLE from "config/role";
import { useConfirm } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createProperty } from "services/properties.service";
import nanoid from "utils/nanoid";
import PropertyForm from "../components/PropertyForm";
import { schema } from "../constant";

const prepareID = nanoid();

const AdvertisementNumber = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const { isConfirmed } = useConfirm();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(),
  });

  const { me } = useSelector((state) => state?.auth);

  const { loadingCreate } = useSelector((state) => state?.properties);

  useEffect(() => {
    if (me?.role === ROLE.ADMIN || me?.role === ROLE.WEBSITE_AGENT) {
      setValue("status", "published");

      // takeout, new property always admin as a responsible
      setValue("responsible", {
        label: me?.displayName,
        value: me?.uid,
      });
    } else {
      setValue("status", "draft");
    }

    setValue("addedBy", {
      label: me?.displayName,
      value: me?.uid,
      role: me?.role,
    });
  }, []);

  const onAction = async (values, action) => {
    const confirmed = await isConfirmed();
    if (!confirmed) return;

    const payload = {
      id: prepareID,
      ...values,
    };

    if (action === "submitForReview") {
      payload.status = "new";
      setLoadingReview(true);
    } else if (action === "saveDraft") {
      payload.status = "draft";
      setLoadingDraft(true);
    }

    const res = await dispatch(createProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/properties");
      messageSuccessSave();
      setLoadingDraft(false);
      setLoadingReview(false);
    } else {
      setLoadingDraft(false);
      setLoadingReview(false);
    }
  };

  const onSave = async (values) => {
    const confirmed = await isConfirmed();
    if (!confirmed) return;

    const payload = {
      id: prepareID,
      ...values,
    };

    const res = await dispatch(createProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/properties");
      messageSuccessSave();
    }
  };

  // TODO: WEBSITE AGENT CANT ACCESS THIS, CREATE COMPONENT DONT HAVE PERMISSIONS
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Add New Property" />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          {[
            ROLE.ADMIN,
            ROLE.PROPERTY_AGENT,
            ROLE.PROPERTY_OWNER,
            ROLE.PROPERTY_BROKER,
          ].includes(me?.role) && (
            <Button
              colorScheme="gray"
              onClick={handleSubmit((values) => onAction(values, "saveDraft"))}
              isLoading={loadingDraft}
            >
              Save Draft
            </Button>
          )}
          {[
            ROLE.PROPERTY_AGENT,
            ROLE.PROPERTY_OWNER,
            ROLE.PROPERTY_BROKER,
          ].includes(me?.role) && (
            <Fragment>
              <Button
                onClick={handleSubmit((values) =>
                  onAction(values, "submitForReview")
                )}
                colorScheme="primary"
                isLoading={loadingReview}
              >
                Submit for Review
              </Button>
            </Fragment>
          )}

          {me?.role === ROLE.ADMIN && (
            <Button
              onClick={handleSubmit(onSave)}
              colorScheme="primary"
              isLoading={loadingCreate}
            >
              Add New Property
            </Button>
          )}
        </LayoutHeaderRight>
      </LayoutHeader>

      <LayoutBody>
        <PropertyForm
          control={control}
          setValue={setValue}
          prepareID={prepareID}
        />
      </LayoutBody>
    </Layout>
  );
};

export default AdvertisementNumber;
