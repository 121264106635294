import { axiosInstance } from "./fetchApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchError } from "../utils/catchError";

export const register = createAsyncThunk(
  "auth/register",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/auth/signup", payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(catchError(err));
    }
  },
);

export const sessionLogin = createAsyncThunk(
  "auth/session-login",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/auth/session-login");
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(catchError(err));
    }
  },
);

export const me = createAsyncThunk("auth/me", async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get("/auth/me");
    return response.data.data;
  } catch (err) {
    return thunkApi.rejectWithValue(catchError(err));
  }
});

export const forgot = createAsyncThunk(
  "auth/forgot",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/auth/forgot", payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(catchError(err));
    }
  },
);

export const verify = createAsyncThunk(
  "auth/verify",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/auth/verify", payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(catchError(err));
    }
  },
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get("/auth/updatePassword");
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(catchError(err));
    }
  },
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (payload, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/auth/updateProfile", payload);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(catchError(err));
    }
  },
);
