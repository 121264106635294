import { createSlice } from "@reduxjs/toolkit";

import {
  fetchContracts,
  fetchContractById,
  createContract,
  updateContract,
  deleteContract,
  uploadContract
} from "../services/contracts.service";

const initialState = {
  contracts: [],
  contract: null,
  meta: {
    nextOffset: 0,
    prevOffset: 0,
    hasNext: false,
    hasPrev: false,
    totalRecords: 0
  },
  loading: false,
  loadingSingle: false,
  loadingCreate: false,
  loadingDelete: false,
  loadingUpdate: false,
  loadingUpload: false,
  error: null
};

const contractsSlice = createSlice({
  name: "contracts",
  initialState,
  extraReducers: builder => {
    // FETCH
    builder.addCase(fetchContracts.pending, (state) => {
      state.loading = true;
    })

    builder.addCase(fetchContracts.fulfilled, (state, action) => {
      state.loading = false;
      state.meta = action.payload.meta;
      state.contracts = action.payload.data;
    })

    builder.addCase(fetchContracts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // FETCH BY ID
    builder.addCase(fetchContractById.pending, (state) => {
      state.loadingSingle = true;
    })

    builder.addCase(fetchContractById.fulfilled, (state, action) => {
      state.loadingSingle = false;
      state.contract = action.payload.data;
    })

    builder.addCase(fetchContractById.rejected, (state, action) => {
      state.loadingSingle = false;
      state.error = action.payload;
    })

    // CREATE
    builder.addCase(createContract.pending, (state) => {
      state.loadingCreate = true;
    })

    builder.addCase(createContract.fulfilled, (state) => {
      state.loadingCreate = false;
    })

    builder.addCase(createContract.rejected, (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    })

    // UPDATE
    builder.addCase(updateContract.pending, (state) => {
      state.loadingUpdate = true;
    })

    builder.addCase(updateContract.fulfilled, (state) => {
      state.loadingUpdate = false;
    })

    builder.addCase(updateContract.rejected, (state, action) => {
      state.loadingUpdate = false;
      state.error = action.payload;
    })

    // DELETE
    builder.addCase(deleteContract.pending, state => {
      state.loadingDelete = true;
    })
    
    builder.addCase(deleteContract.fulfilled, state => {
      state.loadingDelete = false;
    })
    
    builder.addCase(deleteContract.rejected, (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
    })

    // UPLOAD
    builder.addCase(uploadContract.pending, state => {
      state.loadingUpload = true;
    })
    
    builder.addCase(uploadContract.fulfilled, state => {
      state.loadingUpload = false;
    })
    
    builder.addCase(uploadContract.rejected, (state, action) => {
      state.loadingUpload = false;
      state.error = action.payload;
    })
  }
});

export default contractsSlice.reducer;
