import { api, deleteMutation } from "../api";

const endpoint = "/v1/countries";
const endpointV2 = "/v2/countries";

export const countriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    autocompleteCountries: build.query({
      query: (payload) => ({
        url: `${endpoint}/autocomplete`,
        method: "get",
        params: {
          ...payload,
        },
      }),
    }),

    getCountries: build.query({
      query: (payload) => ({
        url: `${endpoint}`,
        method: "GET",
        params: {
          pageSize: 20,
          ...payload,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (currentCache.data && arg?.nextCursor) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    getCountry: build.query({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "GET",
      }),
    }),

    addCountry: build.mutation({
      query: (data) => ({
        url: `${endpoint}`,
        method: "POST",
        data: data,
      }),
      async onQueryStarted(newData, { dispatch, queryFulfilled }) {
        try {
          const { data: createdData } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData("getCountries", undefined, (draft) => {
              draft.data.unshift(createdData.data);
            }),
          );
        } catch {}
      },
    }),

    updateCountry: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}`,
        method: "PATCH",
        data: patch,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData("getCountries", undefined, (draft) => {
              let index = draft.data.findIndex((item) => item.id === args.id);
              draft.data[index] = updatedData.data;
            }),
          );
        } catch {}
      },
    }),

    deleteCountry: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getCountries", id, (draft) =>
            deleteMutation(id, draft),
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useLazyAutocompleteCountriesQuery,
  useGetCountriesQuery,
  useGetCountryQuery,
  useAddCountryMutation,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
} = countriesApi;
