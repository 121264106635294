import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Text } from "@chakra-ui/react";

const CardCover = ({ imgCover = "", text = "", onClick = () => {} }) => {
  return (
    <Box
      onClick={onClick}
      position="relative"
      height="244px"
      bgImg={`url(${imgCover})`}
      bgSize="cover"
      cursor="pointer"
      borderRadius="8px"
      _before={{
        content: '""',
        bgColor: "rgba(0,0,0,0.3)",
        position: "absolute",
        left: 0,
        top: 0,
        w: "100%",
        h: "100%",
        transition: "250ms ease-in-out",
        borderRadius: "8px"
      }}
      _hover={{
        _before: {
          bgColor: "rgba(0,0,0,0.1)"
        }
      }}
    >
      <Text
        textTransform="capitalize"
        fontSize="xl"
        fontWeight="semibold"
        color="white"
        zIndex="1"
        pos="absolute"
        left="50%"
        top="50%"
        transform="translate(-50%, -50%)"
      >
        {text}
      </Text>
    </Box>
  );
};

CardCover.propTypes = {
  text: PropTypes.string,
  imgCover: PropTypes.string,
  onClick: PropTypes.func
};

export default memo(CardCover);
