import React, { useEffect, useState } from "react";
import { Button, useToast, VStack } from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { InputField, InputNumberField, SelectField } from "components/field";
import { registerRoleOptions, ROLE } from "config/role";
import * as yup from "yup";
import { numberSchema, stringSchema } from "../../../utils/sharedSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../services/auth.service";

const schema = yup.object().shape({
  role: stringSchema,
  identificationNumber: numberSchema,
  advertiserID: yup.string().when("role", {
    is: values => values !== "personal",
    then: yup.string().required("Field is required")
  })
});

const AccountTypeForm = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { me } = useSelector(state => state?.auth);

  // const excludeActiveAccountType = registerRoleOptions.filter((item) => {
  //   return item.value !== me?.role
  // })

  const { control, handleSubmit, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      role: me?.role,
      identificationNumber: me?.identificationNumber,
      advertiserID: me?.advertiserID
    }
  });

  const watcherRule = useWatch({
    control,
    name: "role",
    defaultValue: me?.role
  });

  const onSubmit = async values => {
    const payload = {
      ...values
    };

    if (watcherRule === "personal") {
      delete payload.advertiserID;
    }

    setLoading(true);
    const res = await dispatch(updateProfile(payload));
    if (res.meta.requestStatus === "fulfilled") {
      toast({
        title: "Account Type Updated",
        description: "Account Type updated successfully.",
        position: "top-right",
        status: "success",
        duration: 4000,
        isClosable: true
      });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing="6" align="start">
        <SelectField
          label="Choose Account Type"
          name="role"
          control={control}
          options={registerRoleOptions}
        />

        {watcherRule !== ROLE.SERVICE_OFFICE_PROVIDER && (
          <InputNumberField
            name="identificationNumber"
            label="Identification Number"
            control={control}
            isRequired
          />
        )}

        {(watcherRule !== ROLE.PERSONAL && watcherRule !== ROLE.SERVICE_OFFICE_PROVIDER) && (
          <InputField
            name="advertiserID"
            label="Advertiser ID"
            control={control}
            isRequired
          />
        )}

        {watcherRule !== ROLE.SERVICE_OFFICE_PROVIDER && (
          <Button
            type="submit"
            width="fit-content"
            colorScheme="primary"
            size="sm"
            isLoading={loading}
          >
            Save Changes
          </Button>
        )}
      </VStack>
    </form>
  );
};

export default AccountTypeForm;
