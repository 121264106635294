import React, { memo } from "react";
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { messageSuccessUpdate } from "../../../components/messages";
import { useAcceptTourRequestMutation } from "services/tour-request.services";

const ModalAccept = ({ refetch, tourRequest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();

  // service
  const [acceptTourRequest, { isLoading }] = useAcceptTourRequestMutation();

  const isAccepted = tourRequest?.data?.statusByAgent === "accepted";

  const onAcceptTourRequest = async () => {
    try {
      const res = await acceptTourRequest({ id }).unwrap();
      messageSuccessUpdate({
        title: "Success",
        description: res.message,
      });

      refetch();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Button onClick={onOpen} size="sm" isDisabled={isAccepted}>
        Accept
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Accept Tour Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to accept this tour request?</Text>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="primary"
              onClick={onAcceptTourRequest}
              isLoading={isLoading}
              size="sm"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(ModalAccept);
