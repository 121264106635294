import React, { Suspense } from "react";

// UPGRADE TO REACT 18
import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);

import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./redux/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { BrowserRouter as Router } from "react-router-dom";

// import { createStandaloneToast } from "@chakra-ui/toast";
// const { ToastContainer } = createStandaloneToast();

import App from "./App";
import theme from "./config/theme";

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <ChakraProvider theme={theme}>
        <Suspense fallback={<div></div>}>
          <Router>
            <App />
            {/*<ToastContainer />*/}
          </Router>
        </Suspense>
      </ChakraProvider>
    </PersistGate>
  </Provider>,
);
