import React, { useEffect, useMemo } from "react";
import { PopOutFiled } from "components/field";
import { useForm } from "react-hook-form";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const SortBy = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { control, handleSubmit, reset } = useForm();
  const placementResponsive = useBreakpointValue(
    {
      base: "bottom-start",
      lg: "bottom-end",
    },
    { ssr: false },
  );

  const options = useMemo(
    () => [
      {
        label: `${t("common:label.latest_space")}`,
        ar_label: `${t("common:label.latest_space")}`,
        value: "latest",
      },
      {
        label: `${t("common:label.oldest_space")}`,
        ar_label: `${t("common:label.oldest_space")}`,
        value: "oldest",
      },
      {
        label: `${t("common:label.lowest_price")}`,
        ar_label: `${t("common:label.lowest_price")}`,
        value: "lowest_price",
      },
      {
        label: `${t("common:label.highest_price")}`,
        ar_label: `${t("common:label.highest_price")}`,
        value: "highest_price",
      },
    ],
    [t],
  );

  useEffect(() => {
    const tempSort = query.sort_by ? query.sort_by : "";
    const indexOption = options.findIndex((item) => item.value === tempSort);

    const valueFormQuery = {
      sort_by: indexOption === -1 ? options[0] : options[indexOption],
    };

    // set value filter from query when pathname change
    reset(valueFormQuery);
  }, [options, query.sort_by, reset]);

  // useEffect(() => {
  //   const tempSort = query.sort_by ? query.sort_by : "";
  //   const indexOption = options.findIndex((item) => item.value === tempSort);
  //
  //   const valueFormQuery = {
  //     sort_by: indexOption === -1 ? options[0] : options[indexOption],
  //   };
  //
  //   // set value filter from query when pathname change
  //   reset(valueFormQuery);
  // }, [location.pathname, location.search]);

  const onSubmit = async (values) => {
    const params = {
      ...query,
    };

    if (values.sort_by?.value) {
      params.sort_by = values.sort_by.value;
    }

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(params),
    });
  };

  return (
    <Box minW="170px">
      <PopOutFiled
        label={t("common:label.sort_by")}
        placeholder={t("common:placeholder.search")}
        name="sort_by"
        placement={placementResponsive}
        control={control}
        options={options}
        callback={handleSubmit(onSubmit)}
      />
    </Box>
  );
};

export default SortBy;
