import React from "react";
import PropTypes from "prop-types";
import { Tag, Center, Text } from "@chakra-ui/react";

const TypeCell = value => {
  return (
    <Center>
      <Tag bg="primary.500" color="white" textTransform="capitalize">
        <Text noOfLines={1}>{value}</Text>
      </Tag>
    </Center>
  );
};

TypeCell.propTypes = {
  value: PropTypes.string
};

export default TypeCell;
