import React from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "components/layout";
import { Grid, GridItem, Heading } from "@chakra-ui/react";
import { TextHeader } from "components/layout/header";
import Paper from "components/paper";
import RegionForm from "./components/RegionForm";
import RegionsTable from "./components/RegionsTable";
import PaperTable from "../../../components/paper-table";

const RegionsPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Regions" />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <Grid templateColumns={["repeat(12, 1fr)"]} gap={6}>
          <GridItem colSpan={[12, 12, 12, 12, 4]}>
            <Paper>
              <Heading fontSize="lg" mb="8">
                Add New Region
              </Heading>
              <RegionForm />
            </Paper>
          </GridItem>
          <GridItem colSpan={[12, 12, 12, 12, 8]}>
            <PaperTable>
              <RegionsTable />
            </PaperTable>
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};

export default RegionsPage;
