import React, { useEffect } from "react";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Result from "../../components/Result";
import {
  DatePicker,
  InputField,
  NumberField,
  PhoneField,
  SelectField,
  TextareaField,
} from "../../components/field";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "../../components/layout";
import {
  BackButtonHeader,
  BadgeStatusHeader,
  TextHeader,
} from "../../components/layout/header";
import Paper from "../../components/paper";
import {
  useCompleteSpecialRequestMutation,
  useGetSpecialRequestQuery,
  useProcessSpecialRequestMutation,
} from "../../services/specialRequest.services";
import { periodOptions } from "../../utils/constant";
import { schema } from "./constant";
import DialogHoldReopenRequest from "./components/DialogHoldReopenRequest";
import DialogForwardRequest from "./components/DialogForwardRequest";
import DialogRespondRequest from "./components/DialogRespondRequest";

const statusOptions = [
  { label: "New", value: "new" },
  { label: "Processed", value: "processed" },
];

const SpecialRequestDetailsPage = () => {
  const history = useHistory();
  const toast = useToast();
  const { id } = useParams();
  const { me } = useSelector((state) => state?.auth);

  const role = me?.role;

  const formatArea = (val) => val + ` Sq m`;
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    data: specialRequest,
    error,
    isLoading,
    refetch,
  } = useGetSpecialRequestQuery(id);

  const [completeSpecialRequest, { isLoading: completeLoading }] =
    useCompleteSpecialRequestMutation();

  const [processSpecialRequest, { isLoading: processLoading }] =
    useProcessSpecialRequestMutation();

  const statusByRole =
    role === "admin"
      ? specialRequest?.data?.statusByAdmin
      : specialRequest?.data?.statusByAgent;

  // INITIATE DATA
  useEffect(() => {
    if (specialRequest) {
      const defaultValues = {
        ...specialRequest?.data,
        preferredVisitingDate: new Date(
          specialRequest?.data.preferredVisitingDate
        ),
      };
      reset(defaultValues);
    }
  }, [specialRequest, reset]);

  const handleProcess = async () => {
    await processSpecialRequest(id).unwrap();

    toast({
      title: "Data Updated",
      description: "processed special request successfully.",
      position: "top-right",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    refetch();
  };

  const handleComplete = async () => {
    await completeSpecialRequest(id).unwrap();

    toast({
      title: "Data Updated",
      description: "completed special request successfully.",
      position: "top-right",
      status: "success",
      duration: 4000,
      isClosable: true,
    });

    refetch();
  };

  return (
    <Layout>
      <Result isFound={!!specialRequest} isLoaded={!isLoading}>
        <LayoutHeader>
          <LayoutHeaderLeft>
            <Flex alignItems="center">
              <BackButtonHeader route="/special-request" />
              <TextHeader text="Special Request Details" />
              <BadgeStatusHeader value={statusByRole} />
            </Flex>
          </LayoutHeaderLeft>
          <LayoutHeaderRight>
            {me?.role === "admin" && (
              <>
                <DialogRespondRequest data={specialRequest?.data} />
                <DialogForwardRequest
                  data={specialRequest?.data}
                  refetch={refetch}
                />
                <DialogHoldReopenRequest
                  data={specialRequest?.data}
                  refetch={refetch}
                />
              </>
            )}
          </LayoutHeaderRight>
        </LayoutHeader>

        <LayoutBody>
          <Grid
            templateColumns={{ base: "repeat(3, 1fr)", lg: "repeat(6, 1fr)" }}
            gap={6}
          >
            <GridItem colSpan="4">
              <Paper>
                <VStack spacing="6">
                  <InputField
                    name="generatedID"
                    label="Request ID"
                    control={control}
                    isReadOnly
                  />
                  <HStack w="full" spacing="6">
                    <InputField
                      name="clientName"
                      label="Client Name"
                      control={control}
                      isReadOnly
                    />
                    <InputField
                      name="companyName"
                      label="Company Name"
                      control={control}
                      isReadOnly
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <InputField
                      name="email"
                      type="email"
                      label="Email"
                      control={control}
                      isReadOnly
                    />
                    <PhoneField
                      name="phoneNumber"
                      label="Phone Number"
                      isGeneral
                      control={control}
                      isReadOnly
                    />
                  </HStack>

                  <TextareaField
                    name="note"
                    label="Notes from client"
                    control={control}
                    isReadOnly
                  />

                  <HStack w="full" spacing="6">
                    <NumberField
                      name="spaceArea"
                      label="Space Area"
                      masking={formatArea}
                      control={control}
                      isReadOnly
                    />
                    <InputField
                      name="preferredLocation"
                      label="Preferred Location"
                      control={control}
                      isReadOnly
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <SelectField
                      name="rentalPeriod"
                      label="Rental Period"
                      options={periodOptions}
                      control={control}
                      isDisabled
                    />
                    <DatePicker
                      name="preferredVisitingDate"
                      label="When do you want to get the spaces?"
                      control={control}
                      isReadOnly
                    />
                  </HStack>

                  <HStack w="full" spacing="6">
                    <InputField
                      name="numberOfPeople"
                      label="Number of People"
                      control={control}
                      isReadOnly
                    />
                    <InputField
                      name="numberOfOffices"
                      label="Number of Offices"
                      control={control}
                      isReadOnly
                    />
                  </HStack>
                </VStack>
              </Paper>
            </GridItem>
            <GridItem colSpan={{ base: 4, lg: 2 }}>
              {statusByRole !== "processed" && statusByRole !== "completed" && (
                <Paper mb="6">
                  <VStack spacing="2" align="start">
                    <Text fontWeight="bold">Process Request</Text>
                    <Text pb="4">
                      This will change the status of the request to processed.
                    </Text>

                    <Button
                      isDisabled={statusByRole === "hold"}
                      isLoading={processLoading}
                      onClick={handleProcess}
                      size="sm"
                      colorScheme="primary"
                    >
                      Process
                    </Button>
                  </VStack>
                </Paper>
              )}

              {(statusByRole === "processed" ||
                statusByRole === "completed") && (
                <Paper mb="6">
                  <VStack spacing="2" align="start">
                    <Text fontWeight="bold">Complete Request</Text>
                    <Text pb="4">
                      This will change the status of the request to completed.
                    </Text>

                    <Button
                      isDisabled={statusByRole === "completed"}
                      isLoading={completeLoading}
                      onClick={handleComplete}
                      size="sm"
                      colorScheme="green"
                    >
                      Complete
                    </Button>
                  </VStack>
                </Paper>
              )}
            </GridItem>
          </Grid>
        </LayoutBody>
      </Result>
    </Layout>
  );
};

export default SpecialRequestDetailsPage;
