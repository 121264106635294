import React from 'react';
import PropTypes from 'prop-types';
import CityForm from './CityForm';
import { FaPen } from 'react-icons/fa';
import { useModalForm } from 'components/hooks/useModalForm';

const PopupEdit = ({ data }) => {
  const { ModalForm, IconButtonTrigger, onClose } = useModalForm();

  return (
    <>
      <IconButtonTrigger icon={<FaPen />} />
      <ModalForm title="Edit City">
        <CityForm data={data} onClose={onClose} />
      </ModalForm>
    </>
  );
};

PopupEdit.propTypes = {
  data: PropTypes.object,
};
export default PopupEdit;
