import {
  Box,
  Grid,
  GridItem,
  Skeleton,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import ImageSlider from "components/client/ImageSlider";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { get } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-scroll";
import { fetchClientPropertyById } from "services/client/properties.services";
import Layout, { LayoutBody } from "components/layout/client";

// SECTION
import ContactUsSection from "./components/ContactUsSection";
import TagSection from "./components/TagSection";
import DetailSection from "./components/DetailSection";
import PriceSection from "./components/PriceSection";
import ActionButtonSection from "./components/ActionButtonSection";
import ImageSliderSkeleton from "./components/ImageSliderSkeleton";

export const PropertyDetailsPage = () => {
  const { t, i18n } = useTranslation();
  const ARABIC = i18n.language === "ar";
  const { id } = useParams();
  const dispatch = useDispatch();

  const { property, loadingSingle } = useSelector(
    (state) => state?.clientProperties,
  );

  useEffect(() => {
    // window.scrollTo(0, 0);
    dispatch(fetchClientPropertyById(id));
  }, [dispatch, id]);

  return (
    <Layout>
      <LayoutBody>
        <Box>
          {loadingSingle ? (
            <Skeleton w="300px" h="30px" mb="2" />
          ) : (
            <Text
              mb={["0", "2"]}
              fontSize="3xl"
              fontWeight="semibold"
              textTransform="capitalize"
            >
              {get(property, "title", "-")}
            </Text>
          )}

          <Stack direction={{ base: "column", lg: "row" }}>
            <Stack direction={["column", "row"]}>
              {loadingSingle ? (
                <Skeleton w="100px" />
              ) : (
                <Text
                  fontWeight={["normal", "semibold"]}
                  fontSize={["md", "lg"]}
                  color="gray.700"
                  textTransform="capitalize"
                >
                  {ARABIC
                    ? get(property, "type.ar_label", "-")
                    : get(property, "type.label", "-")}
                </Text>
              )}

              <Text color="gray.700" display={["none", "block"]}>
                ·
              </Text>

              {loadingSingle ? (
                <Skeleton w="300px" />
              ) : (
                <Text
                  pt="0"
                  fontSize={["md", "lg"]}
                  as={Link}
                  to="pin-map-location"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  color="gray.700"
                  textDecoration="underline"
                  cursor="pointer"
                  textTransform="capitalize"
                >
                  {`${property?.country.label}, ${property?.region.label}, ${property?.city.label}`}
                </Text>
              )}
            </Stack>
            <Spacer />

            {/* ACTION BUTTON FAVORITE, COMPARE */}
            <ActionButtonSection />
          </Stack>
        </Box>

        <Grid
          templateColumns={["repeat(12, 1fr)"]}
          gap={[0, 0, 12, 12]}
          mt={[4, 6, 12]}
        >
          <GridItem colSpan={[12, 12, 12, 8]}>
            <Box mb="12" position="relative">
              {loadingSingle ? (
                <ImageSliderSkeleton />
              ) : (
                <ImageSlider slidesData={property?.propertyGallery} />
              )}
            </Box>

            {/* PRICE SECTION */}
            <PriceSection />

            {/* DESCRIPTION, FEATURE, LOCATION, REQUEST TOUR SECTION, RAISE COMPLAINT */}
            <DetailSection />
          </GridItem>

          <GridItem colSpan={[12, 12, 12, 4]} mt={["12", "0"]}>
            {/* CONTACT US SECTION */}
            <ContactUsSection />

            {/* TAG SECTION */}
            {property?.tags && <TagSection property={property} />}
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};
