import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const PaperTable = ({ children, ...props }) => (
  <Box
    bg="white"
    borderRadius="md"
    boxShadow="sm"
    border="1px solid"
    borderColor="gray.300"
    {...props}
  >
    <Box>{children}</Box>
  </Box>
);

PaperTable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PaperTable;
