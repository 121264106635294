import { api, deleteMutation } from "./api";
import isEqual from "lodash/isEqual";

const endpoint = "/v1/complaint";

export const complaintApi = api.injectEndpoints({
  tagTypes: ["Complaint"],
  endpoints: (build) => ({
    getComplaints: build.query({
      query: (payload) => ({
        url: `${endpoint}`,
        method: "GET",
        params: {
          ...payload,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (currentCache.data && arg?.nextCursor) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        // return currentArg !== previousArg;
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ["Complaint"],
    }),

    getComplaint: build.query({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Complaint"],
    }),

    addComplaint: build.mutation({
      query: (payload) => ({
        url: `${endpoint}`,
        method: "POST",
        data: payload,
      }),
    }),

    updateComplaint: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}`,
        method: "PATCH",
        data: patch,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData("getComplaints", undefined, (draft) => {
              let index = draft.data.findIndex((item) => item.id === args.id);
              draft.data[index] = updatedData.data;
            }),
          );
        } catch {}
      },
    }),

    holdComplaintProperty: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}/hold-property`,
        method: "PATCH",
      }),
      invalidatesTags: ["Complaint"],
    }),

    deleteComplaintProperty: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}/delete-property`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: ["Complaint"],
    }),

    resolveComplaint: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}/resolve`,
        method: "PATCH",
      }),
      invalidatesTags: ["Complaint"],
    }),

    respondComplaint: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}/respond`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: ["Complaint"],
    }),

    forwardComplaint: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}/forward`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: ["Complaint"],
    }),

    deleteComplaint: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getComplaints", id, (draft) =>
            deleteMutation(id, draft),
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useGetComplaintsQuery,
  useGetComplaintQuery,
  useAddComplaintMutation,
  useUpdateComplaintMutation,
  useHoldComplaintPropertyMutation,
  useDeleteComplaintPropertyMutation,
  useResolveComplaintMutation,
  useRespondComplaintMutation,
  useForwardComplaintMutation,
  useDeleteComplaintMutation,
} = complaintApi;
