import React, { Fragment, useEffect } from 'react';
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from 'components/layout';
import { TextHeader, BackButtonHeader } from 'components/layout/header';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Grid,
  GridItem,
  SimpleGrid,
  Text,
  VStack,
  Flex,
} from '@chakra-ui/react';
import Paper from '../../components/paper';
import {
  DatePicker,
  InputField,
  LinkField,
  PhoneField,
  TextareaField,
  TextOnly,
  TimeRangeField,
} from '../../components/field';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  useGetTourRequestQuery,
  useApproveTourRequestMutation,
  useRejectTourRequestMutation,
} from '../../services/tour-request.services';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { stringSchema } from '../../utils/sharedSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { messageSuccessUpdate } from '../../components/messages';
import ModalDelegate from './components/ModalDelegate';
import ModalTransfer from './components/ModalTransfer';
import ModalRejection from './components/ModalRejection';
import ModalAccept from './components/ModalAccept';
import ROLE from '../../config/role';
import Result from '../../components/Result';

const schema = yup.object().shape({
  status: stringSchema,
});

const schemaForAgent = yup.object().shape({
  statusByAgent: stringSchema,
});

const TourRequestDetailsPage = () => {
  const { id } = useParams();
  const { me } = useSelector((state) => state?.auth);

  // Service
  const {
    data: tourRequest,
    error,
    isLoading,
    refetch,
  } = useGetTourRequestQuery(id);

  const [approveTourRequest, { isLoading: isLoadingApprove }] =
    useApproveTourRequestMutation();

  const [rejectTourRequest, { isLoading: isLoadingReject }] =
    useRejectTourRequestMutation();

  const { control, reset } = useForm({
    resolver: yupResolver(
      me?.role === ROLE.PROPERTY_AGENT ? schemaForAgent : schema
    ),
  });

  // /**
  //  * Handles the change of the preferred visiting date.
  //  * Updates the preferred visiting time to match the selected date.
  //  *
  //  * @param {Date} value - The selected date.
  //  */
  // const handleDateChange = (value) => {
  //   setValue('preferredVisitingTime', [value, value], {
  //     shouldValidate: true,
  //   });
  // };

  useEffect(() => {
    if (tourRequest) {
      const defaultValues = {
        ...tourRequest?.data,
        responsible: tourRequest?.data?.responsible?.label, // get the label of the agent in charge
        agentInCharge: tourRequest?.data?.agentInCharge?.label, // get the label of the agent in charge
        preferredVisitingDate: new Date(
          tourRequest?.data.preferredVisitingDate
        ),
      };
      reset(defaultValues);
    }
  }, [tourRequest, reset]);

  const onApproveTourRequest = async () => {
    try {
      const payload = {
        id,
        agentInCharge: {
          label: tourRequest?.data.propertyAgent.label,
          value: tourRequest?.data.propertyAgent.value,
        },
        responsible: {
          label: me?.displayName,
          value: me?.uid,
        },
      };

      const res = await approveTourRequest(payload).unwrap();

      messageSuccessUpdate({
        title: 'Success',
        description: res.message,
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const onRejectTourRequest = async () => {
    try {
      const payload = {
        id,
        responsible: {
          label: me?.displayName,
          value: me?.uid,
        },
      };

      const res = await rejectTourRequest(payload).unwrap();
      messageSuccessUpdate({
        title: 'Success',
        description: res.message,
      });
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusByRole = (role) => {
    if (role === ROLE.ADMIN) {
      return tourRequest?.data.statusByAdmin;
    } else if (role === ROLE.WEBSITE_AGENT) {
      return tourRequest?.data.statusByWebsiteAgent;
    } else {
      return tourRequest?.data.statusByAgent;
    }
  };

  const ButtonApprove = () => (
    <Button
      onClick={onApproveTourRequest}
      isLoading={isLoadingApprove}
      size="sm"
    >
      Approve
    </Button>
  );

  const ButtonReject = () => (
    <Button onClick={onRejectTourRequest} isLoading={isLoadingReject} size="sm">
      Reject
    </Button>
  );

  const renderActionsButton = (role) => {
    if (role === ROLE.ADMIN) {
      return (
        <Fragment>
          <ButtonApprove />
          <ButtonReject />
          <ModalDelegate refetch={refetch} />
          <ModalTransfer refetch={refetch} />
        </Fragment>
      );
    } else if (role === ROLE.WEBSITE_AGENT) {
      return (
        <Fragment>
          <ButtonApprove />
          <ButtonReject />
          <ModalTransfer refetch={refetch} />
        </Fragment>
      );
    } else if (role === ROLE.PROPERTY_AGENT) {
      if (getStatusByRole(ROLE.PROPERTY_AGENT) === 'rejected') {
        return (
          <Text fontWeight="bold" color="red.500">
            You Rejected this tour request.
          </Text>
        );
      } else if (getStatusByRole(ROLE.PROPERTY_AGENT) === 'accepted') {
        return (
          <Text fontWeight="bold" color="green.500">
            You Accepted this tour request.
          </Text>
        );
      } else {
        return (
          <Fragment>
            <ModalAccept refetch={refetch} tourRequest={tourRequest} />
            <ModalRejection refetch={refetch} tourRequest={tourRequest} />
          </Fragment>
        );
      }
    }
  };

  return (
    <Layout>
      <Result isFound={!!tourRequest} isLoaded={!isLoading}>
        <LayoutHeader>
          <LayoutHeaderLeft>
            <Flex alignItems="center">
              <BackButtonHeader route="/tour-request" />
              <TextHeader text="Tour Request Details" />
            </Flex>
          </LayoutHeaderLeft>
          <LayoutHeaderRight>
            <Fragment>{renderActionsButton(me?.role)}</Fragment>
          </LayoutHeaderRight>
        </LayoutHeader>
        <LayoutBody>
          {[ROLE.ADMIN, ROLE.WEBSITE_AGENT].includes(me?.role) &&
            tourRequest?.data.statusByAgent === 'new' && (
              <Alert status="warning" mb="8">
                <AlertIcon />
                <Box flex="1">
                  <AlertDescription display="block">
                    Waiting response from property agent.
                  </AlertDescription>
                </Box>
              </Alert>
            )}

          {[ROLE.ADMIN, ROLE.WEBSITE_AGENT].includes(me?.role) &&
            tourRequest?.data.statusByAgent === 'rejected' && (
              <Alert status="error" mb="8">
                <AlertIcon />
                <Box flex="1">
                  <AlertTitle>Tour request rejected by agent!</AlertTitle>
                  <AlertDescription display="block">
                    {me?.role === ROLE.ADMIN
                      ? 'you can Transfer to another property agent.'
                      : 'Tour request is being re-processed by the admin.'}
                  </AlertDescription>
                </Box>
              </Alert>
            )}

          <Grid
            templateColumns={{ base: 'repeat(3, 1fr)', lg: 'repeat(6, 1fr)' }}
            gap={6}
          >
            <GridItem colSpan="4">
              <Paper>
                <VStack spacing="6">
                  <InputField
                    name="generatedID"
                    label="Request ID"
                    control={control}
                    isReadOnly
                    textTransform="uppercase"
                  />
                  <SimpleGrid columns={[1, 1, 1, 1, 2]} spacing={6} w="full">
                    <InputField
                      name="clientName"
                      label="Client Name"
                      control={control}
                      isReadOnly
                    />
                    <InputField
                      name="companyName"
                      label="Company Name"
                      control={control}
                      isReadOnly
                    />
                  </SimpleGrid>

                  <SimpleGrid columns={[1, 1, 1, 1, 2]} spacing={6} w="full">
                    <InputField
                      name="email"
                      type="email"
                      label="Email"
                      control={control}
                      isReadOnly
                    />
                    <PhoneField
                      name="phoneNumber"
                      label="Phone Number"
                      isGeneral
                      control={control}
                      isReadOnly
                    />
                  </SimpleGrid>

                  <SimpleGrid columns={[1, 1, 1, 1, 2, 2]} spacing={6} w="full">
                    <DatePicker
                      name="preferredVisitingDate"
                      label="Preferred Visiting Date"
                      control={control}
                      dateFormat="MMMM d, yyyy"
                      isReadOnly
                    />

                    <TimeRangeField
                      label="Preferred Visiting Time"
                      name="preferredVisitingTime"
                      control={control}
                      isReadOnly
                    />
                  </SimpleGrid>

                  <TextareaField
                    name="specialRequest"
                    label="Special Request"
                    control={control}
                    isReadOnly
                  />

                  <SimpleGrid columns={[1, 1, 1, 1, 2]} spacing={6} w="full">
                    <LinkField
                      label="Inquired Property"
                      name="inquiredProperty"
                      control={control}
                      baseURL="/property"
                    />
                    <LinkField
                      label="Property Agent"
                      name="propertyAgent"
                      control={control}
                      baseURL="/v/agent"
                    />
                  </SimpleGrid>
                </VStack>
              </Paper>
            </GridItem>
            <GridItem colSpan={{ base: 4, lg: 2 }}>
              {me?.role !== ROLE.PROPERTY_AGENT && (
                <Paper mb="4">
                  <InputField
                    label="Status"
                    name="status"
                    textTransform="capitalize"
                    isReadOnly
                    control={control}
                  />
                </Paper>
              )}

              {me?.role !== ROLE.PROPERTY_AGENT && (
                <Paper mb="4">
                  <VStack spacing="6">
                    <Fragment>
                      <InputField
                        label="Responsible"
                        name="responsible"
                        helperText={
                          'please approve or delegate to assign responsible agent.'
                        }
                        isReadOnly
                        control={control}
                      />
                    </Fragment>
                  </VStack>
                </Paper>
              )}

              <Paper>
                <VStack spacing="6">
                  <InputField
                    label="Agent In Charge"
                    name="agentInCharge"
                    helperText={
                      'please approve or transfer to assign agent in charge.'
                    }
                    isReadOnly
                    control={control}
                  />
                  <InputField
                    label="Status by the Agent"
                    name="statusByAgent"
                    isReadOnly
                    textTransform="capitalize"
                    control={control}
                  />
                  {tourRequest?.data.statusByAgent === 'rejected' && (
                    <TextOnly
                      label="Rejection Reason"
                      value={tourRequest?.data.rejectionReason}
                    />
                  )}
                </VStack>
              </Paper>
            </GridItem>
          </Grid>
        </LayoutBody>
      </Result>
    </Layout>
  );
};

export default TourRequestDetailsPage;
