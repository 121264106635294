import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { RtlProvider } from "../RtlProvider";
import NavigationFooterMobile from "../footer/NavigationFooterMobile";

const Layout = ({ children }) => {
  const device = useBreakpointValue(
    {
      base: "mobile",
      lg: "desktop",
    },
    { ssr: false },
  );

  return (
    <RtlProvider>
      <Box
        backgroundSize="cover"
        backgroundImage={process.env.PUBLIC_URL + "/images/bg_auth_page.jpg"}
      >
        <Box
          bg="blackAlpha.700"
          backdropFilter="auto"
          minH="100vh"
          py="12"
          px={{
            base: "4",
            lg: "8",
          }}
        >
          {children}
        </Box>
      </Box>
      {device === "mobile" && <NavigationFooterMobile />}
    </RtlProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default memo(Layout);
