import React, { useLayoutEffect } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtl from "stylis-plugin-rtl";
import "i18n/index";
import { useTranslation } from "react-i18next";

// NB: A unique `key` is important for it to work!
const options = {
  rtl: { key: "css-ar", stylisPlugins: [rtl] },
  ltr: { key: "css-en" },
};

// eslint-disable-next-line react/prop-types
export function RtlProvider({ children, direction }) {
  const { i18n } = useTranslation();
  const dir = direction || i18n.dir();
  const cache = createCache(options[dir]);

  useLayoutEffect(() => {
    document.body.dir = dir;
    if (dir === "rtl") {
      document.body.style.overflowX = "hidden";
    } else {
      document.body.style.overflowX = "auto";
    }
  }, [dir]);

  return <CacheProvider value={cache}>{children}</CacheProvider>;
}
