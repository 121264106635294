import { Translation } from 'react-i18next';

export const complainOptions = [
  {
    label: (
      <Translation ns={'complaint'}>
        {(t) => t('complaint.options.fakeAdvertisement')}
      </Translation>
    ),
    value: 'fake advertisement',
  },
  {
    label: (
      <Translation ns={'complaint'}>
        {(t) => t('complaint.options.informationNotCorrect')}
      </Translation>
    ),
    value: 'the information is not correct',
  },
  {
    label: (
      <Translation ns={'complaint'}>
        {(t) => t('complaint.options.spam')}
      </Translation>
    ),
    value: 'spam',
  },
  {
    label: (
      <Translation ns={'complaint'}>
        {(t) => t('complaint.options.others')}
      </Translation>
    ),
    value: 'others',
  },
];
