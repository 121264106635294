import {
  Box,
  Button,
  Center,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link as ReachLink, useHistory } from "react-router-dom";
import { useDeviceDetected } from "hooks";

const MenuWrapper = ({ children }) => {
  const { isShrunk } = useSelector((state) => state?.layout);
  const { isMobile } = useDeviceDetected();

  return (
    <Box
      bg={isShrunk ? "white" : "transparent"}
      w="full"
      position="fixed"
      zIndex="docked"
      transition="100ms"
    >
      <Stack
        boxShadow={isMobile ? "none" : isShrunk ? "md" : "none"}
        py={isShrunk ? "0" : "4"}
        transition="250ms ease-in-out"
      >
        <Center>
          <Stack
            direction={{ base: "column", lg: "row" }}
            alignItems="center"
            mt={{ base: 8, lg: 0 }}
            spacing={{ base: 4, lg: 12 }}
          >
            {children}
          </Stack>
        </Center>
      </Stack>
    </Box>
  );
};

const MenuWrapperItem = ({
  text,
  href,
  children,
  linkColor,
  onClick,
  haveSubMenu,
}) => (
  <Box cursor="pointer" py="2" px="2" fontSize="sm" onClick={onClick}>
    {text ? (
      <Link
        to={href}
        as={ReachLink}
        textTransform="uppercase"
        fontWeight="bold"
        fontSize="15px"
        color={linkColor}
      >
        {text}
        {haveSubMenu && <Icon ml="3" as={FaChevronDown} />}
      </Link>
    ) : (
      children
    )}
  </Box>
);

const generateMenu = (item, linkColor, history, language) => {
  if (item.sub_menu.length !== 0) {
    return (
      <Menu key={item.name}>
        <MenuButton>
          <MenuWrapperItem
            haveSubMenu
            text={language === "ar" ? item.ar_name : item.name}
            linkColor={linkColor}
            href="#"
          />
        </MenuButton>
        <MenuList>
          {item.sub_menu.map((itemSubMenu) => (
            <MenuItem
              key={itemSubMenu.name}
              onClick={() => history.push(itemSubMenu.contentUrl)}
            >
              <MenuWrapperItem
                key={itemSubMenu.name}
                text={
                  language === "ar" ? itemSubMenu.ar_name : itemSubMenu.name
                }
                href="#"
              />
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  } else {
    return (
      <MenuWrapperItem
        key={item.name}
        text={language === "ar" ? item.ar_name : item.name}
        href={item.contentUrl}
        linkColor={linkColor}
      />
    );
  }
};

const DesktopMenu = ({ menuItems }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const history = useHistory();
  const { navbarLinkColor } = useSelector((state) => state?.layout);
  const linkColor = navbarLinkColor;

  return (
    <MenuWrapper>
      <MenuWrapperItem
        text={t("common:menu.home")}
        href="/"
        linkColor={linkColor}
      />
      {menuItems.map((item) =>
        generateMenu(item, linkColor, history, language),
      )}
      <MenuWrapperItem linkColor={linkColor}>
        <Button
          onClick={() => history.push("/c/special-spaces")}
          size="sm"
          bg="red.600"
          color="white"
          _hover={{
            bg: "red.700",
          }}
        >
          {t("common:menu.special_spaces")}
        </Button>
      </MenuWrapperItem>
    </MenuWrapper>
  );
};

DesktopMenu.propTypes = {
  menuItems: PropTypes.array,
};

MenuWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

MenuWrapperItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  text: PropTypes.string,
  href: PropTypes.string,
  linkColor: PropTypes.string,
  onClick: PropTypes.func,
  haveSubMenu: PropTypes.bool,
};

export default memo(DesktopMenu);
