import React from "react";
import PropTypes from "prop-types";
import { Tag, Center, Text } from "@chakra-ui/react";

const DescriptionCell = value => {
  return (
    <Text align="center" noOfLines={3}>
      {value}
    </Text>
  );
};

DescriptionCell.propTypes = {
  value: PropTypes.string
};

export default DescriptionCell;
