export function invalidateOn({ success = [], error = [] }) {
  return (result) => (result ? success : error);
}
export function deleteMutation(id, draft) {
  const newData = draft.data.filter((item) => item.id !== id);
  const isLastDocDeleted = draft.meta.nextCursor === id;

  return {
    ...draft,
    data: newData,
    meta: {
      nextCursor:
        isLastDocDeleted && newData.length > 0
          ? newData[newData.length - 1].id
          : draft.meta.nextCursor,
    },
  };
}
