import React from "react";
import PropTypes from "prop-types";
import { IconWithFallback } from "components/base";

const IconCell = ({ value }) => {
  return <IconWithFallback src={value} />;
};

IconCell.propTypes = {
  value: PropTypes.string,
};

export default IconCell;
