import { useMemo } from "react";
import { parse } from "query-string";
import { useLocation } from "react-router-dom";

const useQuerySearch = () => {
  const location = useLocation();

  return useMemo(() => {
    return parse(location.search);
  }, [location.search]);
};

export default useQuerySearch;
