import * as yup from 'yup';
import { autoCompleteSchema, stringSchema } from '../../utils/sharedSchema';

export const defaultValues = {
  clientName: '',
  companyName: '',
  phoneNumber: '',
  email: '',
  preferredVisitingDate: new Date(),
  preferredVisitingTime: [new Date(), new Date()],
  specialRequest: '',
  status: '',
};

export const schemaAssign = yup.object().shape({
  assign: autoCompleteSchema.default(null),
});

export const schemaTransfer = yup.object().shape({
  agentInCharge: autoCompleteSchema.default(null),
});

export const schemaDelegated = yup.object().shape({
  websiteAgent: autoCompleteSchema.default(null),
});

export const schemaRejection = yup.object().shape({
  rejectionReason: stringSchema.default(''),
});

export const statusOptions = [
  { label: 'New', value: 'new' },
  { label: 'Approved', value: 'approved' },
  { label: 'Rejected', value: 'rejected' },
];
