import { api } from "./api";
import { savePersistedMenu } from "../redux/menuSlice";

const menuApi = api.injectEndpoints({
  tagTypes: ["menu"],
  endpoints: (build) => ({
    getMenu: build.query({
      query: (payload) => ({
        url: `/menu`,
        method: "get",
        params: {
          offset: payload?.offset || 0,
          limit: payload?.limit || 6,
        },
      }),
      providesTags: ["menu"],
    }),

    getMenuRaw: build.query({
      query: () => ({
        url: `/menu/menu`,
        method: "get",
      }),
      onQueryStarted: async (arg, api) => {
        const { dispatch, queryFulfilled } = api;
        const { data } = await queryFulfilled;
        dispatch(savePersistedMenu(data));
      },
      providesTags: ["menu"],
    }),

    autocompleteMenu: build.query({
      query: (payload) => ({
        url: `/menu/autocomplete`,
        method: "get",
        params: {
          search: payload?.search || "",
        },
      }),
      providesTags: ["menu"],
    }),

    addMenu: build.mutation({
      query: (newAccountType) => ({
        url: `/menu`,
        method: "POST",
        data: newAccountType,
      }),
      invalidatesTags: ["menu"],
    }),

    updateMenu: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/menu/${id}`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: ["menu"],
    }),

    deleteMenu: build.mutation({
      query: (id) => ({
        url: `/menu/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["menu"],
    }),

    deleteSubMenu: build.mutation({
      query: ({ parentID, submenuID }) => ({
        url: `/menu/${parentID}/submenu/${submenuID}`,
        method: "DELETE",
      }),
      invalidatesTags: ["menu"],
    }),
  }),
});

export const {
  useGetMenuQuery,
  useGetMenuRawQuery,
  useAutocompleteMenuQuery,
  useAddMenuMutation,
  useUpdateMenuMutation,
  useDeleteMenuMutation,
  useDeleteSubMenuMutation,
} = menuApi;
