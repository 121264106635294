import {
  Avatar,
  Box,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Divider,
} from '@chakra-ui/react';
import ar from 'date-fns/locale/ar-SA';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useState } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { Trans, useTranslation } from 'react-i18next';
import { AiOutlineSearch } from 'react-icons/ai';
import { CgMenuRight } from 'react-icons/cg';
import { MdLanguage } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { setLayout } from '../../redux/layoutSlice';
import { logout } from '../../redux/authSlice';
import { CompareIcon, FavoriteIcon } from '../icon';
import HeaderSearch from './HeaderSearch';
import { useUpdateLanguageMutation } from 'services/agent.services';
import { debounce } from 'lodash';
import { useDeviceDetected } from '../../hooks';
import HeaderInfo from './HeaderInfo';
import { CiMenuKebab } from 'react-icons/ci';

const Header = ({ toggleMobileMenu }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const history = useHistory();

  const { me } = useSelector((state) => state?.auth);
  const [updateLanguage] = useUpdateLanguageMutation();

  const handleLogout = () => {
    try {
      dispatch(logout());
      history.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  const debounceUpdateLanguage = debounce(async (lang) => {
    try {
      await updateLanguage({ language: lang });
    } catch {}
  }, 2000);

  const changeLanguage = (lang) => {
    if (language === lang) return;

    i18n.changeLanguage(lang);

    // only logged user will update lang to database
    if (me) {
      debounceUpdateLanguage(lang);
    }
  };

  const { pathname } = history.location;
  const { isShrunk, bgHeader } = useSelector((state) => state?.layout);
  const [searchVisible, setSearchVisible] = useState(false);

  const { isMobile } = useDeviceDetected();

  // set locale to datepicker when language is changes
  useEffect(() => {
    if (language === 'ar') {
      setDefaultLocale(ar);
    }
  }, [language]);

  const handler = useMemo(() => {
    return function () {
      const objLayout = {
        isShrunk: true,
        bgHeader: 'brand.dark',
        navbarLinkColor: 'black',
      };

      if (pathname === '/') {
        if (
          !isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          objLayout.isShrunk = true;
          objLayout.bgHeader = 'brand.dark';
          objLayout.navbarLinkColor = 'black';
          return dispatch(setLayout({ ...objLayout }));
        }

        if (
          isShrunk &&
          document.body.scrollTop < 4 &&
          document.documentElement.scrollTop < 4
        ) {
          objLayout.isShrunk = false;
          objLayout.bgHeader = 'transparent';
          objLayout.navbarLinkColor = 'white';
          return dispatch(setLayout({ ...objLayout }));
        }
      } else {
        if (
          isShrunk &&
          (document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20)
        ) {
          objLayout.isShrunk = true;
          objLayout.bgHeader = 'brand.dark';
          objLayout.navbarLinkColor = 'black';
          return dispatch(setLayout({ ...objLayout }));
        }

        if (
          !isShrunk &&
          document.body.scrollTop < 20 &&
          document.documentElement.scrollTop < 20
        ) {
          objLayout.isShrunk = true;
          objLayout.bgHeader = 'brand.dark';
          objLayout.navbarLinkColor = 'black';
          return dispatch(setLayout({ ...objLayout }));
        }
      }
    };
  }, [dispatch, isShrunk, pathname]);

  useEffect(() => {
    handler();
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, [handler]);

  return (
    <Box
      as="header"
      bg={bgHeader}
      transition="100ms"
      borderColor="gray.700"
      position="fixed"
      height={isMobile ? '65px' : '75px'}
      w="full"
      zIndex="sticky"
    >
      <Box
        px={{ base: '4', md: '6', lg: '12' }}
        py="2"
        transition="250ms ease-in-out"
        maxW="container.2xl"
      >
        <Box
          as="nav"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack>
            <Image
              display={isMobile ? (searchVisible ? 'none' : 'block') : 'block'}
              cursor="pointer"
              onClick={() => history.push('/')}
              src={process.env.PUBLIC_URL + '/images/logo.png'}
              ignoreFallback
              w={isMobile ? '160px' : '185px'}
              transition="250ms ease-in-out"
            />
          </HStack>
          <HeaderSearch
            isMobile={isMobile}
            searchVisible={searchVisible}
            toggleVisible={() => setSearchVisible(!searchVisible)}
          />

          {/* BOX RIGHT */}
          <Box display={{ base: searchVisible ? 'none' : 'flex', lg: 'flex' }}>
            <HStack spacing="6">
              <HStack spacing="2">
                {!isMobile && (
                  <>
                    <IconButton
                      aria-label="Favorite"
                      icon={
                        <FavoriteIcon w={6} h={6} fillColor="transparent" />
                      }
                      onClick={() => history.push('/c/my-favorites')}
                      bg="transparent"
                      _hover={{
                        bg: 'transparent',
                      }}
                    />
                    <IconButton
                      aria-label="Compare"
                      icon={<CompareIcon w={7} h={7} fillColor="transparent" />}
                      onClick={() => history.push('/c/compare-properties')}
                      bg="transparent"
                      _hover={{
                        bg: 'transparent',
                      }}
                    />
                    {/*  SEARCH NEED FIXING  */}
                    <IconButton
                      aria-label="Search"
                      display={{ base: 'block', lg: 'none' }}
                      icon={
                        <Icon
                          as={AiOutlineSearch}
                          color="white"
                          viewBox="0 0 32 32"
                          boxSize="1.5em"
                        />
                      }
                      onClick={() => setSearchVisible(!searchVisible)}
                      bg="transparent"
                      _hover={{
                        bg: 'transparent',
                      }}
                    />
                  </>
                )}
              </HStack>

              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  variant="ghost"
                  color="white"
                  fontSize="24px"
                  icon={<MdLanguage />}
                  _hover={{ bg: 'transparent' }}
                  _active={{ bg: 'transparent' }}
                />
                <MenuList>
                  <MenuItem onClick={() => changeLanguage('ar')}>
                    {t('common:lang.arabic')}
                  </MenuItem>
                  <MenuItem onClick={() => changeLanguage('en')}>
                    {t('common:lang.english')}
                  </MenuItem>
                </MenuList>
              </Menu>

              <IconButton
                aria-label="Mobile-menu"
                icon={<Icon as={CgMenuRight} w={7} h={7} color="white" />}
                onClick={toggleMobileMenu}
                display={{ base: searchVisible ? 'none' : 'block', lg: 'none' }}
                ml="2"
                bg="transparent"
                _hover={{
                  bg: 'transparent',
                }}
              />

              <HStack display={{ base: 'none', lg: 'flex' }}>
                {!me?.email ? (
                  <>
                    <Link as={RouterLink} to="/auth/login" color="white">
                      <Trans i18nKey="common:menu.agent_login">
                        Agent Login
                      </Trans>
                    </Link>
                    <Text color="white">/</Text>
                    <Link as={RouterLink} to="/auth/register" color="white">
                      <Trans i18nKey="common:menu.register">Register</Trans>
                    </Link>
                  </>
                ) : (
                  <Menu>
                    <MenuButton
                      as={Box}
                      bg="gray.100"
                      p="1"
                      border="1px"
                      borderRadius="full"
                      borderColor="gray.300"
                      cursor="pointer"
                      _hover={{ boxShadow: 'md' }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        maxW={{
                          base: '100px',
                          md: '150px',
                          xl: '180px',
                          '2xl': '400px',
                        }}
                      >
                        <Avatar h="32px" w="32px" ml="2" src={me.imageURL} />
                        <Text mx="2" noOfLines={1}>
                          Hi, {me?.displayName}
                        </Text>
                        <Icon
                          as={CiMenuKebab}
                          fontSize="20px"
                          mx="1"
                          color="gray.500"
                        />
                      </Box>
                    </MenuButton>
                    <MenuList
                      zIndex="dropdown"
                      rootProps={{ style: { right: 0 } }}
                      width="270px"
                    >
                      <HeaderInfo />
                      <MenuItem
                        fontWeight="semibold"
                        onClick={() => history.push('/dashboard')}
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        fontWeight="semibold"
                        onClick={() => history.push('/account')}
                      >
                        My Account
                      </MenuItem>
                      <Divider />
                      <MenuItem fontWeight="semibold" onClick={handleLogout}>
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </HStack>
            </HStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Header.propTypes = {
  toggleMobileMenu: PropTypes.func,
};

export default memo(Header);
