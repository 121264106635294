import React, { memo } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { AutocompleteAgentsByRole } from "components/shared";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaTransfer } from "../constant";
import { useParams } from "react-router-dom";
import { useTransferTourRequestMutation } from "services/tour-request.services";
import { messageSuccessUpdate } from "components/messages";

const defaultValues = {
  agentInCharge: null,
};

const ModalTransfer = ({ refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaTransfer),
    defaultValues,
  });

  const { me } = useSelector((state) => state?.auth);

  // service
  const [transferTourRequest, { isLoading }] = useTransferTourRequestMutation();

  const onSubmit = async (values) => {
    const payload = {
      id,
      agentInCharge: {
        label: values.agentInCharge.label,
        value: values.agentInCharge.value,
      },
      responsible: {
        label: me?.displayName,
        value: me?.uid,
      },
    };

    try {
      await transferTourRequest(payload).unwrap();
      messageSuccessUpdate({
        title: "Success",
        description: "Tour request has been transferred",
      });
      reset(defaultValues);
      onClose();
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Transfer
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transfer Tour Request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AutocompleteAgentsByRole
              name="agentInCharge"
              label="Property Agent"
              placeholder="Select Property Agent"
              control={control}
              role="property_agent,service_office_provider"
              isRequired
              isClearable
            />
            <Text mt="4" color="gray.500" fontSize="sm">
              Note:
            </Text>
            <UnorderedList mt="2" fontSize="sm" color="gray.500">
              <ListItem>
                Submitted tour request will be sent to the selected Property
                Agent to process it. And you will be responsible for this tour
              </ListItem>
              <ListItem>
                When you change the agent, previous agent can&apos;t access this
                tour request anymore.
              </ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default memo(ModalTransfer);
