import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  persistedTypes: [],
};

const typesPropertySlice = createSlice({
  name: "typesProperty",
  initialState,
  reducers: {
    savePersisted: (state, action) => {
      state.persistedTypes = action.payload?.data;
    },
  },
});

export const { savePersisted } = typesPropertySlice.actions;
export default typesPropertySlice.reducer;
