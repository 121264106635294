import React, { useRef } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { useController } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";

const DatePicker = ({
  name,
  control,
  label,
  isRequired = false,
  isDisabled = false,
  isReadOnly = false,
  isClearable = true,
  dateChange = () => {},
  disabledPastDate = true,
  ...props
}) => {
  const inputRef = useRef();

  const {
    field: { ref, onChange, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error }
  } = useController({
    name,
    control,
    defaultValue: new Date(),
    rules: { required: isRequired }
  });

  const handleChange = value => {
    onChange(value);
    dateChange(value);
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <ReactDatePicker
        name={name}
        ref={inputRef}
        onChange={handleChange}
        selected={value}
        showPopperArrow={false}
        onKeyDown={e => e.preventDefault()}
        autoComplete="off"
        disabled={isDisabled}
        readOnly={isReadOnly}
        minDate={disabledPastDate ? new Date() : null}
        wrapperClassName={isDisabled ? "wrapper-disable" : ""}
        {...inputProps}
        {...props}
      />
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string,
  acceptedFileTypes: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isClearable: PropTypes.bool,
  disabledPastDate: PropTypes.bool,
  dateChange: PropTypes.func
};

export default DatePicker;
