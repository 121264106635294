import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa6";

const ConfirmDialog = ({
  buttonText = "Confirm",
  buttonIcon,
  iconOnly,
  buttonSize,
  headerText,
  bodyText,
  eventClick = () => {},
  isDisabled,
  isLoading = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const preset = {
    colorScheme: "green",
    size: buttonSize || "sm",
    onClick: onOpen,
  };

  const defaultIcon = buttonIcon ? buttonIcon : <FaCheck />;

  const internalEventClick = () => {
    eventClick();
    onClose();
  };

  return (
    <>
      {iconOnly ? (
        <IconButton
          aria-label="delete"
          icon={defaultIcon}
          {...preset}
          isDisabled={isDisabled}
        />
      ) : (
        <Button leftIcon={defaultIcon} {...preset} isDisabled={isDisabled}>
          {buttonText}
        </Button>
      )}

      <AlertDialog
        motionPreset="slideInBottom"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg">{headerText}</AlertDialogHeader>

          <AlertDialogBody>{bodyText}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={() => onClose()} size="sm">
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="red"
              ml={3}
              onClick={() => internalEventClick()}
              size="sm"
            >
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

ConfirmDialog.propTypes = {
  buttonText: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonIcon: PropTypes.node,
  iconOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  eventClick: PropTypes.func,
};

export default ConfirmDialog;
