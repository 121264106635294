import { createSlice } from "@reduxjs/toolkit";

import {
  fetchFeature,
  fetchAutocompleteFeature,
  createFeature,
  deleteFeature,
  updateFeature,
  uploadIcon
} from "../services/featuresProperty.service";

const initialState = {
  features: [],
  meta: {
    nextOffset: 0,
    prevOffset: 0,
    hasNext: false,
    hasPrev: false,
    totalRecords: 0
  },
  autocomplete: [],
  autocompleteMeta: {
    nextOffset: 0,
    prevOffset: 0,
    hasNext: false,
    hasPrev: false,
    totalRecords: 0
  },
  loading: false,
  loadingAutocomplete: false,
  loadingCreate: false,
  loadingDelete: false,
  loadingUpdate: false,
  loadingUpload: false,
  error: null
};

const featuresPropertySlice = createSlice({
  name: "featuresProperty",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // FETCH AUTOCOMPLETE
    builder.addCase(fetchAutocompleteFeature.pending, (state) => {
      state.loadingAutocomplete = true;
    })

    builder.addCase(fetchAutocompleteFeature.fulfilled, (state, action) => {
      state.loadingAutocomplete = false;
      state.autocompleteMeta = action.payload.meta;
      state.autocomplete = action.payload.data;
    })

    builder.addCase(fetchAutocompleteFeature.rejected, (state, action) => {
      state.loadingAutocomplete = false;
      state.error = action.payload;
    })

    // FETCH
    builder.addCase(fetchFeature.pending, (state) => {
      state.loading = true;
    })

    builder.addCase(fetchFeature.fulfilled, (state, action) => {
      state.loading = false;
      state.meta = action.payload.meta;
      state.features = action.payload.data;
    })

    builder.addCase(fetchFeature.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // CREATE
    builder.addCase(createFeature.pending, (state) => {
      state.loadingCreate = true;
    })

    builder.addCase(createFeature.fulfilled, (state, action) => {
      state.loadingCreate = false;
      state.meta.totalRecords = state.meta.totalRecords + 1;
      state.features = [action.payload.data, ...state.features];
    })

    builder.addCase(createFeature.rejected, (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    })

    // DELETE
    builder.addCase(deleteFeature.pending, (state) => {
      state.loadingDelete = true;
    })

    builder.addCase(deleteFeature.fulfilled, (state) => {
      state.loadingDelete = false;
    })

    builder.addCase(deleteFeature.rejected, (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
    })

    // UPDATE
    builder.addCase(updateFeature.pending, (state) => {
      state.loadingUpdate = true;
    })

    builder.addCase(updateFeature.fulfilled, (state) => {
      state.loadingUpdate = false;
    })

    builder.addCase(updateFeature.rejected, (state, action) => {
      state.loadingUpdate = false;
      state.error = action.payload;
    })

    // UPLOAD
    builder.addCase(uploadIcon.pending, state => {
      state.loadingUpload = true;
    })

    builder.addCase(uploadIcon.fulfilled, state => {
      state.loadingUpload = false;
    })

    builder.addCase(uploadIcon.rejected, (state, action) => {
      state.loadingUpload = false;
      state.error = action.payload;
    })
  }
});

export default featuresPropertySlice.reducer;
