import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import { FIELD_VARIANT } from "../../config/preset";
import { useController } from "react-hook-form";

const TextareaField = ({
  name,
  placeholder,
  control,
  label,
  isRequired = false,
  ...props
}) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: { required: isRequired },
  });

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Textarea
        ref={ref}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        variant={FIELD_VARIANT.default}
        {...inputProps}
        {...props}
      />
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

TextareaField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default TextareaField;
