import React from "react";
import PropTypes from "prop-types";
import { Button, VStack } from "@chakra-ui/react";
import { AutoComplete, InputField } from "components/field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { autoCompleteSchema, stringSchema } from "utils/sharedSchema";
import { useLazyAutocompleteRegionsQuery } from "services/location/regions.services";
import {
  useAddCityMutation,
  useUpdateCityMutation,
} from "services/location/cities.services";

const schema = yup.object().shape({
  name: stringSchema,
  ar_name: stringSchema,
  region: autoCompleteSchema,
});

const CityForm = ({ data, onClose }) => {
  // ADD
  const [addCity, { isLoading: isLoadingAdd }] = useAddCityMutation();

  //UPDATE
  const [updateCity, { isLoading: isLoadingUpdate }] = useUpdateCityMutation();

  // AUTOCOMPLETE
  const [
    getAutocompleteRegions,
    { data: regions, isLoading: isLoadingAutocomplete },
  ] = useLazyAutocompleteRegionsQuery();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.name || "",
      ar_name: data?.ar_name || "",
      region: data?.region || null,
    },
  });

  const handlingUpdateData = async (values) => {
    const payload = {
      ...values,
      id: data.id,
    };

    try {
      await updateCity({ ...payload }).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handlingAddData = async (values) => {
    if (values.data) delete values.data;

    try {
      await addCity({ ...values }).unwrap();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values) => {
    // EDIT DATA
    if (data) {
      await handlingUpdateData(values);
      return;
    }

    // ADD DATA
    await handlingAddData(values);
  };

  const handleInputChange = (inputValue) => {
    getAutocompleteRegions({
      search: inputValue,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing="6" alignItems="flex-start">
        <InputField
          name="name"
          label="City Name"
          control={control}
          isRequired
        />
        <InputField
          isRTL
          name="ar_name"
          label="Arabic City Name"
          control={control}
          isRequired
        />
        <AutoComplete
          name="region"
          label="Region"
          placeholder="Type Region"
          control={control}
          options={regions ? regions.data : []}
          eventInputChange={handleInputChange}
          isLoading={isLoadingAutocomplete}
          isRequired
        />
        <Button
          type="submit"
          colorScheme="primary"
          isLoading={data ? isLoadingUpdate : isLoadingAdd}
        >
          {data ? "Update" : "Add New"}
        </Button>
      </VStack>
    </form>
  );
};

CityForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default CityForm;
