import React from "react";
import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";

const UpperTextCell = ({ value }) => {
  return <Text textTransform="uppercase">{value}</Text>;
};

UpperTextCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
};

export default UpperTextCell;
