import React from "react";
import PropTypes from "prop-types";
import { AutoComplete } from "../field";
import { useLazyAutocompleteCountriesQuery } from "services/location/countries.services";

const AutocompleteCountries = ({
  control,
  name = "country",
  label = "Country",
  ...props
}) => {
  // COUNTRIES
  const [
    getAutocompleteCountries,
    { data: countries, isLoading: isLoadingCountries },
  ] = useLazyAutocompleteCountriesQuery();

  const handleAutocomplete = (inputValue) => {
    getAutocompleteCountries({ search: inputValue });
  };

  return (
    <AutoComplete
      name={name}
      label={label}
      control={control}
      options={countries ? countries.data : []}
      eventInputChange={handleAutocomplete}
      isLoading={isLoadingCountries}
      {...props}
    />
  );
};

AutocompleteCountries.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default AutocompleteCountries;
