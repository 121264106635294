import React from "react";
import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";

const FreeTextCell = ({ value }) => {
  return <Text>{value}</Text>;
};

FreeTextCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object
};

export default FreeTextCell;
