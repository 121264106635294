import React, { memo } from "react";
import { Box, Button, Container, Stack } from "@chakra-ui/react";
import { PopOutFiled, PriceSlider } from "components/field";
import { useWatch, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useLazyAutocompleteCountriesQuery } from "services/location/countries.services";
import { useLazyAutocompleteRegionsQuery } from "services/location/regions.services";
import { useLazyAutocompleteCitiesQuery } from "services/location/cities.services";
import { useLazyAutocompleteTypesQuery } from "services/typesProperty.services";

import "rc-slider/assets/index.css";

const HomeFilter = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { control, handleSubmit, setValue } = useForm();

  // COUNTRIES
  const [
    getAutocompleteCountries,
    { data: countries, isLoading: isLoadingCountries },
  ] = useLazyAutocompleteCountriesQuery();

  // REGIONS
  const [
    getAutocompleteRegions,
    { data: regions, isLoading: isLoadingRegions },
  ] = useLazyAutocompleteRegionsQuery();

  // CITIES
  const [getAutocompleteCities, { data: cities, isLoading: isLoadingCities }] =
    useLazyAutocompleteCitiesQuery();

  // TYPES
  const [getAutocompleteTypes, { data: types, isLoading: isLoadingTypes }] =
    useLazyAutocompleteTypesQuery();

  // WATCHER
  const basedOnCountry = useWatch({
    control,
    name: "country",
    defaultValue: null,
  });

  const basedOnRegion = useWatch({
    control,
    name: "region",
    defaultValue: null,
  });

  // HANDLE AUTOCOMPLETE
  const handleAutocompleteCountry = (inputValue) => {
    getAutocompleteCountries({
      search: inputValue,
      lang: i18n.language,
    });
  };

  const handleAutocompleteRegion = (inputValue) => {
    getAutocompleteRegions({
      search: inputValue,
      lang: i18n.language,
      based: basedOnCountry?.value,
    });
  };

  const handleAutocompleteCity = (inputValue) => {
    getAutocompleteCities({
      search: inputValue,
      lang: i18n.language,
      based: basedOnRegion?.value,
    });
  };

  const handleAutocompleteType = (inputValue) => {
    getAutocompleteTypes({
      search: inputValue,
      lang: i18n.language,
    });
  };

  // CALLBACK
  const handleCallbackCountry = (selected) => {
    setValue("region", null);
    setValue("city", null);

    // re request when based data is changed
    getAutocompleteRegions({
      based: selected?.value,
    });
  };

  const handleCallbackRegion = (selected) => {
    setValue("city", null);

    // re request when based data is changed
    getAutocompleteCities({
      based: selected?.value,
    });
  };

  const onSubmit = async (values) => {
    const params = {
      ...query,
      price_min: values.price[0],
    };

    if (values.country?.label) {
      params.country = values.country.label;
    }

    if (values.region?.label) {
      params.region = values.region.label;
    }

    if (values.city?.label) {
      params.city = values.city.label;
    }

    if (values.type?.label) {
      params.property_type = values.type.label;
    }

    if (values.price[1] !== 1000) {
      params.price_max = values.price[1];
    }

    history.push({
      pathname: "/c/properties",
      search: queryString.stringify(params),
    });
  };

  return (
    <Container mt="-7.5rem" maxW="container.xl" position="relative" zIndex="1">
      <Box bg="white" borderRadius="md" px="4" pt="6" boxShadow="md">
        <Stack spacing="6" mb="4" direction={{ base: "column", lg: "row" }}>
          <PopOutFiled
            label={t("common:label.country")}
            placeholder={t("common:placeholder.search")}
            name="country"
            control={control}
            eventInputChange={handleAutocompleteCountry}
            options={countries ? countries.data : []}
            isLoading={isLoadingCountries}
            callback={handleCallbackCountry}
          />
          <PopOutFiled
            label={t("common:label.region")}
            placeholder={t("common:placeholder.search")}
            name="region"
            control={control}
            eventInputChange={handleAutocompleteRegion}
            options={regions ? regions.data : []}
            isLoading={isLoadingRegions}
            callback={handleCallbackRegion}
          />
          <PopOutFiled
            label={t("common:label.city")}
            placeholder={t("common:placeholder.search")}
            name="city"
            control={control}
            eventInputChange={handleAutocompleteCity}
            options={cities ? cities.data : []}
            isLoading={isLoadingCities}
          />
          <PopOutFiled
            label={t("common:label.property_type")}
            placeholder={t("common:placeholder.search")}
            name="type"
            control={control}
            eventInputChange={handleAutocompleteType}
            options={types ? types.data : []}
            isLoading={isLoadingTypes}
          />
        </Stack>
        <Stack
          spacing="8"
          direction={{ base: "column", lg: "row" }}
          alignItems="end"
          pb="8"
        >
          <PriceSlider name="price" control={control} />
          <Button
            colorScheme="primary"
            px="16"
            onClick={handleSubmit(onSubmit)}
            w={{ base: "full", lg: "auto" }}
          >
            {t("common:button.search")}
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default memo(HomeFilter);
