import React, { useEffect, Fragment, useState } from "react";
import { Button, Stack, Skeleton } from "@chakra-ui/react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import Result from "../../components/Result";
import { useForm } from "react-hook-form";
import ContractForm from "./components/ContractForm";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchContractById,
  deleteContract,
  updateContract,
} from "../../services/contracts.service";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useParams } from "react-router-dom";
import {
  messageSuccessDelete,
  messageSuccessUpdate,
} from "../../components/messages";
import ROLE from "../../config/role";

const ContractDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { control, reset } = useForm();

  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);

  const { loadingSingle, loadingUpdate, contract } = useSelector(
    (state) => state?.contracts
  );
  const { me } = useSelector((state) => state?.auth);

  const loadData = async () => {
    const { data } = unwrapResult(await dispatch(fetchContractById(id)));
    const defaultValues = {
      name: data.name,
      generatedID: data.generatedID,
      propertyAgent: data.propertyAgent,
      property: data.property,
      contractCost: data.contractCost,
      contractStartingDate: new Date(data.contractStartingDate),
      numberOfPeople: data.numberOfPeople,
      numberOfOffices: data.numberOfOffices,
      contractPeriod: data.contractPeriod,
      document: data.document,
      status: data.status,
      statusByAgent: data.statusByAgent,
    };
    reset(defaultValues);
  };

  const handleDelete = async () => {
    unwrapResult(await dispatch(deleteContract(id)));
    messageSuccessDelete();
    history.push("/contracts");
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const onAction = async (action) => {
    const payload = {
      id: id,
    };

    if (me?.role === ROLE.PROPERTY_AGENT) {
      if (action === "approve") {
        payload.statusByAgent = "approved";
        payload.status = "accepted";
        setLoadingApprove(true);
      } else if (action === "reject") {
        payload.statusByAgent = "rejected";
        payload.status = "rejected";
        setLoadingReject(true);
      }
    }

    const res = await dispatch(updateContract(payload));
    if (res.meta.requestStatus === "fulfilled") {
      setLoadingApprove(false);
      setLoadingReject(false);
      history.push("/contracts");
      messageSuccessUpdate();
    } else {
      setLoadingApprove(false);
      setLoadingReject(false);
    }
  };

  return (
    <Layout>
      <Result isFound={!!contract} isLoaded={!loadingSingle}>
        <LayoutHeader>
          <LayoutHeaderLeft>
            <TextHeader text="Contract Details" />
          </LayoutHeaderLeft>
          <Skeleton isLoaded={!loadingSingle}>
            <LayoutHeaderRight>
              {[ROLE.PROPERTY_AGENT].includes(me?.role) && (
                <Fragment>
                  {["sent", "new"].includes(contract?.status) && (
                    <Fragment>
                      <Button
                        colorScheme="green"
                        onClick={() => onAction("approve")}
                        isLoading={loadingApprove}
                      >
                        Accept
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => onAction("reject")}
                        isLoading={loadingReject}
                      >
                        Reject
                      </Button>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </LayoutHeaderRight>
          </Skeleton>
        </LayoutHeader>
        <LayoutBody>
          <ContractForm
            isLoaded={!loadingSingle}
            control={control}
            editMode={true}
            handleDelete={handleDelete}
            disabledAll
            readOnlyAll
          />
        </LayoutBody>
      </Result>
    </Layout>
  );
};

export default ContractDetailPage;
