import {
  Button,
  Container,
  Flex,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { SearchField } from "components/field";
import { TextHeader } from "components/layout/header";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "../../components/layout";
import Paper from "../../components/paper";
import ROLE from "../../config/role";
import { fetchProperties } from "../../services/properties.service";
import PropertiesTable from "./components/PropertiesTable";
import { metaTab } from "./constant";
import CertificateImage from "./img/certificate.svg";

const PropertiesPage = () => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  // AUTH
  const { me } = useSelector((state) => state.auth);

  // PROPERTIES
  const { meta, properties, loading } = useSelector(
    (state) => state?.properties
  );

  const handleTabsChange = (index) => {
    if (index === metaTab.REJECTED) {
      dispatch(fetchProperties({ status: "rejected" }));
    } else {
      dispatch(fetchProperties());
    }
    setTabIndex(index);
  };

  useEffect(() => {
    if (me?.role === ROLE.PERSONAL) return;
    dispatch(fetchProperties());
  }, [dispatch]);

  const handleAddNew = () => {
    history.push("/properties/new");
  };

  const handleSearch = (value) => {
    const payload = {
      search: value,
    };
    setTabIndex(0);
    dispatch(fetchProperties(payload));
  };

  const handlePagination = (offset) => {
    const payload = {
      offset,
    };
    if (tabIndex === 1) {
      payload.status = "rejected";
    }
    dispatch(fetchProperties(payload));
  };

  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Properties" />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          {me?.role !== ROLE.PERSONAL && (
            <SearchField
              placeholder="Search Properties Title"
              callback={handleSearch}
            />
          )}

          {[
            ROLE.ADMIN,
            ROLE.PROPERTY_AGENT,
            ROLE.PROPERTY_BROKER,
            ROLE.PROPERTY_OWNER,
          ].includes(me?.role) && (
            <Button onClick={handleAddNew} colorScheme="primary">
              <Text noOfLines={1}>Add New Property</Text>
            </Button>
          )}
        </LayoutHeaderRight>
      </LayoutHeader>

      <LayoutBody>
        {me?.role === ROLE.PERSONAL ? (
          <Container>
            <Flex justify="center" alignItems="center" direction="column">
              <Image src={CertificateImage} w="200px" ignoreFallback />

              <Text fontSize="lg" mt="8">
                Your account type is personal, you can explore properties or if
                you want to submit your own proporty ad you will need to
                register an advertiser ID through the Real Estate General
                Authority website.{" "}
                <Link
                  href="https://eservices.rega.gov.sa/"
                  color="primary.500"
                  isExternal
                >
                  Register here
                </Link>
              </Text>

              <Text mt="6" fontSize="lg">
                Already have an advertiser ID? You can change your account type
                through{" "}
                <Link
                  to="/account#account-type"
                  scroll={(el) => {
                    el.scrollIntoView(true);
                    window.scrollBy(0, -64);
                  }}
                  as={HashLink}
                  color="primary.500"
                >
                  here
                </Link>
              </Text>
            </Flex>
          </Container>
        ) : (
          <Tabs
            isLazy
            size="sm"
            index={tabIndex}
            variant="soft-rounded"
            onChange={handleTabsChange}
          >
            <TabList>
              <Tab
                fontWeight="semibold"
                _selected={{ color: "white", bg: "primary.500" }}
              >
                All Properties
              </Tab>
              <Tab
                fontWeight="semibold"
                _selected={{ color: "white", bg: "red.500" }}
              >
                Rejected
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0">
                <Paper>
                  <PropertiesTable
                    data={properties}
                    isLoading={loading}
                    meta={meta}
                    onPagination={handlePagination}
                  />
                </Paper>
              </TabPanel>
              <TabPanel px="0">
                <Paper>
                  <PropertiesTable
                    data={properties}
                    isLoading={loading}
                    meta={meta}
                    onPagination={handlePagination}
                  />
                </Paper>
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </LayoutBody>
    </Layout>
  );
};

export default PropertiesPage;
