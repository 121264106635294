import React from "react";
import { Button, VStack } from "@chakra-ui/react";
import { InputField, AutoComplete } from "components/field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { autoCompleteSchema, stringSchema } from "utils/sharedSchema";
import * as yup from "yup";
import PropTypes from "prop-types";
import {
  useAddRegionMutation,
  useUpdateRegionMutation,
} from "services/location/regions.services";
import { useLazyAutocompleteCountriesQuery } from "services/location/countries.services";

const schema = yup.object().shape({
  name: stringSchema,
  ar_name: stringSchema,
  country: autoCompleteSchema,
});

const RegionForm = ({ data, onClose }) => {
  const [addRegion, { isLoading: isLoadingAdd }] = useAddRegionMutation();

  const [updateRegion, { isLoading: isLoadingUpdate }] =
    useUpdateRegionMutation();

  const [
    getAutocompleteCountries,
    { data: countries, isLoading: isLoadingAutocomplete },
  ] = useLazyAutocompleteCountriesQuery();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.name || "",
      ar_name: data?.ar_name || "",
      country: data?.country || null,
    },
  });

  const handlingUpdateData = async (values) => {
    const payload = {
      ...values,
      id: data.id,
    };

    try {
      await updateRegion({ ...payload }).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handlingAddData = async (values) => {
    // remove data from edit form, because we use same component
    if (values.data) delete values.data;

    try {
      await addRegion({ ...values }).unwrap();
      reset();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (values) => {
    // EDIT DATA
    if (data) {
      await handlingUpdateData(values);
      return;
    }

    // ADD DATA
    await handlingAddData(values);
  };

  const handleInputChange = (inputValue) => {
    getAutocompleteCountries({
      search: inputValue,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <VStack spacing="6" alignItems="flex-start">
        <InputField
          name="name"
          label="Region Name"
          control={control}
          isRequired
        />
        <InputField
          isRTL
          name="ar_name"
          label="Arabic Region Name"
          control={control}
          isRequired
        />
        <AutoComplete
          name="country"
          label="Country"
          placeholder="Type Country"
          control={control}
          options={countries ? countries.data : []}
          eventInputChange={handleInputChange}
          isLoading={isLoadingAutocomplete}
          isRequired
        />
        <Button
          type="submit"
          colorScheme="primary"
          isLoading={data ? isLoadingUpdate : isLoadingAdd}
        >
          {data ? "Update" : "Add New"}
        </Button>
      </VStack>
    </form>
  );
};

RegionForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
};

export default RegionForm;
