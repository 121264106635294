import React, { useState } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useController } from "react-hook-form";
import { FIELD_VARIANT } from "../../config/preset";
/* TODO: DISABLED FILED WILL TRIGGER ERROR IN REACT HOOK FORM*/
const SelectField = ({
  name,
  label,
  placeholder,
  control,
  options = [],
  isRequired = false,
  isDisabled = false,
  allowedUnregistered = false, // auto added unregistered value in options
  ...props
}) => {
  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: { required: isRequired },
  });

  const [updatedOptions, setUpdatedOptions] = useState(options);

  const capitalizeFirstLetter = (value) => {
    const strValue = String(value);
    return strValue.charAt(0).toUpperCase() + strValue.slice(1);
  };

  useEffect(() => {
    if (allowedUnregistered && value) {
      if (updatedOptions.filter((e) => e.value === value).length <= 0) {
        setUpdatedOptions((prevOptions) => [
          ...prevOptions,
          {
            label: capitalizeFirstLetter(value),
            value: value,
          },
        ]);
      }
    }
  }, [value, updatedOptions, allowedUnregistered]);

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Select
        ref={ref}
        name={name}
        id={name}
        value={value}
        variant={FIELD_VARIANT.default}
        iconColor={isDisabled ? "gray.200" : "gray.700"}
        pointerEvents={isDisabled ? "none" : "auto"}
        {...inputProps}
        {...props}
      >
        <option hidden>{placeholder || "Select Options"}</option>
        {updatedOptions.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

SelectField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  allowedUnregistered: PropTypes.bool,
};

export default SelectField;
