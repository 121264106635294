import * as yup from 'yup';
import ROLE from '../../config/role';
import {
  arraySchema,
  autoCompleteSchema,
  gallerySchema,
  numberGteZeroSchema,
  numberSchema,
  pinMapSchema,
  stringSchema,
} from '../../utils/sharedSchema';

export const metaTab = {
  ALL: 0,
  REJECTED: 1,
};
export const schemaDelegate = yup.object().shape({
  delegate: autoCompleteSchema.default(null),
});

export const schema = yup.object().shape({
  title: stringSchema.default(''),
  type: autoCompleteSchema.default(null),
  numberOfPeople: numberSchema.default(1),
  price: numberGteZeroSchema.default(0),
  payment: stringSchema.default(''),
  area: numberGteZeroSchema.default(0),
  country: autoCompleteSchema.default(null),
  region: autoCompleteSchema.default(null),
  city: autoCompleteSchema.default(null),
  description: stringSchema.default(''),
  featureImage: stringSchema.default(''),
  propertyGallery: gallerySchema.default([]),
  features: arraySchema.default([]),
  parking: stringSchema.default(''),
  numberOfConferenceRooms: numberSchema.default(0),
  tags: arraySchema.default([]),
  pinMapLocation: pinMapSchema.default(null),
  status: stringSchema.default(''),
});
export const defaultValues = {
  title: '',
  type: '',
  numberOfPeople: 1,
  price: 0,
  payment: '',
  area: 0,

  country: '',
  region: '',
  city: '',

  description: '',
  featureImage: '',
  propertyGallery: [],
  features: [], // TODO: NEED CHECKING
  parking: '',
  numberOfConferenceRooms: 0,
  tags: [],
  // pinMapLocation: null,

  addedBy: '',
  responsible: '',
  status: '',
};

export const paymentOptions = [
  { label: 'Hourly', value: 'hourly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
];

export const complainOptions = [
  { label: 'Fake Advertisement', value: 'Fake Advertisement' },
  {
    label: 'The information is not correct',
    value: 'The information is not correct',
  },
  { label: 'Spam', value: 'Spam' },
  { label: 'Others', value: 'Others' },
];

export const parkingOptions = [
  { label: 'Covered Parking', value: 'covered' },
  { label: 'Zoned Parking', value: 'zoned parking' },
  { label: 'Per Request', value: 'per request' },
];

export const statusOptions = [
  { label: 'New', value: 'new' },
  { label: 'Published', value: 'published' },
  { label: 'Delegated', value: 'delegated' },
  { label: 'Draft', value: 'Draft' },
];

export const statusOptionsByRole = (role) => {
  switch (role) {
    case ROLE.ADMIN:
      return [
        { label: 'New', value: 'new' },
        { label: 'Published', value: 'published' },
        { label: 'Draft', value: 'draft' },
        { label: 'Hold', value: 'hold' },
      ];
    case ROLE.WEBSITE_AGENT:
      return [
        { label: 'Published', value: 'published' },
        { label: 'Draft', value: 'draft' },
      ];
    case ROLE.PROPERTY_AGENT:
      return [
        { label: 'Published', value: 'published' },
        { label: 'Draft', value: 'draft' },
      ];
    default:
      return [{ label: 'Draft', value: 'draft' }];
  }
};
