import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import {
  Button,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { SearchField } from "../../components/field";
import Paper from "../../components/paper";
import ContractsTable from "./components/ContractsTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchContracts } from "../../services/contracts.service";
import ROLE from "../../config/role";

const metaTab = {
  ALL: 0,
  REJECTED: 1,
};

const ContractsPage = () => {
  const [tabIndex, setTabIndex] = React.useState(0);

  const history = useHistory();
  const dispatch = useDispatch();

  const { meta, contracts, loading } = useSelector((state) => state?.contracts);
  const { me } = useSelector((state) => state?.auth);

  const handleTabsChange = (index) => {
    if (index === metaTab.REJECTED) {
      dispatch(fetchContracts({ status: "rejected" }));
    } else {
      dispatch(fetchContracts());
    }
    setTabIndex(index);
  };

  useEffect(() => {
    dispatch(fetchContracts());
  }, [dispatch]);

  const handlePagination = (offset) => {
    const payload = {
      offset,
    };
    if (tabIndex === 1) {
      payload.status = "rejected";
    }
    dispatch(fetchContracts(payload));
  };

  const handleAddNew = () => {
    history.push("/contract/new");
  };

  const handleSearch = (value) => {
    const payload = {
      search: value,
    };
    setTabIndex(0);
    dispatch(fetchContracts(payload));
  };

  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Contracts" />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <SearchField
            placeholder="Search Contract ID"
            callback={handleSearch}
          />
          {![ROLE.SERVICE_OFFICE_PROVIDER, ROLE.PROPERTY_AGENT].includes(
            me?.role
          ) && (
            <Button colorScheme="primary" onClick={handleAddNew}>
              <Text noOfLines={1}>Add New Contract</Text>
            </Button>
          )}
        </LayoutHeaderRight>
      </LayoutHeader>

      <LayoutBody>
        <Tabs
          isLazy
          size="sm"
          index={tabIndex}
          variant="soft-rounded"
          onChange={handleTabsChange}
        >
          <TabList>
            <Tab
              fontWeight="semibold"
              _selected={{ color: "white", bg: "primary.500" }}
            >
              All Contracts
            </Tab>
            <Tab
              fontWeight="semibold"
              _selected={{ color: "white", bg: "red.500" }}
            >
              Rejected
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0">
              <Paper>
                <ContractsTable
                  data={contracts}
                  isLoading={loading}
                  meta={meta}
                  onPagination={handlePagination}
                />
              </Paper>
            </TabPanel>
            <TabPanel px="0">
              <Paper>
                <ContractsTable
                  data={contracts}
                  isLoading={loading}
                  meta={meta}
                  onPagination={handlePagination}
                />
              </Paper>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </LayoutBody>
    </Layout>
  );
};

export default ContractsPage;
