import React from "react";
import PropertyTypeForm from "./PropertyTypeForm";
import { useModalForm } from "../../../../components/hooks/useModalForm";

const PopupAdd = () => {
  const { ModalForm, ButtonTrigger, onClose } = useModalForm();

  return (
    <>
      <ButtonTrigger size="md">Add New Type</ButtonTrigger>
      <ModalForm title="Add New Type">
        <PropertyTypeForm onClose={onClose} />
      </ModalForm>
    </>
  );
};

export default PopupAdd;
