export const MODAL_PRESET = {
  isCentered: true,
  motionPreset: "slideInBottom",
};

export const FIELD_VARIANT = {
  default: "outline",
  outline: "outline",
  unstyled: "unstyled",
  filled: "filled",
  flushed: "flushed",
};
