import React from 'react';
import {
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { AutocompleteAgentsByRole } from 'components/shared';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaTransfer } from '../constant';
import { messageSuccessUpdate } from 'components/messages';
import { useTransferInquiryMutation } from 'services/inquiries.services';

const defaultValues = {
  agentInCharge: null,
};

const ModalTransfer = ({ refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaTransfer),
  });

  const { me } = useSelector((state) => state?.auth);

  // service
  const [transferInquiry, { isLoading }] = useTransferInquiryMutation();

  const onSubmit = async (values) => {
    const payload = {
      id,
      agentInCharge: {
        label: values.agentInCharge.label,
        value: values.agentInCharge.value,
      },
      responsible: {
        label: me?.displayName,
        value: me?.uid,
      },
      ...values,
    };

    try {
      const res = await transferInquiry(payload).unwrap();
      messageSuccessUpdate({
        title: 'Success',
        description: res.message,
      });
      reset(defaultValues);
      onClose();
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Transfer
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transfer Inquiry</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AutocompleteAgentsByRole
              name="agentInCharge"
              label="Property Agent"
              placeholder="Select Property Agent"
              control={control}
              role="property_agent"
              isRequired
              isClearable
            />

            <Text mt="4" color="gray.500" fontSize="sm">
              Note:
            </Text>
            <UnorderedList mt="2" fontSize="sm" color="gray.500">
              <ListItem>
                Submitted inquiry will be sent to the selected Property Agent to
                process it. And you will be responsible for this tour
              </ListItem>
              <ListItem>
                When you change the agent, previous agent can&apos;t access this
                inquiry anymore.
              </ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter>
            <Button size="sm" mr={3} onClick={handleClose}>
              Close
            </Button>
            <Button
              size="sm"
              colorScheme="primary"
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoading}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalTransfer;
