import React from "react";
import { Flex, Text, Image } from "@chakra-ui/react";

const TableError = () => {
  return (
    <Flex
      direction="column"
      bg="white"
      p="6"
      justifyContent="center"
      alignItems="center"
    >
      <Image
        ignoreFallback
        src={process.env.PUBLIC_URL + "/illustration/something-wrong.svg"}
        boxSize="350"
      />
      <Text fontSize="2xl" fontWeight="bold" mb="4">
        Something Went Wrong!
      </Text>
      <Text color="grey">Please refresh page to load data</Text>
    </Flex>
  );
};

export default TableError;
