import React from "react";
import ConfirmContextProvider from "context/ConfirmContextProvider";
import AddNew from "./AddNew";
import { ModalBylaw } from "components/modal/ModalBylaw";

export default function AddNewProperty() {
  return (
    <ConfirmContextProvider>
      <AddNew />
      <ModalBylaw />
    </ConfirmContextProvider>
  );
}
