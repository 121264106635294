import * as yup from 'yup';
import messages from '../config/messages';

const usernameRegex = /^[a-z0-9]+$/;

console.log('messages', messages);

export const arraySchema = yup.array().min(1, messages.required).ensure();

export const gallerySchema = yup
  .array()
  .min(1, 'Property gallery minimal have 1 images')
  .ensure();

export const autoCompleteSchema = yup
  .object()
  .shape({
    label: yup.string().required(messages.required),
    value: yup.string().required(messages.required),
  })
  .required(messages.required)
  .nullable();

export const pinMapSchema = yup
  .object()
  .shape({
    lat: yup.number().required(messages.required),
    lng: yup.number().required(messages.required),
  })
  .required('Please pin location')
  .nullable();

export const emailSchema = yup.string().email().required(messages.required);

export const stringSchema = yup.string().required(messages.requireds);

export const numberSchema = yup.string().required(messages.required);

export const numberGteZeroSchema = yup
  .number()
  .typeError('Must be a number')
  .min(1, 'Must be greater than 0');

export const usernameSchema = yup
  .string()
  .matches(usernameRegex, 'Username is not valid')
  .required(messages.required);

export const generalPhoneSchema = yup.string().required(messages.required);

export const phoneSchema = yup
  .string()
  .notRequired()
  .test('phoneNumber', 'phone number must start with 05', function (value) {
    if (!!value) {
      return value.substring(0, 2) === '05';
    }
    return true;
  });

export const phoneRequiredSchema = yup
  .string()
  .required(messages.phone.required)
  .test('phoneNumber', 'phone number must start with 0511', function (value) {
    if (!!value) {
      return value.substring(0, 2) === '05';
    }
    return true;
  });

export const fileSchema = yup
  .mixed()
  .test('type', messages.required, (value) => {
    return value;
  });
