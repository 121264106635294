export const ROLE = {
  ADMIN: "admin",
  SERVICE_OFFICE_PROVIDER: "service_office_provider",
  PROPERTY_AGENT: "property_agent",
  WEBSITE_AGENT: "website_agent",
  PROPERTY_BROKER: "property_broker",
  PROPERTY_OWNER: "property_owner",
  PERSONAL: "personal",
  CLIENT: "client",
};

export const ALL_ROLE = [
  ROLE.ADMIN,
  ROLE.WEBSITE_AGENT,
  ROLE.PROPERTY_AGENT,
  ROLE.SERVICE_OFFICE_PROVIDER,
  ROLE.PROPERTY_OWNER,
  ROLE.PROPERTY_BROKER,
  ROLE.PERSONAL,
];

export const ROLE_ADMIN_AND_AGENT = [
  ROLE.ADMIN,
  ROLE.PROPERTY_AGENT,
  ROLE.SERVICE_OFFICE_PROVIDER,
  ROLE.PROPERTY_BROKER,
  ROLE.PROPERTY_OWNER,
];

export const ROLE_WEB_AND_PERSONAL = [ROLE.WEBSITE_AGENT, ROLE.PERSONAL];

export const ROLE_LABEL = {
  admin: "Admin",
  website_agent: "Website Agent",
  service_office_provider: "Service Office provider",
  property_agent: "Property Agent",
  property_broker: "Property Broker",
  property_owner: "Property Owner",
  personal: "Personal",
};

export const registerRoleOptions = [
  { label: "Service Office provider", value: "service_office_provider" },
  { label: "Property Agent", value: "property_agent" },
  { label: "Property Broker", value: "property_broker" },
  { label: "Property Owner", value: "property_owner" },
  { label: "Personal", value: "personal" },
];

export const typeIds = registerRoleOptions.map((option, index) => {
  return { value: option.value, id: index + 1 };
});

export const allRoleOptions = [
  { label: "Admin", value: "admin" },
  { label: "Website Agent", value: "website_agent" },
  { label: "Service Office provider", value: "service_office_provider" },
  { label: "Property Agent", value: "property_agent" },
  { label: "Property Broker", value: "property_broker" },
  { label: "Property Owner", value: "property_owner" },
  { label: "personal", value: "personal" },
];

export default ROLE;
