import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import Layout from 'components/layout/auth';
import { useTranslation } from 'react-i18next';
import { Link as ReachLink } from 'react-router-dom';
import ForgotForm from '../../components/form/ForgotForm';

const ForgotPage = () => {
  const { t } = useTranslation('forgot');

  return (
    <Layout>
      <Box maxW="xl" mx="auto">
        <Flex
          textAlign="center"
          justify="center"
          align="center"
          direction="column"
        >
          <Heading color="white" size="xl" fontWeight="extrabold">
            {t('forgot:title')}
          </Heading>
          <Text
            mt="4"
            mb="12"
            maxW="sm"
            fontWeight="medium"
            color="white"
            fontSize="lg"
          >
            {t('forgot:sub_title')}
          </Text>
        </Flex>

        <Box
          p={{ base: 6, md: 8, lg: 10 }}
          bg="white"
          borderRadius="2xl"
          border="1px"
          borderColor="gray.200"
          boxShadow="lg"
        >
          <ForgotForm />
        </Box>
        <Text mt="4" mb="8" fontWeight="medium">
          <Text color="white" as="span" mr="2">
            {t('forgot:have_account')}
          </Text>
          <Link as={ReachLink} color="primary.300" to="/auth/login">
            {t('forgot:login_to_hub')}
          </Link>
        </Text>
      </Box>
    </Layout>
  );
};

export default ForgotPage;
