import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Switch,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

const SwitchField = ({
  name,
  control,
  label,
  helperText = "",
  isRequired = false,
  eventChange = () => {},
  ...props
}) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: "",
    rules: { required: isRequired },
  });

  const handleChange = (value) => {
    eventChange(value);
    onChange(value);
  };

  return (
    <FormControl
      isInvalid={invalid}
      isRequired={isRequired}
      as={SimpleGrid}
      columns={{ base: 2, lg: 2 }}
    >
      <FormLabel htmlFor={name} mb="0">
        {label}
      </FormLabel>
      <Flex justifyContent="end" alignItems="center">
        <Switch
          colorScheme="green"
          size="lg"
          ref={ref}
          name={name}
          id={name}
          isChecked={value}
          onChange={handleChange}
          {...inputProps}
        />
      </Flex>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

SwitchField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  eventChange: PropTypes.func,
};

export default SwitchField;
