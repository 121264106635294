import { api } from "./api";

export const accountTypeApi = api.injectEndpoints({
  tagTypes: ["AccountType"],
  endpoints: (build) => ({
    getAccountType: build.query({
      query: (payload) => ({
        url: `/account-type`,
        method: "GET",
        params: {
          offset: payload?.offset || 0,
          limit: payload?.limit || 6,
        },
      }),
      providesTags: ["AccountType"],
    }),

    addAccountType: build.mutation({
      query: (newAccountType) => ({
        url: `/account-type`,
        method: "POST",
        data: newAccountType,
      }),
      invalidatesTags: ["AccountType"],
    }),

    updateAccountType: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `/account-type/${id}`,
        method: "PATCH",
        data: patch,
      }),
      invalidatesTags: ["AccountType"],
    }),

    deleteAccountType: build.mutation({
      query: (id) => ({
        url: `/account-type/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["AccountType"],
    }),
  }),
});

export const {
  useGetAccountTypeQuery,
  useAddAccountTypeMutation,
  useUpdateAccountTypeMutation,
  useDeleteAccountTypeMutation,
} = accountTypeApi;
