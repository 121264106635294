import React from "react";
import {
  Button,
  Box,
  Container,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Page404 = () => {
  const { t } = useTranslation();
  let history = useHistory();

  const goBack = () => {
    history.push("/");
  };

  return (
    <Box bg="primary.50" minH="100vh">
      <Container maxW="container.xl">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minH="100vh"
        >
          <VStack>
            <Heading fontSize="8rem" color="primary.600">
              404
            </Heading>
            <Text fontSize="4xl" color="primary.600">
              {t("common:label.404_title")}
            </Text>
            <Text fontSize="2xl" color="primary.600">
              {t("common:label.404_description")}
            </Text>
            <VStack py="8">
              <Button
                onClick={goBack}
                leftIcon={<FaArrowCircleLeft />}
                colorScheme="primary"
                variant="outline"
              >
                {t("common:button.go_back")}
              </Button>
            </VStack>
          </VStack>
        </Box>
      </Container>
    </Box>
  );
};

export default Page404;
