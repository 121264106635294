import React, { useState } from "react";
import { Button, Grid, SimpleGrid, useToast } from "@chakra-ui/react";
import { PhoneField, TextareaField, TextOnly } from "../../../components/field";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { phoneSchema } from "../../../utils/sharedSchema";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../services/auth.service";

const schema = yup.object().shape({
  phoneNumber: phoneSchema
});

const PersonalForm = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { me } = useSelector(state => state?.auth);
  const { control, handleSubmit, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      phoneNumber: me?.phoneNumber,
      bio: me?.bio
    }
  });

  const onSubmit = async values => {
    setLoading(true);
    const res = await dispatch(updateProfile(values));
    if (res.meta.requestStatus === "fulfilled") {
      toast({
        title: "Personal Info Updated",
        description: "Personal info updated successfully.",
        position: "top-right",
        status: "success",
        duration: 4000,
        isClosable: true
      });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid spacing="12px" gap={4}>
        <SimpleGrid spacing="12px">
          <TextOnly label="Username" value={me?.username} />
          <PhoneField
            name="phoneNumber"
            label="Phone Number"
            control={control}
          />
        </SimpleGrid>
        <TextareaField name="bio" label="Bio" control={control} />
        <Button
          type="submit"
          width="fit-content"
          colorScheme="primary"
          size="sm"
          isLoading={loading}
        >
          Save Changes
        </Button>
      </Grid>
    </form>
  );
};

export default PersonalForm;
