import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import {
  Box,
  Text,
  Center,
  Skeleton,
  Button,
  VStack,
  Image
} from "@chakra-ui/react";

const Result = ({
  title = "Empty Place",
  subTitle = "unfortunately nothing could be found.",
  children,
  isFound,
  isLoaded
}) => {
  const history = useHistory();

  return (
    <Box>
      <Skeleton isLoaded={isLoaded}>
        {isFound ? (
          children
        ) : (
          <Box>
            <Center h="600px">
              <VStack>
                <Text color="gray.700" fontSize="3xl" fontWeight="bold">
                  {title}
                </Text>
                <Text color="gray.500" fontSize="lg">
                  {subTitle}
                </Text>
                <Image
                  boxSize="400px"
                  src={process.env.PUBLIC_URL + "/illustration/empty.svg"}
                />
                <Button onClick={() => history.goBack()}>Go Back</Button>
              </VStack>
            </Center>
          </Box>
        )}
        {/*{children}*/}
      </Skeleton>
    </Box>
  );
};

Result.propTypes = {
  isFound: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Result;
