import React from "react";
import Layout, {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutBody,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import ComplaintTable from "./components/ComplaintTable";
import PaperTable from "components/paper-table";

const ComplaintPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Complaints" />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <PaperTable>
          <ComplaintTable />
        </PaperTable>
      </LayoutBody>
    </Layout>
  );
};

export default ComplaintPage;
