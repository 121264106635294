import React from 'react';
import {
  Box,
  IconButton,
  Text,
  Stack,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import {
  TbArrowsSort,
  TbArrowNarrowUp,
  TbArrowNarrowDown,
} from 'react-icons/tb';
import { useForm, Controller } from 'react-hook-form';

const TableSorting = ({ callback, sortOptions }) => {
  // get default sort option
  const defaultSortOption = sortOptions.find(
    (option) => option.isDefault === true
  );

  const { control, handleSubmit } = useForm();
  const [direction, setDirection] = React.useState(
    defaultSortOption?.direction || ''
  );

  const onSubmitAsc = async ({ field }) => {
    setDirection('asc');
    callback({ field: field, direction: 'asc' });
  };

  const onSubmitDesc = async ({ field }) => {
    setDirection('desc');
    callback({ field: field, direction: 'desc' });
  };

  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <IconButton size="sm" icon={<TbArrowsSort />} variant="outline" />
        </PopoverTrigger>
        <PopoverContent width="fit-content" minW="180px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Text mb="2" color="gray.600" fontWeight="semibold" fontSize="sm">
              Sort by
            </Text>
            <Controller
              name="field"
              control={control}
              defaultValue={defaultSortOption?.value || ''}
              render={({ field }) => (
                <RadioGroup
                  onChange={(value) => {
                    field.onChange(value);
                    callback({
                      field: value,
                      direction: direction,
                    });
                  }}
                  value={field.value}
                  colorScheme="black"
                >
                  <Stack spacing="1" direction="column">
                    {sortOptions.map((option, index) => (
                      <Radio key={index} value={option.value}>
                        <Text color="gray.500" fontSize="sm">
                          {option.label} {option.directionLabel}
                        </Text>
                      </Radio>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            />
          </PopoverBody>
          <PopoverFooter>
            <Stack>
              <Button
                leftIcon={<TbArrowNarrowUp />}
                onClick={handleSubmit(onSubmitAsc)}
                justifyContent="flex-start"
                colorScheme={direction === 'asc' ? 'black' : 'gray'}
                size="sm"
              >
                Ascending
              </Button>

              <Button
                leftIcon={<TbArrowNarrowDown />}
                onClick={handleSubmit(onSubmitDesc)}
                justifyContent="flex-start"
                colorScheme={direction === 'desc' ? 'black' : 'gray'}
                size="sm"
              >
                Descending
              </Button>
            </Stack>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default TableSorting;
