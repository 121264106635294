import LoginPage from "../../pages/auth/login";
import LogoutPage from "../../pages/auth/logout";
import RegisterPage from "../../pages/auth/register";
import ForgotPage from "../../pages/auth/forgot";
// import ResetPage from "../../pages/auth/reset";
import VerifyEmail from "../../pages/auth/verifyEmail";

const authRoutes = [
  { path: "/auth/login", component: LoginPage },
  { path: "/auth/logout", component: LogoutPage },
  { path: "/auth/register", component: RegisterPage },
  { path: "/auth/forgot", component: ForgotPage },
  { path: "/auth/verify", component: VerifyEmail },
  // TODO: for now this is not used, because reset password form has handled by firebase.
  // { path: "/auth/reset-password", component: ResetPage }
];

export default authRoutes;
