import React from "react";
import PropTypes from "prop-types";
import { Button } from "@chakra-ui/react";

const ButtonDark = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      bg="brand.dark"
      color="white"
      _hover={{
        bg: "#120b0d"
      }}
      _active={{
        bg: "#120b0d"
      }}
    >
      {children}
    </Button>
  );
};

ButtonDark.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onClick: PropTypes.func
};

export default ButtonDark;
