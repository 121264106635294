import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MapsConfig from "config/maps";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react";
import { useController, useWatch } from "react-hook-form";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const libraries = ["places"];

const PinMapField = ({
  name,
  control,
  label,
  helperText = "",
  isRequired = false,
}) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: MapsConfig.defaultCenter,
    rules: { required: isRequired },
  });

  const containerStyle = {
    width: "100%",
    height: "400px",
    border: invalid ? "2px solid #e53e3e" : "0",
    borderRadius: "4px",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries,
    googleMapsApiKey: MapsConfig.key,
  });

  const handleMapClick = (map) => {
    onChange({
      lat: Number(map.latLng.lat()),
      lng: Number(map.latLng.lng()),
    });
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Fragment>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={MapsConfig.zoom}
            center={value || MapsConfig.defaultCenter}
            onClick={handleMapClick}
          >
            {value && <Marker position={value} />}
          </GoogleMap>
        ) : (
          "Preparing map..."
        )}
      </Fragment>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

PinMapField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
};

export default PinMapField;
