import React from "react";
import AccountTypeForm from "./AccountTypeForm";
import { useModalForm } from "components/hooks/useModalForm";

const PopupAdd = () => {
  const { ModalForm, ButtonTrigger, onClose } = useModalForm();

  return (
    <>
      <ButtonTrigger size="md">Add New Type</ButtonTrigger>
      <ModalForm title="Add New Account Type">
        <AccountTypeForm onClose={onClose} />
      </ModalForm>
    </>
  );
};

export default PopupAdd;
