import CountriesPage from "../../pages/location/countries";
import RegionsPage from "../../pages/location/regions";
import CitiesPage from "../../pages/location/cities";

const locationRoutes = [
  { path: "/location/countries", component: CountriesPage },
  { path: "/location/regions", component: RegionsPage },
  { path: "/location/cities", component: CitiesPage }
];

export default locationRoutes;
