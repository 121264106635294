import React, { useState } from 'react';
import { Button, Stack, useToast } from '@chakra-ui/react';
import { InputField } from '../field';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import messages from '../../config/messages';
import { forgot } from '../../services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email format')
    .required(messages.required),
});

const ForgotForm = () => {
  const { t } = useTranslation('forgot');
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const toast = useToast();
  // const { loading } = useSelector(state => state?.auth);
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    setIsLoading(true);
    const res = await dispatch(forgot(values));
    setIsLoading(false);
    if (res.meta.requestStatus === 'fulfilled') {
      reset();

      toast({
        title: 'Success',
        position: 'top-right',
        description: 'Reset link has been sent to your email.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack spacing="6">
        <InputField
          name="email"
          type="email"
          label={t('forgot:email')}
          control={control}
          isRequired
        />
        <Button
          type="submit"
          colorScheme="primary"
          size="lg"
          fontSize="md"
          isLoading={isLoading}
        >
          {t('forgot:send_link')}
        </Button>
      </Stack>
    </form>
  );
};

export default ForgotForm;
