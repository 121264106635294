import { axiosInstance } from "./fetchApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { catchError } from "../utils/catchError";

const endpoint = "/tags";

export const fetchAutocompleteTag = createAsyncThunk(
  "tags/autocomplete",
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/autocomplete`, {
        params: {
          offset: payload?.offset || 0,
          limit: 6,
          ...payload
        }
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const fetchTag = createAsyncThunk(
  "tags/fetchAll",
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.get(endpoint, {
        params: {
          offset: payload?.offset || 0,
          limit: 6,
          ...payload
        }
      });
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const fetchTagById = createAsyncThunk(
  "tags/fetchById",
  async (id, thunkApi) => {
    try {
      const response = await axiosInstance.get(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const createTag = createAsyncThunk(
  "tags/create",
  async (payload, thunkApi) => {
    try {
      const response = await axiosInstance.post(endpoint, payload);
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(catchError(err));
    }
  }
);

export const updateTag = createAsyncThunk(
  "tags/update",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`${endpoint}/${payload.id}`, {
        name: payload?.name,
        ar_name: payload?.ar_name
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTag = createAsyncThunk(
  "tags/delete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`${endpoint}/${id}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
