import React from "react";
import { Box, Heading, HStack, Tag, TagLabel } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const TagSection = ({ property }) => {
  const { t, i18n } = useTranslation();

  const ARABIC = i18n.language === "ar";

  return (
    <Box mt="8" mb="8">
      <Heading mb={"4"} fontSize="xl" fontWeight="semibold">
        {t("common:label.tags")}
      </Heading>
      <HStack>
        {property?.tags.map((item) => (
          <Box key={item.value}>
            <Tag borderRadius="full" px="6" py="2">
              <TagLabel textTransform="capitalize">
                {ARABIC ? item.ar_label : item.label}
              </TagLabel>
            </Tag>
          </Box>
        ))}
      </HStack>
    </Box>
  );
};

export default TagSection;
