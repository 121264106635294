import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

export function useModalComponent(api) {
  const ModalComponent = ({ children, title }) => {
    const { onClose, isOpen } = ModalComponent.api;

    return (
      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{children}</ModalBody>
            <ModalFooter />
          </ModalContent>
        </Modal>
      </>
    );
  };

  ModalComponent.api = api;

  return ModalComponent;
}
