import React from "react";
import { Text, Button } from "@chakra-ui/react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import { useForm } from "react-hook-form";
import { createContract } from "../../services/contracts.service";
import { useDispatch, useSelector } from "react-redux";
import ContractForm from "./components/ContractForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  autoCompleteSchema,
  fileSchema,
  numberGteZeroSchema,
  numberSchema,
  stringSchema,
} from "../../utils/sharedSchema";
import { messageSuccessSave } from "../../components/messages";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
  status: stringSchema,
  propertyAgent: autoCompleteSchema,
  property: autoCompleteSchema,
  numberOfOffices: numberSchema,
  numberOfPeople: numberSchema,
  contractPeriod: stringSchema,
  contractCost: numberGteZeroSchema,
  document: fileSchema,
});

const AddNewContractPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // CONTRACTS
  const { loadingCreate, loadingUpload } = useSelector(
    (state) => state?.contracts
  );

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      propertyAgent: null,
      property: null,
      contractCost: 0,
      contractStartingDate: new Date(),
      numberOfPeople: 1,
      numberOfOffices: 1,
      document: "",
      status: "sent",
    },
  });

  const onSaveNew = async (values) => {
    const payload = {
      ...values,
      statusByAgent: "new",
    };

    const res = await dispatch(createContract(payload));
    if (res.meta.requestStatus === "fulfilled") {
      reset();
      history.push("/contracts");
      messageSuccessSave();
    }
  };

  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Add New Contract" />
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <Button
            onClick={handleSubmit(onSaveNew)}
            colorScheme="primary"
            isLoading={loadingCreate}
            isDisabled={loadingUpload}
          >
            <Text noOfLines={1}>Add New Contract</Text>
          </Button>
        </LayoutHeaderRight>
      </LayoutHeader>

      <LayoutBody>
        <ContractForm isLoaded={true} control={control} addMode={true} />
      </LayoutBody>
    </Layout>
  );
};

export default AddNewContractPage;
