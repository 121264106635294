import { Button, Grid, GridItem, HStack, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  AutoComplete,
  ImageUpload,
  InputField,
  PasswordField,
  PhoneField,
  SelectField,
  TextareaField,
} from "../../components/field";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "../../components/layout";
import { TextHeader } from "../../components/layout/header";
import { messageSuccessSave } from "../../components/messages";
import Paper from "../../components/paper";
import {
  checkUsername,
  createAgent,
  upload,
} from "../../services/agents.service";
import { useLazyAutocompleteCitiesQuery } from "services/location/cities.services";
import {
  emailSchema,
  phoneSchema,
  stringSchema,
  usernameSchema,
} from "../../utils/sharedSchema";
import {
  categoryOptions,
  fullCategoryOptions,
  registerDefaultValues,
  statusOptions,
} from "./constant";

const schema = yup.object().shape({
  username: usernameSchema,
  displayName: stringSchema,
  role: stringSchema,
  status: stringSchema,
  email: emailSchema,
  password: stringSchema,
  phoneNumber: phoneSchema,
});

const AddNewAgentPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [getAutocompleteCities, { data: cities, isLoading: isLoadingCities }] =
    useLazyAutocompleteCitiesQuery();

  const { me } = useSelector((state) => state?.auth);

  const { loadingCreate, loadingUsername, loadingUpload } = useSelector(
    (state) => state?.agents
  );

  const { control, handleSubmit, reset, setError, clearErrors, setValue } =
    useForm({
      mode: "onChange",
      resolver: yupResolver(schema),
      defaultValues: registerDefaultValues,
    });

  const handleInputChange = (inputValue) => {
    getAutocompleteCities({
      search: inputValue,
    });
  };

  const onSaveNew = async (values) => {
    const payload = {
      ...values,
      createdBy: me?.role,
    };

    // status will be waiting if admin created new property_agent user
    if (me?.role === "admin" && values.role === "property_agent") {
      payload.status = "waiting";
    }

    // check username first
    const resUsername = await dispatch(checkUsername(payload));
    if (resUsername.meta.requestStatus === "fulfilled") {
      const res = await dispatch(createAgent(payload));
      if (res.meta.requestStatus === "fulfilled") {
        reset();
        history.push("/agents");
        messageSuccessSave();
      }
    }
  };

  const handleCallback = async (file, uploadedData) => {
    if (!file) return;

    const payload = {
      data: file,
    };
    const res = await dispatch(upload(payload));
    if (res.meta.requestStatus === "fulfilled") {
      uploadedData(res.payload.imageURL);

      // save thumbnail image
      setValue("thumbnailImageURL", res.payload.thumbnailImageURL);
    }
  };

  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Add New Agent" />
        </LayoutHeaderLeft>
        {/* <LayoutHeaderRight>
          <Button
            onClick={handleSubmit(onSaveNew)}
            colorScheme="primary"
            isLoading={loadingCreate || loadingUsername}
            isDisabled={loadingUpload}
          >
            Add New Agent
          </Button>
        </LayoutHeaderRight> */}
      </LayoutHeader>

      <LayoutBody>
        <Grid
          templateColumns={{ base: "repeat(3, 1fr)", xl: "repeat(6, 1fr)" }}
          gap={6}
        >
          <GridItem colSpan="4">
            <Paper>
              <VStack spacing="6">
                <HStack w="full" spacing="6">
                  <InputField
                    name="username"
                    label="Username"
                    control={control}
                    isRequired
                  />
                  <InputField
                    name="displayName"
                    label="Agent Name"
                    control={control}
                    isRequired
                  />
                </HStack>

                <HStack w="full" spacing="6">
                  <SelectField
                    w={["full", "full", "calc(50% - 12px)"]}
                    name="role"
                    label="Agent Category"
                    placeholder="Choose account type"
                    control={control}
                    options={
                      me?.role === "admin"
                        ? fullCategoryOptions
                        : categoryOptions
                    }
                    isRequired
                  />
                </HStack>
                <HStack w="full" spacing="6">
                  <InputField
                    name="companyName"
                    label="Company Name"
                    control={control}
                  />
                  <AutoComplete
                    name="city"
                    label="City"
                    placeholder="Select City"
                    control={control}
                    options={cities ? cities.data : []}
                    eventInputChange={handleInputChange}
                    isLoading={isLoadingCities}
                  />
                </HStack>

                <TextareaField name="bio" label="Bio" control={control} />

                <HStack w="full" spacing="6">
                  <InputField
                    name="email"
                    type="email"
                    label="Email"
                    control={control}
                    isRequired
                  />
                  <PhoneField
                    name="phoneNumber"
                    label="Phone Number"
                    control={control}
                  />
                </HStack>

                <ImageUpload
                  name="imageURL"
                  label={loadingUpload ? "Uploading Image..." : "Image"}
                  isLoading={loadingUpload}
                  control={control}
                  callback={handleCallback}
                />
              </VStack>
            </Paper>
          </GridItem>
          <GridItem colSpan={{ base: 4, xl: 2 }}>
            <Paper>
              <VStack spacing="6">
                <PasswordField
                  name="password"
                  label="New Password for this account"
                  control={control}
                  isRequired
                />
              </VStack>
            </Paper>
            <Paper mt="8">
              <VStack spacing="6">
                <SelectField
                  name="status"
                  label="Status"
                  placeholder="Select status"
                  control={control}
                  options={statusOptions}
                  isRequired
                />
              </VStack>
            </Paper>
          </GridItem>
        </Grid>
      </LayoutBody>

      <LayoutBody>
        <Button
          px={20}
          onClick={handleSubmit(onSaveNew)}
          colorScheme="primary"
          isLoading={loadingCreate || loadingUsername}
          isDisabled={loadingUpload}
        >
          Submit
        </Button>
      </LayoutBody>
    </Layout>
  );
};

export default AddNewAgentPage;
