import React from "react";
import PropTypes from "prop-types";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
  Checkbox,
  RadioGroup,
  Stack,
  Radio,
  CheckboxGroup,
  HStack,
  Text
} from "@chakra-ui/react";
import { FIELD_VARIANT } from "../../config/preset";
import { useController, useWatch } from "react-hook-form";

const CheckBoxGroupField = ({
  name,
  type = "text",
  placeholder,
  control,
  label,
  labelValue,
  helperText = "",
  isRequired = false,
  labelAsValue = false,
  items = [],
  eventChange = () => {},
  itemDirections = ["row", "column"],
  itemSpace = "12px",
  additionalComponent = null,
  activeOption = "",
  colorScheme = "primary",
  children = null,
  ...props
}) => {
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error }
  } = useController({
    name,
    control,
    defaultValue: [],
    rules: { required: isRequired }
  });

  const handleChange = value => {
    eventChange(value);
    onChange(value);
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <CheckboxGroup
        name={name}
        id={name}
        value={value}
        variant={FIELD_VARIANT.default}
        onChange={handleChange}
        {...inputProps}
        {...props}
      >
        <Stack direction={itemDirections}>
          {children
            ? children
            : items.map((item, index) => (
                <Checkbox
                  key={index}
                  value={labelAsValue ? item.label : item.value}
                  colorScheme={colorScheme}
                >
                  <Text textTransform="capitalize">{item.label}</Text>
                </Checkbox>
              ))}
        </Stack>
      </CheckboxGroup>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

CheckBoxGroupField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelValue: PropTypes.string,
  isRequired: PropTypes.bool,
  labelAsValue: PropTypes.bool,
  type: PropTypes.string,
  items: PropTypes.array,
  itemDirections: PropTypes.array,
  itemSpace: PropTypes.string,
  eventChange: PropTypes.func,
  additionalComponent: PropTypes.element,
  activeOption: PropTypes.string,
  colorScheme: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default CheckBoxGroupField;
