import React from 'react';
import Layout, { LayoutBody } from '../../../../components/layout/client';
import {
  Box,
  Text,
  Container,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const WhyProjectPage = () => {
  const { t } = useTranslation('whyProject');

  return (
    <Layout>
      <LayoutBody>
        <Container maxW={'container.lg'}>
          <Box>
            <Text fontSize="3xl" fontWeight="bold" mb="8">
              {t('whyProject:title')}
            </Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('whyProject:title')}
            </Text>
            <Text>{t('whyProject:paragraph_1')}</Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('whyProject:types_of_offices.title')}
            </Text>
            <Text mb="6">
              {t('whyProject:types_of_offices.paragraph_1')}
              <br />
              <br />
              {t('whyProject:types_of_offices.paragraph_2')}
              <br />
              {t('whyProject:types_of_offices.paragraph_3')}
            </Text>
            <Text fontWeight="bold" mb="2">
              {t('whyProject:types_of_offices.pricing_policy.title')}
            </Text>
            <Text mb="6">
              {t('whyProject:types_of_offices.pricing_policy.paragraph_1')}
              <br />
              <br />
              {t('whyProject:types_of_offices.pricing_policy.paragraph_2')}
            </Text>
            <UnorderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t('whyProject:types_of_offices.pricing_policy:ordered_list.1')}
              </ListItem>
              <ListItem>
                {t('whyProject:types_of_offices.pricing_policy:ordered_list.2')}
              </ListItem>
              <ListItem>
                {t('whyProject:types_of_offices.pricing_policy:ordered_list.3')}
              </ListItem>
              <ListItem>
                {t('whyProject:types_of_offices.pricing_policy:ordered_list.4')}
              </ListItem>
              <ListItem>
                {t('whyProject:types_of_offices.pricing_policy:ordered_list.5')}
              </ListItem>
            </UnorderedList>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="2" fontSize="18">
              {t('whyProject:pricing_policy.title')}
            </Text>
            <Text mb="6">{t('whyProject:pricing_policy.paragraph_1')}</Text>
            <UnorderedList mb="8" pl="8" spacing={3}>
              <ListItem>
                {t('whyProject:pricing_policy:ordered_list.1')}
              </ListItem>
              <ListItem>
                {t('whyProject:pricing_policy:ordered_list.2')}
              </ListItem>
            </UnorderedList>
            <Text>{t('whyProject:pricing_policy.paragraph_2')}</Text>
          </Box>

          <Box mb="12">
            <Text fontWeight="bold" mb="4" fontSize="18">
              {t('whyProject:some_factors.title')}
            </Text>
            <Box>
              <Text fontWeight="bold" mb="2">
                {t('whyProject:some_factors.supply_and_demand.title')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t(
                    'whyProject:some_factors.supply_and_demand:ordered_list.1'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'whyProject:some_factors.supply_and_demand:ordered_list.2'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'whyProject:some_factors.supply_and_demand:ordered_list.3'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'whyProject:some_factors.supply_and_demand:ordered_list.4'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'whyProject:some_factors.supply_and_demand:ordered_list.5'
                  )}
                </ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Text fontWeight="bold" mb="2">
                {t('whyProject:some_factors.timing.title')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t('whyProject:some_factors.timing:ordered_list.1')}
                </ListItem>
                <ListItem>
                  {t('whyProject:some_factors.timing:ordered_list.2')}
                </ListItem>
                <ListItem>
                  {t('whyProject:some_factors.timing:ordered_list.3')}
                </ListItem>
                <ListItem>
                  {t('whyProject:some_factors.timing:ordered_list.4')}
                </ListItem>
                <ListItem>
                  {t('whyProject:some_factors.timing:ordered_list.5')}
                </ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Text fontWeight="bold" mb="2">
                {t('whyProject:some_factors.how_to_use_the_space.title')}
              </Text>
              <UnorderedList mb="8" pl="8" spacing={3}>
                <ListItem>
                  {t(
                    'whyProject:some_factors.how_to_use_the_space:ordered_list.1'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'whyProject:some_factors.how_to_use_the_space:ordered_list.2'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'whyProject:some_factors.how_to_use_the_space:ordered_list.3'
                  )}
                </ListItem>
                <ListItem>
                  {t(
                    'whyProject:some_factors.how_to_use_the_space:ordered_list.4'
                  )}
                </ListItem>
              </UnorderedList>
              <Text mb="2">
                {t('whyProject:some_factors.how_to_use_the_space.paragraph_1')}
              </Text>
            </Box>
          </Box>
        </Container>
      </LayoutBody>
    </Layout>
  );
};

export default WhyProjectPage;
