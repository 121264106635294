import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, HStack, IconButton, Text, useToken } from "@chakra-ui/react";
import { MdGraphicEq } from "react-icons/md";
import { Range, Handle } from "rc-slider";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";

const PriceSlider = ({
  name,
  control,
  size = "md",
  onAfterChange = () => {},
}) => {
  const { t, i18n } = useTranslation();
  const [primary100, primary300] = useToken("colors", [
    "primary.100",
    "primary.300",
  ]);

  const initValue = {
    priceMin: 1,
    priceMax: 10000,
  };

  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    defaultValue: [initValue.priceMin, initValue.priceMax],
    rules: { required: false },
  });

  const supportRtlPosition = i18n.dir() === "rtl" ? "5px" : "-5px";

  const RangeButton = () => (
    <IconButton
      bg="white"
      boxShadow="lg"
      isRound={true}
      size={size === "md" ? "sm" : "xs"}
      color="primary.500"
      border="2px"
      borderColor="primary.500"
      icon={<MdGraphicEq />}
      transform={
        size === "md"
          ? `translate3d(${supportRtlPosition}, -11px, 10px)`
          : "translate3d(-5px, -9px, 10px)"
      }
    />
  );

  return (
    <Box w="full" p={size === "md" ? 4 : 2}>
      <Range
        reverse={i18n.dir() === "rtl"}
        ref={ref}
        name={name}
        defaultValue={[initValue.priceMin, initValue.priceMax]}
        value={value}
        min={initValue.priceMin}
        max={initValue.priceMax}
        onAfterChange={onAfterChange}
        pushable
        step={3}
        count={1}
        allowCross={false}
        railStyle={{
          backgroundColor: primary100,
          height: size === "md" ? "8px" : "4px",
        }}
        trackStyle={[
          {
            backgroundColor: primary300,
            height: size === "md" ? "8px" : "4px",
          },
        ]}
        handleStyle={[{ boxShadow: "none" }, { boxShadow: "none" }]}
        handle={(handleProps) => {
          const { index, dragging, ...restProps } = handleProps;

          return (
            <Handle key={index} dragging={dragging.toString()} {...restProps}>
              <RangeButton />
            </Handle>
          );
        }}
        {...inputProps}
      />

      <Box mt="6" userSelect="none">
        <Text
          fontSize={size === "md" ? "sm" : "xs"}
          fontWeight="semibold"
          color="gray.500"
          mb="1"
        >
          {t("common:label.price_range")}
        </Text>
        <HStack spacing={size === "md" ? "3" : "2"}>
          <Text fontSize={size === "md" ? "xl" : "md"}>SAR {value[0]}</Text>
          <Text fontSize={size === "md" ? "xl" : "md"}>-</Text>
          <HStack spacing="0">
            <Text fontSize={size === "md" ? "xl" : "md"}>SAR {value[1]}</Text>
            <Text>{value[1] === 10000 ? "+" : ""}</Text>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
};

PriceSlider.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  control: PropTypes.object.isRequired,
  onAfterChange: PropTypes.func,
};

export default memo(PriceSlider);
