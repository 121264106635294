import * as yup from 'yup';
import {
  arraySchema,
  autoCompleteSchema,
  numberGteZeroSchema,
  numberSchema,
  phoneSchema,
  stringSchema,
} from '../../utils/sharedSchema';

export const schema = yup.object().shape({
  clientName: stringSchema.default(''),
});

export const schemaAssign = yup.object().shape({
  assign: autoCompleteSchema.default(null),
});

export const schemaTransfer = yup.object().shape({
  agentInCharge: autoCompleteSchema.default(null),
});

export const schemaDelegate = yup.object().shape({
  websiteAgent: autoCompleteSchema.default(null),
});

export const schemaRejection = yup.object().shape({
  rejectionReason: stringSchema.default(''),
});

export const defaultValues = {
  id: '',
  clientName: '',
  companyName: '',
  phoneNumber: '',
  email: '',
  preferredVisitingTime: [new Date(), new Date()],
  agentInCharge: null,
};

export const metaTab = {
  ALL: 0,
  REJECTED: 1,
};
export const statusOptions = [
  { label: 'New', value: 'new' },
  { label: 'Approved', value: 'approved' },
  { label: 'Rejected', value: 'rejected' },
];

export const reasonOptions = [
  { label: 'Inappropriate', value: 'inappropriate' },
  { label: 'Inquired property is not available', value: 'notAvailable' },
  { label: 'Inquiry sent directly from the client', value: 'direct' },
  { label: 'Client rejected the offer', value: 'rejected' },
  { label: 'Could not reach the client', value: 'couldNotReach' },
  { label: 'Others', value: 'others' },
];
