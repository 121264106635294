import React from "react";
import PropTypes from "prop-types";
import { AutocompletePaginateField } from "../field";
import { useDispatch } from "react-redux";
import { fetchAutocompleteFeature } from "../../services/featuresProperty.service";

const AutocompleteFeatures = ({
  control,
  name = "features",
  label = "Features",
  ...props
}) => {
  const dispatch = useDispatch();

  const loadOptions = async (search, prevOptions) => {
    let filteredOptions = [];
    let hasMore = false;

    const payload = {
      search: search,
      offset: Math.max(prevOptions.length, prevOptions.length - 1),
    };

    const result = await dispatch(fetchAutocompleteFeature(payload));

    if (result.meta.requestStatus === "fulfilled") {
      filteredOptions = result.payload.data;
      hasMore = result.payload.meta.hasNext;
    }

    return {
      options: filteredOptions,
      hasMore,
    };
  };

  return (
    <AutocompletePaginateField
      name={name}
      label={label}
      control={control}
      loadOptions={loadOptions}
      {...props}
    />
  );
};

AutocompleteFeatures.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default AutocompleteFeatures;
