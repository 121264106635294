import { createSlice } from "@reduxjs/toolkit";

import {
  fetchTag,
  fetchAutocompleteTag,
  createTag,
  deleteTag,
  updateTag
} from "../services/tagsProperty.service";

const initialState = {
  tags: [],
  autocomplete: [],
  meta: {
    nextOffset: 0,
    prevOffset: 0,
    hasNext: false,
    hasPrev: false,
    totalRecords: 0
  },
  loading: false,
  loadingAutocomplete: false,
  loadingCreate: false,
  loadingDelete: false,
  loadingUpdate: false,
  error: null
};

const tagsPropertySlice = createSlice({
  name: "tagsProperty",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // FETCH AUTOCOMPLETE
    builder.addCase(fetchAutocompleteTag.pending, (state) => {
      state.loadingAutocomplete = true;
    })

    builder.addCase(fetchAutocompleteTag.fulfilled, (state, action) => {
      state.loadingAutocomplete = false;
      state.meta = action.payload.meta;
      state.autocomplete = action.payload.data;
    })

    builder.addCase(fetchAutocompleteTag.rejected, (state, action) => {
      state.loadingAutocomplete = false;
      state.error = action.payload;
    })

    // FETCH
    builder.addCase(fetchTag.pending, (state) => {
      state.loading = true;
    })

    builder.addCase(fetchTag.fulfilled, (state, action) => {
      state.loading = false;
      state.meta = action.payload.meta;
      state.tags = action.payload.data;
    })

    builder.addCase(fetchTag.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })

    // CREATE
    builder.addCase(createTag.pending, (state) => {
      state.loadingCreate = true;
    })

    builder.addCase(createTag.fulfilled, (state, action) => {
      state.loadingCreate = false;
      state.meta.totalRecords = state.meta.totalRecords + 1;
      state.tags = [action.payload.data, ...state.tags];
    })

    builder.addCase(createTag.rejected, (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    })

    // DELETE
    builder.addCase(deleteTag.pending, (state) => {
      state.loadingDelete = true;
    })

    builder.addCase(deleteTag.fulfilled, (state) => {
      state.loadingDelete = false;
    })

    builder.addCase(deleteTag.rejected, (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
    })

    // UPDATE
    builder.addCase(updateTag.pending, (state) => {
      state.loadingUpdate = true;
    })

    builder.addCase(updateTag.fulfilled, (state) => {
      state.loadingUpdate = false;
    })

    builder.addCase(updateTag.rejected, (state, action) => {
      state.loadingUpdate = false;
      state.error = action.payload;
    })
  }
});

export default tagsPropertySlice.reducer;
