import { api, deleteMutation } from "../api";

const endpoint = "/v1/regions";
const endpointV2 = "/v2/regions";

export const regionsApi = api.injectEndpoints({
  tagTypes: ["Region"],
  endpoints: (build) => ({
    autocompleteRegions: build.query({
      query: (payload) => ({
        url: `${endpoint}/autocomplete`,
        method: "get",
        params: {
          limit: 20,
          ...payload,
        },
      }),
    }),

    getRegions: build.query({
      query: (payload) => ({
        url: `${endpoint}`,
        method: "GET",
        params: {
          pageSize: 20,
          ...payload,
        },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        if (currentCache.data && arg?.nextCursor) {
          return {
            ...currentCache,
            ...newItems,
            data: [...currentCache.data, ...newItems.data],
          };
        }

        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),

    getRegion: build.query({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "GET",
      }),
    }),

    addRegion: build.mutation({
      query: (newData) => ({
        url: `${endpoint}`,
        method: "POST",
        data: newData,
      }),
      async onQueryStarted(newData, { dispatch, queryFulfilled }) {
        try {
          const { data: createdData } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData("getRegions", undefined, (draft) => {
              draft.data.unshift(createdData.data);
            }),
          );
        } catch {}
      },
    }),

    updateRegion: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `${endpoint}/${id}`,
        method: "PATCH",
        data: patch,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedRegion } = await queryFulfilled;

          dispatch(
            api.util.updateQueryData("getRegions", undefined, (draft) => {
              let index = draft.data.findIndex((item) => item.id === args.id);
              draft.data[index] = updatedRegion.data;
            }),
          );
        } catch {}
      },
    }),

    deleteRegion: build.mutation({
      query: (id) => ({
        url: `${endpoint}/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData("getRegions", id, (draft) =>
            deleteMutation(id, draft),
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useLazyAutocompleteRegionsQuery,
  useGetRegionsQuery,
  useGetRegionQuery,
  useAddRegionMutation,
  useUpdateRegionMutation,
  useDeleteRegionMutation,
} = regionsApi;
