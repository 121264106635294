import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { MdOutlineLibraryAddCheck } from "react-icons/md";
import PropertyFeatureForm from "./PropertyFeatureForm";


const PopupAdd = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button leftIcon={<MdOutlineLibraryAddCheck style={{fontSize: "20px", color: "white"}} /> } onClick={onOpen} colorScheme={"primary"}>
        Add New Feature
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Feature</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PropertyFeatureForm onClose={onClose} />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default PopupAdd;
