import { Button, Box, Text, FormErrorMessage } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextHeader } from "components/layout/header";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "components/layout";
import { messageSuccessSave } from "components/messages";
import ROLE from "config/role";
import { useConfirm } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createProperty } from "services/properties.service";
import { macroid } from "utils/nanoid";
import PropertyForm from "../components/PropertyForm";
import { schema } from "../constant";

const prepareID = macroid();

const AddNewPropertyPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [loadingReview, setLoadingReview] = useState(false);
  const { isConfirmed } = useConfirm();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(),
  });

  const { me } = useSelector((state) => state?.auth);

  const { loadingCreate } = useSelector((state) => state?.properties);

  useEffect(() => {
    if (me?.role === ROLE.ADMIN || me?.role === ROLE.WEBSITE_AGENT) {
      setValue("status", "published");

      // Feedback from user: Delete responsible in property
      // takeout, new property always admin as a responsible
      // setValue("responsible", {
      //   label: me?.displayName,
      //   value: me?.uid,
      // });
    } else {
      setValue("status", "draft");
    }

    setValue("addedBy", {
      label: me?.displayName,
      value: me?.uid,
      role: me?.role,
    });
  }, []);

  const onAction = async (values, action) => {
    const confirmed = await isConfirmed();
    if (!confirmed) return;

    const payload = {
      id: prepareID,
      ...values,
    };

    if (action === "submitForReview") {
      payload.status = "new";
      setLoadingReview(true);
    } else if (action === "saveDraft") {
      payload.status = "draft";
      setLoadingDraft(true);
    }

    const res = await dispatch(createProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/properties");
      messageSuccessSave();
      setLoadingDraft(false);
      setLoadingReview(false);
    } else {
      setLoadingDraft(false);
      setLoadingReview(false);
    }
  };

  const onSave = async (values) => {
    const confirmed = await isConfirmed();
    if (!confirmed) return;

    const payload = {
      id: prepareID,
      ...values,
    };

    const res = await dispatch(createProperty(payload));
    if (res.meta.requestStatus === "fulfilled") {
      history.push("/properties");
      messageSuccessSave();
    }
  };

  // TODO: WEBSITE AGENT CANT ACCESS THIS, CREATE COMPONENT DONT HAVE PERMISSIONS
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          {/* Add New Property */}
          <TextHeader text="Add New Property" />
        </LayoutHeaderLeft>
      </LayoutHeader>

      {/* main body */}
      <LayoutBody>
        <PropertyForm
          control={control}
          setValue={setValue}
          prepareID={prepareID}
        />
      </LayoutBody>

      <LayoutBody>
        {[
          ROLE.PROPERTY_AGENT,
          ROLE.SERVICE_OFFICE_PROVIDER,
          ROLE.PROPERTY_OWNER,
          ROLE.PROPERTY_BROKER,
        ].includes(me?.role) && (
          <Fragment>
            <Button
              onClick={handleSubmit((values) =>
                onAction(values, "submitForReview")
              )}
              colorScheme="primary"
              isLoading={loadingReview}
            >
              Submit for Review
            </Button>
          </Fragment>
        )}
        {me?.role === ROLE.ADMIN && (
          <Button
            isDisabled={Object.keys(errors).length > 0}
            px={20}
            onClick={handleSubmit(onSave)}
            colorScheme="primary"
            isLoading={loadingCreate}
          >
            Submit
          </Button>
        )}

        {[
          ROLE.ADMIN,
          ROLE.PROPERTY_AGENT,
          ROLE.SERVICE_OFFICE_PROVIDER,
          ROLE.PROPERTY_OWNER,
          ROLE.PROPERTY_BROKER,
        ].includes(me?.role) && (
          <Button
            ml={4}
            colorScheme="gray"
            onClick={handleSubmit((values) => onAction(values, "saveDraft"))}
            isLoading={loadingDraft}
          >
            Save Draft
          </Button>
        )}

        {Object.keys(errors).length > 0 && (
          <Box mt="2">
            <Text color="red.500" fontSize="sm">
              *please check, you have some error fields
            </Text>
          </Box>
        )}
      </LayoutBody>
    </Layout>
  );
};

export default AddNewPropertyPage;
