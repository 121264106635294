import { createSlice } from "@reduxjs/toolkit";
import { createStandaloneToast } from "@chakra-ui/react";
const { toast } = createStandaloneToast();
import i18next from "i18next";

const initialState = {
  items: [],
};

const compareSlice = createSlice({
  name: "compare",
  initialState: initialState,
  reducers: {
    addCompare(state, action) {
      if (state.items.length >= 3) {
        toast({
          title: `${i18next.t("max_limit")}`,
          description: `${i18next.t("reached_the_maximum_compare_limit")}`,
          status: "warning",
          duration: 4000,
          position: "top-right",
        });
      } else {
        state.items.push(action.payload);
        toast({
          title: `${i18next.t("added_to_compare")}`,
          status: "success",
          duration: 2000,
          position: "top-right",
        });
      }
    },
    removeCompare(state, action) {
      state.items = state.items.filter(
        (item) => item.objectID !== action.payload.objectID,
      );
      toast({
        title: `${i18next.t("removed_from_compare")}`,
        status: "error",
        duration: 2000,
        position: "top-right",
      });
    },
  },
});

export const { addCompare, removeCompare, getTotalCompare } =
  compareSlice.actions;
export default compareSlice.reducer;
