import React from "react";
import Layout, {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "components/layout";
import { TextHeader } from "components/layout/header";
import { Grid, GridItem, Heading } from "@chakra-ui/react";
import Paper from "../../../components/paper";
import PropertyTagForm from "./components/PropertyTagForm";
import TagsTable from "./components/TagsTable";

const PropertyTagsPage = () => {
  return (
    <Layout>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <TextHeader text="Tags" />
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <Grid
          templateColumns={{ base: "repeat(3, 1fr)", lg: "repeat(6, 1fr)" }}
          gap={6}
        >
          <GridItem colSpan="2">
            <Paper>
              <Heading fontSize="lg" mb="8">
                Add New Tags
              </Heading>
              <PropertyTagForm />
            </Paper>
          </GridItem>
          <GridItem colSpan="4">
            <Paper>
              <TagsTable />
            </Paper>
          </GridItem>
        </Grid>
      </LayoutBody>
    </Layout>
  );
};

export default PropertyTagsPage;
