import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton
} from "@chakra-ui/react";
import PropertyFeatureForm from "./PropertyFeatureForm";
import PropTypes from "prop-types";
import { FaPen } from "react-icons/fa";

const PopupEdit = ({ buttonIcon, buttonSize, data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const preset = {
    colorScheme: "primary",
    size: buttonSize || "sm",
    onClick: onOpen
  };

  const defaultIcon = buttonIcon ? buttonIcon : <FaPen />;

  return (
    <>
      <IconButton aria-label="delete" icon={defaultIcon} {...preset} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Feature</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PropertyFeatureForm data={data} onClose={onClose} />
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

PopupEdit.propTypes = {
  buttonText: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonIcon: PropTypes.node,
  iconOnly: PropTypes.bool,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
  eventClick: PropTypes.func,
  data: PropTypes.object
};
export default PopupEdit;
