import {
  Link,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  useColorModeValue as mode,
  FormErrorMessage,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { FIELD_VARIANT } from "../../config/preset";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";
import { Link as ReachLink } from "react-router-dom";

const PasswordField = ({
  name,
  placeholder,
  control,
  label,
  isRequired = false,
  withForgot = false,
  forgotText = "Forgot Password?",
  disableAutoComplete = true,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef(null);
  const [readOnly, setReadOnly] = useState(disableAutoComplete);

  const {
    field: { ref, value, ...inputProps },
    fieldState: { invalid, isTouched, isDirty, error },
  } = useController({
    name,
    control,
    rules: { required: isRequired },
  });

  const mergeRef = useMergeRefs(inputRef, ref);

  const handleFocus = () => {
    setReadOnly(false);
  };

  const handleBlur = () => {
    setReadOnly(true);
  };

  const onClickReveal = () => {
    onToggle();
    const input = inputRef.current;
    if (input) {
      input.focus({ preventScroll: true });
      const length = input.value.length * 2;
      requestAnimationFrame(() => {
        input.setSelectionRange(length, length);
      });
    }
  };

  return (
    <FormControl isInvalid={invalid} isRequired={isRequired}>
      <Flex justify="space-between">
        <FormLabel>{label}</FormLabel>
        {withForgot && (
          <Link
            as={ReachLink}
            color={mode("primary.600", "primary.200")}
            fontWeight="semibold"
            fontSize="sm"
            to="/auth/forgot"
          >
            {forgotText}
          </Link>
        )}
      </Flex>
      <InputGroup>
        <InputRightElement>
          <IconButton
            bg="transparent !important"
            variant="ghost"
            aria-label={isOpen ? "Mask password" : "Reveal password"}
            icon={isOpen ? <FiEyeOff /> : <FiEye />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          ref={mergeRef}
          name={name}
          type={isOpen ? "text" : "password"}
          autoComplete="current-password"
          value={value || ""}
          placeholder={placeholder}
          variant={FIELD_VARIANT.default}
          isReadOnly={disableAutoComplete ? readOnly : undefined} // fixing bug can't deactivate autofill from browser
          {...inputProps}
          onFocus={disableAutoComplete ? handleFocus : undefined}
          onBlur={disableAutoComplete ? handleBlur : undefined}
        />
      </InputGroup>
      <FormErrorMessage>{invalid && error.message}</FormErrorMessage>
    </FormControl>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  forgotText: PropTypes.string,
  isRequired: PropTypes.bool,
  withForgot: PropTypes.bool,
  disableAutoComplete: PropTypes.bool,
};

export default PasswordField;
