import { extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig } from 'chakra-ui-steps';

const CustomSteps = {
  ...StepsStyleConfig,
  baseStyle: (props) => {
    return {
      ...StepsStyleConfig.baseStyle(props),
      iconLabel: {
        ...StepsStyleConfig.baseStyle(props).iconLabel,
        color: 'black',
      },
      label: {
        ...StepsStyleConfig.baseStyle(props).label,
        color: 'white',
      },
    };
  },
};

const formStyles = {
  parts: ['container', 'requiredIndicator', 'helperText'],
  baseStyle: {
    container: {
      label: {
        fontSize: 'sm',
      },
    },
  },
};

// const lang = localStorage.getItem("i18nextLng");
// const direction = lang === "ar" ? "rtl" : "ltr";

// color generator
// https://www.tints.dev/black/3B3B3B

const theme = extendTheme({
  fonts: {
    body: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
    heading: `Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"`,
  },
  components: {
    Steps: CustomSteps,
    Form: formStyles,
    Button: {
      defaultProps: {
        size: 'sm',
      },
    },
  },
  space: {
    sticky: {
      sm: '50px',
      md: '67px',
      lg: '100px',
    },
  },
  colors: {
    brand: {
      dark: '#222222',
      header: '#2c2c2c',
      footer: '#2c2c2c',
      red: '#990001',
      platinum: '#E7E4E4',
      cultured: '#f8f5f5',
    },
    primary: {
      50: '#F7F4EE',
      100: '#E8E0CF',
      200: '#D9CDB0',
      300: '#CAB991',
      400: '#BBA572',
      500: '#AC9253',
      600: '#897443',
      700: '#675732',
      800: '#453A21',
      900: '#221D11',
    },
    black: {
      50: '#EBEBEB',
      100: '#D9D9D9',
      200: '#B0B0B0',
      300: '#8A8A8A',
      400: '#616161',
      500: '#3B3B3B',
      600: '#2E2E2E',
      700: '#242424',
      800: '#171717',
      900: '#0D0D0D',
      950: '#050505',
    },
  },
});

export default theme;
