import {
  Box,
  Container,
  Divider,
  Stack,
  useColorModeValue as mode,
  useDisclosure,
} from "@chakra-ui/react";
import { LazyMotion, domAnimation, m } from "framer-motion";
import PropTypes from "prop-types";
import Header from "../header";
import Sidebar from "../sidebar";
import { RtlProvider } from "../RtlProvider";

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const Layout = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <RtlProvider direction="ltr">
      <Box bg={mode("gray.100", "inherit")} minH="100vh">
        <Header toggleMobileMenu={onOpen} />
        <Box>
          <Sidebar isOpen={isOpen} handleClose={onClose} />
          <Box
            ml={{ base: "0", lg: "320px" }}
            pt={{ base: "80px", lg: "100px" }}
            pb="6rem"
          >
            <Container maxW="container.xl">{children}</Container>
          </Box>
        </Box>
      </Box>
    </RtlProvider>
  );
};

export const LayoutHeader = ({ children }) => (
  <Stack
    direction={["column", "row"]}
    justifyContent="space-between"
    flexWrap="wrap"
  >
    {children}
  </Stack>
);

export const LayoutHeaderLeft = ({ children }) => (
  <Stack
    direction="column"
    w={{ base: "full", lg: "lg" }}
    mb={{ base: "2", md: "4", lg: "0" }}
  >
    {children}
    <Divider display={["flex", "none"]} />
  </Stack>
);

export const LayoutHeaderRight = ({ children }) => (
  <Stack direction="row" justifyContent="flex-end" flex="1 1 0%">
    {children}
  </Stack>
);

export const LayoutBody = ({ children, isCard, ...props }) => (
  <LazyMotion features={domAnimation}>
    <m.div initial="initial" animate="in" exit="out" variants={pageVariants}>
      <Box
        mt="8"
        __css={
          isCard && { bg: "white", p: "6", borderRadius: "md", boxShadow: "md" }
        }
        {...props}
      >
        {children}
      </Box>
    </m.div>
  </LazyMotion>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LayoutHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LayoutHeaderLeft.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LayoutHeaderRight.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

LayoutBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isCard: PropTypes.bool,
};

export default Layout;
