import { createSlice } from "@reduxjs/toolkit";

import {
  fetchProperties,
  fetchAutocompleteProperties,
  fetchPropertyById,
  createProperty,
  updateProperty,
  delegateProperty,
  deleteProperty,
  upload,
  uploadGallery,
} from "../services/properties.service";

const initialState = {
  properties: [],
  property: null,
  autocomplete: [],
  meta: {
    nextOffset: 0,
    prevOffset: 0,
    hasNext: false,
    hasPrev: false,
    // totalRecords: 0
  },
  loading: false,
  loadingSingle: false,
  loadingAutocomplete: false,
  loadingCreate: false,
  loadingDelete: false,
  loadingUpdate: false,
  loadingDelegate: false,
  loadingUpload: false,
  loadingUploadGallery: false,
  error: null,
};

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // FETCH AUTOCOMPLETE
    builder.addCase(fetchAutocompleteProperties.pending, (state) => {
      state.loadingAutocomplete = true;
    });

    builder.addCase(fetchAutocompleteProperties.fulfilled, (state, action) => {
      state.loadingAutocomplete = false;
      const { data } = action.payload;
      state.autocomplete = data;
    });

    builder.addCase(fetchAutocompleteProperties.rejected, (state, action) => {
      state.loadingAutocomplete = false;
      state.error = action.payload;
    });

    // FETCH
    builder.addCase(fetchProperties.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchProperties.fulfilled, (state, action) => {
      state.loading = false;
      state.meta = action.payload.meta;
      state.properties = action.payload.data;
    });

    builder.addCase(fetchProperties.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // FETCH BY ID
    builder.addCase(fetchPropertyById.pending, (state) => {
      state.loadingSingle = true;
    });

    builder.addCase(fetchPropertyById.fulfilled, (state, action) => {
      state.loadingSingle = false;
      state.meta = action.payload.meta;
      state.property = action.payload.data;
    });

    builder.addCase(fetchPropertyById.rejected, (state, action) => {
      state.loadingSingle = false;
      state.error = action.payload;
    });

    // CREATE
    builder.addCase(createProperty.pending, (state) => {
      state.loadingCreate = true;
    });

    builder.addCase(createProperty.fulfilled, (state, action) => {
      state.loadingCreate = false;
      // state.meta.totalRecords = state.meta.totalRecords + 1;
      state.properties = [action.payload.data, ...state.properties];
    });

    builder.addCase(createProperty.rejected, (state, action) => {
      state.loadingCreate = false;
      state.error = action.payload;
    });

    // DELETE
    builder.addCase(deleteProperty.pending, (state) => {
      state.loadingDelete = true;
    });

    builder.addCase(deleteProperty.fulfilled, (state) => {
      state.loadingDelete = false;
    });

    builder.addCase(deleteProperty.rejected, (state, action) => {
      state.loadingDelete = false;
      state.error = action.payload;
    });

    // UPDATE
    builder.addCase(updateProperty.pending, (state) => {
      state.loadingUpdate = true;
    });

    builder.addCase(updateProperty.fulfilled, (state) => {
      state.loadingUpdate = false;
    });

    builder.addCase(updateProperty.rejected, (state, action) => {
      state.loadingUpdate = false;
      state.error = action.payload;
    });

    // DELEGATE
    builder.addCase(delegateProperty.pending, (state) => {
      state.loadingDelegate = true;
    });

    builder.addCase(delegateProperty.fulfilled, (state) => {
      state.loadingDelegate = false;
    });

    builder.addCase(delegateProperty.rejected, (state, action) => {
      state.loadingDelegate = false;
      state.error = action.payload;
    });

    // UPLOAD
    builder.addCase(upload.pending, (state) => {
      state.loadingUpload = true;
    });

    builder.addCase(upload.fulfilled, (state) => {
      state.loadingUpload = false;
    });

    builder.addCase(upload.rejected, (state, action) => {
      state.loadingUpload = false;
      state.error = action.payload;
    });

    // UPLOAD GALLERY
    builder.addCase(uploadGallery.pending, (state) => {
      state.loadingUploadGallery = true;
    });

    builder.addCase(uploadGallery.fulfilled, (state) => {
      state.loadingUploadGallery = false;
    });

    builder.addCase(uploadGallery.rejected, (state, action) => {
      state.loadingUploadGallery = false;
      state.error = action.payload;
    });
  },
});

export default propertiesSlice.reducer;
