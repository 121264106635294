import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useDeferredValue,
} from "react";
import { useGetLocationsQuery } from "services/location/locations.services";
import { Checkbox, Stack, Text } from "@chakra-ui/react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuerySearch } from "hooks";

const FilterLocation = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const ARABIC = language === "ar";
  const history = useHistory();
  const location = useLocation();
  const querySearch = useQuerySearch();

  const { data: locations, isLoading, isFetching } = useGetLocationsQuery();

  const countryFromQuery = useMemo(() => {
    return querySearch.country ? querySearch.country.toString().split(",") : [];
  }, [querySearch.country]);

  const regionFromQuery = useMemo(() => {
    return querySearch.region ? querySearch.region.toString().split(",") : [];
  }, [querySearch.region]);

  const cityFromQuery = useMemo(() => {
    return querySearch.city ? querySearch.city.toString().split(",") : [];
  }, [querySearch.city]);

  const [filterCountry, setFilterCountry] = useState(countryFromQuery);
  const deferredFilterCountry = useDeferredValue(filterCountry);

  const [filterRegion, setFilterRegion] = useState(regionFromQuery);
  const deferredFilterRegion = useDeferredValue(filterRegion);

  const [filterCity, setFilterCity] = useState(cityFromQuery);
  const deferredFilterCity = useDeferredValue(filterCity);

  // RESET FILTER LOCATION WHEN DATA NOT FOUND
  useEffect(() => {
    if (deferredFilterCountry.toString() !== countryFromQuery.toString()) {
      setFilterCountry(countryFromQuery);
    }

    if (deferredFilterRegion.toString() !== regionFromQuery.toString()) {
      setFilterRegion(regionFromQuery);
    }

    if (deferredFilterCity.toString() !== cityFromQuery.toString()) {
      setFilterCity(cityFromQuery);
    }
  }, [location.pathname, location.search]);

  const loadChangedByURI = useCallback(
    (params) => {
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(params),
      });
    },
    [history, location.pathname],
  );

  useEffect(() => {
    const params = {
      ...querySearch,
      country: deferredFilterCountry.toString(),
      region: deferredFilterRegion.toString(),
      city: deferredFilterCity.toString(),
    };

    // removed key from params when length is 0
    if (deferredFilterCountry && deferredFilterCountry.length === 0) {
      delete params.country;
    }

    if (deferredFilterRegion && deferredFilterRegion.length === 0) {
      delete params.region;
    }

    if (deferredFilterCity && deferredFilterCity.length === 0) {
      delete params.city;
    }

    loadChangedByURI(params);
  }, [
    deferredFilterCountry,
    deferredFilterRegion,
    deferredFilterCity,
    loadChangedByURI,
  ]);

  const handleFilter = useCallback(
    (type, value) => {
      switch (type) {
        case "country":
          const dupeCountry = deferredFilterCountry.includes(value);

          if (dupeCountry) {
            setFilterCountry(
              deferredFilterCountry.filter((item) => item !== value),
            );
          } else {
            setFilterCountry([...deferredFilterCountry, value]);
          }
          break;
        case "region":
          const dupeRegion = deferredFilterRegion.includes(value);

          if (dupeRegion) {
            setFilterRegion(
              deferredFilterRegion.filter((item) => item !== value),
            );
          } else {
            setFilterRegion([...deferredFilterRegion, value]);
          }
          break;
        case "city":
          const dupeCity = deferredFilterCity.includes(value);

          if (dupeCity) {
            setFilterCity(deferredFilterCity.filter((item) => item !== value));
          } else {
            setFilterCity([...deferredFilterCity, value]);
          }
          break;
      }
    },
    [deferredFilterCountry, deferredFilterCity, deferredFilterRegion],
  );

  return (
    <Stack>
      {locations &&
        locations.countries?.map((country, index) => (
          <Stack key={country.id}>
            <Checkbox
              colorScheme="primary"
              value={country.name}
              onChange={(e) => handleFilter("country", e.target.value)}
              isChecked={deferredFilterCountry.includes(country.name)}
            >
              <Text textTransform="capitalize">
                {ARABIC ? country.ar_name : country.name}
              </Text>
            </Checkbox>

            {country.regions.map((region, index) => (
              <Stack pl={6} key={region.id}>
                <Checkbox
                  colorScheme="primary"
                  value={region.name}
                  onChange={(e) => handleFilter("region", e.target.value)}
                  isChecked={deferredFilterRegion.includes(region.name)}
                >
                  <Text textTransform="capitalize">
                    {ARABIC ? region.ar_name : region.name}
                  </Text>
                </Checkbox>

                {region.cities.map((city, index) => (
                  <Stack pl={6} key={city.id}>
                    <Checkbox
                      colorScheme="primary"
                      value={city.name}
                      onChange={(e) => handleFilter("city", e.target.value)}
                      isChecked={deferredFilterCity.includes(city.name)}
                    >
                      <Text textTransform="capitalize">
                        {ARABIC ? city.ar_name : city.name}
                      </Text>
                    </Checkbox>
                  </Stack>
                ))}
              </Stack>
            ))}
          </Stack>
        ))}

      {locations && locations.countries.length === 0 && isLoading && (
        <Text mt="4" color="gray.400">
          {t("common:label.getting_data")}
        </Text>
      )}
    </Stack>
  );
};

export default memo(FilterLocation);
