import React from "react";
import { IconButton } from "@chakra-ui/react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useHistory } from "react-router-dom";

const BackButtonHeader = ({ route }) => {
  const history = useHistory();

  return (
    <IconButton
      icon={<FaArrowLeftLong />}
      onClick={() => history.push(route)}
    />
  );
};

export default BackButtonHeader;
