import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { upperCaseEachWord } from "utils/textTransform";
import dayjs from "dayjs";

const HeaderInfo = () => {
  const { me } = useSelector((state) => state?.auth);

  const role = me?.role === "admin" ? "Administrator" : me?.role;
  const resultString = upperCaseEachWord(role.replace(/_/g, " "));

  return (
    <Box
      mx="2"
      p="2"
      mb="2"
      userSelect="none"
      bg="gray.50"
      border="1px"
      borderColor="gray.300"
      borderRadius="4"
      sx={{
        ":hover": {
          bg: "gray.100", // Change the background color on hover
        },
      }}
    >
      <Box display="flex" flexDirection="column">
        <Text fontSize="md" fontWeight="semibold">
          {resultString}
        </Text>
        <Text color="gray.500" fontSize="sm">
          Joined {dayjs(me?.createdAt).format("LL")}
        </Text>
      </Box>
    </Box>
  );
};

export default HeaderInfo;
