import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Link,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useHoldSpecialRequestMutation,
  useReopenSpecialRequestMutation,
} from "services/specialRequest.services";
import { messageSuccessUpdate } from "components/messages";

const DialogHoldReopenRequest = ({ data, refetch }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  console.log(data);

  const [holdSpecialRequest, { isLoading }] = useHoldSpecialRequestMutation();

  const [reopenSpecialRequest, { isLoading: reopenLoading }] =
    useReopenSpecialRequestMutation();

  const handleAction = async () => {
    if (data?.statusByAdmin === "hold") {
      await reopenSpecialRequest(data?.id).unwrap();
    } else {
      await holdSpecialRequest(data?.id).unwrap();
    }

    messageSuccessUpdate();

    refetch();
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen} size="sm">
        {data?.statusByAdmin === "hold" ? "Reopen" : "Hold"}
      </Button>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg">
            {data?.statusByAdmin === "hold" ? "Re-open" : "Hold"} Special
            Request
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {data?.statusByAdmin !== "hold" && (
              <Text mb="1">
                Are you sure you want to hold this special request? This will
                disable action Respond, Forward, and Process.
              </Text>
            )}

            {data?.statusByAdmin === "hold" && (
              <Text mb="1">
                Are you sure you want to re-open this special request?
              </Text>
            )}

            <Link
              color="blue.500"
              href={`/property/${data?.complaintProperty?.value}`}
              isExternal
            >
              {data?.complaintProperty?.label}
            </Link>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} size="sm">
              Cancel
            </Button>
            <Button
              colorScheme="primary"
              ml={3}
              size="sm"
              onClick={handleAction}
              isLoading={isLoading || reopenLoading}
            >
              Submit
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DialogHoldReopenRequest;
