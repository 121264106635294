import { api } from "./api";

export const mailApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendMail: build.mutation({
      query: (payload) => ({
        url: `/send-mail`,
        method: "POST",
        data: payload,
      }),
    }),
  }),
});

export const { useSendMailMutation } = mailApi;
